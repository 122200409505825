.events-place {
    overflow-x: hidden;
    &--item {
        &-img {
            position: relative;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            min-height: 500px;
            height: 100%;
        }
        &-content {
            min-height: 500px;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-price {
            position: absolute;
            width: 94px;
            height: 94px;
            border-radius: 50%;
            background-color: #fff;
            font-size: 14px;
            top: 31px;
            left: 31px;
            .price-value {
                font-size: 24px;
            }
        }
        &-details {
            margin-top: auto;
            font-size: 14px;
        }
        @media screen and (max-width: 576px) {
            &-details {
                margin-top: 30px;
            }
            &-content {
                min-height: auto;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        &--item {
            &-content {
                padding-left: 0px;
            }
        }
    }
}


@media screen and (max-width: 1199px) {
    .no-padding {
        padding: 0!important;
    }
}
