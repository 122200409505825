.aheto-header-5 {
  .dropdown-btn {
    &:before {
      content: '\f104';
      font-size: 9px;
      position: relative;
      top: 1px;
    }
  }

  &__line {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    max-width: 1750px;
    margin: 0 auto;
  }

  &__logo {
    padding: 10px 15px;

    @include menu-size-min {
      flex: 1 1 0;
    }
  }

  &__menu {
    position: relative;
    padding: 0 15px;
    z-index: 1;

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - 57px);
      padding: 30px 25px;
      background-color: $c-white;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  &__buttons {
    display: flex;
    padding: 16px 15px 14px;

    @include menu-size-min {
      flex: 1 1 0;
      justify-content: flex-end;
    }

    @include menu-size-max {
      margin-left: auto;
    }
  }

  &__button {
    margin-right: 15px;

    @media screen and (min-width: 1500px) {
      margin-right: 20px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__button-link {
    position: relative;
  }

  &__button-icon {

    &--search {
      &:before {
        font-family: $f-elegant;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        content: '\55';
      }
    }

    &--basket {
      &:before {
        font-family: $f-elegant;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        content: "\e015";
      }
    }
  }

  &__button-number {
    position: absolute;
    top: -13px;
    right: -11px;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
    color: $c-white;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
  }

  &__hamburger {
    @include menu-size-min {
      display: none;
    }
  }

  // Menu
  .main-menu {

    .menu-item {
      position: relative;
      a {
        display: inline-block;
        font-weight: 500;
      }
    }

    @include menu-size-min {
      display: flex;
      flex-wrap: wrap;

      > .menu-item {
        margin-right: 35px;

        &:last-child {
          margin-right: 0;
        }

        > a {
          &.active {
            color: #7FBC48;
          }
          padding: 23px 0 20px;
        }

        &:before {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          width: 0;
          height: 5px;
          transition: width 0.2s;
          content: '';
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }

      .menu-item {

        &.active {
          > a {
            color: #7FBC48;
          }

          &:after {
            position: absolute;
            display: block;
            content: "";
            bottom: 0;
            left: 0;
            right: 0;
            height: 5px;
            background-color: #7FBC48;
          }
        }

        a {
          font-size: 15px;
          position: relative;
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @include menu-size-max {
      overflow: hidden;
      .menu-item {
        a {
          font-size: 16px;
        }
      }

      > .menu-item {
        padding-bottom: 20px;
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;

      .menu-item {
        display: flex;
        align-items: center;
        min-width: 225px;
        padding: 0 30px;
        border-bottom: 1px solid rgba($c-black, 0.04);

        a {
          flex: 1 1 0;
          padding: 18px 0 15px;
        }
      }

      .sub-menu {
        top: 50%;
        left: calc(100% + 4px);
      }

      .dropdown-btn {
        vertical-align: center;

        &:before {
          content: '\f10a';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 20px 25px 0;
        a {
          font-size: 15px;
          &:hover {
            color: #7FBC48;
          }
          &.active {
            color: #7FBC48;
          }
        }
      }

      .dropdown-btn {
        display: inline-block;
        font-size: 10px;
        line-height: 24px;
        vertical-align: top;
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      border-top: 0;
      background-color: transparent;

      &__title {
        font-size: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.04);
      }

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-black, 0.04);
        background-color: $c-white;

        &:last-child {
          border-right: 0;
        }
      }

      .menu-item {
        padding: 0;
        border-bottom: 0;

        a {
          padding: 10px 0 0;
        }
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        margin: 15px 25px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.04);
        font-size: 20px;
      }
    }
  }

  // Dropdown btn
  .dropdown-btn {
    margin-left: 10px;
  }

  // Logo
  .logo {
    &__text {
      font-size: 26px;
      font-weight: 700;
    }
  }
}
