.aht-sidebar-yoga {

  .widget {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      text-align: left;
      color: #555;
    }
  }

  .yoga--widget_categories {
    .widget-title {
      font-family: $f-futura;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: #555;
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }

  // Categories widget
  .widget_categories {

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 35px;
      margin-bottom: 0;
    }

    li {
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 4px;
      padding-left: 0;
      line-height: 18px;

      &:before {
        content: none;
      }
    }

    a {
      display: inline-block;
      padding: 3px 18px;
      border: 1px solid #eee;
      border-radius: 18px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      line-height: 21px;
      height: 26px;

      &:hover {
        color: $c-white;
      }
    }
  }


  // Search widget
  .search {

    &-form {
      position: relative;
    }

    &-field,
    &-submit {
      border: 0;
      outline: 0;
    }

    &-field {
      width: 100%;
      padding: 20px;
      transition: all 0.2s ease-out;
      border: 1px solid #eee;
      border-radius: 5px;
      background-color: $c-white;
      font-weight: 500;
      height: 60px;
    }

    &-submit {
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 15px;
      padding: 5px;
      line-height: 53px !important;
      // transform: translate(0, -50%);
      background-color: $c-white;
      cursor: pointer;

      span {
        margin-top: 2px;

        &:before {
          color: #222;
        }
      }
    }

    .screen-reader-text {
      display: none;
    }
  }
  
  // Advertisement
  .aht-widget-advert {
    height: 300px;
    width: 300px;
    padding: 28px 30px 35px;
    background-position: center;
    background-size: cover;
    text-align: center;

    @include large-size-max {
      max-width: 300px;
      width: auto;
      margin: 0 auto;
      padding-right: 5px;
      padding-left: 5px;
    }

    &__subtitle {
      margin: 0 0 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.86;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 15px;
      line-height: 0.88;
      letter-spacing: 1.6px;
    }

    &__desc-wrap {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      min-height: 120px;
    }

    &__desc {
      margin: 23px 0;
      letter-spacing: 1.6px;
    }

    &__link {
      &:hover {
        color: $c-white;
      }
    }
  }

  // Posts
  .aht-widget-posts {

    &__buttons {

      display: flex;
     //box-shadow: 0px 15px 30px 0 #2a74ed19;
      background-color: #ffffff;


  }

    &__button {
      flex: 1;
      padding: 17px 5px 13px;
      transition: all 0.3s ease-out;
      border-top: 0;
      border-right: 1px solid #eee;
      border-bottom: 2px solid $c-white;
      border-left: 0;
      outline: none;
      background-color: $c-white;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      line-height: 2;
      font-family: $f-futura;

      &:last-child {
        border-right: 0;
      }
    }
    
    &__list {
      display: none;
      margin-top: 40px;
      margin-bottom: 0;
      
      &.active {
        display: block;
      }
    }

    &__item {
      margin-bottom: 30px;
      padding-left: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '';
      }
    }

    &__item-inner {
      display: flex;

    }

    &__img {
      width: 80px;
      height: 80px;
      margin-right: 19px;
      background-position: center;
      background-size: cover;
    }

    &__img-link {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    &__text {
      flex: 1;
      line-height: 20px;
    }

    &__title {
      display: inline-block;
      margin: -3px 0 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      font-family: $f-futura;
    }

    &__date {
      margin: 8px 0 0;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.73;
    }
  }
  
}
