.aheto-video {
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  overflow: hidden;

  &--big {
    height: 798px;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0;
    @include medium-size-max {
      height: 500px;
    }
  }

  &__cover,
  &__cover img,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__mask {
    opacity: 0.78;
  }

  &__cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
    transition: 1s;
    text-align: center;
    opacity: 1;
    visibility: visible;
    z-index: 3;

    img {
      object-fit: cover;
    }
  }

  &__play {
    display: block;
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    color: $c-white;
    font-size: 30px;
    line-height: 100px;
    text-align: center;

    &:hover {
      color: $c-white;

     &:before {
      top: -8%;
      left: -8%;
      width: 116%;
      height: 116%;
    }
  }

    &:before {
      content: "";
      background: transparent;
      position: absolute;
      top: -0%;
      left: -0%;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      transition: all .3s;
    }

    @include small-size-max {
      width: 80px;
      height: 80px;
      font-size: 25px;
      line-height: 80px;
    }

    @include xs-size-max {
      width: 60px;
      height: 60px;
      font-size: 20px;
      line-height: 60px;
    }
  }

  &__pause {
    display: block;
    position: absolute;
    top: 15px;
    left: 15px;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    transition: .5s;
    border-radius: 50%;
    color: #fff;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 5;
    &:hover {
      color:$c-white;
    }
    i {
        &:hover {
          color:$c-white;
        }
    }
    @include xs-size-max {
      width: 30px;
      height: 30px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  &.play {
    &:hover {
      .aheto-video__pause {
        opacity: 1;
        visibility: visible;
      }
    }

    @include medium-size-max {
      .aheto-video__pause {
        opacity: 1;
        visibility: visible;
      }
    }

    .aheto-video__cover {
      opacity: 0;
      visibility: hidden;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    position: relative;
    margin-bottom: 0;
    color: #fff;
  }

  iframe {
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    transform: translate(-50%, -50%);
    border: 0;
  }
}

.aht-video {

  @include large-size-min {
    &--play {
      &-top {
        .aht-video__link {
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-right {
        .aht-video__link {
          top: 50%;
          right: 0;
          transform: translate(50%, -50%);
        }
      }

      &-bottom {
        .aht-video__link {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }

      &-left {
        .aht-video__link {
          top: 50%;
          left: 0;
          transform: translate(-50%, -50%);
        }
      }

      &-center {
        .aht-video__link {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &--trvl,
  &--construction {
    position: relative;
    box-shadow: 0px 0px 68px 20px rgba($c-black, 0.1);

    .aht-video {
      &__img {
        height: 460px;
        background-position: center;
        background-size: cover;
        border-radius: 5px;
        // box-shadow: inset 0px 0px 7px 3px rgba($c-black, 0.3);
        @include xs-size-max {
          height: 350px;
        }
      }

      &__link {
        display: inline-block;
        position: absolute;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        outline: none;
        background-color: $c-white;
        font-size: 20px;
        line-height: 90px;
        text-align: center;
        box-shadow: 0px 0px 35px 0px rgba($c-black, 0.13);

        @include large-size-max {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &--construction {
    .aht-video {
      &__img {
        height: 580px;
        border-radius: 0;
        @include medium-size-max {
          height: 400px;
        }
        @include xs-size-max {
          height: 350px;
        }
      }
    }
  }

}
.section-video{
  background: linear-gradient(to top, #eff4ff 50%, #fff 50%);
  .aht-video{
    &--political {
      position: relative;
      box-shadow: 0px 27px 47.9px 3.1px rgba($c-black, 0.25);
      border-radius: 5px;
      .aht-video {
        &__img {
          height: 600px;
          background-position: center;
          background-size: cover;
          border-radius: 5px;
          &:before{
            content: '';
            position: absolute;
            z-index: 11;
            width: 100%;
            height: 100%;
            background: rgba($c-black, 0.6);
            border-radius: 5px;
          }
          @include xs-size-max {
            height: 350px;
          }
        }

        &__link {
          display: inline-block;
          position: absolute;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          outline: none;
          background-color: $c-white;
          font-size: 20px;
          line-height: 99px;
          text-align: center;
          z-index: 12;
          &:hover{
            color: #0536a5;
            &:before {
              width: 100px;
              height: 100px;
              transform: translate(0px, 0px);
            }
          }
          &:before{
            content: '';
            position: absolute;
            z-index: 12;
            width: 116px;
            height: 116px;
            opacity: 0.5;
            border: solid 1px $c-white;
            border-radius: 50%;
            left: 0%;
            top: 0%;
            transform: translate(-8px, -8px);
            transition: all 0.5s ease-in-out;
          }
          @include large-size-max {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}


// home creative agency


.about-us-agency {
  &--mrg {
    margin-top: -5vh;
  }
  .section-video {
    background: transparent;
    .aht-video--agency {
      border-radius: 0;
      box-shadow: none;
      .aht-video__img {
        max-height: 500px;
        border-radius: 0;
        &:before {
          display: none;
        }
      }
      .aht-video__link {
        position: absolute;
        top: 0;
        left: 50%;
        // transform: translate(-50px, -50px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;
        color: $c-light;
        background-image: linear-gradient(330deg, #1200ff, #d800ff);
        &:before {
          display: none;
        }
      }
    }
  }
}
