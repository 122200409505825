.aht-course-det {
  &--edu {

    .aht-course-det {

      &__thumb {
        margin-bottom: 50px;
      }

      &__img {
        width: 100%;
      }

      &__ath {
        margin-top: 40px;

        &-about {
          margin: 0 0 35px;
          font-weight: 700;
        }

        &-main {
          display: flex;
        }

        &-img {
          width: 95px;
          height: 95px;
          margin-right: 20px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
        }

        &-name {
          margin: 16px 0 0;
          font-size: 18px;
          font-weight: 700;
        }

        &-pos {
          margin: 2px 0 0;
          font-size: 14px;
          font-weight: 500;
        }

        &-soc {
          margin-top: 15px;
        }

        &-desc {
          margin: 26px 0 0;
        }
      }

      &__content {

        b {
          font-weight: inherit;
        }

        p {
          margin: 24px 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 27px;
        }

        img {
          max-width: 100%;
        }
      }

      &__cmnts {
        margin-top: 35px;
        border-top: 1px solid #eee;

        h4 {
          margin: 35px 0 -5px;
          font-weight: 700;
        }

        p {
          margin: 0;

          &:empty {
            display: none;
          }
        }

        ul {
          margin: 0;
        }

        li {
          padding-left: 0;

          &:before {
            content: none;
          }
        }

        .comment {
          padding: 40px 0 28px;
          border-bottom: 1px solid #eee;
        }

        .comm {
          &-block {
            display: flex;

            @include xs-size-max {
              flex-direction: column;
            }
          }

          &-img {
            min-width: 75px;
            margin-right: 20px;

            @include xs-size-max {
              margin-bottom: 30px;
            }

            img {
              display: block;
              width: 75px;
              height: 75px;
              border-radius: 50%;

              @include xs-size-max {
                margin: 0 auto;
              }
            }
          }
        }

        .author {
          margin: 0;
          font-size: 18px;
        }

        .date-post {
          margin-top: 4px;
          margin-bottom: 19px;

          p {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .children {

          .comment {
            padding-left: 30px;

            @include small-size-min {
              padding-left: 50px;
            }
          }
        }

        .comment-reply-link {
          display: inline-block;
          margin-top: 13px;
          font-size: 14px;
          letter-spacing: 1.5px;
          text-transform: uppercase;

          &:before {
            content: '+ ';
          }
        }

        // Respond
        .comment {
          &-respond {
            padding: 55px 0 0;

            label {
              display: none;
            }
          }

          &-reply-title {
            margin: 0 0 35px;
            font-size: 24px;
            font-weight: bold;

            b {
              font-weight: inherit;
            }
          }

          &-form {
            display: flex;
            flex-wrap: wrap;

            textarea {
              height: 200px;
              padding: 17px 20px;
              transition: all 0.3s ease-out;
              border: 1px solid #eee;
              border-radius: 5px;
              outline: none;
              background-color: $c-white;
              font-size: 16px;
              line-height: 26px;
              resize: none;
            }
          }
        }

        .wrapper-column {
          display: flex;
          flex-basis: 100%;
          flex-wrap: wrap;

          @include small-size-max {
            flex-direction: column;
          }
        }

        .column {
          flex: 1;

          @include small-size-min {
            padding-right: 30px;

            &:last-child {
              padding-right: 0;
            }
          }

          @include small-size-max {
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          input {
            width: 100%;
            padding: 17px 20px;
            transition: all 0.3s ease-out;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
            background-color: $c-white;
            font-size: 16px;
            line-height: 26px;
          }
        }

        .input-comment {
          flex-basis: 100%;
          margin-top: 30px;
        }

        .submit-btn {
          margin: 40px auto 0 0;
          padding: 21px 38px;
          transition: all 0.3s ease-out;
          border: 0;
          border-radius: 5px;
          color: $c-white;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }
      .aht-page__sb .widget_mc4wp_form_widget {
        background: #2a74ed url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
        padding: 50px 30px 95px;

        .widget-title {
          margin: 0 0 5px;
          color: #fff;
          font-weight: 500;
          font-size: 20px;
        }

        p {
          margin: 0 0 40px;
          color:#fff;
          opacity: 0.51;
          font-size: 14px;
        }

        input[type="email"] {
            background: #1452b7;
            border: none;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 25px;
            color:#fff;
            height: 50px;
            padding: 0 20px;
            font-size: 15px;

            &::placeholder {
        		  color: $c-white;
        		}
        }

        input[type="submit"] {
          border: 1px solid rgba(255, 255, 255, 0.5);
          background-color: #fff;
          color: #2A74ED;
          border-radius: 5px;
          display: inline-block;
          position: relative;
          padding: 15px 35px;
          border-width: 1px;
          outline: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 1.5px;
          line-height: 1.15;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.3s all;

          &:hover {
            background: transparent;
            color:#fff;
            border:1px solid #fff;
            transition: 0.3s all;
          }
        }
      }
    }

  }
}

.aht-page--right-sb {
  .aht-page__content {
    blockquote {
      padding: 0;
      padding-left: 30px;
      margin-top: 91px;
      h3 {
        font-style: italic;
        font-size: 30px;
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        line-height: 36px;
        letter-spacing: normal;
        color: #262527;
      }
      p {
        margin-top: -10px;
        font-size: 14px;
        font-weight: bold;
      }
      &::before {
        content: none;
      }
    }
    @media screen and (max-width: 1024px){
      blockquote {
        margin-top: 0px;
        h3 {
          font-size: 20px;
        }
        p {
          margin-top: 0px;
        }
      }
    }
    .img_text_container {
      h6 {
        b {
          font-size: 18px;
          line-height: 26px;

        }
      }
      p {
        margin-bottom: 45px;
      }
      .img-content {
        float: left;
        padding-right: 30px;
        h6 {
          b {
            font-size: 16px;
            line-height: 26px;
            color: #222;
            font-style: italic;
            font-weight: normal;

          }
        }
      }
      @media screen and (max-width: 576px){
        p {
          margin-bottom: 20px;
        }
        .img-content {
          padding-right: 0;
        }
      }

    }
    .post-meta {
      margin: 50px 0 70px;
      .tags {
        font-size: 16px;
        font-weight: 500;
        color: #222;
        span {
          display: inline-block;
          margin-right: 19px;
        }
        a {
          font-size: 16px;
          font-weight: normal;
          letter-spacing: normal;
        }
      }
      .likes {
        padding: 0 30px;
        line-height: 49px;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        float: right;
        margin-top: -10px;
        color: #222;
        display: table;
        -webkit-transition: 0.3s all;
        transition: 0.3s all;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #222222;
      }
    }
    @media screen and (max-width: 576px){
      .post-meta {
        margin: 0;
        .likes {
          float: none;
          margin-top: 30px;
        }
      }
    }
    .post-author-info {
      border: 1px solid #eee;
      padding: 50px 50px 50px 174px;
      position: relative;
      margin-bottom: 50px;
      position: relative;
      img {
        position: absolute;
        top: 50px;
        left: 73px;
      }
      p {
        b {
          font-size: 18px;
        }
        &:last-child {
          margin-top: 52px;
          a {
            text-decoration: underline!important;
            font-weight: normal;
            letter-spacing: normal;
          }
        }
      }
    }
    @media screen and (max-width: 576px){
      .post-author-info  {
        padding: 50px;
        img {
          position: static;
          margin-bottom: 15px;
        }
      }
    }
    &-inner {
      // padding: 0 0!important;
      // margin: 0 0!important;
      .comment {
        max-width: 100%;
        &-content {
          .author-name {
            font-weight: bold;
            font-size: 18px;
          }
        }
        &-author {
          margin-right: 31px;
        }
      }
    }
  }
}

.aht-course--comments {
  .aht-page__content-inner {
    padding: 0px 0px!important;
    margin: 0px 0px!important;
  }
}

.blog--single {
  &__chr {
    .aht-page--right-sb {
      .aht-page__content {
        .post-meta {
          margin-top: 65px;
        }
      }
    }
  }
}

@include large-size-all {
  .blog--single {
    &__chr {
      .aht-page--right-sb {
        .aht-page__content {
          .post-meta {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
}

@include medium-size-all {
  .blog--single {
    &__chr {
      .aht-page--right-sb {
        .aht-page__content {
          .post-meta {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@include xs-size-all {
  .blog--single {
    &__chr {
      .aht-page--right-sb {
        .aht-page__content {
          .post-meta {
            margin-top: 10px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
