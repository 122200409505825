.aht-ev-det {
  &--yoga {

    img {
      max-width: 100%;
    }

    p {
      margin: 24px 0;
    }

    .aht-ev-det {

      &__title {
        margin: 0;
      }

      &__details {
        margin-top: 20px;
      }

      &__detail {
        display: inline-block;
        margin-right: 25px;
      }

      &__detail-icon {
        margin-right: 5px;
        font-size: 16px;
      }

      &__detail-text {
        font-size: 15px;
        font-weight: 500;
      }

      &__img-holder {
        position: relative;
        margin-top: 50px;
      }

      &__date {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 80px;
        height: 80px;
        padding: 18px 0;
        border-radius: 50%;
        background-color: $c-white;
        text-align: center;
      }

      &__date-text {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 1;
        text-transform: uppercase;
      }

      &__date-number {
        display: block;
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 28px;
      }

      &__content {
        margin-top: 72px;
      }
    }
  }
}
