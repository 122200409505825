.aheto-image-block {
  position: relative;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  &__link {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 50px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    z-index: 2;
  }

  &__title {
    margin: 0;
    color: $c-white;
  }

  &__desc {
    margin-top: 10px;
    margin-bottom: 0;
    color: $c-white;
    line-height: 1;
    font-size: 14px;
  }

  &::after {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 150px 100px rgba($c-black, 0.6);
    content: '';
    opacity: 0;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    .aheto-image-block__link {
      opacity: 1;
    }
  }

  @include medium-size-max {
    height: 300px;

    &::after {
      opacity: 1;
    }

    .aheto-image-block__link {
      padding: 30px;
      opacity: 1;
    }
  }

  @include small-size-max {
    max-width: 400px;
    margin: 0 auto;

    .aheto-image-block__link {
      padding: 25px;
    }
  }

  @include xs-size-max {
    height: 275px;
  }
}
