.aheto-instagram-gallery {
  position: relative;
  overflow: hidden;
  .aheto-btn {
    &.aheto-btn {
      &--light {
        &:hover {
          color: $c-white;
        }
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

 li {
    flex: 1;
    padding: 0;
    background-position: center;
    background-size: cover;

    a {
      width: 101%;
      display: table;

      img {
        width: 100%;
        height: auto;
      }
    }

    &::before {
      content: '';
    }


    @include medium-size-max {
      flex: 1 0 33.33%;
      height: 200px;
    }

    @include xs-size-max {
      flex: 1 0 50%;
      height: 150px;
    }
  }

  &__link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
  }

  &--edu {
    .aheto-instagram-gallery {
      &__list {
        height: 320px;
      }
      &__link {
        text-align: center;
        .aheto-btn {
          letter-spacing: normal;
          font-size: 16px;
        }
        &-text {
          font-size: 16px;
        }
      }
    }
    @media screen and (max-width: 992px) {
      .aheto-instagram-gallery {
        &__list {
          height: 100%;
        }
      }
    }
  }
}
