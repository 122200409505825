// Header is WHITE by default
.main-header {
  position: relative;
  z-index: 100;
  @include menu-size-max {
    -webkit-overflow-scrolling: touch;

    &.is-open {
      height: 100vh;
    }
  }
  .logo-white{
    display: none;
  }
  // Menu wrap
  &__menu-wrap {
    @include menu-size-max {
      position: relative;
    }
  }

  // Main line
  &__main-line {
    display: flex;
  }

  // Hamburger
  .hamburger {
    display: none;
    outline: none;
    z-index: 1;

    &-box {
      position: relative;
      top: 2px;
    }

    &-inner {
      &:after,
      &:before {
        background-color: inherit;
      }
    }

    @include menu-size-max {
      display: block;
    }
  }

  // Dropdown button
  .dropdown-btn {
    display: inline-block;
    padding-left: 10px;
    font-size: 7px;
    white-space: normal;
    vertical-align: middle;

    &:before {
      display: inline-block;
      transition: all 0.2s;
      font-family: $f-ionicons;
      content: '\f123';
      vertical-align: middle;
    }

    &.is-active {
      &:before {
        transform: rotate(-180deg);
      }
    }

    @include menu-size-max {
      display: none;
    }
  }

  // Logo
  &__logo {

    @include small-size-max {
      padding: 10px 15px;
    }
  }

  .aheto-logo {
    padding: 15px;
  }

  // Icons
  &__icons {
    justify-content: flex-end;
    padding: 15px 0;

    .icons-widget__item {
      i {
        font-size: 20px;
      }
      margin-left: 10px;
    }
    @include menu-size-max {
      margin-left: auto;
    }

    @include small-size-max {
      padding: 10px 0;
    }
  }

  &__main-line {
    @include menu-size-max {
      flex-wrap: wrap;
    }
  }

  // Action Button
  &__action-btn {
    align-self: center;
    margin: 15px 15px 15px 0;
    text-align: center;

    @include menu-size-max {
      order: 2;
      margin: 15px;
    }
  }

  // Menu box
  &__menu-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    padding: 0 15px;
    z-index: 2;

    ul {
      margin: 0;
      padding-left: 0;
    }

    li {
      padding-left: 0;
      line-height: 24px;
    }

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 110px);
      overflow: auto;
    }

    @include small-size-max {
      padding: 0;
    }
  }

  // Main menu
  .main-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include menu-size-max {
      flex-direction: column;
      align-items: flex-start;
      width: 970px;
      padding: 0 15px 30px;
    }

    @include medium-size-max {
      width: 750px;
    }

    @include small-size-max {
      width: auto;
    }

    // Slide sub-menus
    > .menu-item {
      > .sub-menu {
        left: 30px;
      }

      &:hover {
        > .sub-menu {
          left: 0;
        }
      }
    }

    .sub-menu {
      .menu-item {
        .sub-menu {
          top: 30px;
        }

        &:hover {
          .sub-menu {
            top: 0;
          }
        }
      }
    }


    // Hide Dropdown Submenus
    &--hide-submenu {
      .sub-menu {
        display: none !important;
      }
    }

    // Hide Menu Arrows
    &--hide-arrows {
      .dropdown-btn {
        display: none !important;
      }
    }

    // Current item
    &--ci-short-line {
      @include menu-size-min {
        > .menu-item {
          > a {
            &:hover {
              text-decoration: underline;
            }
          }

        }

        .sub-menu {
          .menu-item {
            a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &--ci-line-abow {
      @include menu-size-min {
        .menu-item {
          &:before {
            content: '';
          }
        }
      }
    }

    &--ci-line-below {
      @include menu-size-min {
        .menu-item {
          &:before {
            top: auto;
            bottom: 0;
            content: '';
          }
        }
      }
    }

    &--ci-rounded-solid-bg {

      @include menu-size-min {
        > .menu-item {
          > a {
            padding: 5px 10px;
            border-radius: 20px;
          }
        }
      }
    }

    // Dropdown styles
    @include menu-size-min {

      &--ddt-default {

        .sub-menu {
          padding: 30px 0;
          .menu-item {
            padding: 5px 20px;

            &:hover {
              .sub-menu {
                top: -30px;
              }
            }
          }

          .menu-item {
            left: 5px;
            min-width: 230px;
            padding: 5px 20px;
            white-space: normal;
          }

          .dropdown-btn {
            float: right;
          }
        }

        .mega-menu {
          padding: 0;

          &__col {
            padding: 30px 30px 40px;
          }

          &__title {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba($c-black, 0.07);
          }

          &__list {
            .menu-item {
              position: static;
              padding: 5px 0;
            }
          }
        }

        &.main-menu--ddc-white {
          .sub-menu {
            background-color: $c-white;
          }
        }

        &.main-menu--ddc-dark {
          .sub-menu {

            .menu-item {

              .dropdown-btn,
              a {
                color: $c-white;
              }
              &:hover {
                > .dropdown-btn,
                > a {
                  color: rgba($c-white, 0.8);
                }
              }
            }
          }

          .mega-menu {
            &__title {
              border-color: rgba($c-white, 0.3);
              color: $c-white;
            }
          }
        }
      }

      &--ddt-classic {

        .sub-menu {
          padding: 20px 0;

          .menu-item {
            > a {
              font-weight: 500;
            }

            &:hover {
              .sub-menu {
                top: -20px;
              }
            }
          }

          .menu-item {
            padding: 8px 30px;
          }
        }

        .mega-menu {
          padding: 0;

          &__col {
            padding: 30px 30px 40px;
          }

          &__title {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba($c-black, 0.07);
          }

          &__list {
            .menu-item {
              position: static;
              padding: 8px 0;
            }
          }
        }

        &.main-menu--ddc-white {
          .sub-menu {
            background-color: $c-white;
          }
        }

        &.main-menu--ddc-dark {
          .sub-menu {
            .menu-item {

              .dropdown-btn,
              a {
                color: $c-white;
              }

              &:hover {
                > .dropdown-btn,
                > a {
                  color: rgba($c-white, 0.8);
                }
              }
            }
          }

          .mega-menu {
            &__title {
              border-color: rgba($c-white, 0.3);
              color: $c-white;
            }
          }
        }
      }

      &--ddt-clean {

        .sub-menu {
          padding: 15px 0;

          .dropdown-btn {
            vertical-align: top;
          }

          .menu-item {
            min-width: 200px;
            padding: 3px 30px;

            a {
              font-weight: 500;
            }

            &:hover {
              .sub-menu {
                top: -15px;
              }
            }
          }
        }

        .mega-menu {
          padding: 0;

          &__title {
            padding-bottom: 10px;
            border-bottom: 1px solid rgba($c-white, 0.2);
          }

          &__col {
            padding: 25px 25px 30px;
          }

          &__list {
            .menu-item {
              padding: 3px 0;
            }
          }
        }

        &.main-menu--ddc-white {
          .sub-menu {
            background-color: $c-white;
          }
        }

        &.main-menu--ddc-dark {
          .sub-menu {
            .menu-item {
              a {
                color: $c-white;
              }

              .dropdown-btn {
                color: $c-white;
              }

              &:hover {
                > a,
                > .dropdown-btn {
                  color: rgba($c-white, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }

  // Nav
  nav {
    @include small-size-max {
      width: 100%;
    }
  }

  // Menu Item
  .menu-item {
    position: relative;
    padding: 33px 5px;
    transition: all 0.2s;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 2px;
      transform: translate(-50%, 0);
      transition: all 0.2s;
    }

    i {
      margin-right: 10px;
    }

    a {
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
    }

    &__subtext {
      display: block;
      font-size: 12px;
      line-height: 1;
    }

    // Current
    @include menu-size-min {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {

        &:before {
          width: 100%;
        }

        > .sub-menu {
          left: 0;
          opacity: 1;
          visibility: visible;

          > .menu-item {
            left: 0;
          }
        }
      }

      // Current
      &.current-menu-item {
        &:before {
          width: 100%;
        }
      }
    }

    @include menu-size-max {
      width: 100%;
      padding: 10px 0 0;
      &.active > a {
        //color: #2A74ED;
        border-bottom: 1px solid #222222;
      }
      > a.active {
        border-bottom: 1px solid #222222;
      }

      > a {
        font-size: 16px;
      }
    }
  }

  // Sub menu
  .sub-menu {
    transition: all 0.2s;
    white-space: nowrap;

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      z-index: 1;

      .menu-item {
        margin-right: 0;

        &:before {
          content: none;
        }

        > a {
          font-weight: 400;
        }

        > .sub-menu {
          top: 0;
          left: 100%;
        }
      }

      .dropdown-btn {
        padding-left: 15px;

        &:before {
          content: '\f125';
        }
      }
    }

    @include menu-size-max {
      visibility: visible;

      .menu-item {
        padding-right: 20px;
        padding-left: 20px;
      }

      .dropdown-btn {
        &:before {
          content: '\f123';
        }
      }
    }
  }

  // Mega menu
  .mega-menu {
    display: flex;

    &__col {
      width: 290px;
      border-right: 1px solid rgba($c-black, 0.07);

      &:last-child {
        border-right: 0;
      }

      @media screen and (max-width: 1170px) {
        width: 250px;
      }
    }

    &__title {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    &__list {
      padding-top: 15px;
    }

    @include menu-size-max {
      flex-direction: column;

      &__col {
        width: auto;
        max-width: 250px;
        padding: 10px 20px;
        border-right: 0;
      }

      &__title {
        padding: 0 0 10px;
        border-bottom: 1px solid rgba($c-black, 0.06);
      }

      &__list {
        padding: 0;

        .menu-item {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  // OPTIONS

  // Fullwidth container
  &__fluid-container {
    max-width: 1750px;
    margin: 0 auto;

    @include menu-size-max {

      .main-header__menu-box {
        padding: 0;
      }

      .menu-main-container {
        width: 100%;
      }

      .main-menu {
        width: 100%;
      }

      nav {
        width: 100%;
      }
    }
  }

  // Modificators
  &--center-nav {
    .main-header__icons {
      flex-basis: 0%;
    }

    .main-header__logo {
      flex-basis: 16%;
    }

    .main-header__menu-box {
      flex: 1;
    }
  }

  // Colors
  // Solid is supposed to be some dark color

  &--solid {
    // Make white items
    .menu-item__subtext,
    .aheto-logo__text,
    .icons-widget__link {
      color: $c-white;
    }

    .hamburger-inner {
      background-color: $c-white;
    }

    @include menu-size-min {
      .main-menu {
        > .menu-item {
        >  .dropdown-btn,
          > a {
            color: $c-white;
          }

          &:hover {

            > .dropdown-btn,
            > a {
              color: rgba($c-white, 0.8);
            }
          }
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        a {
          color: $c-white;
          font-weight: 400;

          &:hover {
            color: rgba($c-white, 0.8);
          }
        }
      }

      .mega-menu {
        &__title {
          border-color: rgba($c-white, 0.3);
          color: rgba($c-white, 0.8);
          font-weight: 400;
        }
      }
    }

    // Widgets
    .widget {
      &s-area__title {
        color: $c-white;
      }

      .aht-socials {
        &__item {
          a {
            border-color: $c-white;
            color: $c-white;

            &:hover {
              background-color: transparent;
              color: $c-white;
            }
          }
        }
      }
    }
  }

  &--white {

    .main-header__menu-wrap {
      background-color: $c-white;
    }

    .main-header__menu-box {

      @include menu-size-max {
        background-color: $c-white;
      }
    }

    .sub-menu {
      background-color: $c-white;
    }
  }

  &--grad {
    .main-header__menu-wrap {
      background-color: transparent;
    }
  }

  &--grad-hor {
    .main-header__menu-wrap {
      background-color: transparent;
    }
  }

  // Transparent Colors
  &--transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .main-header__menu-wrap {
      background-color: transparent;
    }

    .main-header__menu-box {
      background-color: transparent;
    }

    @include menu-size-max {
      background-color: rgba($c-white, 0.9);
    }
  }

  &--translucent-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .main-header__menu-wrap {
      background-color: rgba($c-black, 0.5);
    }

    @include menu-size-max {
      background-color: transparent;

      .main-header__menu-box,
      .main-header__menu-wrap {
        background-color: rgba($c-black, 0.9);
      }
    }

    // Make white items
    .menu-item__subtext,
    .aheto-logo__text,
    .icons-widget__link {
      color: $c-white;
    }

    .hamburger-inner {
      background-color: $c-white;
    }

    @include menu-size-min {
      .main-menu {
        > .menu-item {
        >  .dropdown-btn,
          > a {
            color: $c-white;
          }

          &:hover {

            > .dropdown-btn,
            > a {
              color: rgba($c-white, 0.8);
            }
          }
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        a {
          color: $c-white;
          font-weight: 400;

          &:hover {
            color: rgba($c-white, 0.8);
          }
        }
      }

      .mega-menu {
        &__title {
          border-color: rgba($c-white, 0.3);
          color: rgba($c-white, 0.8);
          font-weight: 400;
        }
      }
    }

    // Widgets
    .widget {
      &s-area__title {
        color: $c-white;
      }

      .aht-socials {
        &__item {
          a {
            border-color: $c-white;
            color: $c-white;

            &:hover {
              background-color: transparent;
              color: $c-white;
            }
          }
        }
      }
    }
  }

  &--translucent-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($c-white, 0.6);

    .main-header__menu-wrap {
      background-color: transparent;
    }

    @include menu-size-max {
      background-color: rgba($c-white, 0.9);
    }
  }

  // Fullscreen Menu has its own style of items and dropdowns
  &--fullscreen-menu {

    @include menu-size-min {

      .hamburger {
        display: block;
        padding-right: 30px;
        padding-left: 30px;
        z-index: 2;
      }

      .dropdown-btn {
        position: relative;
        top: -6px;
        margin-left: 10px;
        padding-left: 10px;
        float: none !important;
        font-size: 20px !important;
        cursor: pointer;
        vertical-align: middle !important;
      }

      .main-header__icons {
        margin-left: auto;
      }

      .main-header__menu-box {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba($c-white, 0.8);
        overflow: auto;
      }

      nav {
        margin: auto;
        padding: 100px;
      }

      .main-menu {
        flex-direction: column;
        align-items: center;

        .menu-item {
          &:before {
            content: none;
          }
        }

        > .menu-item {
          &:hover {
            a {
              background-color: transparent;
            }
          }
        }
      }

      .menu-item {
        position: static;
        margin-right: 0;
        padding: 25px 0 15px;
        text-align: center;

        a {
          font-size: 42px;
          font-weight: 600 !important;
          letter-spacing: 1.5px;
        }

        .sub-menu {
          margin-top: 20px;
          padding: 0;
          box-shadow: none;
        }
      }

      .sub-menu {
        display: none;
        position: static;
        transition: none;
        background-color: transparent !important;
        background-image: none;
        opacity: 1;
        visibility: visible;

        .dropdown-btn {
          top: -4px;
          padding-left: 10px;

          &:before {
            content: '\f123';
          }
        }

        .menu-item {
          a {
            font-size: 22px;
            line-height: 30px;
          }
        }

        .sub-menu {
          margin-top: 5px;
        }
      }

      .mega-menu {
        &__col {
          padding: 20px 0 10px !important;
          border-right: 0;
        }

        &__title {
          border-color: rgba($c-black, 0.1) !important;
          font-size: 26px;
        }
      }

      &.is-open {
        .hamburger {
          position: fixed;
          top: 40px;
          right: 40px;
          padding: 10px;
          transform: scale(1.2);
        }
      }
    }
  }

  &--fullscreen-menu.main-header--menu-dark {
    @include menu-size-min {

      &.is-open {
        .hamburger-inner {
          background-color: $c-white;

          &:before,
          &:after {
            background-color: $c-white;
          }
        }
      }

      .dropdown-btn {
        color: $c-white !important;
      }

      .sub-menu {
        background-color: transparent !important;
        background-image: none;
      }

      .menu-item {

        a {
          color: $c-white !important;
        }
      }

      .mega-menu {
        &__title {
          border-color: rgba($c-white, 0.3) !important;
          color: $c-white !important;
        }
      }
    }
  }

  &.js-sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  // Shadows
  &--shadow-1 {
    box-shadow: 0 10px 6px -6px rgba($c-black, 0.1);
  }

  &--shadow-2 {
    box-shadow: 0px 0px 30px 0px rgba($c-black, 0.2);
  }

  &--shadow-3 {
    box-shadow: 0px 10px 50px 0px rgba($c-black, 0.4);
  }

  &--shadow-4 {
    box-shadow: 0px 0px 20px 10px rgba($c-black, 0.7);
  }

  &--shadow-5 {
    .main-header__menu-wrap {
      box-shadow: inset 0px 0px 10px 0px rgba($c-black, 0.15);
    }
  }

  // Widgets
  &__widgets-area {
    .aht-socials {
      &__item {
        a {
          &:hover {
            color: $c-white;
          }
        }
      }
    }
  }

  .button-number {
    position: absolute;
    top: 0px;
    right: 0;
    min-width: 17px;
    height: 17px;
    border-radius: 50%;
    color: $c-white;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    padding-left: 1px;

    @include menu-size-max {
      top: -9px;
    }
  }

  li {
    position: relative;
  }
  .wpml {
    &-ls-legacy-dropdown {
      margin-top: 35px;

      &:hover {
        .wpml-ls-sub-menu {
          opacity: 1;
          visibility: visible;
        }
      }

      @include small-size-max {
        margin-top: 26px;
      }
    }

    &-ls-native {
      font-size: 14px;
      font-weight: 400;

      i {
        display: inline-block;
        padding-left: 10px;
        font-size: 7px;
        white-space: normal;
        vertical-align: middle;
      }
    }

    &-ls-sub-menu {
      position: absolute;
      top: 30px;
      left: 0;
      opacity:0;
      visibility: hidden;
      transition: 0.2s all;
      li {
        padding: 5px 10px;
      }
    }

    &-ls-flag {
        width: 23px;
        height: 23px;
        margin-right: 13px;
    }
  }

  @include menu-size-max {
    .widget {
      margin-bottom: 0px;
      padding: 4px 0px;
      display: block;
      width: 100%;
    }

    .aheto-toolbar {
      padding: 4px 0;
      .registartion,
      .log-in {
        padding: 4px 25px 4px 0;
      }

      .aht-socials {
        padding: 7px 0px 4px 0;
        position: absolute;
        top: 0;
        right: 0;

        &__item {
          margin-right: 14px;
          a {
          font-size:14px;
          }
        }
      }
    }

    .widget_text {
      display: none;
    }
  }

  @include menu-size-max {
    .main-header__icons {
      padding-right: 0px;
    }

    .main-header__logo {
        -ms-flex-preferred-size:  calc(100% - 250px);
        flex-basis: calc(100% - 250px);
    }
  }

  @include xs-size-all {
    .wpml-ls {
      display: none;
    }

    .main-header__icons {
      padding-right: 0px;
    }

    .main-header__logo {
        -ms-flex-preferred-size:  calc(100% - 140px);
        flex-basis: calc(100% - 140px);
    }

  }
}
@media screen and (min-width: 1025px) {
  .main-header.transparent_header {
    position: absolute;
    width: 100%;
    .main-header__toolbar-wrap{
       .aheto-toolbar {
         > *:last-child {
           border-right: 1px solid rgba(246, 246, 246, 0.3);
         }
       }
    }
    .logo-white {
      display: block;
    }

    .logo-blue {
      display: none;
    }

    .main-header__menu-wrap {
      background-color: transparent;
    }

    .main-header__toolbar-wrap--solid {
      background-color: transparent;
    }

    .main-header__toolbar-wrap--solid {
      &:after {
        content: '';
        width: 100%;
        border-top: 1px solid rgba(246, 246, 246, 0.3);
        position: absolute;
      }
    }

    .main-header__toolbar-wrap {
      h6 {
        color: $c-white;
      }

      .widget {
        a {
          color: $c-white;
          &:hover{
            color: #2A74ED;
          }
        }
      }
    }

    .main-header__toolbar-wrap {
      .registartion, .log-in {
        a {
          color: $c-white;
          &:hover{
            color: #2A74ED;
          }
        }
      }
    }

    .aheto-toolbar {
      .aht-socials__item {
        a {
          color: $c-white;

          &:hover {
            color: #2A74ED;
          }
        }
      }
    }

    .aheto-logo__text {
      color: $c-white;
    }

    .dropdown-btn {
      color: $c-white;
    }

    .icons-widget__link {
      color: $c-white;
      &:hover{
        color: #2A74ED;
      }
    }

    .menu-item {
      a {
        color: $c-white;
      }

      &::before {
        background-color: $c-white;
      }

      &:hover {
        a {
          color: $c-white;
        }

        .dropdown-btn {
          color: #ffffff;
        }
      }
    }

    .wpml-ls-item-toggle {
      span {
        color: $c-white;
      }
      i {
        color: $c-white;
      }
    }

    .main-header__toolbar-wrap {
      .aheto-toolbar {
        > * {
          border-left: 1px solid rgba(246, 246, 246, 0.3);
        }
      }
    }
  }
}
