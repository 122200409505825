.widget {
  @include medium-size-max {
    margin-bottom: 50px;
  }
  input {
    outline: none;
    box-shadow: none;
  }
  ul {
    padding: 0;
    li {
      list-style: none;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &-title {
    margin: 0 0 40px;
    color: $c-white;
    font-size: 20px;
    line-height: 24px;
  }
  &_aheto {
    &__logo {
      margin-bottom: 28px;
      position: relative;
      top: 7px;
    }
    &__info {
      &--address {
        padding-right: 20px;
      }
      padding-left: 37px;
      position: relative;
      font-weight: 500;
    }
    &__link {
      font-weight: 500;
    }
    &__icon {
      margin-right: 20px;
      position: absolute;
      left: 1px;
      top: 0;
      font-style: normal;
      &--call {
        transform: scale(-1, 1);
      }
    }
    &__title {
      margin-bottom: 27px;
      font-size: 30px;
      font-weight: 700;
      color: $c-white;
      @include small-size-max {
        margin-bottom: 20px;
      }
    }
    &__desc {
      margin-bottom: 45px;
      font-size: 16px;
      line-height: 1.5;
    }
    &__link {
      font-weight: 400;
      &:hover {
        color: $c-white;
      }
    }
    .aheto-socials {
      padding: 0;
      margin-top: 40px;
      &__link {
        margin-left: 0;
        margin-right: 13px;
        &:hover {
          color: $c-white;
        } &:hover {
          color: $c-white;
        }
        &__icon {
          font-size: 19px;
          transition: all 0.3s;
        }
      }
    }
  }
  // NavMenu
  &_nav_menu {
    .menu {
      &-item {
        margin-bottom: 20px;
        line-height: 20px;
        a {
          font-weight: 400;
          &:hover {
            color: $c-white;
          }
        }
        &.current-menu-item {
          a {
            color: $c-white;
          }
        }
      }
    }
    &_1 {
      .menu {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: 100%;
          margin-bottom: 20px;
          line-height: 20px;
          a {
            font-weight: 500;
            letter-spacing: 0;
            padding-left: 20px;
            position: relative;
            &:before {
              content: '';
              margin-right: 10px;
              position: absolute;
              border-style: solid;
              border-width: 5px 0 5px 5px;
              display: block;
              width: 0;
              z-index: 1;
              left: 0;
              top: 4px;
            }
            @include large-size-max {
              font-size: 14px;
            }
          }
        }
      }
    }
    &_2 {
      .menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        &-item {
          -ms-flex-preferred-size: 50%;
          flex-basis: 50%;
          margin-bottom: 20px;
          line-height: 20px;
          a {
            font-weight: 400;
            &:hover {
              color: $c-white;
            }
          }
          &.current-menu-item {
            a {
              color: $c-white;
            }
          }
        }
      }
    }
    &_3 {
      .menu {
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: 100%;
          margin-bottom: 20px;
          line-height: 20px;
          a {
            font-weight: 400;
            letter-spacing: 0;
            padding-left: 20px;
            position: relative;
            &:before {
              content: '';
              width: 5px;
              height: 5px;
              position: absolute;
              left: 0;
              top: 8px;
              border-radius: 50%;
            }
          }
          &.current-menu-item {
            a {
              color: $c-white;
            }
          }
        }
      }
    }
  }
  // Recent entries
  &_recent_entries {
    &_1 {
      li {
        margin-bottom: 35px;
        line-height: 1.5;
        @include small-size-max {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $c-white;
          font-weight: 400;
          line-height: 2;
          font-size: 16px;
          &:hover {
            opacity: 0.8;
          }
        }
        span {
          display: block;
          margin-top: 13px;
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }
    &_2 {
      li {
        margin-bottom: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      a {
        font-weight: 700;
        display: table;
        line-height: 20px;
        &:hover {
          opacity: 1;
        }
      }
      .widget-img {
        min-width: 60px;
        width: 60px;
        height: 60px;
        margin-right: 12px;
      }
      .post-date {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 0;
        display: block;
      }
    }
    &_3 {
      li {
        margin-bottom: 25px;
        padding-bottom: 25px;
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0px;
          padding-bottom: 0px;
        }
      }
      a {
        display: block;
        color: $c-white;
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
      }
      .post-date {
        margin-top: 7px;
        font-size: 16px;
        line-height: 20px;
        display: block;
      }
    }
  }
  // Mailchimp Newsletter
  &_mc4wp_form_widget {
    .mc4wp-form-fields {
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
    &.aheto_mc {
      &_1 {
        input {
          width: 100%;
          padding: 15px 20px;
          border: 0;
          border-radius: 0px;
          outline: none;
          font-size: 16px;
          line-height: 20px;
          background-color: rgba($c-white, 0.1);
          font-family: $f-karla;
          color: $c-white;
          &[type=submit] {
            width: auto;
            padding-right: 25px;
            padding-left: 25px;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 1.5px;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
      &_2 {
        button,
        input {
          flex-grow: 1;
          border: 0;
          border-radius: 0px;
          width: calc(100% - 50px);
          padding: 0 20px;
          height: 50px;

          &[type=submit] {
            flex-grow: 0;
            width: 50px;
            margin: 0;
            padding: 10px;
            color: $c-white;
            font-size: 26px;
          }
        }
        button{
          position: relative;
          i{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &_3 {
        margin-bottom: 35px;
        input {
          padding: 20px;
          border: 1px solid rgba($c-black, 0.07);
          font-size: 15px;
          width: 100%;
          padding-right: 70px;
          &::placeholder {
            font-size: 15px;
          }
        }
        button {
          &[type=submit] {
            position: absolute;
            top: 50%;
            right: 10px;
            width: auto;
            margin-top: 0;
            transform: translate(0, -50%);
            background-color: $c-white;
            font-size: 20px;
            padding: 15px 20px;
            border: 0;
            border-radius: 5px;
            outline: none;
            font-size: 16px;
            line-height: 20px;
            &:hover {
              color: $c-white;
            }
          }
        }
      }
    }
    p {
      flex-basis: 100%;
      margin: 25px 0 0;
    }
  }
  // Payment
  &_payment {
    text-align: right;
    @include medium-size-max {
      text-align: center;
    }
    .card {
      display: inline-block;
      margin-bottom: 4px;
      margin-left: 4px;
      img {
        max-width: 100%;
      }
      @include large-size-max {
        width: 30px;
      }
      @include small-size-max {
        width: 40px;
      }
    }
    @include medium-size-max {
      text-align: left;
    }
  }
  // Time Schedule
  &_time_schedule {
    li {
      margin-bottom: 20px;
      line-height: 20px;
      b {
        color: rgba($c-white, 0.8);
        font-weight: inherit;
      }
    }
    &_2 {
      ul {
        display: inline-block;
        padding: 20px 25px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        li {
          &:last-child {
            margin: 30px 0 0;
          }
        }
      }
    }
  }
  // Media Gallery
  &_media_gallery {
    .gallery {
      display: grid;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      grid-template-columns: auto auto auto;
      grid-gap: 10px;
      margin: 0;
      @include large-size-max {
        grid-template-columns: auto auto;
      }
      @include medium-size-max {
        grid-template-columns: auto auto auto;
      }
      &-item {
        margin: 0;
        padding: 0;
      }
    }
  }
  // Form
  &_custom_form {
    textarea,
    input[type=text],
    input[type=email],
    input[type=url] {
      background-color: rgba($c-white, 0.05);
      color: $c-white;
      width: 100%;
      padding: 15px 20px;
      border: 0;
      border-radius: 5px;
      outline: none;
      font-size: 16px;
      line-height: 20px;
      &::placeholder {
        color: $c-white;
      }
    }
    textarea {
      height: 120px;
      margin-top: 20px;
      resize: none;
    }
    button,
    input {
      &[type=submit] {
        width: auto;
        margin-top: 20px;
        padding-right: 35px;
        padding-left: 35px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        color: $c-white;
        line-height: 50px;
        border: 0;
        border-radius: 5px;
      }
    }
  }
  // Twitter
  &_twitter {
    .widget-post {
      display: flex;
      margin-bottom: 38px;
    }
    .widget-icon {
      //padding-top: 3px;
      i {
        display: block;
        margin-right: 15px;
        color: $c-white;
        font-size: 20px;
      }
    }
    .widget-content {
      p {
        margin: 0;
        font-size: 15px;
        line-height: 24px;
      }
    }
    .widget-add {
      margin-top: 12px;
      span,
      a {
        color: $c-white;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .widget {
    &-title {
      @include small-size-max {
        margin: 0 0 30px;
      }
    }
    &_aheto__logo {
      @include small-size-max {
        margin-bottom: 38px;
      }
    }
  }
  &-courses {
    &--edu {
      .widget {
        &-title {
          font-size: 16px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          text-align: left;
          color: #333333;
          margin: 0 0 34px;
        }
      }
      .aht-courses-wd {
        margin-bottom: 54px;
        &__item {
          margin-bottom: 25px;
          &::before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: #e0e0e0;
            display: inline-block;
            margin-right: 11px;
          }
        }
        &__link {
          font-weight: normal;
          font-size: 16px;
          letter-spacing: normal;
        }
      }

    }
  }
}
// Social
.aheto-socials {
  @include small-size-max {
    text-align: center;
  }
  &__link {
    margin-left: 13px;

  }
  .aht-socials {
    &__link {
      display: inline-block;
      margin-right: 20px;
      transition: all 0.2s ease-out;
      &:last-child {
        margin-right: 0;
      }
    }
    &__icon {
      color: inherit;
      font-size: 16px;
      transition: all 0.3s;
    }
  }
}
.aht-socials--edu {
  .aheto-socials {
    &__link:first-child {
      margin-left: 0;
    }
  }
}
.aht-socials--circle {
  @include medium-size-max {
    margin: -50px 0 50px;
  }
}
// Footer menu
.aheto-footer-menu {
  display: block;
  padding: 0;
  text-align: right;
  @include small-size-max {
    text-align: center;
  }
  ul {
    padding: 0px;
  }
  .menu {
    &-item {
      display: inline-block;
      margin-right: 45px;
      padding-left: 0;
      @include medium-size-max {
        margin: 0 0 0 15px;
      }
      @include small-size-max {
        margin: 0 8px;
      }
      a {
        color: $c-white;
        font-size: 15px;
        line-height: 24px;
        text-transform: none;
        @include small-size-max {
          font-size: 13px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.aht-course--edu-details {
  .aht-page {
    margin-top: 86px;
    margin-bottom: 100px;
  }
  @media screen and (max-width: 1024px) {
    .aht-page {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
  @media screen and (max-width: 576px) {
    .aht-page {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  .aht-widget-advert {
    background-size: cover;
    margin-bottom: 86px;
    &--edu {
      text-align: center;
      .aht-widget-advert {
        &__subtitle {
          font-size: 14px;
          line-height: 26px;
          letter-spacing: normal;
          text-align: center;
          margin-bottom: 19px;
        }
        &__title {
          margin-bottom: 95px;
          letter-spacing: 1.6px;
          line-height: 14px;
        }
        &__desc {
          letter-spacing: 1.6px;
          font-weight: 500;
          margin-bottom: 30px;
        }
        &__link {
          font-weight: bold;
          letter-spacing: 1.4px;
        }
      }
    }
  }
  .aht-twitter {
    text-align: center;
    &__link {
      font-size: 20px;
      display: block;
      width: 59px;
      height: 59px;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 6px;
      line-height: 59px;
      transform: translateY(-50%);
    }
    .btn {
      margin-top: 40px;
    }
    &__content {
      padding-left: 25px;
        padding-right: 25px;
      .tweets {
        span {
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: center;
          color: #999999;
        }
      }

    }
    &__follow {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 14px;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .aheto_mc_edu {
      padding: 47px 30px 94px;
      .widget-title {
        margin: 0 0 0px;
        font-weight: 500;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 26px;
        letter-spacing: normal;
      }
      .mc4wp-form-fields {
        p {
          margin: 16px 0 0!important;
          color:#fff;
          opacity: 0.51;
          font-size: 14px;
        }
        input[type="email"] {
          background: #1452b7;
          border: none;
          width: 100%;
          border-radius: 5px;
          margin-bottom: 25px;
          color:#fff;
          height: 50px;
          padding: 0 20px;
          font-size: 15px;
          margin-top: 41px;

          &::placeholder {
            color: $c-white;
          }
        }
        input[type="submit"] {
          border-radius: 5px;
          display: inline-block;
          position: relative;
          padding: 15px 35px;
          border-width: 1px;
          outline: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 1.4px;
          line-height: 1.15;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.3s all;
          margin-top: 24px;
        }
      }
  }
}
