@import '../../colors/_colors-theme-16';
.aheto-header-16 {

  &__inner {
    @include menu-size-min {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__line {
    display: flex;
    position: relative;

    @include menu-size-max {
      flex-wrap: wrap;
      background-color: $c-dark;
    }
  }

  &__nav-wrap {
    width: 100%;
  }

  &__logo {
    @include menu-size-max {
      display: flex;
      flex-direction: row;
      flex-basis: 100%;

    }
  }

  &__hamburger {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 5px;

    @include menu-size-min {
      display: none;
    }
  }

  &__menu {
    display: flex;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - 99px);
      padding: 30px 15px 30px;
      background-color: $c-white;
      overflow-y: scroll;
      background-color: $c-bg-mob-menu;
      .main-menu {
        flex-direction: column;
        //height: 50vh;
        justify-content: space-between;
        color: white;
        padding-left: 20px;
        .menu-item {
          line-height: 40px;
          a {
            font-family: $f-open-sans;
          }
          a:hover {
            color: $c-white;
          }
        }
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    padding: 15px 65px 15px 75px;

    @include menu-size-max {
      display: none;
    }

    @media screen and (max-width: 1500px) {
      padding: 15px 25px 15px;
    }

    @include menu-size-min {
      border-bottom: 1px solid rgba($c-white, 0.2);
    }

    @include menu-size-max {
      padding: 15px;
    }
  }

  .main-menu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @include menu-size-min {

      > .menu-item {
        display: flex;
        position: relative;
        flex-basis: 0%;
        flex-grow: 1;
        flex-shrink: 1;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        border-right: 1px solid rgba($c-white, 0.2);
        border-bottom: 1px solid rgba($c-white, 0.2);
        > a {
          color: $c-white;
          white-space: nowrap;
          font-family: $f-open-sans;
          &:hover {
            color: $c-active;
          }
        }

        &:hover {
          > .sub-menu {
            top: 100%;
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @include menu-size-max {
      flex-direction: column;
    }
  }

  .sub-menu {

    .menu-item {
      padding: 5px 30px;
      position: relative;

      a {
        font-size: 15px;
        font-weight: 400;
      }
    }

    @include menu-size-min {
      position: absolute;
      top: 110%;
      left: 0;
      min-width: 100%;
      padding: 20px 0;
      transition: all 0.2s;
      background-color: $c-white;
      text-align: left;
      opacity: 0;
      visibility: hidden;

      .sub-menu {
        top: 10px;
        left: 100%;
      }

      .menu-item {
        min-width: 180px;

        &:hover {
          > .sub-menu {
            top: -20px;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .dropdown-btn {
        float: right;

        &:before {
          content: '\f125';
          vertical-align: baseline;
        }
      }
    }
  }

  // Mega-menu
  .mega-menu {

    &__title {
      font-size: 20px;
    }

    @include menu-size-min {
      display: flex;
      padding: 0;

      &__title {
        margin-bottom: 10px;
        padding-bottom: 10px;
      }

      &__col {
        padding: 30px 20px;
      }

      .menu-item {
        padding: 5px 0;
      }
    }

    @include menu-size-max {
      &__title {
        margin-top: 10px;
        padding-bottom: 5px;
        padding-left: 30px;
        font-size: 20px;
      }
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    margin-left: 10px;
    color: $c-white;
    font-size: 10px;

    @include menu-size-max {
      display: none;
    }
  }

  // Logo
  .logo {
    height: 100%;

    &__link {
      height: 100%;
    }

    &__img-holder {
      padding: 0px 20px 0px 15px;

      @include menu-size-max {
        padding: 7px 12px;
      }

      & + .logo__text-holder {
        margin-left: 0;
        text-align: center;
        @include small-size-max {
          text-align: left;
        }
      }
    }

    &__text-holder {
      padding: 12px 54px;
      background-color: $c-white;

      @media screen and (max-width: 1500px) {
        padding: 15px 20px;
      }

      @include menu-size-max {
        flex-grow: 1;
        padding: 7px 12px;
        background-color: $c-dark;
      }
    }

    &__text {
      font-size: 25px;
      font-weight: bolder;
      letter-spacing: 4.1px ;
      line-height: 1;
      font-family: $fnt-family-1;
      @include menu-size-max {
        color: $c-white;
        line-height: 1.5;
       }
    }
  }

  // Socials
  .aht-socials {
    display: flex;

    &__link {
      margin-right: 17px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__icon {
      font-size: 16px;
    }
  }
}

