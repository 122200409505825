.aheto-socials {

  &__link {

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    transition: none;
    font-size: 18px;
  }

  // Increased retreats
  &--retreat {
    .aht-socials__link {
      margin-right: 31px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // Circle social
  &--circle {

    .aht-socials__link {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-right: 8px;
      margin-bottom: 10px;
      border-radius: 50%;
      line-height: 50px;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $c-white;
        opacity: 1;

        .aht-socials__icon {
          color: inherit;
        }
      }
    }

    .aht-socials__icon {
      margin: auto;
    }

    // Class active for circled social
    .active {
      width: 60px;
      height: 60px;
      color: $c-white;
      opacity: 1;

      .aht-socials__icon {
        font-size: 20px;
      }
    }
  }

  // Make icon color white
  &--i-white {
    .aht-socials__icon {
      color: $c-white;
    }
  }

  &--right {
    text-align: right;

    @include small-size-max {
      text-align: left;
    }
  }

  &--banner {
    .aht-socials__link {
      color: $c-white;

      & + .aht-socials__link {
        margin-left: 25px;
      }
    }
    .aht-socials__icon {
      font-size: 22px;
    }
  }
  // Events page social

  &--events {
    position: absolute;
    bottom: 76px;
    left: 100px;
  }
  @media screen and (max-width: 768px) {
    &--events {
        position: absolute;
        bottom: 50px;
        left: 80px;
    }
  }
}


// Old Karma socials (for footer)
.aheto-socials {
  &--align-right {
    text-align: right;
  }

  &__item {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 10px;
    padding-left: 0;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      width: 50px;
      height: 50px;
      border: 1px solid rgb(48, 47, 47);
      border-radius: 50%;
      color: $c-white;
      line-height: 50px;
      text-align: center;
    }

    &:before {
      content: none;
    }
  }
}

// New normal socials
.aht-socials {

  &__link {
    display: inline-block;
    margin-right: 20px;
    transition: all 0.2s ease-out;

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    color: inherit;
    font-size: 16px;
    transition: none;
  }

  &--circle {
    margin: -4px;

    .aht-socials {

      &__link {
        width: 50px;
        height: 50px;
        margin: 4px;
        border-radius: 50%;
        line-height: 50px;
        text-align: center;

        &:hover {
          border-color: transparent;
          color: $c-white;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &--edu {
      text-align: left!important;
    }
  }

}


.aheto-header-7__aside-socials {
  text-align: right;
  .aht-socials__link {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  .aht-socials__icon {
    font-size: 24px;
  }
}
.aht-ban--events {
  .aheto-socials--banner .aht-socials__link + .aht-socials__link {
    margin-left: 10px;
  }
}
