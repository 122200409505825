.aht-info {
  transition: all 0.3s;
  background-color: $c-white;

  &__img {
    height: 280px;
    background-position: center;
    background-size: cover;
  }

  &__img-inner {
    width: 0;
    height: 0;
  }

  &__img-link {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__content {
  }

  &__persons {
    display: flex;
  }

  &__person {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }

  &__person-img {
    width: 0;
    height: 0;
  }

  &__subtitle {
    margin: 5px 0 0;
  }

  &__title {
    font-weight: 700;
  }

  &__desc {
    margin: 25px 0 0;
    line-height: 24px;
  }

  &__link {
    margin-top: 30px;
    letter-spacing: 1px;
  }

  &__details {
    display: flex;
  }

  &__detail {
    display: flex;
    flex: 1 1 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 18px 10px;
  }

  &__detail-text {
    font-size: 15px;
    line-height: 24px;
  }

  &__detail-icon {
    display: inline-block;
    margin-right: 6px;
    font-style: normal;
  }

  &--yoga-course {
    text-align: center;
    box-shadow: 0px 4px 50px 0px rgba($c-black, 0.07);

    @include menu-size-min {
      &:hover {
        transform: translate(0, -30px);
        box-shadow: 0px 39px 54px 0px rgba($c-black, 0.14);

        .aht-info__person {
          margin-left: -18px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    @include small-size-max {
      max-width: 500px;
      margin: 0 auto;
    }

    .aht-info {

      &__content {
        position: relative;
        padding: 60px 40px 40px;

        @include small-size-max {
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      &__persons {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &__person {
        position: relative;
        margin-left: -58px;
        transition: all 0.3s;
        border: 5px solid $c-white;

        &:first-child {
          margin-left: 0;
          z-index: 5;
        }

        &:nth-child(2) {
          z-index: 4;
        }

        &:nth-child(3) {
          z-index: 3;
        }

        &:nth-child(4) {
          z-index: 2;
        }

        &:nth-child(5) {
          z-index: 5;
        }
      }

      &__subtitle {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__title {
        margin: 10px 0;
      }

      &__desc {
        min-height: 48px;
        font-weight: 500;
      }

      &__link {
        &:not(:hover) {
          border-color: rgba($c-black, 0.07);
          background-color: transparent;
        }
      }

      &__details {
        border-top: 1px solid rgba($c-black, 0.07);
      }

      &__detail {
        border-right: 1px solid rgba($c-black, 0.07);

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  &--yoga-event {
    position: relative;
    text-align: center;
    box-shadow: 0px 4px 50px 0px rgba($c-black, 0.07);

    @include menu-size-min {
      &:hover {
        transform: translate(0, -30px);
        box-shadow: 0px 39px 54px 0px rgba($c-black, 0.14);
      }
    }

    .aht-info {
      &__title {
        margin: 0;
        letter-spacing: 0.3px;
      }

      &__desc {
        margin-top: 23px;
        font-weight: 500;
      }

      &__content {
        padding: 35px 30px 45px;
      }

      &__link {
        padding-right: 35px;
        padding-left: 35px;

        &:not(:hover) {
          border: 1px solid rgba($c-black, 0.07);
          background-color: transparent;
        }
      }

      &__details {
        justify-content: space-between;
        padding-right: 27px;
        padding-left: 15px;
        border-top: 1px solid rgba($c-black, 0.07);

        @media screen and (max-width: 1500px) {
          padding-right: 0;
          padding-left: 0;
        }
      }

      &__detail {
        flex: 0 1 auto;

        &--date {
          position: absolute;
          top: 20px;
          left: 20px;
          width: 80px;
          height: 80px;
          padding: 18px 0;
          border-radius: 50%;
          background-color: $c-white;

          .aht-info__detail-text {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 3px;
            line-height: 1;
            text-transform: uppercase;
            word-spacing: 80px;

            &:first-line {
              font-size: 30px;
              letter-spacing: 1px;
            }
          }
        }
      }

      &__detail-text {
        line-height: 20px;
      }
    }
  }
}
