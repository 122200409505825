$pricing-border-radius: 5px;
$pricing-bg: #fff;
$pricing-content-padding: 50px 50px 80px;
$pricing-header-padding: 13px 30px;
$pricing-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
$pricing-default-btn-bg: #eeeeee;
$pricing-default-btn-color: #999999;

$pricing-transtion: 0.3s color, 0.3s background-color, 0.3s box-shadow, 0.3s transform, 0.3s border-color;


.aheto-pricing {
  $p: &;
  transition: $pricing-transtion;
  border-radius: $pricing-border-radius;
  background-color: $pricing-bg;
  text-align: center;
  overflow: hidden;


  &--alternative {
    box-shadow: $pricing-shadow;

    .aheto-pricing__content {
      padding: 40px 40px 55px;
      transition: $pricing-transtion;
      border: 1px solid #eee;
      border-top: 0;

      @include large-size-max {
        padding: 30px 20px 50px;
      }

      @include medium-size-max {
        padding: 30px 30px 50px;
      }
    }

    .aheto-pricing__title {
      margin: 0;
      transition: $pricing-transtion;
    }

    .aheto-pricing__header {
      padding: $pricing-header-padding;
      transition: $pricing-transtion;
    }

    .aheto-pricing__cost {
      margin-bottom: 40px;
      transition: $pricing-transtion;

      &-value {
        font-size: 90px;

        @include medium-size-max {
          font-size: 60px;
        }
      }

      &-time {
        margin-top: -15px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }

    .aheto-pricing__description {
      ul {
        li {
          margin-bottom: 10px;
          padding-left: 25px;
          position: relative;
          font-size: 14px;

          &:before {
            content: "";
            width: 7px;
            height: 7px;
            position: absolute;
            top: 6px;
            left: 0;
            border-radius: 50%;
          }
        }
      }
    }

    .aheto-pricing__btn {
      margin-top: 30px;
    }

    @include medium-size-max {
      .aheto-pricing__cost {
        margin-bottom: 25px;
      }
    }

    @include medium-size-min {
      &:hover {
        .aheto-pricing__cost,
        ul li,
        ol li,
        ul li:before,
        ol li:before,
        .aheto-pricing__title {
          color: #fff;
        }

        .aheto-pricing__header {
          outline: none;
        }

        .aheto-pricing__description {
          ul {
            li {
              color:$c-white;

              &:before {
                background: $c-white;
              }
            }
          }
        }
      }
    }
  }

  &--default {
    .aheto-pricing__title {
      margin: 0 0 20px;
      font-size: 20px;
    }

    .aheto-pricing__cost {
      margin-bottom: 30px;
    }

    .aheto-pricing__description {
      margin-top: 45px;
    }

    @include medium-size-max {
      .aheto-pricing__cost {
        margin-bottom: 30px;
      }

      .aheto-pricing__description {
        margin-top: 30px;
      }
    }
    @media screen and (max-width: 768px) {
      .aheto-pricing__cost {
        margin-bottom: 20px;
      }
      .aheto-pricing__description {
        margin-top: 15px;
      }
    }
  }

  &--home-saas {
    $border: 1px solid #f7f7f7;

    width: calc(100% / 3);
    flex-grow: 1;
    padding-bottom: 75px;
    border-radius: 0;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
      padding-bottom: 45px;
    }
    .aheto-pricing__btn{
      cursor: pointer;
      &:hover{
        background-color: #FF704F;
        color: #fff;
        box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(255, 112, 79, 0.2)!important;
        border-color:#FF704F;
      }
    }
    &:not(:last-of-type) {
      position: relative;
      &:after {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        border-left: $border;
        content: '';
      }
    }
    #{$p}__header {
      margin: 0 -50px;
    }
    #{$p}__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      background: #f3f9ff;
      font-weight: bold;
    }
    #{$p}__cost-value {
      margin-top: 50px;
      margin-bottom: 40px;
      font-weight: 400;
      @media screen and (max-width: 768px) {
        margin-top: 28px;
        margin-bottom: 20px
      }
    }
    #{$p}__content {
      padding-top: 0;
      padding-bottom: 40px;
      @media screen and (max-width: 768px) {
        padding-bottom: 20px;
      }
    }
    #{$p}__details {
      padding-left: 0;
    }
    #{$p}__detail {
      border-bottom: $border;
      min-height: 41px;
      &--checked {
        margin-top: 16px;
        color: #999;
        &:before {
          content: "\f00c";
          font-family: FontAwesome;
          top: -3px;
          left: 50%;
          transform: translateX(-50%);
          color: #4fb557;
        }
      }
      &--not-labeled {
        padding: 0 20px;
        margin-top: 20px;
        position: relative;
      }

      .green{
        color: #4fb557;
      }
      .padding-10r{
        padding-right: 10px;
      }
    }
    .ul--dotted{
      li{
        &:before {
          top: 5px;
          display: block;
          position: relative;
          float: left;
          font-size: 9px;
        }
      }
    }
    @include medium-size-max {
      [data-label] {
        padding-left: 0;
        &:before {
          display: none;
        }
        &:after {
          content: attr(data-label);
        }
      }
    }
  }

  &--legend {
    &__header {
      justify-content: flex-start;
      max-width: 360px;
    }
    #{$p}__title {
      height: 180px;
      font-size: 30px;
      font-weight: 300;
      text-align: left;
      text-transform: none;
    }

    @include medium-size-max {
      display: none;
    }
  }

  @include medium-size-min {
    &--default,
    &--alternative {
      &:hover {
        transform: translateY(-30px);
        box-shadow: $pricing-shadow;
      }
    }
  }

  &__content {
    position: relative;
    padding: $pricing-content-padding;

    @include medium-size-max {
      padding: 30px 30px 50px;
    }
  }

  &__cost {
    font-family: $f-roboto;

    &-value {
      font-size: 40px;
      font-weight: 300;
    }
  }

  &__description {
    ul,
    ol,
    p {
      margin: 0;
      padding: 0;
    }
  }

  &__ico {
    font-size: 60px;
  }

  &__btn {
    text-transform: uppercase;
  }

  &__footer {
    .aheto-btn {
      display: block;
      width: 100%;
      border: 0;
      border-radius: 0;
      background: $pricing-default-btn-bg;
      color: $pricing-default-btn-color;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__options {
    position: absolute;
    top: 26px;
    right: 0;

    &-item {
      padding: 3px 11px 4px;
      border-radius: 2px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: #fff;
      font-family: $f-roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;

      + .aheto-pricing__options-item {
        margin-top: 5px;
      }
    }
  }
}

// krm table item
.aheto-pricing {
  &:hover{
    .aheto-pricing__footer{
      .aheto-btn{
        background-color: #2a74ed;
        color: #ffffff;
      }
    }
  }
  &__list {
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -1px;
      padding: 16px 0 15px;
      border-bottom: 1px solid #eee;

      &--empty {
        padding: 0;
      }

      h6 {
        margin: 0;
      }

      p {
        display: none;
      }
    }

    &-ico-ok {
      color: #48b80c;
    }
  }

  &--tableHead {
    text-align: left;

    .aheto-pricing__header {
      background: #f6f9ff !important;
      padding: 53px 44px;
    }

    .aheto-pricing__list {
      &-item {
        h6 {
          &:before {
            position: relative;
            top: -2px;
            margin-right: 17px;
            font-family: $f-ionicons;
            font-size: 10px;
            content: "";
          }
        }
      }
    }
  }

  &--tableColumn {
    @include medium-size-min {
      &:hover {
        position: relative;
        box-shadow: $pricing-shadow;
        z-index: 2;
      }
    }

    .aheto-pricing__btn {
      margin: 50px 0 35px;
      border-color: $pricing-default-btn-bg;
      background: $pricing-default-btn-bg;
      color: $pricing-default-btn-color;

      &:hover {
        opacity: 1;
      }
    }
    .aheto-pricing__list-item--empty {
        min-height: 51px;

        @include medium-size-max {
          min-height: auto;
        }
    }

    .aheto-pricing__header {
      padding: 42px 15px 28px;
      transition: $pricing-transtion;

      .aheto-pricing__cost-value {
        font-size: 50px;
      }
    }

    .aheto-pricing__cost {
      margin-top: 20px;
    }

    .aheto-pricing__title,
    .aheto-pricing__cost {
      color: #fff;
    }
    @include medium-size-max {
      .aheto-pricing__btn {
        margin: 35px 0 20px;
      }
    }
  }

  &--tableHead,
  &--tableColumn {
    border-radius: 0;

    .aheto-pricing__header {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .aheto-pricing__title {
        margin: 0;
      }
    }

    .aheto-pricing__content {
      flex: 1;
      padding: 10px 42px;
      border: 1px solid #eee;
      border-top:0px;
    }
  }
}

.aheto-pricing-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 15px;
  &--home-saas {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 73.1px 10.9px rgba(0, 0, 0, 0.09);
    @include medium-size-max {
      /*max-width: 600px;*/
    }
  }
  &--legend {
    max-width: none;
    margin: 0;
    flex-wrap: nowrap;
    .aheto-pricing {
      &__content {
        padding-right: 20px;
        padding-left: 20px;
      }
    }
    @include medium-size-max {
      flex-wrap: wrap;
      max-width: 600px;
      margin: 0 15px;
      .aheto-pricing {
        width: 100%;
      }
    }
  }
}

@include medium-size-max {
  .aheto-pricing {
    &--tableHead {
      .aheto-pricing__content {
        display: none;
      }

      .aheto-pricing__header {
        padding: 25px 30px;
        text-align: center;

        br {
          display: none;
        }
      }
    }

    &__list {
      &-item {
        p {
          display: block;
          margin-top: 0;
          margin-bottom: 5px;
        }
      }
    }

    &--tableColumn {
      .aheto-pricing__header {
        padding: 25px 30px;
      }

      .aheto-pricing__content {
        padding: 15px 30px;
      }
    }
  }
}

@include small-size-max {
  .aheto-pricing {
    &__list {
      &-item {
        &--empty {
          display: none;
        }
      }
    }
  }
}

// for pricing table
.aheto-pricing {
  &-table {
    display: flex;

    &__head {
      display: flex;
      flex: 4;
      flex-direction: column;
    }

    &__content {
      display: flex;
      flex: 9;
    }
  }
  &--tableHead {
    display: flex;
    flex-direction: column;
    height: 100%;

    .aheto-pricing__list-item {
        position: relative;
        padding-left: 12px;

        &:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -3px;
      }
    }
  }

  &--tableColumn {
    flex: 1;
    min-width: 200px;
    .aheto-pricing__content {
      margin-left: -1px;

      @include medium-size-max {
        margin-left: 0;
      }
    }
  }
}

@include medium-size-max {
  .aheto-pricing {
    &-table {
      flex-direction: column;

      &__content {
        flex-wrap: wrap;
      }
    }

    &--tableColumn {
      min-width: 100%;
    }
  }
}

@include small-size-max {
  .aheto-pricing {
    &-table {
      &__content {
        flex-direction: column;
      }
    }

    &--tableColumn {
      min-width: auto;
    }
  }
}


.aheto-pricing {
  &--home-event {
    position: relative;
    padding: 35px 0 50px;
    transition: $pricing-transtion;
    border-radius: 0;
    background-image: url(../img/home/home-event/fill-1.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    &:hover {
      background-color: transparent;
      box-shadow: 0px 15px 30px 0px rgba(0,0,0, .2);
      background: url(../img/home/home-event/fill-2.png);
      background-position: 50% 35%;
    }

    .aheto-pricing__content {
      flex-direction: column;
      padding: 0;
      background: none;
    }

    .aheto-pricing__header {
      padding: 0 10px 30px;
    }

    .aheto-pricing__title {
      margin-bottom: 20px;
    }

    .aheto-pricing__description {
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 600;
    }

    .aheto-pricing__cost-value {
      font-size: 50px;
      font-weight: 700;
      margin-top: 25px;
      margin-bottom: 40px;
    }

    .aheto-pricing__details {
      margin: 10px 0 45px;
      padding: 0 20px;
    }

    .aheto-pricing__detail {
      padding-left: 0;
      margin-bottom: 20px;

      &::before {
        content: none;
      }

      &::after {
        display: block;
        width: 100%;
        margin-top: 15px;
        content: '';
      }

      &:last-of-type {
        &::after {
          border-bottom: 0;
        }
      }
    }

    .aheto-pricing__btn {
      color: $c-white;
      &:hover {
        opacity: 1;
      }
    }
  }
  @media screen and (max-width: 576px) {
    &--home-event {
      background-position-x: 25%;
    }
  }

  &--home-education {
    margin: 0 -15px;
    padding: 65px 40px 75px;
    border-radius: 0;

    &:hover {
      background-color: $c-white;
      box-shadow: 0px 0px 46.98px 7.02px rgba(81, 81, 81, 0.05);
    }

    @media screen and (max-width: 991px) {
      margin: 0;
      &:hover {
        box-shadow: none;
      }
    }

    .aheto-pricing__img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      margin-bottom: 50px;
      background-size: 100px;
    }

    .aheto-pricing__title {
      margin: 0;
    }

    .aheto-pricing__description {
      margin-bottom: 40px;
      font-size: 14px;
      letter-spacing: 1px;
    }

    .aheto-pricing__prices {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 25px 5px 22px;
      border-bottom: 1px solid #eee;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .aheto-pricing__price-value {
      margin: 0;
    }

    .aheto-pricing__price-period {
      margin: 0;
      font-size: 14px;
    }

    .aheto-pricing__btn {
      margin-top: 50px;

      &:hover {
        opacity: 1;
      }
    }
  }
  &--home-construction {
    background: url('../img/construction/constr-pricing-bg.jpg');
    background-size: cover;
    background-position: center -170px;
    .aheto-pricing {
      box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06);
      /*margin-top: 25px;
      margin-bottom: 30px;*/
      max-width: 400px;
      margin: 25px auto 30px auto;
      @include small-size-max{
        margin-top: 0px;
      }
      &__ico {
        font-family: "ElegantIcons";
      }
      &__cost-value {
        font-size: 50px;
        font-weight: 600;
        line-height: 1.2;
        span {
          font-size: 30px;
          line-height: normal;
        }
      }
      hr{
        background: #d9d9d9;
        margin-top: 20px;
      }
      &__content {
        padding: 40px 29px 20px 39px;
      }
      &__description {
        margin-top: 22px;
        li {
          text-align: left;
          margin-bottom: 8px;
          padding-left: 23px;
          position: relative;
          line-height: 1.63;
          &:before {
            display: none;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }
      &__footer{
        .aheto-btn{
          line-height: 1.64;
          letter-spacing: 2px;
          padding: 14px 20px;
        }
      }
    }

  }
}


.aht-pricing {
  display: flex;
  flex-wrap: wrap;

  &__img {
    max-width: 100%;
  }

  &--business {

    .aht-pricing {

      &__head {
        display: flex;
        flex: 4;
        flex-direction: column;
        background-color: $c-white;

        &-caption {
          padding: 47px 45px 50px;
        }

        &-title {
          margin: 0;
        }

        &-list {
          margin: 0;
        }

        &-item {
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba($c-black, 0.07);

          &:before {
            top: -3px;
            position: relative;
            font-size: 7px;
            font-family: 'FontAwesome';
            content: '\f111';
            color:#2a74ed;
            padding-right: 20px;
          }
        }

        &-title {
          letter-spacing: 0;
        }

        &-content {
          flex: 1;
          padding: 10px 40px 40px;
        }

        &-caption {
          min-height: 175px;
        }

        @include medium-size-max {
          flex-basis: 100%;
          display: none;
        }
      }

      &__main {
        display: flex;
        flex: 9;
        flex-wrap: wrap;
      }

      &__item {
        flex: 1 1 0;
        background-color: $c-white;

        &-price,
        &-desc,
        &-title {
          margin: 0;
        }
      }
    }

    .aht-pricing__main {
      @include medium-size-max {
        margin-top: 30px;
      }

      @include small-size-max {
        margin-top: 0;
      }
    }

    .aht-pricing__item {
      transition: all 0.3s;
      text-align: center;

      @include medium-size-min {
        &:hover {
          position: relative;
          margin-top: -18px;
          margin-bottom: -18px;
          box-shadow: 0px 4px 50px 0px rgba($c-black, 0.07);
          z-index: 2;

          .aht-pricing__item-caption {
            min-height: 193px;
            padding-top: 55px;
          }

          .aht-pricing__item-content {
            padding-bottom: 72px;
          }

          .aht-pricing__item-link {

          }
        }
      }

      @include small-size-min {
        &:last-child {
          .aht-pricing__item-caption,
          .aht-pricing__item-content {
            border-right: 0;
          }
        }
      }

      @include small-size-max {
        margin-top: 30px;
      }


      i {
        color: #48B80C;
      }

      &-caption {
        min-height: 175px;
        padding: 37px 30px 30px;
        transition: all 0.3s;

        @include small-size-min {
          border-right: 1px solid rgba($c-white, 0.1);
        }
      }

      &-title {
        margin-bottom: 23px;
        color: $c-white;
        font-size: 16px;
        letter-spacing: 0;
      }

      &-desc {
        margin-top: 10px;
        color: $c-white;
      }

      &-price {
        color: $c-white;
        font-size: 50px;
        letter-spacing: 0;
      }

      &-content {
        padding: 10px 15px 53px;
        transition: all 0.3s;
      }

      &-list {
        margin: 0;
      }

      &-item {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0;
        border-bottom: 1px solid rgba($c-black, 0.07);
        span{
          display: none;
          @include medium-size-max {
            display: block;
          }
        }
        i{
          @include medium-size-max {
            display: none;
          }
        }

        &:before {
          content: none;
        }
      }

      &-link {
        margin-top: 50px;
        text-transform: uppercase;

        @include medium-size-max {
          color: $c-white;
        }

        &:hover {
          color: $c-white;
        }
      }

      @include small-size-max {
        flex-basis: 100%;
      }
    }
  }

  &--yoga {
    text-align: center;

    .aht-pricing {

      &__head {
        flex: 4;
        background-color: $c-white;

        &-caption {
          padding: 47px 45px 55px;
        }

        &-title {
          margin: 0;
        }
      }

      &__main {
        display: flex;
        flex: 9;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -15px;
      }

      &__item {
        flex: 1 1 0;
        margin: 0 15px 65px;
        padding: 50px 37px 55px 43px;
        background-color: $c-white;

        &-price,
        &-desc,
        &-title {
          margin: 0;
        }

        &--active {
          position: relative;
          margin-bottom: 0;
          padding-bottom: 48px;

          &:before {
            display: block;
            position: absolute;
            top: 17px;
            right: 22px;
            width: 59px;
            height: 59px;
            border-radius: 50%;
            color: $c-white;
            font-family: $f-elegant;
            font-size: 26px;
            line-height: 56px;
            content: '\e033';
          }
        }

        @include medium-size-max {
          flex-basis: 100%;
          margin-bottom: 30px;
        }
      }

      &__item-title {
        font-size: 24px;
        font-weight: 700;
      }

      &__item-price {
        margin-top: 19px;
        font-size: 40px;
        font-weight: 700;
      }

      &__item-desc {
        margin-top: 15px;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__item-list {
        margin: 35px 0 40px;
        text-align: left;
      }

      &__item-item {
        padding: 19px 0 16px 33px;
        border-bottom: 1px solid rgba($c-black, 0.07);
        line-height: 24px;

        &:before {
          top: 18px;
          left: -2px;
          font-family: $f-elegant;
          content: '\4e';
        }
      }

      &__item-link {
        border-color: transparent;
        background-color: rgba($c-black, 0.07);
        text-transform: uppercase;

        &:hover {
          color: $c-white;
        }
      }
    }
  }

  &--edu {

    &.two-in-row {
      .aht-pricing__item {
        flex-basis: 50%;
      }
    }

    &.three-in-row {
      .aht-pricing__item {
        flex-basis: 33.33%;

        @include medium-size-max {
          flex-basis: 100%;
        }
      }
    }


    .aht-pricing {

      &__item {
        flex-basis: 100%;
        margin-bottom: 50px;
        padding: 65px 35px 75px;
        transition: all 0.3s;
        border-right: 1px solid rgba($c-black, 0.05);
        text-align: center;

        &:last-child {
          border-right: 0;
        }

        &:hover {
          background-color: $c-white;
        }
      }

      &__img {
        display: inline-block;
        max-height: 100px;
        margin-bottom: 37px;
      }

      &__icon {
        display: inline-block;
        margin-bottom: 37px;
      }

      &__title {
        margin: 0;
        font-weight: bold;
      }

      &__desc {
        display: inline-block;
        margin-top: 18px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__list {
        margin: 50px 0 0;
      }

      &__line {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: 0;
        }

        &:before {
          content: none;
        }
      }

      &__price {
        margin-right: 15px;
        font-size: 24px;
        font-weight: bold;
      }

      &__per {
        font-size: 14px;
        font-weight: bold;
        line-height: 24px;
        text-transform: uppercase;

        &:before {
          content: '/ ';
        }
      }

      &__link {
        margin-top: 50px;
        letter-spacing: 2px;
      }
    }
  }

  &--trvl {
    display: block;
    position: relative;

    &:before {
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      transition: all 0.3s ease-out;
      border: 14px dotted $c-white;
      content: '';
    }

    &:hover {
      z-index: 1;

      &:before {
        border-color: #F8F8F8;
      }

      .aht-pricing__inner {
        background-color: $c-white;
        box-shadow: 0px 0px 50px 20px rgba($c-black, 0.03);
      }
    }

    .aht-pricing {

      &__inner {
        padding: 7px;
        transition: all 0.3s ease-out;
        text-align: center;
      }

      &__caption {
        padding: 40px 15px;
        border-bottom: 1px dotted #eee;
      }

      &__title {
        margin: 0;
      }

      &__desc {
        display: inline-block;
        margin-top: 13px;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.2em;
        text-transform: uppercase;
      }

      &__content {
        padding: 37px 20px 50px;
      }

      &__price {
        font-size: 50px;
        font-weight: bold;
      }

      &__list {
        margin: 17px 0 0;
      }

      &__list-item {
        padding: 13px 0;
        border-bottom: 1px solid #eee;

        &:before {
          content: none;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      &__link {
        margin-top: 40px;

        &:hover {
          color: $c-white;
        }
      }
    }
  }


  &--rest {
    .aht-pricing {
      &__list {
        margin-top: 60px;
        @include medium-size-max {
          margin-top: 20px;
        }
      }
      &__item {
        flex-basis: 50%;
        @include small-size-max {
          flex-basis: 100%;
          &:last-child {
            margin-top: 30px;
          }
        }
      }
      &__line {
        padding-left: 20px;
        margin: 14px 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 150px;
        transition: ease 0.4s;
        background-color: #ffffff;
        position: relative;
        @include xs-size-max {
          flex-direction: column-reverse;
          max-height: unset;
          padding: 20px 10px;
          margin-bottom: 20px;
          height: 250px;
        }
        &:hover {
          box-shadow: 0 15px 38px rgba(0, 0, 0, 0.08);
        }
      }
      &__special {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid #80261b;
        top: 0px;
        left: 0px;
      }
      &__special-text {
        position: absolute;
        top: -22px;
        right: 60px;
        color: #80261b;
        font-family: $f-playfair-display;
        font-size: 24px;
        font-weight: 700;
        font-style: italic;
        padding: 0 12px;
        -webkit-font-smoothing: antialiased;
        background-image: repeating-linear-gradient(rgba(107, 107, 107, 0) 23px, rgba(100, 100, 100, 0) 41px,
                #ffffff 15px, #ffffff 46px);
      }
      &__img-holder {
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @include xs-size-max {
          width: 120px;
          height: 120px;
        }

      }
      &__price-ultraWrap {
        padding: 34px 34px 34px 14px;
        display: flex;
        flex-direction: column;
        max-width: 448px;
        width: 100%;
        @include small-size-max {
          max-width: 570px;
        }
        @include xs-size-max {
          padding: 10px;
        }
      }
      &__price-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &__per,
      &__price {
        color: #222;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        -webkit-font-smoothing: antialiased;
      }
      &__title {
        color: #80261b;
        font-family: $f-playfair-display;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
      &__composition {
        color: #999;
        font-family: $f-playfair-display;
        font-size: 16px;
        font-style: italic;
        line-height: 24px;
        max-width: 315px;
        margin-top: 20px;
        @include small-size-max {
          max-width: 420px;
        }
      }
    }
  }




  &--rest-speciality {
    .aht-pricing {
      &__item {
        flex-basis: 50%;
        @include xs-size-all {
          flex-basis: 100%;
          padding: 0!important;
        }
        &:first-child {
          padding-right: 30px;
        }
        &:last-child {
          padding-left: 30px;
        }
      }
      &__price-ultraWrap {
        width: 100%;
      }
      &__price-wrap {
        display: flex;
        justify-content: space-between;
      }
      &__line {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
      }
      &__per {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
      }
      &__price {
        color: #999;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1.8px;
      }
      &__list {
        margin-bottom: 0;
      }
    }
  }

}

.corporate {
  img {
    margin-right: 10px;
  }
}

.restaurant-menu-wrap {
  position: relative;
  .bg-text {
    position: absolute;
    top: -50px;
    right: -10px;
    color: #F7F7F7;
    font-size: 150px;
    font-weight: 800;
    letter-spacing: 15px;
    text-transform: uppercase;
    @include small-size-max {
      display: none;
    }
  }
}

.rest-menu-salads,
.rest-menu-deserts,
.leaf-bg,
.rest-menu-drinks,
.restaurant-speciality,
.rest-menu-form {
  background-repeat: no-repeat;
}

.rest-menu-deserts,
.leaf-bg,
.rest-menu-drinks,
.rest-menu-form {
  background-size: auto;
}

.rest-menu-salads {
  background-size: 100%;
  background-position-y: bottom;
}
.rest-menu-deserts {
  background-position-y: bottom;
  .aht-pricing--rest .aht-pricing__line {
    background-color: #FBFBFB;
  }
}
.leaf-bg {
  background-position: right top;
  background-color: #fbfbfb;
}
.rest-menu-drinks {
  background-position: right 30%;
}
.rest-menu-salads-home {
  background-position-y: 130px;
}
.rest-menu-form {
  background-position: left top;
}




.restaurant-speciality {
  background-size: auto;
  background-position: right top;
  .bg-text {
    left: -50px;
    top: -40px;
    right: unset;
    @include medium-size-max {
      display: none;
    }
  }
  .rest-speciality-container {
    display: flex;
    align-items: center;
    @include small-size-max {
      flex-direction: column;
    }
  }
  .speciality-img {
    height: 100%;
    width: 55%;
    .aheto-single-img {
      height: 100%;
      box-shadow: 0 0 59px rgba(0, 0, 0, 0.2);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 1439px) {
      width: 80%;
    }
    @include small-size-max {
      width: 100%;
    }
  }
  .speciality-info-wrap {
    padding: 66px 0;
    max-width: 770px;
    width: 100%;
    transform: translateX(-170px);
    .speciality-info {
      padding: 80px 100px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      max-width: 770px;
      width: 100%;
      &:before {
        display: none;
      }
      .aheto-btn-container {
        @include xs-size-all {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: fit-content;
          margin-right: 0;
        }
        a {
          -webkit-font-smoothing: antialiased;
        }
        .aheto-btn:first-child {
          margin-right: 33px;
          @include xs-size-all {
            margin-bottom: 30px;
            margin-right: 0;
          }
          &:hover {
            color: #80261B;
            background-color: #fff;
          }
        }
      }
      @include large-size-max {
        padding: 70px 66px;
      }
      @include small-size-max {
        padding: 70px 50px;
      }
      @include xs-size-max {
        padding: 50px 35px;
      }
    }
    @media screen and (max-width: 1439px) {
      margin-left: -440px;
      transform: none;
    }
    @include small-size-max {
     margin: unset;
     padding: 0;
    }
  }
}
