.karma-copyright {
  padding: 40px 0;
  color: $c-white;
  font-size: 15px;
  line-height: 24px;

  p {
    margin: 0;
    color: $c-white;
    font-size: 15px;
    line-height: 24px;
  }

  @include small-size-max {
    padding: 20px 0;
  }
}
