.aht-deal {

  &--trvl-1 {

    @include small-size-max {
      margin: 0 -15px;
    }

    .aht-deal {
      &__item {
        width: 25%;
        height: 440px;
        border: 15px solid transparent;

        @media screen and (max-width: 1500px) {
          height: 340px;
        }

        @include large-size-min {
          &--height {
            height: 880px;

            .aht-deal__content {
              padding: 30px 30px 65px;
            }

            @media screen and (max-width: 1500px) {
              height: 680px;
            }
          }

          &--width {
            width: 50%;

            .aht-deal__content {
              padding: 30px 30px 65px;
            }
          }
        }

        @include large-size-max {
          width: 50%;
          height: 520px;
        }

        @include medium-size-max {
          height: 380px;
        }

        @include small-size-max {
          width: 100%;
          height: 520px;
        }

        @include xs-size-max {
          height: 400px;
        }
      }

      &__item-inner {
        height: 100%;
        transition: all 0.3s;
        border-radius: 5px;
        background-position: center;
        background-size: cover;
        box-shadow: inset 0px -200px 130px -65px rgba($c-black, 0.7);
        overflow: hidden;

        &:hover {
          box-shadow: inset 0px -200px 130px -65px rgba($c-black, 0.2);

          .aht-deal__desc {
            color: $c-white;
          }
        }
      }

      &__bg {
        width: 0;
        height: 0;
      }

      &__link {
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
      }

      &__content {
        padding: 30px;
      }

      &__price {
        margin: 0 0 5px;
      }

      &__title {
        margin: 0;
        color: $c-white;
      }

      &__desc {
        margin: 13px 0 0;
        transition: all 0.3s;
        font-weight: 400;
      }
    }
  }
  
  &--trvl-2 {
    position: relative;
    height: 800px;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 1600px) {
      height: 600px;
    }

    @include xs-size-max {
      height: 500px;
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      background-image: linear-gradient(to bottom, transparent, $c-black);
      content: '';
      opacity: 0.85;
      z-index: 1;

      @include menu-size-max {
        opacity: 0.6;
      }
    }
    
    @include menu-size-min {
      &:hover {
        &:before {
          opacity: 0.4;
        }

        .aht-deal__desc {
          color: $c-white;
        }
      }
    }

    .aht-deal {

      &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 50px 30px;
        z-index: 2;

        @include large-size-min {
          padding: 71px 50px;
        }
      }

      &__promo {
        display: inline-block;
        position: absolute;
        top: 30px;
        right: 30px;
        min-width: 125px;
        padding: 7px 20px;
        border-radius: 15px;
        color: $c-white;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
      }

      &__price {
        margin: 0 0 9px;
      }

      &__title {
        margin: 0;
        color: $c-white;
      }

      &__desc {
        margin: 8px 0 0;
        transition: all 0.3s;

        @include menu-size-max {
          color: $c-white;
        }
      }

      &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }
  }
}
