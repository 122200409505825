.aht-cta {

  h1 {
    @include small-size-max {
      font-size: 40px;
    }
  }

  &__sub-title {
    margin-top: 0;
    margin-bottom: 40px;
    letter-spacing: 3.2px;
    @include small-size-max {
      margin-bottom: 20px;
    }
  }

  &__title {
    b {
		font-weight: inherit;
    }
  }

  &__links {
    margin: 45px -15px -15px;
    @include small-size-max {
      margin: 20px -15px -15px;
    }
    a {
      margin: 15px;
    }
  }

  &__link {
    .icon {
      margin-right: 15px;
    }
  }

  &__tel {
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    vertical-align: middle;

    &-icon {
      display: inline-flex;
      position: relative;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      margin-right: 12px;
      border-radius: 50%;
      font-size: 36px;

      &:after {
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        transform: rotate(35deg);
        font-size: 16px;
        content: '▼';
      }
    }
  }

  &--simple {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 0;

    @include medium-size-max {
      flex-direction: column;
    }

    .aht-cta {

      &__text {
        flex: 1 1 0;
        margin-bottom: 30px;

        @include medium-size-min {
          margin-right: 30px;
          margin-bottom: 0;
        }
      }

      &__subtitle {
        margin: 0 0 15px;
      }

      &__title {
        margin: 0;
        font-weight: bold;
        letter-spacing: 0px;
      }

      &__desc {
        margin: 13px 0 0;
      }

      &__links {
        margin: 0 -10px -10px;
      }

      &__link {
        margin: 10px;
        border-color: rgba($c-white, 0.5);
        line-height: 18px;
        text-transform: uppercase;

        span {
          display: inline-block;
          position: relative;
          top: -3px;
          height: 18px;
          margin-right: 12px;
        }

        &:not(:hover) {
          background-color: $c-white;
        }

        @include small-size-max {
          padding: 12px 25px;
        }
      }
    }
  }

  &--classic {

    .aht-cta {

      &__sub-title {
        margin-bottom: 31px;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px;
      }

      &__title {
        margin: 31px 0 19px;
        font-weight: 300;
        @media screen and (max-width: 768px) {
          margin: 21px 0 9px;
        }
      }

      &__links {
        margin-top: 30px;
        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }
      }

      &__link {
        text-transform: uppercase;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &--modern {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include medium-size-max {
      flex-direction: column;
      text-align: center;
    }


    .aht-cta {

      &__title {
        font-weight: 300;
      }

      &__links {
        flex-shrink: 0;
        margin-top: -15px;
      }

      &__link {
        border-color: $c-white;
        background-color: $c-white;
        text-transform: uppercase;

        &.aheto-btn {
          &--trans {
            color: $c-white;
            &:hover {
              background-color: $c-white;
            }
          }
        }
      }
    }
  }

  &--t-white {
    .aht-cta__sub-title,
    .aht-cta__title,
    .aht-cta__desc,
    .aht-cta__tel {
      color: $c-white;
    }
  }

  &--business {
    .aht-cta__sub-title {
      font-weight: bold;
      text-transform: uppercase;
    }

    .aht-cta__title {
      font-weight: 700;
    }

    .aht-cta__link {
      border-color: rgba($c-white, 0.3);
      background-color: transparent;
      i{
        margin-right: 0px;
      }
      &:hover{
        border-color: #CF2632;
      }
    }
    .aht-cta__tel{
      i{
        &:before{
          position: absolute;
          top: 3px;
          right: 8px;
        }
        &:after{

        }
      }
    }
  }

  &--business-2 {

    .aht-cta__link {
      border-color: rgba($c-white, 0.3);
      background-color: transparent;
    }

    .aht-cta__sub-title {
      display: inline-block;
      position: relative;
      margin-bottom: 10px;
      font-weight: 700;
      text-transform: uppercase;

      &:before {
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba($c-white, 0.3);
        content: '';
      }
    }

    .aht-cta__title {
      margin: 20px 0;
      font-weight: bold;
    }

    .aht-cta__links {
      margin-top: 55px;
    }
  }

  &--yoga {
    .aht-cta {

      &__sub-title {
        margin-bottom: 15px;
        font-family: $f-dancing-script;
        font-size: 30px;
        letter-spacing: 0;
      }

      &__title {
        margin: 0;
        color: $c-white;
        font-weight: 700;
        letter-spacing: 2.5px;
        text-transform: uppercase;
      }

      &__links {
        margin-top: 20px;
      }

      &__link {
        text-transform: uppercase;
      }
    }
  }

  &--construction {

    .aht-cta {
      &__title {
        font-size: 50px;
        font-weight: 600;
        a {
          position: relative;
          font-size: 50px;
          font-style: italic;

          &:before {
            display: block;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 5px;
            content: '';
          }
          @include medium-size-max {
            font-size: 35px;
          }
        }
        @include medium-size-max {
          font-size: 35px;
        }
      }
      &__link {
        text-transform: uppercase;
        border-radius: 0px;
        font-weight: 600;
        letter-spacing: 2px;
        line-height: 1.64;
        padding: 13px 39px;
      }
    }
  }

  &--construction_about {

    .aht-cta {
      &__title {
        font-size: 48px;
        line-height: 1.25;
        @include small-size-max {
            font-size: 25px;
        }
        @include xs-size-max {
            font-size: 22px;
        }
        a {
          @include small-size-max {
            display: table;
            margin: 0 auto;
            font-size: 18px;
          }
        }
      }
      &__links {
        margin: 30px -15px -15px;
      }
      &__link {
        border-radius: 5px;
        padding: 16px 38px;
      }
    }
  }





  &--trvl {
    .aht-cta {

      &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 35px 0;

        @include medium-size-max {
          justify-content: center;
          text-align: center;
        }
      }

      &__title {
        margin: 15px 0;
        color: $c-white;
      }

      &__link {
        margin: 15px 0;

        &:hover {
          border-color: $c-white;
          background-color: transparent;
          color: $c-white;
        }
      }
    }
  }

  &--trvl-2 {
    display: flex;
    position: relative;
    background-position: center;
    background-size: cover;

    @include medium-size-min {
      min-height: 500px;
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($c-black, 0.7);
      content: '';
    }

    .aht-cta {

      &__container {
        display: flex;
        position: relative;

        @include medium-size-min {
          align-items: center;
        }

        @include medium-size-max {
          flex-direction: column;
        }
      }

      &__text {
        padding: 50px 0;

        @include medium-size-min {
          flex: 1 1 50%;
          margin-right: 15px;
        }
      }

      &__subtitle {
        margin: 0;
        font-weight: 400;
      }

      &__title {
        margin: 0;
        color: $c-white;
      }

      &__img-holder {
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
        justify-content: center;

        @include medium-size-min {
          flex: 1 1 50%;
          margin-left: 15px;
        }

        @include medium-size-max {
          align-self: center;
        }
      }

      &__img {
        max-width: 100%;
        max-height: 100%;
      }

      &__form {
        margin-top: 47px;

        br {
          display: none;
        }

        p {
          margin: 0;
        }

        form {
          border-radius: 30px;
          overflow: hidden;

          > p {
            display: flex;

            @include xs-size-max {
              flex-direction: column;
            }
          }
        }

        .wpcf7-form-control-wrap {
          flex: 1;
        }

        [type=email],
        [type=submit] {
          padding: 19px 40px;
          border: 0;
          outline: none;
          font-size: 15px;
          line-height: 1.2;
        }

        [type=email] {
          width: 100%;
          font-weight: 500;
        }

        [type=submit] {
          transition: all 0.3s;
          color: $c-white;
          font-weight: 700;
          letter-spacing: 3px;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }

  &--chr {
    display: flex;
    align-items: center;
    min-height: 580px;
    padding: 60px 0 40px;
    background-position: center;
    background-size: cover;

    .aht-cta {

      &__content {

        @include large-size-min {
          &--right {
            width: 56%;
            margin-left: auto;
            margin-right: -6%;
          }

          &--left {
            width: 44%;
            margin-right: auto;
          }
        }
      }

      &__img {
        display: inline-block;
        max-height: 60px;
        margin-bottom: 30px;
      }

      &__title {
        margin: 0;
        color: $c-white;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: 2.3px;


        b {
          color: inherit;
          font-style: italic;
          font-weight: inherit;
        }
      }

      &__desc {
        margin: 20px 0 0;
        color: $c-white;
        font-size: 18px;
      }

      &__icon-links {
        margin-top: 35px;
      }

      &__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-right: 22px;
        margin-bottom: 15px;
        border-radius: 50%;
        background-color: rgba($c-black, 0.1);
        color: $c-white;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: $c-white;
        }
      }

      &__icon {
        color: inherit;
        font-size: 28px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .aht-cta__text {
      margin-bottom: 15px;
    }
  }

  &--rest {
    .aht-cta {
      &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
      }
      &__img-wrap {
        max-width: 700px;
        position: relative;
      }
      &__img {
        width: 100%;
      }
      &__title {
        margin-top: 50px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 3.6px;
        -webkit-font-smoothing: antialiased;
        text-align: center;
      }
    }
  }
}

.aht-cta-restaurant {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-church-cat-wrap-1,
.news-church-cat-wrap-1,
.ministries-church-cat-wrap-1,
.gallery-church-cat-wrap-1 {
  .aht-cta {
    &--chr {
      .aht-cta {
        &__img {
          margin-bottom: 25px;
        }

        &__title {
          line-height: normal;
          letter-spacing: normal;
        }

        &__desc {
          line-height: normal;
          margin-top: 15px;
        }

        &__icon-links {
          margin-top: 49px;
        }
      }
    }
  }
}
