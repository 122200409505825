.aheto-footer-1 {
  .widget {
    &_aheto__logo {
      margin-bottom: 38px;
    }
    &-title {
      margin-bottom: 50px;
      padding-bottom: 3px;
      color: $c-white;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      display: table;
      @include small-size-max {
        margin-bottom: 30px;
      }
    }
  }
}

.aheto-footer-2 {
  .widget {
    &_aheto {
      &__info {
        &--address {
          padding-right: 20px;
        }
        padding-left: 25px;
        position: relative;
        font-weight: 500;
        a {
          font-weight: 500;
          &:hover {
            color: $c-white;
          }
        }
      }
    }
    &-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
}

.aheto-footer-3 {
  // Instafeed
  .instafeed {
    padding: 0;
    li {
      list-style: none;
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      img {
        max-width: 60px;
        height: auto;
      }
    }
  }
  .widget {
    &_aheto {
      &__title {
        margin-bottom: 37px;
      }
      &__logo {
        margin-bottom: 38px;
      }
      &__desc {
        font-size: 15px;
      }
      .aheto-socials {
        @include medium-size-max {
          text-align: left;
        }
      }
    }
    &-title {
      margin-bottom: 50px;
      font-size: 16px;
      font-weight: 600;
    }
    // NavMenu
    &_nav_menu {
      &_2 {
        .menu {
          &-item {
            a {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

.aheto-footer-4 {
  // Instafeed
  .instafeed {
    padding: 0;
    li {
      list-style: none;
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      img {
        max-width: 80px;
        height: auto;
      }
    }
  }
  .widget {
    &_aheto {
      &__title {
        margin-bottom: 37px;
      }
      &__logo {
        margin-bottom: 38px;
      }
      &__link {
        font-weight: 500;
      }
    }
    &_recent_entries {
      &_2 {
        .post-date {
          margin-top: 7px;
        }
      }
    }
    &-title {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 50px;
    }
  }
}

.aheto-footer-5 {
  .widget {
    &-title {
      color: $c-white;
      margin: 0 0 60px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }
    &_aheto {
      &__title {
        margin-bottom: 47px;
      }
      &__logo {
        margin-bottom: 40px;
      }
      &__info {
        color: $c-white;
      }
      &__link {
        color: $c-white;
      }
    }
    &_recent_entries {
      &_2 {
        li {
          a {
            display: block;
            color: $c-white;
            font-size: 16px;
            line-height: 20px;
            @include small-size-max {
              font-size: 14px;
            }
          }
          .post-date {
            margin-top: 10px;
          }
        }
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_1 {
          button,
          input {
            &[type=email] {
              background-color: $c-white;
              border-radius: 5px;
              width: 100%;
            }
            &[type=submit] {
              color: $c-white;
              letter-spacing: 1.5px;
              width: 100%;
              border-radius: 5px;
            }
          }
        }
      }
      p {
        margin: 25px 0;
      }
    }
  }
}

.aheto-footer-6 {
  .widget {
    &-title {
      color: $c-white;
      margin: 0 0 60px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }
    &_aheto {
      &__title {
        margin-bottom: 47px;
      }
      &__logo {
        margin-bottom: 40px;
      }
      &__info {
        color: $c-white;
        font-family: $f-roboto-slab;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 26px;
        letter-spacing: normal;
        &--mail {
          font-size: 16px;
        }
      }
      &__link {
        color: $c-white;
        font-family: $f-roboto-slab;
        font-weight: normal;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 26px;
        letter-spacing: normal;
      }
    }
    &_recent_entries {
      &_2 {
        li {
          a {
            display: block;
            color: $c-white;
            font-family: $f-roboto-slab;
            line-height: normal;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            @include small-size-max {
              font-size: 14px;
            }
          }
          .post-date {
            margin-top: 10px;
          }
        }
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_1 {
          button,
          input {
            &[type=email] {
              background-color: $c-white;
              border-radius: 5px;
              width: 100%;
            }
            &[type=submit] {
              letter-spacing: 1.5px;
              width: 100%;
              border-radius: 5px;
            }
          }
        }
      }
      p {
        margin: 25px 0;
      }
    }
  }
}

.aheto-footer-7 {
  .widget {
    &_nav_menu {
      margin-bottom: 94px;
      margin-top: 54px;
      @include medium-size-max {
        margin-bottom: 60px;
        margin-top: 20px;
      }
      @include xs-size-max {
        margin-bottom: 40px;
      }
      .menu {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        &-item {
          -webkit-box-flex: 0;
          -ms-flex-positive: 0;
          flex-grow: 0;
          width: auto;
          margin: 5px 22px;
          @include medium-size-max {
            margin: 5px 15px;
          }
          a {
            font-size: 14px;
            letter-spacing: 1px;
            text-transform: uppercase;
            &:hover {
              color: $c-white;
            }
          }
        }
      }
    }
  }
}

.aheto-footer-8 {
  .widget {
    .tweets {
      li {
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;
        padding-left: 35px;
        margin-bottom: 25px;
        position: relative;
        &:after {
          content: "\f099";
          font-family: fontawesome;
          color: #fff;
          font-size: 21px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      span{
        color:#fff;
        font-size: 15px;

        a {
          color: #fff;
          font-size: 15px;
          font-weight: 400;
          display: block;
          margin-top: 17px;
        }
      }
    }

    &_aheto {
      &__info {
        &--address {
          padding-right: 20px;
        }
        padding-left: 0px;
        position: relative;
        color: rgba($c-white, 0.6);
        font-weight: 400;
        font-size: 15px;
        a {
          color: rgba($c-white, 0.6);
          font-weight: 400;
          font-size: 15px;
          &:hover {
            color: $c-white;
          }
        }
      }
    }
    &-title {
      position: relative;
      margin-bottom: 75px;
      font-weight: bold;
      &:after {
        display: block;
        position: absolute;
        top: 60px;
        left: -15px;
        width: 40px;
        height: 2px;
        background-color: rgba($c-white, 0.1);
        content: '';
      }
      @include small-size-max {
        margin-bottom: 40px;
        &:after {
          top: 40px;
          left: -5px;
        }
      }
    }
    // Nav Menu
    &_nav_menu {
      .menu {
        &-item {
          width: 100%;
          a {
            color: rgba($c-white, 0.6);
            font-size: 15px;
            font-weight: 400;
            &:hover {
              color: $c-white;
            }
          }
          &.current-menu-item {
            a {
              color: $c-white;
            }
          }
        }
      }
    }
    // Twitter
    &_twitter {
      .widget-content {
        p {
          color: rgba($c-white, 0.6);
        }
      }
      .widget-post {
        margin-bottom: 25px;
      }
      .widget-add {
        span,
        a {
          font-weight: 400;
        }
      }
    }
  }
}

.aheto-footer-9 {
  .widget {
    &_aheto {
      &__title {
        margin-bottom: 48px;
      }
      &__logo {
        margin-bottom: 47px;
        @include small-size-max {
          margin-bottom: 25px;
        }
      }
      &__info {
        &--address {
          padding-right: 20px;
          font-weight: 400;
        }
        padding-left: 0px;
        position: relative;
        font-weight: 500;
        a {
          font-weight: 400;
        }
        &--tel {
          order: 2;
          a {
            font-size: 20px;
            border-bottom:1px solid transparent;
          }
        }
      }
    }
    &-title {
      margin-bottom: 55px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2.8px;
      line-height: 1.86;
      text-transform: uppercase;
      @include small-size-max {
        margin-bottom: 25px;
      }
    }
  }
}

.aheto-footer-10 {
  .widget {
    @include medium-size-max{
      margin-bottom: 40px;
    }
    @include small-size-max{
      margin-bottom: 30px;
    }
    p {
      font-family: $f-source-sans-pro;
    }
    &_aheto {
      &__title {
        margin-bottom: 48px;
      }
      &__logo {
        margin-bottom: 47px;
      }
      &__info {
        position: relative;
        font-weight: 400;
        a {
          font-weight: 400;
          font-size: 16px;
          &:hover {
            color: $c-white;
          }
        }
      }
    }
    &-title {
      margin-bottom: 55px;
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 700;
      @include medium-size-max{
        margin-bottom: 40px;
      }
      @include small-size-max{
        margin-bottom: 30px;
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_2 {
          button,
          input {
            border-radius: 5px 0 0 5px;
            width: calc(100% - 60px);
            &[type=submit] {
              font-size: 18px;
              border-radius: 0 5px 5px 0;
              padding: 10px 15px;
              width: 60px;
            }
          }
        }
      }
    }
    @include medium-size-max {
      p:empty {
        display: none;
      }
    }
  }
}

.aheto-footer-11 {
  .widget {
    &-title {
      margin-bottom: 15px;
      padding-top: 15px;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.2px;
      line-height: 26px;
    }
    &_aheto {
      margin-bottom: 45px;
      &__info {
        padding: 0;
      }
      &__link {
        font-weight: 600;
      }
      &__desc {
        margin: 20px 0;
        font-weight: 600;
        line-height: 24px;
        a {
          padding-left: 15px;
          color: $c-white;
          font-weight: 400;
          &:after {
            display: inline-block;
            margin-left: 10px;
            content: '>';
            position: relative;
            top: 1.5px;
          }
        }
      }
      &__infos {
        display: flex;
        flex-direction: column;
      }
      &__info {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        &--mail {
          order: -1;
        }
      }
    }
    // Nav Manu
    &_nav_menu {
      .menu {
        &-item {
          width: 100%;
          margin-bottom: 10px;
          a {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.aheto-footer-12 {
  .widget {
    &-title {
      font-size: 18px;
      font-weight: 700;
    }
    &_payment {
      .cards {
        margin-top: 40px;
      }
    }
    &_aheto {
      &__info {
        padding-left: 0px;
        position: relative;
        font-weight: 400;
        a {
          font-weight: 400;
          font-style: normal;
          font-size: 16px;
        }
      }
    }
    .aht-socials {
      margin-top: 5px;
      &__icon {
        &:hover {
          background-color: transparent;
        }
      }
      &__link {
        border-color: #eee;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.aheto-footer-13 {
  .widget {
    .tweets {
      li {
        padding-left: 35px;
        margin-bottom: 45px;
        position: relative;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        line-height: 1.71;
        color: #777777;

        &:before {
          content: "\f099";
          font-family: fontawesome;
          color: #fa6742;
          font-size: 15px;
          position: absolute;
          top: 0;
          left: 0;
        }

        span {
          width: 100%;
        }

        a {
          color: $c-white;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          line-height: 1.71;

          &:hover {
            color: #fa6742;
          }
        }
      }

    }
    &_aheto {
      &__title {
        margin-bottom: 48px;
      }
      &__logo {
        margin-bottom: 40px;
      }
      &__desc {
        margin-bottom: 25px;
      }
      &__info {
        position: relative;
        font-weight: 500;
        a {
          font-weight: 500;
          &:hover {
            color: $c-white;
          }
        }
      }
      &__icon {
        color: $c-white;
      }
    }
    &-title {
      margin: 0 0 60px;
      color: $c-white;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.aheto-footer-14 {
  .widget {
    .MyTweets {
      li {
        font-family: Lato, sans-serif;
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;
        padding-left: 35px;
        margin-bottom: 25px;
        position: relative;
        &:before {
          content: "\f099";
          font-family: fontawesome;
          color: #fff;
          font-size: 21px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      span{
        color:#fff;
        font-size: 15px;
        font-family: Lato, sans-serif;
        line-height: 1.6;

        a {
          color: #fff;
          font-size: 15px;
          font-weight: 400;
          display: block;
          margin-top: 17px;
       }
      }
    }
    &_aheto {
      &__title {
        margin-bottom: 48px;
      }
      &__logo {
        margin-bottom: 47px;
      }
      &__info {
        padding: 0;
        position: relative;
        font-weight: 400;
        a {
          font-weight: 500;
          &:hover {
            color: $c-white;
          }
        }
      }
      &__icon {
        color: $c-white;
      }
    }
    &-title {
      margin: 0 0 60px;
      color: $c-white;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 500;
      @include small-size-max {
        margin: 0 0 30px;
      }
    }
    &_nav_menu{
      .menu-item {
        margin-bottom: 0px;
        line-height: 2.67;
        a{
          font-size: 15px;
        }
      }
    }
    .MyTweets{
      .tweets{
       a{
         color: $c-white;
         font-weight: 400;
         line-height: 1.6;
       }
      }
    }
  }
  .aht-socials--circle {
    margin-top: 26px;
  }
  .widget_nav_menu_2{
    .widget-title{
      margin-bottom: 50px;
    }
  }
}

.aheto-footer-15 {
  .widget {
    &-title {
      color: $c-white;
      margin: 0 0 60px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
    }
    &_aheto {
      &__title {
        margin-bottom: 47px;
      }
      &__logo {
        margin-bottom: 50px;
        top: 0px;
      }
      &__icon {
        color: $c-white;
      }
    }
    &_recent_entries {
      &_2 {
        li {
          margin-top: 40px;
          a {
            display: block;
            color: $c-white;
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            max-width: 140px;
            @include small-size-max {
              font-size: 14px;
            }
          }
          .post-date {
            font-size: 13px;
            text-transform: unset;
            font-weight: 400
          }
        }
        .menu-item.current-menu-item{
          a{
            color: #999;
          }
        }
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_1 {
          button,
          input {
            &[type=email] {
              background-color: $c-white;
              border-radius: 5px;
              width: 100%;
            }
            &[type=submit] {
              color: $c-white;
              letter-spacing: 1.5px;
              width: 100%;
              border-radius: 5px;
            }
          }
        }
      }
      p {
        margin: 25px 0;
      }
    }

  }
  .aht-socials--circle{
    .aht-socials__link{
      color: $c-white;
    }
  }
}

