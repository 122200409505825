.aht-post-rest {

  &__cats {
    margin: 0 auto;
    text-align: center;
  }

  &__cat {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 7px 10px;
    border-radius: 3px;
    color: $c-white;
    font-size: 14px;


    &:last-child {
      margin: 0;
    }
  }
  
  &__title {
    margin: 20px 0 12px;
    font-weight: bold;
    text-align: center;

    b {
      font-weight: inherit;
    }
  }

  &__details {
    text-align: center;
  }
  
  &__date,
  &__read {
    font-size: 16px;
    line-height: 24px;
  }

  &__read {
    &:before {
      margin-right: 17px;
      margin-left: 18px;
      font-weight: bold;
      content: '•';
    }
  }

  &__thumbnail {
    margin-top: 69px;
    margin-bottom: 10px;
  }
  @include small-size-max {
    &__thumbnail {
      margin-top: 40px;
    }
  }

  &__content {
    padding-bottom: 60px;
  }
  @include small-size-max {
    &__content {
      padding-bottom: 10px;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include small-size-max {
      justify-content: center;
    }
  }

  &__tags {
    margin-right: 30px;

    &-name {
      margin-right: 15px;
      font-size: 16px;
      font-weight: 500;
    }

    &-link {
      font-weight: 400;
    }

    @include small-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__likes {
    padding: 15px 21px;
    border: 1px solid #eee;
    border-radius: 5px;
    font-weight: 400;

    &-icon {
      margin-right: 7px;
      color: #EE2865;
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding: 50px 50px 44px 70px;
    border: 1px solid #eee;

    @include small-size-max {
      padding: 50px 30px;
      margin-top: 40px;
    }
  }

  &__author-img-holder {
    margin-right: 40px;

    @include small-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__author-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    @include small-size-max {
      margin: 0 auto;
    }
  }

  &__author-about {
    flex: 1;
  }

  &__author-title {
    margin: -3px 0 0;
    font-size: 18px;
    font-weight: bold;
  }

  &__author-desc {
    margin-top: 25px;
  }

  &__author-more {
    margin: 45px 0 0;
  }

  &__author-link {
    font-weight: 400;
    text-decoration: underline;
  }

  &__socials {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .aheto-socials {
      &__link {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #eee;
        text-align: center;
        line-height: 50px;
      }
    }

    @include medium-size-max {
      .aht-socials--circle {
        margin: 0;
      }
    }
    
  }

  @include small-size-max {
    &__socials {
      margin-top: 40px;
      .aheto-socials {
        &__link:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__content {

    blockquote {
      padding-right: 30px;
      padding-left: 30px;

      &:before {
        font-size: 145px;
        top: -3px;
      }

      p {
        max-width: 800px;
        margin: 5px auto 0;
        color: #262527;
        font-size: 30px;
        font-style: italic;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 40px;
        text-transform: none;

        @include small-size-max {
          font-size: 24px;
        }
      }
      
    }
    .centered-text {
      max-width: 830px;
      margin: auto;
    }

    .alignnone {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    h6 {
      margin: 11px 0;
      font-size: 18px;
    }
  }

  img {
    max-width: 100%;
  }
}
