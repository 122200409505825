.label {
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 10px;
    border-radius: 3px;
    padding-left: 7px;
    padding-right: 7px;
   
}
@media screen and (max-width: 1600px){
    .label {
        top: 10px;
    }    
}