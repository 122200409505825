.aht-bq-img {
  display: flex;
  flex-wrap: wrap;

  &__left {
    flex: 1 1 0;
    min-width: 280px;
    min-height: 400px;
    border-right: 3px solid $c-white;
    border-bottom: 3px solid $c-white;
    background-position: center;
    background-size: cover;
  }

  &__right {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    min-width: 280px;
    border-right: 3px solid $c-white;
    border-bottom: 3px solid $c-white;
  }

  &__bq {
    flex: 1 1 220px;
    padding: 35px 40px;
    border-bottom: 3px solid $c-white;

    &:before,
    &:after {
      display: block;
      height: 45px;
      font-family: $f-roboto;
      font-size: 89px;
    }

    &:before {
      content: '“';
    }

    &:after {
      text-align: right;
      content: '”';
    }
  }

  &__bq-text {
    margin: 0;
    padding: 0 10px 0 22px;
    color: $c-white;
    letter-spacing: 0;
    line-height: 26px;
  }

  &__bq-img {
    flex: 1 1 220px;
    background-position: center;
    background-size: cover;
  }
}
