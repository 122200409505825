.aht-pag-rest {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .wrap {
    margin: 0 25px;

    .page-numbers {
      display: inline-block;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      font-weight: 400;
      line-height: 33px;
      font-size: 16px;
      text-align: center;
      color: #222;
      
      &.current,
      &:hover {
        color: $c-white!important;
      }
    }

    @include xs-size-max {
      flex-basis: 100%;
      order: -1;
      text-align: center;
    }
  }

  .prev,
  .next {
    color: #222;
    font-weight: 400;
    margin-top: 3px;
    span {
      font-size: 14px;
      letter-spacing: 1.4px;
    }

    i {
      display: inline-block;
      transition: all 0.3s ease-out;
    }

    @include xs-size-max {
      margin: 15px 15px 0;
    }
  }

  .prev {
    i {
      margin-right: 10px;
    }

    &:hover {
      i {
        transform: translate(-5px, 0);
      }
    }
  }

  .next {
    i {
      margin-left: 10px;
    }

    &:hover {
      i {
        transform: translate(5px, 0);
      }
    }
  }
}
