$c-active: #B58E4C;
$c-light: #F9F9F9;
$c-light-2: #FAFAFA;
$c-grey: #989898;
$c-grey-2: #AEAEAE;
$c-dark: #262626;
$c-dark-2: #515151;
$c-dark-3: #393939;
$c-dark-4: #222222;
$c-dark-5: #222222;
$c-footer-bg: #141414;
$c-alter: #A58247;
$c-alter-2: #A60000;
$c-alter-3: #EEEEEE;
$c-alter-4: #ff0000;
$c-sale-2: #5c9d8a;
$c-bg-mob-menu: rgba(0, 0, 0, .9);

