.aht-tb {
  &--trvl {
    display: flex;
    align-items: center;
    height: 500px;
    background-position: center;
    background-size: cover;

    .aht-tb {

      &__content {
        padding-top: 95px;
        padding-bottom: 50px;
      }

      &__subtitle {
        margin: 0;
        color: $c-white;
        font-weight: 400;

        @include xs-size-max {
          font-size: 24px;
        }
      }

      &__title {
        margin: 0;
        color: $c-white;
        font-size: 100px;
        font-weight: 800;

        @include medium-size-max {
          font-size: 70px;
        }

        @include xs-size-max {
          font-size: 50px;
        }
      }
    }
  } 
}
