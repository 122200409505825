$themify-font-path: "../fonts/themify" !default;

@font-face {
  font-family: 'themify';
  src:url('#{$themify-font-path}/themify.eot?-fvbane');
  src:url('#{$themify-font-path}/themify.eot?#iefix-fvbane') format('embedded-opentype'),
    url('#{$themify-font-path}/themify.woff?-fvbane') format('woff'),
    url('#{$themify-font-path}/themify.ttf?-fvbane') format('truetype'),
    url('#{$themify-font-path}/themify.svg?-fvbane#themify') format('svg');
  font-weight: normal;
  font-style: normal;
}