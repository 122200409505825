.blog--metro {
  .aht-page.container {
      width: 100% !important;
      max-width: 100% !important;
      position: relative;
      margin: 0 !important;
      height: 100%;
  }

  .post {
    .image-wrapper {
      background-size: cover;
      background-position: center;
      object-fit: cover;
    }

    .post-cats {
      margin-bottom: 10px;
      display: table;
      width: 100%;

      a {
        background: #2a74ed;
        color:#fff;
        font-size: 13px;
        line-height: 26px;
        float: left;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 3px;
        padding: 0 10px;
        &:hover {
          background: #1954b4;
          color:#fff;
        }
      }
    }

    .content-wrapper {
      .post-title {
          font-size: 17px;
          line-height: 28px;
          margin: 0;

          a {
            font-size: 20px;
            line-height: 28px;
          }
      }
    }
  }
}
