.aheto-counter {
  position: relative;
  text-align: center;

  &__number {
    margin: 0;
  }

  // Classic
  &--classic {
    .aheto-counter {
      &__icon {
      color: #ccc;
      font-size: 40px;
      }
      &__number {
      font-size: 50px;
      margin-top: 15px;
      margin-bottom: 15px;
        @media screen and (max-width: 768px) {
          margin-top: 0px;
          margin-bottom: 8px;
        }
        &:before {
          content: "+";
        }
      }
      &__desc {
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }

  // Modern
  &--modern {

    .aheto-counter {
      &__number {
      font-size: 40px;
      }

      &__icon {
      font-size: 40px;
      }

      &__number {
      margin-top: 20px;
      margin-bottom: 15px;
     font-weight: 300;
        @media screen and (max-width: 991px) {
          margin-top: 0px;
        }

      &:before {
        content:"+";
      }
      }

      &__desc {
        line-height: 26px;
        @include large-size-min {
          padding: 0 40px;
        }
        @include medium-size-max {
          padding: 0 15px;
          max-width: 300px;
          margin-left:auto;
          margin-right: auto;
        }
      }
  }
  }

  // Home event
  &--home-event {
    margin: 0 -15px;
    padding: 100px 0;

    .aheto-counter {
      &__img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center bottom;

      @include small-size-max {
            background-position: center;
        }
      }

      &__number {
        position: relative;
        margin: 0;
        font-size: 60px;
        z-index: 2;
      }

      &__desc {
        position: relative;
        margin-top: 15px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2.3px;
        z-index: 2;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &--home-event {
      padding: 50px 0px;
    }
  }

  &--divider {

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      content: '';
    }

    @include small-size-max {
      &::after {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
      }
    }
  }

  &--t-white {
    .aheto-counter {
      &__icon,
      &__number,
      &__desc {
      color: $c-white;
    }
  }
  }

  &--yoga {
    text-align: left;

    .aheto-counter {

      &__number {
        margin: 0;
        font-size: 50px;
        font-weight: 700;
        letter-spacing: 1px;
      }

      &__title {
        margin: 8px 0 0;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.7px;
        text-transform: uppercase;
      }

      &__desc {
        max-width: none;
        margin-top: 18px;
        font-weight: 500;
      }

      &--active {
        .aheto-counter__number {
          display: inline-block;
          position: relative;

          &:before {
            position: absolute;
            top: -24px;
            right: -20px;
            font-size: 30px;
            font-weight: 700;
            content: '+';
          }
        }
      }
    }
  }

  &--edu-1 {
    text-align: left;

    @media screen and (max-width: 1229px) {
      display: table;
      margin: 0 auto;
      text-align: center;
    }

    .aheto-counter {
      &__img {
      position: absolute;
      top: -40px;
      left: -50px;
      width: 150px;
      height: 150px;
      background-repeat: no-repeat;
      background-position: (0% 0%);
      background-size: auto;
    }

    &__number {
      position: relative;
      margin: 0;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 3px;
    }

    &__desc {
      max-width: none;
      // margin: 10px 0 0;
      color: $c-white;
      font-weight: bold;
      letter-spacing: 1.5px;
      font-family: $f-roboto;
    }
  }
  }
  // Political
  &--political{
    .aheto-counter{
      &__number{
        color: $c-white;
        font-family: 'Oswald';
        font-size: 40px;
        transform: translateY(-80px);
        margin-top: -20px;
        @include small-size-max {
          transform: translateY(-70px);
          margin-top: -30px;
        }

      }
      &__img{
        height: 150px;
        width: 150px;
        margin: 0 auto;
      }
      &__desc{
        color: #FFFFFF;
      }
    }
  }
  &--edu-2 {
    display: flex;
    flex-wrap: wrap;

    // @include medium-size-max {
    //   padding: 20px 0;
    // }

    @include large-size-min {
      padding: 0 20px;
    }

    .aheto-counter {

      &__item {
        flex: 1 1 0;
        padding: 105px 30px 112px;
        background-repeat: no-repeat;
        background-position: center bottom;

        @include medium-size-min {
          border-right: 1px solid rgba($c-black, 0.07);

          &:last-child {
            border-right: 0;
          }
        }

        @include medium-size-max {
          flex-basis: 50%;
          padding: 70px 15px;
        }

        @include xs-size-max {
          flex-basis: 100%;
          padding: 50px 15px;
        }
      }

      &__number {
        font-size: 60px;
        font-weight: bold;
      }

      &__title {
        margin: 10px 0 0;
        color: $c-white;
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }
  }

  &--construction-home {
    padding: 43px 0 45px;
    @include small-size-max{
      padding: 0px;
    }
    &:after {
      display: none;
    }

    .aheto-counter {

      &__desc {
        //margin-top: 5px;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.6;
      }

      &__number {
        margin: 0;
        font-size: 70px;
        font-weight: normal;
        line-height: 1.2;
        @include small-size-max{
          line-height: 1;
        }
        @include xs-size-max {
          font-size: 50px;
        }
      }
    }
  }

  &--simple {
    display: flex;
    flex-wrap: wrap;

    @include medium-size-max {
      padding: 20px 0;
    }

    @media screen and (min-width: 1500px) {
      padding: 0 50px;
    }

    .aheto-counter {

      &__item {
        flex: 1 1 0;
        padding: 109px 30px 102px;
        background-repeat: no-repeat;
        background-position: center bottom;

        @include medium-size-min {
          border-right: 1px solid rgba($c-black, 0.07);

          &:last-child {
            border-right: 0;
          }
        }

        @include medium-size-max {
          flex-basis: 50%;
          padding: 70px 15px;
        }

        @include xs-size-max {
          flex-basis: 100%;
          padding: 50px 15px;
        }
      }

      &__number {
        color: $c-white;
        font-size: 50px;
        font-weight: bold;
      }

      &__title {
        margin: 7px 0 0;
        color: $c-white;
        font-weight: 600;
      }
    }
  }

// === Restaurant

  &--restaurant {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 40px;
    max-width: 1340px;
    width: calc(100% - 300px);
    margin: 0 auto;
    z-index: 3;
    @media screen and (max-width: 1700px) {
      padding: 0 120px;
    }
    @media screen and (max-width: 1400px) {
      padding: 0;
    }
    @include medium-size-max {
      width: auto;
    }
    .aheto-counter {
      &__item {
        flex: 1 1 0;
        padding: 30px;
        @include medium-size-max {
          flex-basis: 100%;
        }
        @include xs-size-max {
          padding: 20px;
        }
      }
      &__item:first-child .js-counter {
        width: 100px;
      }
      &__item:nth-child(3) .js-counter {
        width: 40px;
      }
      &__item:last-child .js-counter {
        width: 60px;
      }
      &__item-top {
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      &__number {
        font-size: 54px;
        font-weight: 700;
        margin-bottom: 0;
        width: 120px;
        text-align: right;
      }
      &__title {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 5px;
        letter-spacing: 1.2px;
      }
      &__icon-holder {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 60px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &__item:nth-child(2) {
        .aheto-counter__icon-holder {
          background-position: 54% 46%;
        }
      }
      &__item:last-child {
        .aheto-counter__icon-holder {
          background-position: 52% 45%;
        }
      }
    }
  }

  // Home agency
  &--agency {
    margin: 0 -15px;
    padding: 100px 0;

    @include large-size-max {
      padding: 40px 0;
    }

    .aheto-counter {
      &__number {
        position: relative;
        margin: 0;
        font-size: 40px;
        z-index: 2;
      }

      &__desc {
        position: relative;
        margin-top: 15px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2.3px;
        z-index: 2;
      }
    }

    &-plus {
      .aheto-counter {
        &__number {
          &:before {
            content: '+';
            // position: absolute;
            left: 3px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    &--home-event {
      padding: 50px 0px;
    }
  }

}

.rest-counter-wrap {
  position: relative;
  .left-img,
  .right-img {
    position: absolute;
    width: auto;
  }
  .left-img {
    left: 0;
    bottom: -250px;
    z-index: 2;
    @media screen and (max-width: 1700px) {
      bottom: -150px;
    }
    @media screen and (max-width: 1400px) {
      left: -100px;
    }
    @include medium-size-max {
      bottom: -40px;
      left: -130px;
    }
  }
  .right-img {
    right: 0;
    top: -140px;
    @media screen and (max-width: 1400px) {
      top: 0;
      right: -100px;
    }
    @include medium-size-max {
      right: -130px;
    }
  }
}

.img-100hw{
  width: 100%;
  height: 100%;
}
.testemonial-quote{
  position: relative;
  &:after{
    content: '\f10e';
    font-family: 'FontAwesome';
    font-size: 119px;
    color: #f7f7f7;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}
i.icon {
    display: inline;
    line-height: unset;
    vertical-align: middle;
}