.aheto-content {
  $k: &;
  .post {
    margin-top: 60px;
    margin-bottom: 60px;
    background-color: $c-white;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    // Content top
    .content-top-wrapper {
      padding: 40px 50px 45px;

      @include small-size-max {
        padding: 40px 30px 45px;
      }
    }

    // Image
    .image-wrapper {
      position: relative;
      width: 100%;
      height: 500px;
      background-position: center;
      background-size: cover;

      @include small-size-max {
        height: 350px;
      }

      .swiper {
        &--blog-gallery {
          height: 100%;

          .swiper-container {
            height: 100%;
            margin: 0;
            padding: 0;
          }

          .swiper-slide {
            width: 100% !important;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }

          .swiper-button-prev,
          .swiper-button-next {
            display: inline-block;
            transition-duration: 0.2s;
            color: $c-white;
            opacity: 1;

            &:hover {
              opacity: 0.4;
            }
          }

          .swiper-button-prev {
            left: 40px;
          }

          .swiper-button-next {
            right: 40px;
          }

          @include small-size-max {
            .swiper-button-prev {
              left: 15px;
            }

            .swiper-button-next {
              right: 15px;
            }
          }
        }
      }
    }

    // Audio
    .audio-wrapper {
      padding: 0 50px;

      @include small-size-max {
        padding: 0 30px;
      }
    }

    .gallery {
      &-wrapper {
        display: flex;
        flex-wrap: wrap;
      }

      &-image {
        position: relative;
        flex: 1;
        min-width: 270px;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      &-overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        background-color: rgba($c-black, 0);
        cursor: pointer;

        &:hover {
          background-color: rgba($c-black, 0.7);

          i {
            opacity: 1;
          }
        }

        i {
          margin: auto;
          transition: all 0.3s;
          color: $c-white;
          font-size: 24px;
          line-height: 30px;
          opacity: 0;
        }
      }

      &-modal {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($c-black, 0.9);
        overflow: auto;
        z-index: 100;
      }

      &-modal-content {
        display: block;
        width: 80%;
        max-width: 970px;
        margin: auto;
        animation-name: zoom;
        animation-duration: 0.6s;
      }

      @keyframes zoom {
        from {transform: scale(0)}
        to {transform: scale(1)}
      }

      &-close {
        position: absolute;
        top: 20px;
        right: 35px;
        transition: 0.3s;
        font-size: 40px;
        font-weight: bold;

        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    // Video
    .video {
      &-wrapper {
        position: relative;
      }

      &-thumbnail {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 100;
      }

      &-play-btn {
        display: flex;
        position: relative;
        width: 100px;
        height: 100px;
        margin: auto;
        border-radius: 50%;
        background-color: $c-white;
        cursor: pointer;

        &:hover {
          &:before {
            transform: scale(1.1, 1.1);
          }
        }

        &:before {
          margin: auto;
          font-family: $f-ionicons;
          font-size: 30px;
          content: '\f488';
        }
      }

      @include xs-size-max {
        &-play-btn {
          width: 60px;
          height: 60px;

          &:before {
            font-size: 16px;
          }
        }
      }
    }

    .mejs-container {
      width: auto !important;
    }

    video {
      width: 100%;
      height: auto;
    }

    // Youtube, Vimeo
    iframe {
      width: 100%;
      min-height: 500px;

      @include small-size-max {
        min-height: 350px;
      }
    }

    .blqt {
      padding: 80px 100px 50px;

      &:before {
        top: -22px;
        right: 3%;
        left: auto;
        transform: translate(0, 0);
        color: $c-white;
        font-size: 150px;
      }

      p {
        margin: 0;
        color: $c-white;
        font-size: 30px;
        font-weight: 400;
        line-height: 1.2;
        text-transform: none;
      }

      @include small-size-max {
        padding: 75px 15px 60px;

        &:before {
          top: -15px;
          font-size: 150px;
        }

        h3 {
          p {
            font-size: 26px;
          }
        }
      }
    }

    // Content bottom
    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px 50px 45px;

      @include small-size-max {
        padding: 40px 30px 45px;
      }

      .aheto-btn {
        margin-top: 20px;
      }

      .post-cats {
        display: none;
      }
    }

    &-title {
      margin: 10px 0 7px;
      font-size: 30px;

      a {
        font-size: 30px;
        font-weight: 300;
      }
    }

    &-date {
      display: inline-block;
      margin: 0;
    }

    &-author {
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
        border-radius: 50%;
      }

      h6 {
        margin: 0;
      }
    }

    &-likes {

      span {
        font-size: 14px;
      }

      i {
        margin-right: 8px;
        font-size: 16px;
      }
    }

    &-comments {

      span {
        font-size: 14px;
      }

      i {
        margin-right: 12px;
        font-size: 16px;
      }
    }

    &-cats {
      display: inline-block;

      a {
        display: inline-block;
        margin: 3px 3px 3px 0;
        padding: 5px 8px;
        border-radius: 5px;
        color: $c-white;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 13px;
        text-transform: none;
      }
    }

    &-content {
      font-size: 16px;
      line-height: 1.5;
      word-break: break-word;

      p {
        margin: 5px 0;
      }
    }

    // Post bottom info
    &-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      border-top: 1px solid #eee;

      &__item {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        min-width: 150px;
        padding: 10px;
        border-left: 1px solid #eee;

        &:first-child {
          border-left: 0;
        }
      }

      @include small-size-max {
        &__item {
          border-left: 0;
        }
      }
    }

    &-additional {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tags {
        display: inline-block;
        padding-right: 15px;
        line-height: 32px;

        h6 {
          display: inline-block;
          margin: 0;
        }
      }

      .likes {
        display: inline-block;

        button {
          padding: 15px 28px;
          border: 1px solid #eee;
          border-radius: 5px;
          outline: none;
          background-color: transparent;
          font-size: 16px;
          cursor: pointer;

          i {
            margin-right: 10px;
            color: #EE2865;
          }
        }
      }
    }

    // Share
    &-share {
      margin-top: 45px;
      text-align: center;

      button {
        width: 50px;
        height: 50px;
        margin: 4px;
        transition: all 0.3s;
        border-radius: 50%;
        background-color: transparent;
        font-size: 16px;
        line-height: 48px;
        cursor: pointer;

        &:hover {
          color: $c-white;
        }
      }
    }

    &-data {
      padding-bottom: 75px;

      img {
        max-width: 100%;
        height: auto;
      }

      .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
        grid-gap: 30px;

        &-item {
          margin: 0;
        }

        &-caption {
          margin-top: 40px;
          line-height: 26px;
        }

        @media screen and (max-width: 576px) {
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        }
      }
    }
  }

  // About author
  .comm {
    &-block {
      display: flex;
      margin-top: 70px;
      padding: 50px 70px 60px;
      border: 1px solid #eee;
    }

    &-img {
      padding-right: 40px;

      img {
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    &-txt {
      p {
        margin: 0;
      }

      .author {
        font-size: 16px;
        line-height: 26px;

        b {
          font-weight: 500;
        }
      }

      .text {
        margin-top: 25px;
      }

      .read-more {
        margin-top: 40px;

        a {
          text-decoration: underline;
        }
      }
    }

    @include small-size-max {
      &-block {
        flex-wrap: wrap;
        padding-right: 15px;
        padding-left: 15px;
      }

      &-img {
        margin: 0 auto;
        padding-right: 0;
      }

      &-txt {
        .author {
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }

  // Comments
  .karma-comments-list {
    margin-top: 70px;

    ul {
      margin: 0;
      padding-left: 0;

      &.children {
        margin-left: 70px;

        @include small-size-max {
          margin-left: 15px;
        }
      }

      li {
        margin-bottom: 30px;
        padding-left: 0;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: none;
        }
      }
    }

    .comm {
      &-block {
        min-height: 170px;
        margin: 0;
        padding: 30px 30px 35px;
      }

      &-img {
        padding-top: 5px;
        padding-right: 30px;
      }

      &-txt {
        flex: 1;

        .author {
          display: inline-block;
        }

        .text {
          margin-top: 15px;
        }

        .date-post {
          display: inline-block;
          margin-left: 15px;

          p {
            font-size: 14px;

            &:before {
              margin-right: 15px;
              content: '•';
            }
          }
        }
      }

      @include small-size-max {

        &-block {
          padding: 30px 15px;
        }

        &-img {
          flex-basis: 100%;
          padding-right: 0;
          text-align: center;
        }

        &-txt {
          .date-post {
            display: block;
            margin-left: 0;

            p {
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }

  // Leave a comment
  .comment {

    &-reply-link {
      position: absolute;
      top: 115px;
      left: 30px;
      font-size: 14px;

      &:before {
        margin-right: 10px;
        font-family: $f-ionicons;
        font-size: 18px;
        content: '\f21e';
      }

      @include small-size-max {
        display: block;
        position: static;
        margin-left: 0;
      }
    }

    &-respond {
      margin-top: 115px;
      text-align: center;

      h3 {
        font-weight: 300;
      }
    }

    &-reply-title {
      margin-top: 0;
      margin-bottom: 70px;
    }

    &-form {
      label {
        display: none;
      }

      input {
        width: 100%;
        min-width: 200px;
        margin-bottom: 30px;
        padding: 20px;
        transition: all 0.3s;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;

        &:focus {
          border-radius: 5px;
        }

        &[type=submit] {
          width: auto;
          min-width: 0;
          margin-top: 50px;
          margin-bottom: 0;
          padding: 20px 40px;
          color: $c-white;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }

      textarea {
        height: 140px;
        padding: 20px;
        transition: all 0.3s;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;
        resize: none;
        &:focus {
          border-radius: 5px;
        }
      }

      .wrapper-column {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;

        .column {
          flex: 1;
          margin: 0 15px;
        }
      }

      .input-comment {
        width: 100%;
      }
    }
  }

  &--centered {
    max-width: 1000px;

    .content-top-wrapper {

      & + .content-wrapper {
        padding-top: 0;
      }
    }

    .content-wrapper {

      .post {

        &-date {
          display: none;
        }

        &-title {
          display: none;
        }
      }
    }

    .mejs__audio {
      height: 100px !important;

      .mejs {

        &__button {
          width: 100px;
        }

        &__playpause-button {
          margin-right: 10px;
        }

        &__volume-button {
          margin-left: 10px;
        }

        &__time {
          padding: 0 20px;

          span {
            font-size: 16px;
          }
        }
      }

      @include medium-size-max {
        height: 60px !important;

        .mejs {

          &__button {
            width: 60px;
          }

          &__playpause-button {
            margin-right: 0;
          }

          &__volume-button {
            margin-left: 0;
          }

          &__time {
            padding: 0 10px;

            span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  &--small {
    max-width: 1000px;

    .post {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .content-top-wrapper {
        display: none;

        & + .content-wrapper {
          padding-top: 40px;
        }
      }

      .image-wrapper {
        flex: 3;
        max-width: 305px;
        height: 200px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 40px;

        .swiper {
          &--blog-gallery {

            .swiper-button-prev {
              left: 15px;
            }

            .swiper-button-next {
              right: 15px;
            }
          }
        }
      }

      .audio-wrapper {
        flex-basis: 100%;
        padding: 40px 40px 0;

        @include small-size-max {
          padding: 40px 30px 0;
        }
      }

      // Video
      .video {
        &-wrapper {
          flex: 3;
          max-width: 290px;
          margin-top: 40px;
          margin-bottom: 40px;
          margin-left: 40px;

          iframe {
            height: 200px;
            min-height: 0;
          }
        }

        &-play-btn {
          width: 60px;
          height: 60px;

          &:before {
            font-size: 16px;
          }
        }
      }

      .content-wrapper {
        flex: 7;
        padding: 40px;
      }

      @include small-size-max {
        display: block;

        .image-wrapper {
          max-width: none;
          min-height: 350px;
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0;
        }

        .video-wrapper {
          max-width: none;
          margin-top: 0;
          margin-bottom: 0;
          margin-left: 0;

          iframe {
            height: 350px;
          }
        }

        .content-wrapper {
          padding: 40px 30px;
        }
      }

      &.format-gallery {
        .image-wrapper {
          margin: 40px 0;
          padding-left: 40px;

          @include small-size-max {
            margin: 0;
            padding-left: 0;
          }
        }
      }

      &-link {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  &--grid {
    display: grid;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    grid-gap: 30px;

    @include xs-size-max {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }


    .post {
      display: flex;
      flex-direction: column;
      margin: 0;
      transition: all 0.3s;

      .content-top-wrapper {
        position: relative;
        padding: 0;

        .post {
          &-title {
            display: none;
          }

          &-date {
            display: none;
          }

          &-cats {
            position: absolute;
            top: 27px;
            left: 30px;
            z-index: 2;
          }
        }

        & + .content-wrapper {
          margin-top: 45px;
        }
      }

      .content-wrapper {
        display: flex;
        flex: 1;
        padding: 35px 30px 40px;

        .aheto-btn {
          margin-top: auto;
          padding-top: 20px;
        }
      }

      &-info {
        display: none;
      }

      &-date {
        font-size: 13px;
      }

      .image-wrapper {
        height: 200px;
      }

      .video {
        &-wrapper {
          position: relative;
          z-index: 3;
        }

        &-play-btn {
          width: 60px;
          height: 60px;

          &:before {
            font-size: 16px;
          }
        }
      }

      .audio-wrapper {
        padding: 90px 30px 0;
      }

      iframe {
        height: 200px;
        min-height: 0;
      }

      .blqt {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 130px 35px 65px;

        &:before {
          top: 20px;
          right: 30px;
        }
      }

      &.format-quote {
        .content-wrapper {
          display: none;
        }
      }

      @include small-size-max {
        .image-wrapper {
          height: 350px;
        }

        iframe {
          height: 350px;
        }
      }

      &-link {
        margin-top: 20px;
      }
    }
  }

  &--masonry {
    display: grid;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 30px;
    grid-auto-rows: 10px;


    @include xs-size-max {
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .post {
      margin: 0;
      transition: all 0.3s;

      .content-top-wrapper {
        position: relative;
        padding: 0;

        .post {
          &-title {
            display: none;
          }

          &-date {
            display: none;
          }

          &-cats {
            position: absolute;
            top: 27px;
            left: 30px;
            z-index: 2;
          }
        }

        & + .content-wrapper {
          padding-top: 80px;
        }
      }

      .content-wrapper {
        padding: 35px 30px 30px;

        .aheto-btn {
          margin-top: 20px;
        }
      }

      .image-wrapper {
        height: auto;

        img {
          width: 100%;
          height: auto;
        }

        .swiper--blog-gallery {
          .swiper-slide {
            height: 350px;
          }
        }
      }

      &-info {
        display: none;
      }

      &-date {
        font-size: 13px;
      }

      .audio-wrapper {
        padding: 90px 30px 0;
      }

      .video {

        &-wrapper {
          position: relative;
          z-index: 3;
        }

        &-play-btn {
          width: 60px;
          height: 60px;

          &:before {
            font-size: 16px;
          }
        }
      }

      .blqt {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 130px 35px 65px;

        &:before {
          top: 20px;
          right: 30px;
        }
      }

      iframe {
        min-height: 350px;
      }

      &.format-quote {
        .content-wrapper {
          display: none;
        }
      }

      @include small-size-max {
      }

      &-link {
        margin-top: 20px;
      }
    }
  }

  &--metro {
    max-width: none;
    padding: 0;

    .post {
      display: block;
      position: relative;
      width: 16.66%;
      height: 320px;
      margin: 0;
      float: left;
      overflow: hidden;

      &:after {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 0px 150px 90px rgba($c-black, 0.9);
        content: '';
        opacity: 1;
      }

      &:hover {
        &:after {
          box-shadow: 0px 0px 150px 90px rgba($c-black, 0.3);
        }
      }

      .image-wrapper {
        height: 100%;
      }

      .content-top-wrapper {
        padding: 0;

        .post {
          &-title {
            display: none;
          }

          &-date {
            display: none;
          }

          &-cats {
            position: absolute;
            top: 27px;
            left: 30px;
            z-index: 2;
          }
        }
      }

      .content-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 30px 30px 40px;
        z-index: 1;

        .post-title {
          font-size: 16px;
          line-height: 26px;

          a {
            color: $c-white;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
          }
        }

        .post-date {
          display: none;
        }

        .post-content {
          display: none;
        }

        .aheto-btn {
          display: none;
        }
      }

      .post-info {
        display: none;
      }

      iframe {
        height: 320px;
        min-height: 0;
      }

      &:nth-child(12n + 1),
      &:nth-child(12n + 6),
      &:nth-child(12n + 9) {
        width: 33.33%;
        height: 640px;
      }

      &:nth-child(12n + 4),
      &:nth-child(12n + 5),
      &:nth-child(12n + 10) {
        width: 33.33%;
      }

      @media screen and (max-width: 1600px) {
        width: 25%;

        &:nth-child(12n + 1),
        &:nth-child(12n + 6),
        &:nth-child(12n + 9) {
          width: 50%;
          height: 640px;
        }

        &:nth-child(12n + 4),
        &:nth-child(12n + 5),
        &:nth-child(12n + 10) {
          width: 50%;
        }
      }

      @include large-size-max {
        width: 33.33%;

        &:nth-child(12n + 6),
        &:nth-child(12n + 9) {
          width: 33.33%;
          height: 320px;
        }

        &:nth-child(12n + 4),
        &:nth-child(12n + 5),
        &:nth-child(12n + 10) {
          width: 33.33%;
        }

        &:nth-child(12n + 1),
        &:nth-child(12n + 8) {
          width: 66.66%;
          height: 640px;
        }
      }

      @include small-size-max {
        width: 100% !important;
        height: 640px !important;
      }

      @media screen and (max-width: 576px) {
        width: 100% !important;
        height: 480px !important;
      }

      @include xs-size-max {
        height: 350px !important;
      }
    }
  }

  &--full-width {
    max-width: none;
    padding: 0;
  }

  &--single-post {
    max-width: 1030px;
    padding: 95px 30px 120px;

    @include medium-size-max {
      padding-right: 15px;
      padding-left: 15px;
    }
    &.aheto-content--saas {
      .post {
        box-shadow: none;
      }
      blockquote {
        text-align: left;
        &:before {
          display: none;
        }
        h3 {
          position: relative;
          &:before {
            position: absolute;
            top: 0;
            left: -30px;
            display: block;
            width: 2px;
            height: 100%;
            opacity: 1;
            content: '';
          }
        }
      }
    }
  }

  &--recent-posts {
    max-width: 1200px;
    padding: 115px 15px 125px;

    .post {
      margin: 0;
      transition: all 0.3s;

      .content-top-wrapper {
        position: relative;
        padding: 0;

        .post {
          &-title {
            display: none;
          }

          &-date {
            display: none;
          }

          &-cats {
            position: absolute;
            top: 27px;
            left: 30px;
            z-index: 2;
          }
        }

        & + .content-wrapper {
          margin-top: 45px;
        }
      }

      .content-wrapper {
        padding: 35px 30px 40px;

        .aheto-btn {
          margin-top: 0;
          padding-top: 15px;
        }
      }

      &-content {
        display: none;
      }

      &-info {
        display: none;
      }

      &-date {
        font-size: 13px;
      }

      &-title {
        margin-top: 15px;
        a {
          font-size: 24px;
          font-weight: 400;
        }
      }

      .image-wrapper {
        height: 200px;
      }

      .video {
        &-wrapper {
          position: relative;
          z-index: 3;
        }

        &-play-btn {
          width: 60px;
          height: 60px;

          &:before {
            font-size: 16px;
          }
        }
      }

      iframe {
        height: 200px;
        min-height: 0;
      }

      .blqt {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 130px 35px 65px;

        &:before {
          top: 20px;
          right: 30px;
        }
      }

      &.format-quote {
        .content-wrapper {
          display: none;
        }
      }

      @include small-size-max {
        .image-wrapper {
          height: 350px;
        }

        iframe {
          height: 350px;
        }
      }

      &-link {
        margin-top: 20px;
      }
    }

    &--sidebar {
      max-width: 1030px;
      padding: 0 30px 0px;

      @include medium-size-max {
        padding-right: 15px;
        padding-left: 15px;
      }

      .post {
        &:hover {
          box-shadow: none;
        }

        .image-wrapper {
          height: 260px;
        }

        .content-wrapper {
          padding: 35px 0 40px;

          .aheto-btn {
            margin-top: 5px;
          }
        }

        &-title {
          margin-top: 5px;
        }
      }

      .swiper-container {
        margin: 0;
        padding: 0;
      }

      .swiper-pagination {
        margin-top: 20px;
      }
    }
  }

  &--comments {
    max-width: 1000px;
    padding: 105px 15px 120px;

    &--sidebar {
      max-width: 1030px;
      padding: 115px 30px 120px;

      @include medium-size-max {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  &--search {
    padding-top: 0;
  }

  &--saas {
    .post {
      border-radius: 5px;
      overflow: hidden;
      .blqt {
        padding-bottom: 120px;
        text-align: left;
      }
    }
    .post-title {
      font-size: 24px;
      font-weight: normal;

      a {
        font-size: inherit;
        font-weight: inherit;
      }
    }
    .format-quote {
      .post-info {
        display: block;
        &__item:not(.post-author) {
          display: none;
        }
        .post-author {
          position: relative;
          padding: 0;
          .avatar {
            display: none;
          }
          &__name {
            position: absolute;
            bottom: 57px;
            left: 33px;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            &:before {
              display: inline-block;
              margin-right: 4px;
              content: '-';
            }
          }
        }
      }
    }

    .comment-respond {
      position: relative;
      margin-top: 215px;
      &:after {
        position: absolute;
        top: -110px;
        left: 50%;
        display: block;
        width: 100vw;
        height: 150%;
        transform: translateX(-50%);
        background: #f3f9ff;
        content: '';
        z-index: -1;
      }
    }
  }

  &--business {
    .post {

      &-date {
        position: absolute;
        top: 0;
        left: 28px;
        padding: 4px 10px 4px 14px;
        transform: translate(0, -30%);
        color: $c-white;
        letter-spacing: 2px;
        text-transform: uppercase;
        z-index: 4;
      }

      &-cats {
        a {
          margin: 0 10px 0 0;
          padding: 0;
          background-color: transparent;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      &-title {
        margin-top: 15px;
        font-size: 24px;

        a {
          font-size: inherit;
          font-weight: bold;
          line-height: 30px;
        }
      }

      &-content {
        p {
          line-height: 26px;
        }
      }

      .blqt {
        padding-top: 100px;
        padding-bottom: 115px;
        text-align: left;

        p {
          font-style: italic;
          letter-spacing: 0;
          line-height: 40px;
        }
      }

      .content-top-wrapper {

        .post-date {
          display: block;
        }

        .post-cats {
          display: none;
        }
      }

      .content-wrapper {
        padding-top: 30px;

        .post-date {
          display: none;
        }

        .post-cats {
          display: block;
        }

        .aheto-btn {
          margin-top: 18px;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1.8px;
          text-transform: uppercase;

          &:after {
            content: none;
          }
        }
      }

      .image-wrapper {
        .swiper--blog-gallery {
          .swiper-slide {
            height: 200px;
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        font-size: 30px;
      }

      iframe {
        height: 200px;
        min-height: 200px;
      }

      .audio-wrapper {
        padding-bottom: 10px;
      }
    }

  }
}
/*news political*/
.new{
  background-color: $c-white;
  position: relative;
  min-height: 335px;
  margin-bottom: 0px;
  transition: 0.2s all;
  height: 100%;
  box-shadow: 0 0 35.7px 2.3px rgba(0, 0, 0, 0.09);
  &:hover {
    box-shadow: 0px 26px 55.5px 3.5px rgba(0, 0, 0, 0.17);
    transition: 0.2s all;
    transform: translateY(-20px)
  }

  .content-wrapper {
    padding: 20px 35px 25px;
    margin-bottom: 0px;
    .new-title {
      padding-bottom: 20px;
      a {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.25;
        display: inline-block;
        font-family: $f-oswald;
      }

    }
    p{
      padding-top: 15px;
    }
    .new-date {
      font-family: 'Lato';
      font-size: 12px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      text-align: left;
      color: #dd2932;
      text-transform: uppercase;
      display: inline-block;
      &:after{
        content: '';
        width: 1px;
        height: 15px;
        background-color: #eeeeee;
        margin: 0px 10px;
        position: relative;
        display: inline-block;
        bottom: -3px;
      }
    }
    .new-cats {
      display: inline-block;
      a {
        font-family: 'Lato';
        font-size: 12px;
        font-weight: 900;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        text-align: left;
        color: #999999;
        display: inline-block;
      }
    }
  }

  .content-top-wrapper {
    .image-wrapper {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

  }

}

