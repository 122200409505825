.portfolio-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;

  &__dir {
	&--prev {
	  .portfolio-nav__dir-icon {
		margin-right: 10px;
    line-height: 15px;
	  }

	  &:hover {
		.portfolio-nav__dir-icon {
		  left: -3px;
		}
	  }
	}

	&--next {
	  .portfolio-nav__dir-icon {
		margin-left: 10px;
    line-height: 15px;
	  }

	  &:hover {
		.portfolio-nav__dir-icon {
		  left: 3px;
		}
	  }
	}
  }

  &__dir-title {
	margin: 0;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1.4px;
	text-transform: uppercase;
  }

  &__dir-icon {
	position: relative;
	left: 0;
	transition: all 0.3s;
	font-size: 14px;
  }

  &__list-icon {
	font-size: 24px;
  }

  &__link {
	display: flex;
  }
}
