.icons-widget {
  display: flex;
  align-items: center;
  margin: 0;

  &__item {
    padding: 12px;

    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }

    @include small-size-max {
      padding: 6px;

      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

  &__link {
    font-size: 18px;
  }
}
