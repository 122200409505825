.aht-table {
    margin: auto;
    font-size: 14px;
    &__header {
        caption-side: top;
        font-size: 24px;
        padding-top: 0;
        padding-bottom: 0;
    }
    &__head {
        &-item {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        &-item:first-child {
            padding-left: 60px;
        }
    }
    &__body {
        &-cell {
            padding-top: 20px;
            padding-bottom: 20px;
            // border: 1px solid #eee;
        }
    }
    @media screen and (max-width: 768px) {
        &__title {
            text-align: left;
            margin-left: 50px;
        }
    }
    &--edu {
        .aht-table {
            &__head {
                &-item {
                    font-size: 12px;
                    letter-spacing: 1.2px;
                }
                &-item:first-child {
                    padding-left: 78px;
                    padding-right: 93px; 
                }
                &-item:nth-last-child(-n+4) {
                    padding-left: 15px;
                }
            }
            &__header {
                margin-bottom: 35px;
            }
            &__body {
                &-cell {
                    font-size: 15px;
                }
                &-cell:first-child {
                    color: #555;
                    letter-spacing: normal;
                    padding-left: 72px;
                    padding-right: 93px; 
                }
                &-cell:nth-child(2) {
                    color: #555;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            @media screen and (max-width: 1024px){
                &__head {
                    &-item:first-child {
                        padding-left: 53px;
                        padding-right: 60px;
                    }
                }
                &__body {
                    &-cell:first-child {
                        padding-right: 30px;
                        padding-left: 48px;
                    }
                } 
            }
            @media screen and (max-width: 576px){
                &__header {
                    text-align: left;
                    padding-left: 25px;
                }
            }
        }
    }
}