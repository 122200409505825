.aht-post-detail{

  &__title {
    line-height: 1.3;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
  }

  &__detail {
    display: flex;
    align-items: center;
    margin-right: 43px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__detail-icon {
    margin-right: 12px;
    font-size: 16px;
    &.icon_pencil{
      transform: rotate(90deg);
    }
  }

  &__detail-text {
    font-size: 15px;
    line-height: 1.6;
  }

  &__thumbnail {
    margin-top: 50px;
    margin-bottom: 70px;
    @include small-size-max{
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  &__thumbnail-img {
    max-width: 100%;
  }

  &__content {
    margin-bottom: 55px;
    @include small-size-max{
      margin-bottom: 45px;
    }
    p {
      margin: 23px 0;
      @include small-size-max{
        margin: 13px 0px;
      }
      &:first-child{
        padding-bottom: 20px;
        @include small-size-max{
          padding-bottom: 10px;
        }
      }
    }

  }

  &__additional {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

  }

  &__cats {
    margin-right: 30px;

    @include small-size-max {
      margin-right: 0;
      margin-bottom: 30px;
    }
    &-name {
      margin-right: 7px;
      font-size: 16px;
      font-weight: bold;
      line-height: 2;
    }
  }


  &__cats-link {
    transition: none;
    font-weight: inherit;

    &:hover {
      opacity: 0.8;
    }
  }

  &__likes {
    padding: 15px 25px 15px 20px;
    transition: all 0.2s ease-out;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    &-icon {
      margin-right: 5px;
      color: #EE2865;
      font-size: 14px;
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding: 50px 50px 60px 70px;
    border: 1px solid #eee;

    @include small-size-max {
      padding: 50px 30px;
      margin-top: 50px;
    }
  }

  &__author-img-holder {
    margin-right: 40px;

    @include small-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__author-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    @include small-size-max {
      margin: 0 auto;
    }
  }

  &__author-img-inner {
    width: 0;
    height: 0;
  }

  &__author-about {
    flex: 1;
  }

  &__author-title {
    margin: -3px 0 0;
    font-weight: bold;
    line-height: 1.63;
  }

  &__author-desc {
    margin-top: 22px;
  }

  &__author-more {
    margin: 35px 0 0;
    line-height: 1.63;
  }

  &__author-link {
    text-decoration: underline!important;
    font-weight: 400;
  }

  .aheto-socials {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @include small-size-max{
      margin-bottom: 30px;
    }
    &__link{
      width: 51px;
      height: 51px;
      border: solid 1px #eeeeee;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.new-detail{
  margin-left: 0px!important;
  margin-right: 0px!important;
}
