// BLOG Crutches
.aheto-page {

  &--single-post {
    background-color: transparent;
    overflow: hidden;
  }

  &--dual-sidebars {
    .aheto-sidebar-wrapper {
      background-color: transparent;
    }
  }
    
  &--no-sidebar {

    .aheto-sidebar-wrapper {
      background-color: transparent;
    }

    .aheto-content {

      &--single-post {
        .post-data {
          > p,
          > h1,      
          > h2,
          > h3,
          > h4,
          > h5,
          > h6 {
            max-width: 770px;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }
    }
  }

  .aheto-sidebar-wrapper {
  }

  .aheto-content-wrapper {
    &--saas {
      background: #f3f9ff;
    }
  }
}
