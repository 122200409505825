.aheto-btn-container {

  &--fullwidth {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .aheto-btn {
      flex: 1 0 auto;
      margin: 0;
      padding: 37px;
      border-radius: 0;
      font-size: 16px;
      letter-spacing: 1.6px;
      text-align: center;

      @include small-size-max {
        padding: 20px;
      }

      i {
        font-size: 18px;
      }
    }
  }

  &--fullwidth-2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .aheto-btn {
      flex: 1 0 auto;
      margin: 0;
      padding: 20px;
      border-radius: 0;
      font-size: 14px;
      letter-spacing: 1px;
      text-align: center;

      i {
        font-size: 16px;
      }
    }
  }

  &--resaurant_about {
    display: flex;
    justify-content: center;
  }
  
}

// Home events 

.aheto-btn {
  &-evts {
    .aheto-btn--light:first-child {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .aheto-btn--light:last-child {
      padding: 23px 70px;
    }
  }
  @media screen and (max-width: 525px) {
    &-evts {
      .aheto-btn--light:first-child {
        margin-right: 0px;
      }
    }
    
  }
}
  
