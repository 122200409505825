.aht-blog-trvl {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    flex-basis: 33.33%;
    margin-bottom: 30px;
    padding: 0 15px;

    @include medium-size-max {
      flex-basis: 50%;
    }

    @include small-size-max {
      flex-basis: 100%;
    }
  }

  &__item-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.3s;
    background-color: $c-white;

    &:hover {
      box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
    }
  }

  &__img {
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;

    @include small-size-max {
      @include xs-size-min {
        height: 350px;
      }
    }
  }

  &__img-link {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px 34px 35px;

    @include large-size-max {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  &__title-wrap {
    flex-grow: 1;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    margin: 33px -12px -5px;
  }

  &__detail {
    display: flex;
    align-items: center;
    margin: 5px 12px;
  }

  &__icon {
    margin-right: 11px;
  }

  &__text {
    font-size: 15px;
    font-weight: 600;
  }
}
