.aht-event {

  &--edu {
    transition: all 0.3s ease-out;
    border-radius: 5px;
    background-color: $c-white;
    // box-shadow: 0px 5px 15px 3px rgba($c-black, 0.15);
    box-shadow: 0px 5px 15px 0px rgba($c-black, 0.14);
    overflow: hidden;

    &:hover {
      transform: translate(0, -22px);
      // box-shadow: 0px 5px 20px 0px rgba($c-black, 0.14);
      box-shadow: 0px 15px 15px 0px rgba($c-black, 0.15);
    }

    .aht-event {

      &__img {
        height: 250px;
        background-position: center;
        background-size: cover;

        @include small-size-max {
          height: 350px;
        }

        @include xs-size-max {
          height: 250px;
        }
      }

      &__img-link {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      &__caption {
        display: flex;
        padding: 40px 25px 20px;
      }

      &__date {
        position: relative;
        padding-right: 27px;
        border-right: 1px solid #eee;
        text-align: center;

        @include medium-size-max {
          padding-right: 15px;
        }
      }

      &__day {
        display: block;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 40px;
      }

      &__month {
        display: block;
        margin-left: 2px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 32px;
        text-transform: uppercase;
      }

      &__link {
        padding-left: 22px;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;

        @include medium-size-max {
          padding-left: 15px;
        }
      }

      &__details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 15px;
      }

      &__detail {
        display: flex;
        align-items: center;
        margin: 0 10px;
      }

      &__icon {
        margin-right: 8px;
      }

      &__text {
        margin: 0;
      }
    }
  }

  &--edu-2 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    border-radius: 0;

    .aht-event {

      &__item {
        flex-basis: 25%;
        margin-bottom: 30px;
        padding: 0 15px;

        @media screen and (max-width: 1500px) {
          flex-basis: 33.33%;
        }

        @include large-size-max {
          flex-basis: 50%;
        }

        @include small-size-max {
          flex-basis: 100%;
          padding: 0 30px;
        }
      }

      &__item-inner {
        position: relative;
        transition: all 0.3s ease-out;
        background-color: $c-white;
        box-shadow: 0px 4px 30px 0px rgba($c-black, 0.07);

        @include small-size-min {
          &:hover {
            transform: translate(0, -30px);
            box-shadow: 0px 30px 40px 0px rgba($c-black, 0.14);
          }
        }
      }

      &__img-link {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      &__img {
        height: 280px;
        background-position: center;
        background-size: cover;

        @include small-size-max {
          height: 350px;
        }

        @include xs-size-max {
          height: 280px;
        }
      }

      &__date {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 80px;
        height: 80px;
        padding-top: 14px;
        padding-right: 0;
        border-right: 0;
        border-radius: 50%;
        background-color: $c-white;
      }

      &__day {
        display: block;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 1;
      }

      &__month {
        display: block;
        margin-left: 2px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 4.2px;
        line-height: 24px;
        text-transform: uppercase;
      }

      &__details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 18px 15px;
        border-top: 1px solid #eee;
      }

      &__detail {
        display: flex;
        align-items: center;
        margin: 0 10px;
        line-height: 25px;
      }

      &__icon {
        margin-right: 8px;
      }

      &__text {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
      }

      &__caption {
        padding: 32px 25px 43px;
        text-align: center;
      }

      &__title {
        margin: 0;
        font-weight: 700;
        line-height: 30px;
      }

      &__desc {
        margin-top: 25px;
      }

      &__link {
        display: inline-block;
        margin-top: 16px;
        padding: 15px 18px;
        transition: all 0.3s ease-out;
        border-radius: 5px;
        font-size: 14px;
        letter-spacing: 1.4px;
        text-transform: uppercase;

        &:hover {
          color: $c-white;
        }
      }
    }
  }
}
