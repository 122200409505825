.aheto-progress {

  &__title {
    margin: 10px 0;
  }

  // Chart
  &__chart-holder {
    position: relative;
    width: 80%;
    max-width: 350px;
    margin: 0 auto;
  }

  &__chart-bg {
    stroke: #eee;
    stroke-width: 0.5;
  }

  &__chart-circle {
    transform: rotate(-90deg);
    transform-origin: center;
    stroke-width: 0.5;

    // Animation
    &.active {
      animation: circle-chart-fill 2.5s reverse;
    }
  }

  &__chart {
    width: 80%;
    height: 80%;
    @keyframes circle-chart-fill {
      to { stroke-dasharray: 0 100;}
    }
  }

  &__chart-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
  }

  &__chart-number {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  // Progress Bar
  &__bar-icon {
    display: inline-block;
    transform: rotate(43deg);
    font-size: 38px;
  }

  &__bar {
    position: relative;
    height: 4px;
    margin-top: 15px;
    background-color: #eee;
  }

  &__bar-holder {
    position: absolute;
    top: -60px;
    transform: translate(-100%, 0);
  }

  &__bar-perc {
    position: absolute;
    top: 46%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: $c-white;
    font-size: 14px;
  }

  &__bar-val {
    width: 0;
    height: 100%;
  }

  &--chart {
    padding: 0 35px;
    @include medium-size-max {
      padding: 0px;
    }

    @include small-size-max {
      padding: 0 60px 30px;

    }

    @include xs-size-max {
      padding: 0 0 30px;
    }
    @media screen and (max-width: 768px) {
      padding-bottom: 0px;
    }
    .aheto-progress {
      &__chart {
        @include small-size-max {
          width: 50%;
          height: 50%;
        }

        @include xs-size-max {
          width: 80%;
          height: 80%;
        }
      }
      &__title {
        margin-top: 40px;
      }
      &__desc {
        margin-top: 25px;
      }
    }
  }

  &--bar {
    padding-top: 25px;
  }

  &--big-chart-number {
    .aheto-progress {
      &__chart-number {
        font-size: 54px;
      }
    }
  }

  &--simple {
    .aheto-progress {

      &__chart {
        &-holder {
          width: 90%;
        }

        &-bg {
          stroke-width: 0.8;
        }

        &-circle {
          stroke-width: 0.8;
        }
      }
      &__title {
        margin-top: 25px;
        margin-bottom: 0;
        letter-spacing: -0.5px;
        @include small-size-max {
          margin-top: 15px;
        }
      }

      &__desc {
        margin: 7px 0;
      }
    }
  }
  &--political{
    .aheto-progress {
      &__chart-circle {
        stroke: #dd2932;
      }
      &__chart-icon {
        color: #dd2932;
      }
      &__title{
        font-weight: 600;
        margin-bottom: 0px;
        @include small-size-max{
          margin-top: 20px;
        }
      }
      &__desc {
        margin-top: 15px;
      }
    }
  }

  &--construction {
    .aheto-progress {
      &__bar-icon:before {
        transform: rotate(40deg);
      }
      &__bar-holder {
        transform: translate(-60%, 0);
      }
    }
  }




}
