* {
  outline: 0 !important;
  text-decoration: none !important;
}

body {
  overflow-x: hidden;
}

.container-medium {
  max-width: 1710px;
}

.clearfix {
  clear: both;
}

.over-hidden {
  // position: fixed;
  width: 100%;
  height: 100vh;
}

@include menu-size-max {
  .over-hidden {
    width: 100%;
    overflow: hidden;
  }
}

.no-padd {
  padding: 0 !important;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.color-black {
  color: $c-black;
}

.js-bg {
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  opacity: 0;
}

ul {
  padding-left: 0;
  list-style: none;
}

.retreat {
  &--0 {
    padding-right: 0 !important;
    padding-left: 0 !important;

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    [class^='col-'],
    [class*=' col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &--50 {
    padding-right: 50px !important;
    padding-left: 50px !important;

    @include large-size-max {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }

    .row {
      margin-right: -50px;
      margin-left: -50px;
    }

    [class^='col-'],
    [class*=' col-'] {
      padding-right: 50px;
      padding-left: 50px;
    }

    @media screen and (max-width: 1500px) {
      padding-right: 15px;
      padding-left: 15px;
      .row {
        margin-right: -15px;
        margin-left: -15px;
      }

      [class^='col-'],
      [class*=' col-'] {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  &--60 {

    @media screen and (min-width: 1500px) {
      padding-right: 30px;
      padding-left: 30px;

      .row {
        margin-right: -30px;
        margin-left: -30px;
      }

      [class^='col-'],
      [class*=' col-'] {
        padding-right: 30px;
        padding-left: 30px;
      }
    }
  }
}

.row {
  &--flex {
    display: flex;
    flex-wrap: wrap;
  }

  &--v-center {
    align-items: center;
  }

  &--h-center {
    display: flex;
    justify-content: center;
  }
}

.hidden {
  &-lg {
    @include large-size-max {
      display: none;
    }
  }

  &-md {
    @include medium-size-max {
      display: none;;
    }
  }

  &-sm {
    @include small-size-max {
      display: none;
    }
  }

  &-xs {
    @include xs-size-max {
      display: none;
    }
  }
}

.retreat {
  &--0 {

    &.container-fluid {

      padding-right: 0;
      padding-left: 0;

      .row {
        margin-right: 0;
        margin-left: 0;
      }

      [class^='col-'],
      [class*=' col-'] {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.border-bottom {
  border-bottom: 1px solid rgba($c-black, 0.07);
}

.border-top {
  border-bottom: 1px solid rgba($c-black, 0.07);
}

.aheto-content-block .transition-none {
  transition: none;
}

.w-100 {
  width: 100%;
  @include small-size-max {
    margin: 0 auto !important;
  }
}


