.aheto-accordion {
  &__title {
    margin: 0;
    padding: 27px 0;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    user-select: none;
    font-size: 20px;
  }
  sched-list
  &__panel {
    display: none;
  }

  &__desc {
    margin: 30px 0;
  }

  @media screen and (max-width: 768px) {
    .aheto-accordion__title {
      padding: 20px 0;
    }

    .aheto-accordion__desc {
      margin: 15px 0;
    }
  }
}

//church
.about-church-faq-wrap-1 {
  .aheto-accordion{
    &__title{
      padding: 30px 0 24px;
      letter-spacing: -0.6px;
      font-size: 24px;
      font-weight: bold;
    }

    &__desc {
      margin: 27px 0 20px;
      font-family: $f-roboto;
      line-height: 1.63;
    }
  }
}

@media screen and (min-width: 1200px) {
  .church-about-padding-faq {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

//construction
.construction-careersWrap {
  .aheto-accordion {
    &__title {
      margin: 0;
      padding: 27px 0;
      transition: all 0.2s ease-in-out;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      user-select: none;
      font-size: 20px;
      font-weight: 600;
      &.active {
        .aheto-accordion__plus:before {
          content: '\f2f4';
        }
      }
    }
    &__plus {
      position: relative;
      float: right;
      &:before {
        //content: '+';
        content: '\f2c7';
        position: absolute;
        left: -20px;
        top: 4px;
        font-family: $f-ionicons;
        font-size: 18px;
      }
    }
    &__panel {
      display: none;
    }
    &__descWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__desc {
      margin: 30px 0;
      max-width: 750px;
      line-height: 1.5;
    }

    .aheto-btn {
      text-transform: uppercase;
      padding: 0;
      margin-top: -15px;
      margin-left: 20px;
      width: 120px;
      height: 45px;
      line-height: 45px;
      text-align: center;
    }
    @media screen and (max-width: 1024px) {
      &__descWrap {
        flex-wrap: wrap;
      }
      .aheto-btn {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 768px) {
      .aheto-accordion__title {
        padding: 20px 0;
      }

      .aheto-accordion__desc {
        margin: 15px 0;
      }
    }
  }
}
