.aht-filter-yoga {
  display: flex;
  justify-content: center;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 30px;

    @include large-size-max {
      justify-content: flex-start;
    }
  }
  
  &__filter {
    padding: 10px 0;
    text-align: center;
    cursor: pointer;

    @include large-size-min {
      min-width: 154px;
      padding: 0 0 25px;
    }

    // Active
    &.active {
      .aht-filter-yoga__select {
        &:after {
          content: '';
        }
      }
    }

    &:first-child {
      .aht-filter-yoga__line {
        left: 50%;
        width: 50%;
      }
    }

    &:last-child {
      .aht-filter-yoga__line {
        width: 50%;
      }
    }
  }

  &__title {
    margin: 0;
    padding: 0 15px;
    transition: all 0.2s;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &__select {
    position: relative;
    margin-top: 33px;

    @include large-size-max {
      display: none;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      transition: all 0.2s;
      border-radius: 50%;
      z-index: 2;
    }

    &:before {
      top: -5px;
      width: 12px;
      height: 12px;
      background-color: #eee;
      content: '';
    }

    &:after {
      top: -10px;
      width: 22px;
      height: 22px;
      opacity: 0.35;
    }
  }

  &__line {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
}

@media screen and (max-width: 1230px) {
  .aht-filter-edu {
    .aht-filter-yoga__inner {
      justify-content: center;
    }
  }
}