.aheto-header-13 {

  &__inner {
    @include menu-size-min {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__line {
    display: flex;
    position: relative;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1750px;
    margin: 0 auto;

    @include menu-size-max {
      flex-wrap: wrap;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    @media screen and (min-width: 1500px) {
      flex: 1 1 0;
    }

    @include menu-size-min {
      padding: 25px 15px 15px;
    }

    @include xs-size-max {
      padding: 10px 10px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    padding: 15px;

    @media screen and (min-width: 1500px) {
      flex: 1 1 0;
      justify-content: flex-end;
    }

    @include menu-size-max {
      margin-left: auto;
      padding: 10px 15px;
    }
  }

  &__button {
    margin-right: 15px;

    @media screen and (min-width: 1500px) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    &--search {
      padding-top: 3px;
    }

    &--cart {
      padding-right: 10px;
    }
  }

  &__button-link {
    display: inline-block;
    position: relative;
    transition: all 0.2s;
    color: $c-white;
  }

  &__button-icon {
    color: inherit;
    font-size: 22px;
  }

  &__button-number {
    position: absolute;
    top: -10px;
    right: -13px;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
    color: inherit;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  &__hamburger {
    display: flex;
    align-items: center;

    @include menu-size-min {
      display: none;
    }
  }

  // Menu
  &__menu {
    padding: 0 15px;

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 65px);
      padding: 30px 15px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  // Main menu
  @include menu-size-max {
    .main-menu .menu-item.active > a {
      color: $c-active;
    }
  }
  .main-menu {
    .menu-item {
      a {
        display: inline-block;
      }
      .sub-menu {
        .menu-item {
          a.active {
            color: $c-active;
          }
        }
      }
    }

    @include menu-size-min {
      display: flex;
      flex-wrap: wrap;
      height: 100%;


      .menu-item {

        a {
          font-size: 15px;
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      > .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 35px;
        a {
          &::after.active {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 4px;
            transition: width 0.2s;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        > a {
          padding: 35px 0;
          color: $c-white;
          font-weight: 700;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 4px;
          transition: width 0.2s;
          content: '';
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }

    @include menu-size-max {
      .menu-item {

        a {
          color: $c-white;
          &.active {
            color: $c-active;
          }
          &:hover {
            color: $c-active;
          }
        }
      }

      > .menu-item {
        margin-bottom: 20px;

        > a {
          font-size: 20px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      position: absolute;
      top: calc(100% - 15px);
      left: 0;
      padding: 25px 0 30px;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;

      .menu-item {
        display: flex;
        align-items: center;
        min-width: 225px;
        padding: 0 30px;

        a {
          flex: 1 1 0;
          padding: 8px 0;
        }
      }

      .sub-menu {
        top: -25px;
        left: 101%;
      }

      .dropdown-btn {
        &:before {
          content: '\f10a';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 15px 25px 0;
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      padding: 0;
      border-top: 0;
      background-color: transparent;

      &__title {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.04);
      }

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-black, 0.04);
        background-color: $c-white;

        &:last-child {
          border-right: 0;
        }
      }

      .menu-item {
        min-width: 180px;
        padding: 0;

        a {
          padding: 10px 0 0;
        }
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        margin: 15px 25px 0;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba($c-white, 0.2);
        font-size: 20px;
      }
    }
  }

  // Dropdown btn
  .dropdown-btn {
    margin-left: 10px;
    color: $c-white;
    font-size: 10px;

    &:before {
      content: '\f104';
    }

    @include menu-size-max {
      display: none;
    }
  }

  // Logo
  .logo {

    &__img {
      max-height: 40px;
    }

    &__text {
      color: $c-white;
      font-size: 34px;
      line-height: 34px;
    }

    &__sub-text {
      color: $c-white;
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  // Hamburger
  .hamburger {
    &-inner {
      background-color: $c-white;

      &::before,
      &::after {
        background-color: $c-white;
      }
    }
  }
}
