.wpml-ls {
	ul {
		font-size: 14px;
	}
	.wpml-ls-flag {
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
	}
}
.wpml-ls-legacy-dropdown-click,
.wpml-ls-legacy-dropdown {
	width: auto;
	a {
		background: none;
		border: 0;
		&.wpml-ls-item-toggle {
			border-radius: 4px;
			&:after {
				width: 5px;
				height: 5px;
				border-top: 0;
				border-right: 0;
				transform: rotate(-45deg) translateY(-3px);
			}
		}
	}
	.wpml-ls-sub-menu {
		right: auto;
		min-width: 100%;
		border-top: 0;
		border-radius: 4px;
		background: $c-white;
		padding: 5px 0;
		box-shadow: 0 0 7px 0 rgba($c-black, 0.15);
		a {
			white-space: nowrap;
		}
	}
}
