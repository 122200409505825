.aht-blog {
    &-evts {
        &__date {
            font-size: 12px;
        }
        &__post {
            position: relative;
            background-color: #fff;
            box-shadow: 0 5px 15px 3px rgba(0, 0, 0, 0.08);
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &__title {
            line-height: 1.5;
            margin-bottom: 0;
            margin-top: 7px;

            a {
                color: inherit;
                font-size: inherit;
                font-family: inherit;

                &:hover {
                    opacity: 0.75;
                }
            }
        }
        &__content {
            padding: 27px 15px;
            min-height: 170px;
            box-sizing: border-box;
        }
        &__details {
            margin-top: auto;
        }
        &__text {
            font-size: 15px;
        }
    }
}