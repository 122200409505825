body .swiper {
  position: relative;
  .swiper-slide{
    background-size: cover;
  }
  // &-slide {
  //   height: auto;
  // }

  &-container {
    // padding: 10px 10px 0 10px;

    &-vertical {
      .swiper-wrapper {
        height: 100vh;
      }
    }
  }

  &-button-prev,
  &-button-next {
    width: auto;
    height: auto;
    margin: 0;
    transform: translateY(-50%);
    transition: all 0.3s;
    background: none;
    @media (max-width: 767px) {
      display: none;
    }
    &:before {
      color: inherit;
      font-family: $f-ionicons;
    }
  }
  &-button-prev {
    left: -50px;
    right: auto;
    font-size: 25px;
    @include medium-size-max {
      left:-25px;
    }
    &:before {
      content: '\f124';
    }
  }

  &-button-next {
    right: -50px;
    left: auto;
    font-size: 25px;
    @include medium-size-max {
      right:-25px;
    }
    &:before {
      content: '\f125';
    }
  }

  &-pagination {
    position: static;

    &-bullets {
      margin-top: 35px;
      @media screen and (max-width: 991px) {
        margin-top: 25px;
      }
    }

    &-bullet {
      width: 11px;
      height: 11px;
      transition: 0.3s;
      background: rgba($c-black, 0.1);
      opacity: 1;
      margin-left: 5px;
      margin-right: 5px;
    }

    &-progressbar {
      position: relative;
      height: 2px;
      background-color: #eee;

      // &-mark {
      //   position: absolute;
      //   top: 1px;
      //   width: 16px;
      //   height: 16px;
      //   transform: translate(0, -50%);
      //   border-radius: 50%;
      //   background-color: $color-1;
      // }
    }
  }

  &--shadow {
    .swiper {
      &-container {
        margin: -30px -15px;
        padding: 30px 15px;
      }
    }
  }


  &--home-event {
    .gallery-thumbs {
      .swiper-slide {
        transform: scale(0.7);
        transition: 0.5s;
        opacity: 0.5;

        &.swiper-slide-active {
          transform: none;
          opacity: 1;
        }
      }
    }

    .swiper {
      &-button-prev,
      &-button-next {
        display: flex;
        top: 0px;
        width: 55px;
        height: 55px;
        transform: none;
        border-radius: 50%;
        opacity: 1;

        i {
          margin: auto;
          font-size: 14px;
        }

        &:hover {
          i {
            &:before {
              color: $c-white;
            }
          }
        }
      }

      &-button-prev {
        i {
          &:before {
            content: '\f060';
          }
        }
      }

      &-button-next {
        i {
          &:before {
            content: '\f061';
          }
        }
      }
    }

    @include medium-size-max {
      .swiper {
        &-button-prev,
        &-button-next {
          display: none;
        }
      }
    }
  }

  &--events-posts {
    .swiper {
      &-container {
        padding: 30px 15px;
        margin: -30px -15px;
      }
      &-pagination {
        &-bullet {
          background-color: transparent;
          border: solid 1px #a7a7a7;
        }
      }
      &-slide {
        height: auto;
      }
    }
    .swiper-pagination-bullets {
      margin-top: 60px;
    }
  }
  &--shop-item {
    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-button-prev,
    .swiper-button-next {
      font-size: 20px;
      line-height: 1.7;
      letter-spacing: 2px;
      opacity: 0;
      @media (max-width: 1300px) {
        display: block;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }

    .swiper-button-prev {
      left: 15px;
    }
    .swiper-button-next {
      right: 15px;
    }

    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 1;
      }
    }

    .gallery-thumbs {
      .swiper-wrapper {
        flex-wrap: wrap;
        transform: translate3d(0, 0, 0) !important;
      }
      .swiper-slide {
        width: 105px;

        > div {
          transition: border 0.2s;
          border: 1px solid transparent;
        }
      }
    }
  }

  &--titlebar {
    width: 100%;
    height: 100%;

    &:hover {
      .swiper {
        &-button-prev,
        &-button-next {
          opacity: 1;
        }

        &-pagination {
          opacity: 1;
        }
      }
    }

    .swiper {
      &-container {
        height: 100%;
      }

      &-slide {
        display: flex;
      }

      &-button-prev,
      &-button-next {
        height: 50px;
        color: $c-white;
        font-size: 50px;
        opacity: 0;

        &:hover {
          opacity: 0.7;
        }
      }

      &-button-prev {
        left: 50px;
      }

      &-button-next {
        right: 50px;
      }

      &-pagination {
        position: absolute;
        bottom: 35px;
        margin: 0;
        opacity: 0;

        &-bullet {
          background-color: $c-white;
          opacity: 0.4;
          &-active {
            background-color: $c-white;
            opacity: 1;
          }
        }

        @media screen and (max-width: 1230px) {
          opacity: 1;
        }
      }
    }
  }

  &--banner-hp {
    .swiper {

      &-button-prev,
      &-button-next {
        width: 56px;
        height: 56px;
        border: 1px solid rgba(238, 238, 238, 0.2);
        border-radius: 50%;
        line-height: 52px;
        text-align: center;
        opacity: 1;

        i {
          display: block;
          color: $c-white;
          font-size: 24px;
        }

        &:hover {
          background-color: $c-white;
        }
      }

      &-button-prev {
        left: 100px;
        color: $c-white;
        &:before {
          font-size: 16px;
        }
        @media screen and (max-width: 1500px) {
          left: 15px;
        }
      }

      &-button-next {
        right: 100px;
        color: $c-white;
        &:before {
          font-size: 16px;
        }
        @media screen and (max-width: 1500px) {
          right: 15px;
        }
      }
    }
  }

  &--edu {
    .swiper {

      &-button-prev,
      &-button-next {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        background-color: $c-white;
        line-height: 50px;
        text-align: center;
        box-shadow: 0px 4px 10px 0px rgba($c-black, 0.06);
        opacity: 1;

        i {
          display: block;
          transition: all 0.2s;
          font-size: 24px;
        }

        &:hover {
          &:hover {
            i {
              color: $c-white;
            }
          }
        }
      }

      &-pagination {
        margin-bottom: 0;

        &-bullet {
          width: 15px;
          height: 15px;
          margin: 0 5px !important;
        }
      }
    }
  }

  &--edu-tm {
    .swiper {
      &-pagination {
        margin-top: 57px;
        margin-bottom: 0;

        &-bullet {
          width: 14px;
          height: 14px;
          margin: 0 5px !important;
        }
      }
      &-container {
        padding: 50px 20px 0px 50px;
        margin: -50px -20px 0px -50px;
      }
    }
  }

  &--edu-banner {
    .swiper {

      &-button-prev,
      &-button-next {
        @media screen and (max-width: 1350px) {
          display: none;
        }
      }

      &-button-prev {
        left: 100px;

        @media screen and (max-width: 1500px) {
          left: 30px;
        }
      }

      &-button-next {
        right: 100px;

        @media screen and (max-width: 1500px) {
          right: 30px;
        }
      }
    }
  }

  &--edu-courses {
    .aht-course {
      &__price {
        font-family: $f-roboto;
        font-weight: 500;
      }
      &__title {
        font-family: $f-roboto-slab;
        letter-spacing: normal;
      }
    }
    .swiper {
      &-container {
        padding: 30px 15px;
        margin: -30px -15px;
      }
      &-slide {
        > div {
          height: 100%;
        }
      }

      &-button-prev,
      &-button-next {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        background-color: $c-white;
        line-height: 50px;
        text-align: center;
        box-shadow: 0px 4px 10px 0px rgba($c-black, 0.06);
        opacity: 1;

        i {
          display: block;
          transition: all 0.2s;
          font-size: 24px;
        }

        &:hover {
          &:hover {
            i {
              color: $c-white;
            }
          }
        }

        @media screen and (max-width: 1450px) {
          display: none;
        }
      }

      // &-button-prev {
      //   left: -100px;
      // }

      // &-button-next {
      //   right: -100px;
      // }
      &-button-prev, &-button-next {
        top: -140px;
        right: 0;
        left: auto;
      }
      &-button-prev {
        right: 60px;
      }

      &-pagination {
        margin-bottom: 0;

        @media screen and (min-width: 1450px) {
          display: none;
        }

        &-bullet {
          width: 15px;
          height: 15px;
          margin: 0 5px !important;
        }
      }
    }
  }
  &--edu-class-table {
    box-shadow: 0 0 65px 0 rgba($c-black, 0.08);
    .swiper {
      &-container {
        padding: 0;
        margin: 0;
      }
      &-button-prev, &-button-next {
        top: 80px;
        right: 75px;
        left: auto;
      }
      &-button-prev {
        right: 135px;
      }
      @media screen and (min-width: 320px) {
        &-button-prev, &-button-next {
          display: block;
        }

      }
      @media screen and (max-width: 768px) {
        &-button-prev, &-button-next {
          top: 100px;
          right: 25px;
        }
        &-button-prev {
          right: 85px;
        }
      }
      @media screen and (max-width: 576px) {
        &-button-prev, &-button-next {
          top: 60px;
          right: 25px;
        }
        &-button-prev {
          right: 85px;
        }

      }
    }
  }
  &--edu-events {
    .swiper {
      &-container {
        padding: 30px 15px;
        margin: -30px -15px;
      }
      &-pagination {
        max-width: 570px;
        margin: 70px auto 0;

        &-custom {
          position: relative;
          height: 2px;
          background-color: #eee;
          &-line {
            background-color: #0d369f;
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            transition-property: width;
            @include xs-size-max {
              padding: 0 5px;
            }
            &::after {
              content: '';
              position: absolute;
              top: -8px;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: inherit;
            }
          }
        }
      }
      @media screen and (max-width: 1440px) {
        &-button-prev {
          left: -20px;
        }

        &-button-next {
          right: -20px;
        }
      }
      @media screen and (max-width: 1200px) {
        &-button-prev, &-button-next {
          display: none;
        }
      }
      @media screen and (max-width: 576px) {
        &-pagination {
          margin: 50px auto 0;
          max-width: 310px;
        }
      }
    }
  }

  &--edu-images {
    .swiper {
      &-container {
        margin: -30px 0;
        padding: 30px 0;

        @include medium-size-max {
          margin: 0;
          padding: 0;
        }
      }


      &-slide {
        display: flex;
        align-items: center;
        width: 800px;
        height: 470px;
        transition: all 0.8s;

        &-active {
          .swiper-image-wrap {
            height: 470px;
            border-radius: 5px;

            @include medium-size-min {
              box-shadow: 0px 0px 25px 10px rgba($c-black, 0.15);
            }
          }
        }
      }
      @media screen and (max-width: 767px) {
        &-slide {
          height: 360px;
        }
      }

      &-image-wrap {
        flex-basis: 100%;
        height: 370px;
        transition: all 0.8s;
        background-position: center;
        background-size: cover;
        overflow: hidden;
      }

      &-pagination {
        margin-top: 50px;
        margin-bottom: 0;
      }

      @media screen and(max-width: 576px) {
        &-pagination {
          margin-top: 0;
        }
      }

      &-button-prev,
      &-button-next {
        display: block;
        top: 240px;
        width: 50px;
        height: 50px;
        transform: none;
        transition: all 0.3s;
        border-radius: 5px;
        background-color: $c-white;
        font-size: 16px;
        line-height: 50px;
        text-align: center;
        opacity: 1;

        @include medium-size-min {
          box-shadow: 0px 4px 10px 0px rgba($c-black, 0.07);
        }

        @include medium-size-max {
          display: none;
        }

        i {
          display: block;
          font-size: 24px;
        }

        &:hover {
          i {
            color: $c-white;
          }
        }
      }

      &-button-prev {
        left: -25px;
      }

      &-button-next {
        right: -25px;
      }

      @include large-size-max {
        &-slide {
          width: 100%;
        }
      }

      @include small-size-max {

        &-slide {
          &-active {
            .swiper-image-wrap {
              height: 350px;
            }
          }
        }

        &-image-wrap {
          height: 300px;
        }
      }

      @include xs-size-max {
        &-slide {
          &-active {
            .swiper-image-wrap {
              height: 250px;
            }
          }
        }

        &-image-wrap {
          height: 200px;
        }
      }
    }
  }

  &--edu-images-2 {
    .swiper {

      &-button-prev,
      &-button-next {
        top: 44%;
        box-shadow: 0px 0px 20px 0px rgba($c-black, 0.2);
      }

      &-button-prev {
        left: -25px;
      }

      &-button-next {
        right: -25px;
      }

      &-pagination {
        margin-top: 40px;
      }
      @media screen and (max-width: 1200px) {
        &-button-prev, &-button-next {
          display: none;
        }
      }
    }
  }

  &--edu-blog {

    .swiper {

      &-button-prev,
      &-button-next {
        display: block;
        padding: 5px;
        font-size: 24px;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }

        i {
          color: $c-white;
        }
      }

      &-button-prev {
        left: 30px;
      }

      &-button-next {
        right: 30px;
      }
    }
  }

  &--rest-blog {

    .swiper {

      &-button-prev,
      &-button-next {
        display: block;
        padding: 5px;
        font-size: 16px;
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }

        i {
          color: $c-white;
        }
      }

      &-button-prev {
        left: 30px;
      }

      &-button-next {
        right: 30px;
      }
    }
  }

  &--edu-posts {

    .swiper {

      &-container {
        padding: 30px 15px;
        margin: -30px -15px;
      }

      &-pagination {
        margin-top: 60px;
        margin-bottom: 0;

        &-bullet {
          margin: 0 5px;
        }
      }
      @include small-size-max {
        &-pagination {
          margin-top: 40px;
        }
      }
    }
  }
  &--edu-calendar {
    box-shadow: none;
  }
  &--websites {
    .swiper {
      &-container {
        margin: 0 0 -30px;
        padding: 0 0 30px;
      }

      &-slide {
        width: 800px;
        min-height: 535px;
        cursor: grab;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @media screen and (max-width: 800px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &--multiple {
    .swiper {

      &-slide {
        width: 1170px;
        height: 700px;
        background-position: center;
        background-size: cover;
        cursor: grab;

        @media screen and (max-width: 1170px) {
          width: 100%;
        }

        @include medium-size-max {
          height: 580px;
        }

        @include small-size-max {
          height: 430px;
        }

        @include xs-size-max {
          height: 250px;
        }
      }

      &-pagination {
        margin-top: 40px;
        margin-bottom: 0;
      }
    }
  }

  &--simple {
    .swiper {

      &-slide {
        height: 580px;
        background-position: center;
        background-size: cover;

        @include small-size-max {
          height: 430px;
        }

        @include xs-size-max {
          height: 250px;
        }
      }

      &-pagination {
        margin-top: 70px;
        margin-bottom: 0;
        @media screen and (max-width: 991px) {
          margin-top: 30px;
        }
      }
    }
  }

  &--vertical-pagination {
    .swiper-pagination {
      position: absolute;
      right: -70px;
      top: 50%;
      transform: translateY(-50%);
      left: auto;
      display: inline-flex;
      width: 20px;
      margin: 0;
      flex-direction: column;
      & > span {
        margin-bottom: 10px;
      }
    }
  }

  &--testimonials {
    .swiper-container {
      &:before,
      &:after {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        width: 15px;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, rgba($c-white, 1) 100%);
        content: '';
        z-index: 2;
      }
      &:before {
        background: linear-gradient(to left, transparent 0%, rgba($c-white, 1) 100%);
        left: 0;
      }
      @include small-size-max {
        margin: 0 auto;
        padding: 0;
      }
    }
    .swiper-pagination {
      top: calc(50% - 50px);
      @include small-size-max {
        display: none;
      }
    }
  }

  &--barbershop {
    .swiper-container {
      padding-top: 120px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      color: $c-white;
    }
  }

  &--classic {
    .swiper-container {
      margin: 0;
      padding: 0;
    }
  }

  &--trvl {

    .swiper {
      &-pagination {
        margin-top: 60px;

        @media screen and (min-width: 1301px) {
          display: none;
        }

        &-bullet {
          width: 15px;
          height: 15px;
        }
      }

      &-button-next,
      &-button-prev {
        opacity: 1;

        i {
          display: none;
        }

        &:before {
          color: inherit;
          font-family: $f-ionicons;
          font-size: 50px;
        }

        @media screen and (max-width: 1300px) {
          display: none;
        }
      }

      &-button-prev {
        left: -90px;

        @media screen and (max-width: 1500px) {
          left: -40px;
        }

        &:before {
          content: '\f3d5';
        }
      }

      &-button-next {
        right: -90px;

        @media screen and (max-width: 1500px) {
          right: -40px;
        }

        &:before {
          content: '\f3d6';
        }
      }
    }
  }

  &--trvl-2 {
    .swiper {
      &-container {
        margin: -40px 0 0;
        padding: 40px 0 0;
      }

      &-pagination {
        margin-top: 35px;

        &-bullet {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &--trvl-3 {
    .swiper {
      &-container {
        margin: 0;
        padding: 0;
      }

      &-pagination {
        margin-top: 95px;

        &-bullet {
          width: 15px;
          height: 15px;
        }
      }

      &-button-prev,
      &-button-next {
        width: 60px;
        height: 60px;
        background-color: $c-white;
        font-size: 18px;
        line-height: 60px;
        text-align: center;

        @media (max-width: 1300px) {
          display: block;
        }

        @include large-size-max {
          width: 45px;
          height: 45px;
          line-height: 45px;
        }

        &:hover {
          color: $c-white;
        }
      }

      &-button-prev {
        left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        &:before {
          content: '\f124';
        }
      }

      &-button-next {
        right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        &:before {
          content: '\f125';
        }
      }
    }
  }

  &--trvl-4 {
    .swiper {
      &-container {
        @include medium-size-min {
          padding-top: 70px;
        }
      }

      &-pagination {
        margin-top: 60px;
        margin-bottom: 0;

        &-bullet {
          width: 15px;
          height: 15px;
          margin: 0 5px !important;
        }
      }
    }
  }

  &--trvl-5 {
    .swiper {
      &-container {
        margin: 0;
        padding: 0;
      }

      &-pagination {
        margin-top: 30px;
        margin-bottom: 0;

        &-bullet {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &--trvl-6 {
    .swiper-pagination {
      @include small-size-max {
        margin-top: 50px;
      }
    }
  }

  &--business {
    .swiper {
      &-container {
        padding-top: 5px;
      }

      &-pagination {
        margin-top: 60px;
        @include small-size-max {
          margin-top: 30px;
        }
        &-bullet {
          width: 14px;
          height: 14px;
          margin: 0 6px !important;
          background-color: transparent;
          opacity: 0.3;

          &-active {
            opacity: 1;
          }
        }
      }
    }
  }

  &--vert-pag {
    .swiper {
      &-container {
        margin: 0;
        padding: 0;
      }

      &-pagination {
        position: absolute;
        top: 50%;
        right: -10px;
        bottom: auto;
        left: auto;
        width: auto;
        margin: 0;
        transform: translate(0, -50%);

        @include large-size-max {
          right: 0;
        }

        &-bullet {
          display: block;
          width: 10px;
          height: 10px;
          margin-top: 10px !important;
          margin-bottom: 10px !important;
          border-radius: 50%;
          background-color: transparent;
        }
      }
    }
  }
  &--political{
    .swiper{
      &-button-next{
        font-size: 20px;
        color: #999999;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: -30px;
        border: 1px solid #eeeeee;
        border-radius: 50%;
        @media screen and (max-width: 1280px) {
          margin-right: 0px;
        }
        @include medium-size-max {
          display: none;
        }
        &:before{
          content:'\f30f';
        }
        &:hover{
          color: $c-white;
          background-color: #d7d7d7;
        }
      }
      &-button-prev{
        font-size: 20px;
        color: #999999;
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -30px;
        border: 1px solid #eeeeee;
        border-radius: 50%;
        @media screen and (max-width: 1280px) {
          margin-left: 0px;
        }
        @include medium-size-max {
          display: none;
        }
        &:before{
          content:'\f2ca';
        }
        &:hover{
          color: $c-white;
          background-color: #d7d7d7;
        }
      }
    }
  }

  // &--travel-testimonials {
  //   .aht-tm__desc {
  //     font-weight: 300;
  //   }
  // }

  &--travel-team {
    .swiper-container {
      padding: 0 10px;
    }

    .aht-team__desc {
      font-weight: 300;
      padding-right: 10px;
    }

    .aht-team__socials {
      .aheto-socials__icon {
        color: #222;
      }
    }

    .aht-team--t1 {
      box-shadow: 2px 1px 0 0 rgba(0, 0, 0, 0); // Fix for google chrome (black border)

      &:hover {
        box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }

  &--travel-our-directions {
    .swiper-container {
      padding: 30px 10px 0 10px;
    }
  }

  &--yoga-team {
    .swiper-container {
      padding: 30px 10px 0 10px;
    }
  }

  &--travel-popular {
    @include medium-size-min {
      .swiper-container {
        padding: 60px 10px 0 10px;
      }
    }
  }

  &--travel-hero {
    .swiper-wrapper {
       height: 100vh;
    }

    @include menu-size-max {
      .swiper-wrapper {
        margin-top: -64px;
      }
    }

    .swiper-wrapper {
      @include medium-size-min {
        .aht-banner__content {
          padding-bottom: 120px;
        }
      }
    }
  }

  &--yoga-1 {
    .swiper {

      &-pagination {

        @media screen and (min-width: 1300px) {
          position: absolute;
          top: 28%;
          left: 100%;
          margin: 0;
          padding-left: 27px;
        }

        @media screen and (min-width: 1500px) {
          padding-left: 65px;
        }

        &-bullet {
          margin: 3px;
        }
      }
    }
  }

  &--yoga-2 {
    .swiper {

      // Fix for hover effect
      &-container {
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: -30px;
        margin-bottom: -30px;
      }

      &-button-prev,
      &-button-next {
        width: 56px;
        height: 56px;
        transform: translateY(-20%);
        border: 1px solid $c-white;
        border-radius: 50%;
        background-color: rgba($c-black, 0.07);
        font-size: 20px;
        line-height: 56px;
        text-align: center;
        opacity: 1;

        &:hover {
          border: 1px solid #ccc;
          background-color: transparent;
        }
        @media screen and (max-width: 1549px) {
          display: none;
        }
      }

      &-button-prev {
        left: -105px;

        i {
          &:before {
            content: '←';
          }
        }
      }

      &-button-next {
        right: -105px;

        i {
          &:before {
            content: '→';
          }
        }
      }

      &-pagination {
        margin: 60px 0 0;

        @media screen and (min-width: 1550px) {
          display: none;
        }

        &-bullet {
          margin: 0 5px;
        }
      }
    }
  }

  &--constrution {
    h1 {
      font-size: 70px;
    }
    .swiper {

      &-slide {
        display: flex;
        align-items: center;
        background-position: center;
        background-size: cover;
        > div {
          @include medium-size-max {
            max-height: calc(100vh - 116px);
          }
          height: 100vh;
          max-height: 900px;
          flex-basis: 100%;
        }
      }

      &-button-prev,
      &-button-next {
        opacity: 1;
        padding: 20px;
        i {
          display: block;
          color: $c-white;
          font-size: 42px;
        }

        &:hover {
          opacity: 0.5;
        }
      }

      &-button-prev {
        left: 100px;
        @media only screen and (max-width: 1280px) {
          left: 20px;
        }
        @include medium-size-max{
          display: none;
        }
      }

      &-button-next {
        right: 100px;
        @media only screen and (max-width: 1280px) {
          right: 20px;
        }
        @include medium-size-max{
          display: none;
        }
      }
    }
  }

  &--constrution-service {
    .swiper {
      &-container {
        margin: 0;
        padding: 0;
      }

      &-button-prev,
      &-button-next {
        top: 151px;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        text-align: center;
        opacity: 1;

        i {
          display: block;
          margin-top: 50%;
          transform: translateY(-50%);
          color: $c-black;
          font-size: 22px;
        }
      }

      &-button-prev {
        left: -81px;
      }

      &-button-next {
        right: -81px;
      }
    }
  }

  &--constrution-portfolio {
    max-width: 100%;
    margin-top: 70px;
    @include medium-size-max {
      margin-top: 50px;
    }
    @include medium-size-max {
      margin-top: 30px;
    }
    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      margin: 0 5px;
      margin-top: 25px;
      @include small-size-max{
        margin-top: 10px;
      }
    }
  }

  &--construction-tm {

    @include large-size-max {
      position: relative;
      max-width: 100%;
    }

    position: absolute;
    max-width: 70vw;

    .swiper-pagination-progressbar {
      @include large-size-max {
        max-width: 100%;
      }
      height: 1px;
      background-color: rgba($c-white, 0.2);
      max-width: 54vw;
    }
    .swiper-pagination-progressbar-fill {
      height: 3px;
      top: -1px;
      .circle {
        position: absolute;
        top: -8px;
        right: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: inherit;
        -webkit-transition-duration: 0ms !important;
        transition-duration: 0ms !important;
      }
    }
    .swiper-pagination {
      margin-top: 74px;
      margin-bottom: 20px;
      @include medium-size-max{
        margin-top: 50px;
        margin-bottom: 10px;
      }
    }
    .swiper{
      &-pagination {
        max-width: 80%;
        margin: 75px 16px 0 16px;
        @include medium-size-max{
          max-width: calc(100% - 32px);
        }
        &-custom {
          position: relative;
          height: 2px;
          background-color: #4d4d4d;

          &-line {
            background-color: #ebb113;
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            transition-property: width;

            &::after {
              content: '';
              position: absolute;
              top: -8px;
              right: 0;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: inherit;
            }
          }
        }
      }
    }
  }

  &--twitter {
    display: flex;
    flex-wrap: wrap;

    .swiper {
      &-container {
        flex-basis: 100%;
      }

      // &-wrapper {

      // }

      // &-slide {

      // }

      &-button-prev,
      &-button-next {
        display: inline-block;
        position: static;
        flex: 1 1 0;
        padding: 16px 10px;
        transform: none;
        transition-duration: 0.2s;
        border-top: 1px solid #eee;
        border-right: 1px solid #eee;
        font-size: 17px;
        text-align: center;
        opacity: 1;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  &--chr {

    .swiper {

      &-pagination {
        position: absolute;
        top: 50%;
        right: 50px;
        bottom: auto;
        left: auto;
        width: auto;
        transform: translate(0, -50%);

        @include large-size-max {
          right: 10px;
        }

        @include small-size-max {
          display: flex;
          top: 10px;
          right: auto;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &-bullet {
          display: block;
          width: auto;
          height: auto;
          margin: 0 !important;
          padding: 22px 13px;

          border-radius: 0;
          background: none;
          color: $c-white;
          font-size: 14px;
          font-weight: 700;

          @include small-size-min {
            border-left: 1px solid rgba($c-white, 0.2);

            &-active {
              border-left: 2px solid $c-white;
            }
          }

          @include small-size-max {
            border-bottom: 1px solid rgba($c-white, 0.2);

            &-active {
              border-bottom: 2px solid $c-white;
            }
          }
        }
      }
    }
  }

  &--chr-2 {
    .swiper {
      &-pagination {
        margin-top: 55px;

        &-bullet {
          width: 15px;
          height: 15px;
          margin: 0 6px !important;
          background-color: transparent;
        }
      }
    }
  }

  &--chr-3 {
    max-width: 1570px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 35px;

    @include large-size-min {
      padding: 0 70px;
    }

    @include small-size-max {
      padding: 0;
    }

    .swiper {
      &-pagination {
        margin-top: 57px;

        &-bullet {
          width: 14px;
          height: 14px;
          margin: 0 6px !important;
          background-color: transparent;
        }
      }

      &-button-prev,
      &-button-next {
        color: #DDDDDD;
        font-size: 30px;

        @include large-size-max {
          display: none;
        }
      }

      &-button-prev {
        left: 5px;
      }

      &-button-next {
        right: 5px;
      }

      &-pagination {
        @include large-size-min {
          display: none;
        }
      }
    }
  }

  &--restaurant,
  &--single-gallery {
    .swiper {
      // &-wrapper {
        //justify-content: center;
      // }
      &-button {
        &-prev,
        &-next {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          font-size: 16px;
          top: 140px;
        }
        &-prev {
          left: -172px;
        }
        &-next {
          left: -110px;
        }
      }
    }
    @include small-size-max {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
  }

  &--single-gallery {
    height: 100%;
    .swiper {
      &-slide {
        background-position: center;
      }
      &-button {
        &-prev,
        &-next {
          top: 50%;
        }
        &-prev {
          left: -63px;
        }
        &-next {
          left: 0;
        }
        @include large-size-max {
          &-prev {
            bottom: -60px;
            top: auto;
            right: 51%;
            left: unset;
          }
          &-next {
            bottom: -60px;
            top: auto;
            left: unset;
            right: 46%;
          }
        }
        @include large-size-max {
          &-prev {
            right: 51%;
          }
          &-next {
            right: 42%;
          }
        }
      }
    }
  }

  &--rest-home {
    .swiper {
      &-pagination {
        position: absolute;
        top: 50%;
        left: 100px;
        bottom: auto;
        right: auto;
        height: 200px;
        width: auto;
        border-left: 1px solid #fff;
        transform: translate(0, -50%);
        display: flex;
        align-items: center;
        &:after {
          content: '/ 3';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 40px;
          color: $c-white;
          font-size: 20px;
          font-weight: 700;
          -webkit-font-smoothing: antialiased;
        }

        @include medium-size-max {
          display: none;
        }
        &-bullets {
          margin-top: 0;
        }

        &-bullet {
          width: auto;
          height: auto;
          margin: 0 !important;
          padding: 0 40px 0 24px;
          border-radius: 0;
          background: none;
          color: $c-white;
          font-size: 20px;
          font-weight: 700;
          -webkit-font-smoothing: antialiased;
          display: none !important;
          border: none;
          &-active {
            display: block !important;
          }
        }
      }

      &-button-prev,
      &-button-next {
        font-family: $f-ionicons;
        color: #fff;
        font-size: 20px;
        left: 92px;
        right: auto;
        -webkit-font-smoothing: antialiased;
        @include medium-size-max {
          display: none;
        }
      }
      &-button-prev {
        top: 50%;
        transform: translateY(-150px);
        &:before {
          content: '\f126';
        }
      }

      &-button-next {
        transform: translateY(120px);
        top: 50%;
        &:before {
          content: '\f123';

        }
      }
    }
  }

  &--agency {

    .swiper {
      &-pagination {
        margin-top: 60px;

        &-bullet {
          width: 15px;
          height: 15px;
        }
      }

      &-button-next,
      &-button-prev {
        opacity: 1;

        i {
          display: none;
        }

        &:before {
          color: inherit;
          font-family: $f-ionicons;
          font-size: 50px;
        }

        @media screen and (max-width: 1300px) {
          display: none;
        }
      }

      &-button-prev {
        left: -90px;

        @media screen and (max-width: 1500px) {
          left: -40px;
        }

        &:before {
          content: '\f124';
          font-size: 30px;
        }
      }
      
      &-button-next {
        right: -90px;
        
        @media screen and (max-width: 1500px) {
          right: -40px;
        }
        
        &:before {
          content: '\f125';
          font-size: 30px;
        }
      }
    }
  }
}



.news-posts{
  .swiper-container{
    padding: 50px 30px;
  }
  .swiper-slide{
    height: auto;
  }
  .swiper-pagination-bullets {
    margin-top: 0px;
  }
}

.swiper{
  &--constrution{
    &-service{
      .swiper-button{
        &-prev,
        &-next{
          font-size: 16px;
          line-height: 3;
        }
      }
    }
  }
}

.about-church-slider{
  .swiper{
    &-container {
      &-vertical {
        .swiper-wrapper {
          height: 800px;
        }
      }
    }

    &-pagination {
      &-bullets {
        margin-top: 5px;
      }
    }
  }
}

.swiper-banner-yoga {
  .swiper-wrapper {
    height: 100vh;
    margin-top: -67px;

    @include menu-size-max {
      margin-top: -64px;
    }

    .aht-banner--full-height {
      height: 100%;
    }
  }

}

.swiper-absolute {
  display: block;
  min-height: 401px;
}



.rest-testimonials-section {
  .testimonials-ultra-wrap {
    flex-direction: row-reverse;
    .testimonials-wrap {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      box-shadow: 0 0 59px 0 rgba(0, 0, 0, 0.2);
      padding-left: 110px;
      padding-right: 110px;
      margin-right: 13%;
      .aheto-heading {
        &__title {
          font-family: $f-playfair-display;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          color: #fff;
          font-size: 40px;
        }
      }
    }
    @media screen and (max-width: 1440px) {
      .testimonials-wrap {
        margin-right: 1%;
      }
    }
    @include small-size-max {
      .testimonials-wrap {
        padding-left: 30px;
        padding-right: 30px;
        margin-right: 0;
      }
    }
    @include xs-size-max {
      .testimonials-wrap {
        padding-left: 14px;
        padding-right: 14px;
        .aheto-heading {
          &__title {
            font-size: 34px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .testimonials-ultra-wrap {
      justify-content: center;
    }
  }
}


.rest-single-gallery-section {
  background-position: bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}
.rest-single-gallery-section.about-page {
  background-position: bottom left;
}
.rest-single-gallery-section.rest-home {
  background-position: top left;
}
.single-gallery-ultraWrap {
  display: flex;
  justify-content: flex-end;
  position: relative;


  .single-gallery-container {
    padding: 75px 0 50px 0;
    max-width: 700px;
    position: relative;
    @include large-size-max {
      width: 100%;
      padding: 0;
      transform: translateY(-30px);
      z-index: 2;
    }
    @include small-size-max {
      max-width: unset;
      transform: unset;
    }
    @include xs-size-max {
      width: 100%;
    }
  }
  .bg-text {
    position: absolute;
    top: 90px;
    left: 0;
    color: #F7F7F7;
    font-size: 150px;
    font-weight: 800;
    letter-spacing: 15px;
    text-transform: uppercase;
    opacity: 0.7;
  }
  @include large-size-max {
    flex-direction: column-reverse;
    align-items: center;
    .bg-text {
      display: none;
    }
  }
}
.padding-270bottom {
  padding-bottom: 270px;
}
.single-gallery-wrap {
  padding-right: 0 !important;
  padding-left: 0 !important;
  box-shadow: 0 0 59px 0 rgba(0, 0, 0, 0.2);
  width: 54%;
  .swiper-container {
    height: 100%;
  }
  @include large-size-max {
    width: 100%;
    height: 600px;
  }
  @include large-size-max {
    height: 500px;
  }
  @include medium-size-max {
    height: 430px;
  }
  @include xs-size-all {
    height: 330px;
  }
  @media screen and (max-width: 425px) {
    height: 200px;
  }
}

.single-gallery-descrWrap {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0;
  z-index: 1;
}
.single-gallery-descr {
  padding-left: 104px;
  padding-right: 170px;
  @media screen and (max-width: 1440px) {
      padding-right: 104px;
  }
}
@include medium-size-max {
  .single-gallery-ultraWrap {
    flex-direction: column-reverse;
  }
}

.single-gallery-descr-icons {
  margin-top: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 280px;
  width: 100%;
  .aheto-single-img {
    height: 54px;
    width: 54px;
    img {
      height: 100%;
    }
  }
}

.rest-awards-icons-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  .aheto-single-img {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @include medium-size-max {
    flex-wrap: wrap;
    .aheto-single-img {
      margin: 0 6px 20px 6px;
      width: auto;
    }
  }
}

@include xs-size-max {
  .single-gallery-descr-icons {
    margin-top: 50px;
  }
  .single-gallery-descr {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.swiper--edu-imagesWidth {
  .swiper-wrapper {
    .swiper-slide {
      @include large-size-max {
          width: 800px;
      }
    }
  }
}



// Old shit

// .swiper {

// position: relative;

//   &-slide {
//     height: auto;

//     > .testimonials {
//       height: 100%;
//     }
//   }

//   &-button-prev,
//   &-button-next {
//     width: auto;
//     height: auto;
//     margin: 0;
//     transform: translateY(-50%);
//     transition: 0.5s;
//     background: none;
//     color: $btn-primary-bg;
//     font-size: $swiper-arrow-size;
//   }

//   &-button-prev,
//   &-container-rtl .swiper-button-next {
//     left: -70px;


//     @media (max-width: 1300px) {
//       display: none;
//     }
//   }

//   &-button-next,
//   &-container-rtl .swiper-button-prev {
//     right: -70px;

//     @media (max-width: 1300px) {
//       display: none;
//     }
//   }

//   &-container:not(.swiper-container-vertical) {
//     margin: -30px -15px;
//     padding: 30px 15px;
//   }

//   &-container-vertical {
//     .swiper-pagination--vertical {
//       right: 105px;
//       @include medium-size-max {
//         right: 40px;
//       }
//       @include small-size-max {
//         top: 100px;
//         right: 20px;
//         transform: translateY(0);
//       }
//     }
//   }

//   &-pagination {
//     position: static;
//     margin-top: 60px;
//     margin-bottom: 40px;

//     &-bullet {
//       width: 12px;
//       height: 12px;
//       transition: 0.5s;
//       background: #d7d7d7;
//       opacity: 1;

//       &-active {
//         background: $btn-default-bg;
//       }
//     }

//     &--vertical {
//       position: absolute;
//       right: 105px;
//       @include medium-size-max {
//         right: 40px;
//       }
//       @include small-size-max {
//         right: 20px;
//       }
//     }
//     &--numeric {
//       margin: 0;
//       .swiper-pagination-bullet {
//         width: auto;
//         color: $c-white;
//         font-size: 14px;
//         font-weight: 800;
//         letter-spacing: 1.4px;
//         background: none;
//         border-radius: 0;
//         opacity: 0.4;
//         transition: opacity 0.2s ease-out;
//         &:not(:last-of-type) {
//           margin-bottom: 47px;
//           @include small-size-max {
//             margin-bottom: 20px;
//           }
//         }
//         &:after {
//           position: absolute;
//           bottom: -5px;
//           left: 0;
//           display: block;
//           width: 0;
//           height: 1px;
//           background: $c-white;
//           opacity: .4;
//           transition: opacity .2s ease-out;
//           content: '';
//         }
//       }
//       .swiper-pagination-bullet-active {
//         opacity: 1;
//         position: relative;
//         &:after {
//           width: 130px;
//         }
//       }
//     }

//     &-progressbar {
//       position: relative;
//       height: 2px;
//       background-color: #eee;

//       &-fill {
//         background-color: $c-active;
//       }

//       // &-mark {
//       //   position: absolute;
//       //   top: 1px;
//       //   width: 16px;
//       //   height: 16px;
//       //   transform: translate(0, -50%);
//       //   border-radius: 50%;
//       //   background-color: $color-1;
//       // }
//     }
//   }

