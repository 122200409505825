.aht-filter {

  &--edu {
    border-radius: 5px;
    box-shadow: 0 15px 50px 0 rgba($c-black, 0.15);
    overflow: hidden;

    .aht-filter {
      &__form {
        display: flex;
        flex-wrap: wrap;

        @include medium-size-max {
          flex-direction: column;
        }
      }

      &__fields {
        display: flex;
        flex: 11;
        flex-wrap: wrap;

        @include medium-size-max {
          flex-direction: column;
        }
      }

      &__field {
        position: relative;
        flex: 1;
        @include medium-size-min {
          border-right: 1px solid #eee;

          &:last-child {
            border-right: 0;
          }
        }

        @include medium-size-max {
          border-bottom: 1px solid #eee;

          &:last-child {
            border-bottom: 0;
          }
        }

        &--cat,
        &--prc-type {
          &:after {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0, -50%);
            font-size: 10px;
            content: '▼';
          }
        }
      }

      &__icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
      }

      &__input,
      &__select {
        width: 100%;
        height: 100%;
        transition: all 0.2s ease-out;
        border: 0;
        outline: none;
      }

      &__input {
        padding: 30px 20px;
      }

      &__select {
        padding: 30px 15px 30px 42px;
        appearance: none;
        border-radius: 0;
        background-color: #fff;
        &::-ms-expand {
          display: none;
        }

        option {

          &[disabled] {
            display: none;
          }
        }
      }

      &__submit {
        flex: 3;
        padding: 30px 15px;
        transition: all 0.2s ease-out;
        border: 0;
        outline: none;
        color: $c-white;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        cursor: pointer;
        font-family: $f-karla;
      }
    }
  }
}
