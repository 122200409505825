.aheto-header-14 {
  &__inner {
    max-width: 1750px;
    margin: 0 auto;
  }

  &__line {
    display: flex;
    position: relative;
    justify-content: space-between;

    @include menu-size-min {

    }

    @include menu-size-max {
      flex-wrap: wrap;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    border-bottom-right-radius: 5px;

    @include menu-size-min {
      margin-right: 15px;
      margin-bottom: -25px;
      padding: 13px 20px;
      border-bottom-left-radius: 5px;
    }

    @include menu-size-max {
      position: relative;
      padding: 5px 15px;
      z-index: 2;
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    @include menu-size-min {
      flex: 1 1 0;
      padding: 5px 15px;
    }

    @include menu-size-max {
      display: none;
      flex-basis: 100%;
      order: 2;
      padding: 15px !important;
      min-height: 63px;
      }

    @include small-size-all {
        display: block;
        position: absolute;
        right: 177px;
        top: -3px;
        border-right: 1px solid fade-out($c-white, 0.9);
        border-left: 1px solid fade-out($c-white, 0.9);
    }
  }

  &__hamburger {
    display: flex;
    align-items: center;

    @include menu-size-min {
      display: none;
    }
  }

  &__menu {

    @include menu-size-min {
      .aheto-header-14__logo {
        margin-right: 15px;
        margin-left: 15px;

        @media screen and (min-width: 1500px) {
          margin-right: 50px;
          margin-left: 50px;
        }
      }
    }

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 60px);
      padding: 20px 15px 30px;
      background-color: $c-white;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    @include xs-size-all {
      max-height: calc(100vh - 123px);
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    padding: 5px 15px;

    @include menu-size-min {
      flex: 1 1 0;
      justify-content: flex-end;
    }

    @include menu-size-max {
      margin-left: auto;
      padding: 0 15px;
    }
  }

  &__button {
    margin-right: 15px;

    @media screen and (min-width: 1500px) {
      margin-right: 26px;
    }

    &:last-child {
      margin-right: 0;
    }

    &--cart {
      a {
        padding-right: 13px;
        &:hover{
          span{
            color: #dd2932;
          }
        }
      }
    }
  }

  &__button-link {
    position: relative;
  }

  &__button-icon {
    color: $c-white;
    font-size: 16px;
    transition: 0.2s all;
    &:hover {
      transition: 0.2s all;
    }
    @include menu-size-max {
      font-size: 20px;
    }
  }

  &__button-number {
    position: absolute;
    top: -4px;
    right: -13px;
    color: $c-white;
    font-size: 13px;
    font-weight: 400;
  }

  // Main menu
  .main-menu {
    a {
      &.active{
        color: #dd2932;
      }
    }
    @include menu-size-min {
      display: flex;
      flex-wrap: wrap;

      .menu-item {

        a {
          font-size: 16px;
          font-weight: 400;
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
            a {
              &.active{
                color: #dd2932;
              }
            }
          }
        }
      }

      > .menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;

        > a {
          padding: 20px 0;
          color: $c-white;
          &.active{
            color: #dd2932;
            position: relative;
            &:after{
              content: '';
              width: 100%;
              height: 3px;
              background-color: #dd2932;
              position: absolute;
              left: 0px;
              bottom: 0px;
            }
          }
        }
        &.active{
          position: relative;
          >a{
            color: #dd2932;
          }
          >span{
            color: #dd2932;
          }
          &:after{
            content: '';
            width: 100%;
            height: 3px;
            background-color: #dd2932;
            position: absolute;
            left: 0px;
            bottom: 0px;
          }
        }


        @media screen and (min-width: 1500px) {
          margin: 0 30px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }

    @include menu-size-max {

      .menu-item {
        a {
          font-size: 16px;
          font-weight: 700;
        }
      }

      > .menu-item {
        padding-bottom: 20px;

        > a {
          font-size: 20px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      position: absolute;
      top: calc(100% - 3px);
      left: 0;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;

      .sub-menu {
        top: -5px;
        left: 101%;
      }

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 225px;
        padding: 12px 30px;
        border-bottom: 1px solid rgba($c-black, 0.03);

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 30px;
          border-bottom: 0;
        }

        a {
          font-size: 15px;
        }
      }

      .dropdown-btn {
        font-size: 10px;

        &:before {
          content: '\f10a';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 15px 20px 0;
      }
    }
  }

  // Menu item
  .mega-menu {
    @include menu-size-min {
      display: flex;

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-black, 0.03);
        background-color: $c-white;

        &:last-child {
          border-right: 0;
        }
      }

      &__title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.03);
        font-size: 20px;
      }

      .menu-item {
        min-width: 180px;
        padding: 5px 0;
        border-bottom: 0;

        &:first-child {
          padding-top: 5px;
        }

        &:last-child {
          padding-bottom: 5px;
        }
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.15);
        font-size: 20px;
        font-weight: 700;
      }

      &__col {
        padding: 20px 20px 0;
      }

      .menu-item {
        padding: 15px 0 0;
      }
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    margin-left: 12px;
    color: $c-white;
    font-size: 8px;

    &:before {
      content: '\f104';
    }

    @include menu-size-max {
      display: none;
    }
  }

  // Socials
  .aht-socials {
    display: flex;
    flex-wrap: wrap;

    &__link {
      display: inline-block;
      margin-right: 15px;

      @media screen and (min-width: 1500px) {
        margin-right: 25px;
      }

      &:last-child {
        margin-right: 0;
      }

      @include menu-size-max {
        margin-right: 25px;
      }
    }

    &__icon {
      color: $c-white;
      font-size: 16px;
      transition: 0.2s all;

      &:hover {
        transition: 0.2s all;
      }

      @include menu-size-max {
        font-size: 20px;
        line-height: 33px;
      }
    }

    @include xs-size-all {
      justify-content: center;
    }
  }

  // Hamburger
  .hamburger {

    &-inner {
      background-color: $c-white;

      &::before,
      &::after {
        background-color: $c-white;
      }
    }
  }

  // Logo
  .logo {
    &__img {
      max-height: 65px;

      @include menu-size-max {
        max-height: 50px;
      }
    }

    &__text {
      color: $c-white;
    }

    &__sub-text {
      color: $c-white;
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
}
