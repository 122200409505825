.blog-pagination-wrapper {
  margin-bottom: 115px;
  text-align: center;
  @media (max-width: 991px) {
    margin-bottom: 80px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .btn--load-more {
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    .prev,
    .next {
      span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    .prev {
      margin-right: 25px;
      span {
        i {
          margin-right: 12px;
        }
      }
    }

    .next {
      margin-left: 25px;
      span {
        i {
          margin-left: 12px;
        }
      }
    }

    .wrap {

      .page-numbers {
        display: inline-block;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 500;
        line-height: 33px;
        text-align: center;

        &.current,
        &:hover {
          background: #2A74ED;
          color: $c-white;
          opacity: 1;
        }
      }
    }

    @include small-size-max {
      .prev {
        margin-right: 10px;
      }
      .next {
        margin-left: 10px;
      }
    }
    &__learn-more {
      position: relative;
      padding: 5px 0;
      cursor: pointer;
      transition: color 0.2s ease-out;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      color: #999;
      &:after,
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        z-index: 1;
        transition: .2s ease-out;
        background: #999;
      }
      &:before {
        left: 50%;
        width: 0;
        z-index: 2;
      }
      &:hover {
        &:after {
          left: 50%;
          width: 0;
        }
        &:before {
          left: 0;
          width: 100%;
          transition-delay: .2s;
        }
      }
      &--uppercase {
        text-transform: uppercase;
      }
      &--no-decoration {
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }

  &--no-bg {
  }

  &--hidden {
    display: none;
  }

  &--numbers {

  }

  &--without-numbers {
    @include small-size-max {
      margin-top: 0px;
    }
  }
}
