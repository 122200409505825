.aht-move {
    overflow: hidden;
    &--figure {
        position: absolute;
        animation-name: pulse;
        animation-iteration-count: infinite;
        animation-duration: 3s; 
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8); 
    }
    100% {
        transform: scale(1);
    }
}