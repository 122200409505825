.aheto-header-11 {
  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1780px;
    margin: 0 auto;
    padding-top: 40px;
    padding-right: 15px;
    padding-left: 15px;

    @include large-size-max {
      padding-top: 10px;
    }

    @include small-size-max {
      padding-top: 10px;
    }
  }

  &__logo {
    padding: 15px;
  }

  // Modal
  &__modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $c-white;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__modal-inner {
    display: flex;
    flex-direction: column;
    max-width: 1370px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 5% 3.5%;

    @include large-size-max {
      padding: 140px 100px;
    }

    @include small-size-max {
      padding: 40px 40px;
    }
  }

  &__modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 15px;
    font-size: 40px;
  }

  &__socials {
    margin-top: auto;
  }

  &__socials-title {
    margin: 120px 0 25px !important;
    font-size: 18px;
    letter-spacing: 1px !important;

    @include small-size-max {
      margin: 80px 0 25px !important;
    }
  }

  // Main menu
  .main-menu {
    .menu-item {
      a {
        display: inline-block;
        padding: 9px 0;
        font-size: 18px;
        font-weight: 700;
      }
    }

    > .menu-item {
      > a {
        position: relative;
        padding: 12px 0;
        font-size: 36px;
        letter-spacing: 1px;
        line-height: 36px;

        @include menu-size-max {
          font-size: 25px;
        }

        &:before {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 3px;
          transition: all 0.2s;
          content: '';
        }

        &:hover {
          &:before {
            width: 100%;
          }
        }

      }
    }
  }

  // Sub menu
  .sub-menu {
    display: none;
    padding: 3px 0 10px;

    .dropdown-btn {
      margin-top: 3px;
      padding: 5px;
      font-size: 10px;
    }

    .sub-menu {
      padding: 5px 40px 5px;
    }
  }

  // Mega menu
  .mega-menu {

    &__title {
      margin: 10px 0;
      font-weight: 700;
    }

    &__col {
      margin-top: 25px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .dropdown-btn {
    display: inline-block;
    margin-top: 10px;
    margin-left: 15px;
    padding: 10px;
    transition: all 0.2s;
    font-size: 16px;
    cursor: pointer;
    vertical-align: top;

    &:before {
      vertical-align: top;
    }

    &.is-active {
      border:1px solid transparent;

      @include menu-size-max {
        border: 1px solid #ddd;
      }

      &:before {
        transform: rotate(-180deg);
      }
    }
  }

  .logo {

    &__img {
      max-height: 52px;
    }

    &__text {
      font-size: 40px;
      font-weight: 900;
    }
  }

  .hamburger {

    &-box {
      width: 27px;
      height: 23px;
    }

    &-inner {
      width: 27px;
      height: 3px;

      &:before,
      &:after {
        width: 27px;
        height: 3px;
      }

      &:before {
        top: -11px;
      }

      &:after {
        bottom: -11px;
      }
    }
  }

  .aheto-socials {
    &__icon {
      font-size: 20px;
    }
  }

  &.is-open {
    .aheto-header-11__hamburger {
      position: fixed;
      top: 70px;
      right: 90px;

      @media screen and (max-width: 1500px) {
        top: 50px;
        right: 50px;
      }

      @include small-size-max {
        top: 30px;
        right: 30px;
      }
    }
  }
}

@include menu-size-max {
  .over-hidden {
    width: 100%;
    overflow: hidden;
  }
}
