.aht {
  &__sidebar {

    // Search widget
    .search {

      &-form {
        position: relative;
        margin-bottom: 60px;
        @include small-size-max{
          margin-bottom: 30px;
        }
      }

      &-field,
      &-submit {
        border: 0;
        outline: 0;
      }

      &-field {
        width: 100%;
        padding: 20px;
        transition: all 0.2s ease-out;
        border: 1px solid #eee;
        border-radius: 5px;
        font-weight: 500;

      }

      &-submit {
        position: absolute;
        top: 50%;
        right: 15px;
        padding: 5px;
        transform: translate(0, -50%);
        cursor: pointer;
      }

      .screen-reader-text {
        display: none;
      }
    }

    // Posts
    .aht-widget-posts {

      &__buttons {
        display: flex;
      }

      &__button {
        flex: 1;
        padding: 17px 5px 13px;
        transition: all 0.3s ease-out;
        border-top: 0;
        border-right: 1px solid #eee;
        border-left: 0;
        outline: none;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;

        &:last-child {
          border-right: 0;
        }

      }

      &__list {
        display: none;
        margin-top: 40px;
        margin-bottom: 0;

        &.active {
          display: block;
        }
      }

      &__item {
        margin-bottom: 30px;
        padding-left: 0;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
        }
      }

      &__item-inner {
        display: flex;

      }

      &__img {
        width: 80px;
        height: 80px;
        margin-right: 19px;
        background-position: center;
        background-size: cover;
      }

      &__img-link {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      &__text {
        flex: 1;
        line-height: 20px;
      }

      &__title {
        display: inline-block;
        margin: -3px 0 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }

      &__date {
        margin: 8px 0 0;
        font-size: 13px;
        line-height: 2;
      }
    }

    // calendar
    .calendar {
      padding: 40px 25px;
      @media screen and (max-width: 1024px) {
        max-width: 300px;
      }
      @media screen and (max-width: 768px) {
        margin-right: auto;
        margin-left: auto;
      }

      table {
        width: 100%;
        text-align: center;

        tr {
          th {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            line-height: 2.29;
            padding: 10px 0px;
          }

          td {
            font-size: 13px;
            letter-spacing: 0.3px;
            padding: 8px 0px;
          }

          td.active {
            border-radius: 50%;
            width: 35px;
          }
        }


        tr.calendar-first-row {
          td {
            padding-top: 20px;
          }
        }
      }

      .calendar_header {
        display: flex;
        justify-content: space-between;
        padding: 0px 15px;
        align-items: center;

        .month {
          font-size: 20px;
          font-weight: 500;
          line-height: 1.6;

          i {
            padding-right: 5px;
          }
        }
      }

    }

    // Tags widget
    .widget_tags {
      @include small-size-max {
        padding-bottom: 30px;
        margin-top: 30px;
      }

      .widget-title {
        font-size: 16px;
        font-weight: bold;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 35px;
        margin-bottom: 0;
        @include small-size-max {
          margin-top: 25px;
        }
      }

      li {
        display: inline-block;
        margin-right: 6px;
        margin-bottom: 4px;
        padding-left: 0;
        line-height: 18px;

        &:before {
          content: none;
        }
      }

      a {
        display: inline-block;
        padding: 0px 8px;
        border-radius: 3px;
        font-size: 13px;
        font-weight: 400;
        line-height: 2.46;
      }
    }

    // Advertisement
    .aht-widget-advert {
      height: 300px;
      padding: 28px 30px 35px;
      background-position: center;
      background-size: cover;
      text-align: center;

      &__subtitle {
        margin: 0 0 12px;
        font-size: 14px;
        font-weight: 400;
      }

      &__title {
        margin-top: 0;
        margin-bottom: 15px;
        line-height: 0.88;
        letter-spacing: 3.2px;
      }

      &__desc-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        min-height: 100px;
      }

      &__desc {
        letter-spacing: 1.6px;
        line-height: 4.38;
        font-weight: 700;
      }

      &__link {
        text-transform: capitalize;
      }
    }

    // twitter
    .aht-twitter {
      text-align: center;
      margin-top: 80px;
      @include small-size-max {
        margin-top: 50px;
      }

      &__link {
        width: 60px;
        height: 60px;
        display: inline-block;
        line-height: 60px;
        border-radius: 50%;
        position: relative;
        top: -30px;
        font-size: 19px;
      }

      &__content {
        padding: 15px 25px 35px;
      }

      &__text {
        margin: 0;
      }


      &__date {
        margin: 23px 0 27px;
        font-size: 14px;
      }
      .date {
        span{
          font-size: 14px;
        }
      }

      &__follow {
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0px;
          right: 0px;
        }
      }

      .btn {
        display: flex;

        &-left {
          padding: 15px 0px;
          width: 50%;
          cursor: pointer;
        }

        &-right {
          padding: 15px 0px;
          width: 50%;
          cursor: pointer;
        }
      }
    }

    .widget {
      margin-bottom: 0px;
    }
  }
}
