.aht-blog-yoga {
  max-width: 1750px;
  margin: 0 auto;

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    flex-basis: 25%;
    margin-bottom: 30px;

    @media screen and (max-width: 1500px ) {
      flex-basis: 33.33%;
    }

    @include large-size-max {
      flex-basis: 50%;
    }

    @include small-size-max {
      flex-basis: 100%;
    }
  }

  &__item-inner {
    position: relative;
    margin: 0 15px;
    transition: all 0.3s;
    background-color: $c-white;
    box-shadow: 0px 4px 50px 0px rgba($c-black, 0.07);
    
    @include menu-size-min {
      &:hover {
        transform: translate(0, -30px);
        box-shadow: 0px 39px 54px 0px rgba($c-black, 0.14);

        .aht-blog-yoga__img {
          &:after {
            opacity: 1;
          }
        }

        .aht-blog-yoga__link {
          opacity: 1;
        }
      }
    }
  }

  &__img {
    position: relative;
    height: 280px;
    background-position: center;
    background-size: cover;

    &-inner {
      width: 0;
      height: 0;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-out;
      content: '';
      opacity: 0;
      z-index: 2;
    }
  }

  &__cats {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &__cat {
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 6px 15px;
    border-radius: 11px;
    color: $c-white;
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__link {
    transition: all 0.3s ease-out;
    letter-spacing: 1px;
    white-space: nowrap;

    @include menu-size-min {
      position: absolute;
      top: 115px;
      left: 50%;
      transform: translate(-50%, 0);
      opacity: 0;
      z-index: 3;
    }

    @include menu-size-max {
      align-self: center;
      margin-top: 30px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 33px 40px;

    @include small-size-max {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  &__title {
    margin: 0;
    font-weight: bold;
    line-height: 30px;
  }

  &__desc {
    margin: 22px 0 0;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba($c-black, 0.07);
  }

  &__detail {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 19px 10px;
    border-right: 1px solid rgba($c-black, 0.07);

    &:last-child {
      border-right: 0;
    }
  }

  &__det-icon {
    margin-right: 10px;
    font-size: 16px;
  }

  &__det-text {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
  }
}
