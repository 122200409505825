.aheto-header-1 {

  // Toolbar
  &__toolbar {
    padding: 10px 0;

    @include small-size-max {
      padding: 7px 0;
    }
  }

  &__toolbar-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include xs-size-max {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }


  }

  &__toolbar-text {
    font-size: 15px;
  }

  // Header content
  &__main {
    position: relative;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 20px 0 35px;

    @include large-size-max {
      padding: 40px 0 15px;
      align-items: center;
    }

    @include medium-size-max {
      padding-top: 10px;
      align-items: inherit;
    }

    @include small-size-all {
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 10px;
        float:right;
    }

    @include xs-size-all {
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 10px;
      float: none;
    }

    @include xs-size-max {
      padding-bottom: 10px;
      float:none;
    }
  }

  &__detail {
    display: flex;
    align-items: center;
    padding: 5px 0;

    & + & {
      margin-left: 30px;
    }

    @include large-size-max {
      & + & {
        margin-left: 0;
      }
      line-height: 20px;
    }

    @include large-size-max {
      padding-left: 15px;
    }

    @include small-size-max {
      padding: 2px 0;
    }
  }

  &__detail-icon {
    margin-right: 8px;
    font-size: 20px;
  }

  &__detail-text,
  &__detail-link {
    font-size: 16px;
    font-weight: bold;
  }

  &__detail-text {
    letter-spacing: 0;

    @include small-size-max {
      font-size: 15px;
    }
  }

  // Nav
  &__nav-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translate(0, 50%);

    @include large-size-max {
      position: static;
      transform: none;
    }
  }

  &__nav {
    display: flex;
    position: relative;
    justify-content: space-between;

    @include large-size-min {
      margin-left: -30px;
      padding-left: 25px;
    }
  }

  &__menu {
    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      right: -15px;
      left: -15px;
      max-height: calc(100vh - 53px);
      background-color: $c-white;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    @include large-size-max {
      left: 15px;
    }

    @include medium-size-max {
      left: -15px;
    }

  }

  &__additional {
    display: flex;
    position: relative;
    align-items: center;

    @include menu-size-max {
      flex-grow: 1;
    }
  }

  &__lang {
    height: 100%;
    margin-right: 25px;
    color: $c-white;
  }

  &__hamburger {
    @include menu-size-min {
      display: none;
    }

    @include menu-size-max {
      flex-grow: 1;
    }
  }

  &__button {
    margin-right: 20px;
    padding: 10px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button-icon {
    color: $c-white;
    font-size: 22px;
    transition: .3s all;
    &:hover {
      color: #CF2632;
      transition: .3s all;
    }
  }

  // Menu
  .dropdown-btn {
    margin-left: 9px;
    color: $c-white;

    @include menu-size-max {
      display: none;
    }
  }

  .main-menu {
    display: flex;
    flex-wrap: wrap;

    .menu-item {
      position: relative;

      a {
        font-size: 15px;
        font-weight: 700;
      }
      a.active{
        color: #CF2632;
      }
    }
    .menu-item-has-children.active{
      >a{
        color: #CF2632;
      }
      .dropdown-btn{
        color: #CF2632;
      }
    }

    @include menu-size-min {
      > .menu-item {
        padding: 0 18px;

        > a {
          display: inline-block;
          padding: 18px 0;
          color: $c-white;
        }
      }
      .menu-item {
        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @include menu-size-max {
      flex-direction: column;
      padding: 30px 15px;
    }
  }

  .sub-menu {

    .sub-menu {
      top: -30px;
      left: 100%;
    }

    .dropdown-btn {
      float: right;
      font-size: 10px;

      &:before {
        content: '\f125';
      }
    }

    .menu-item {
      min-width: 230px;
      padding: 5px 30px;
      @include menu-size-max {
        padding: 0px 30px;
      }
      a {
        font-size: 15px;
      }
    }

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 30px 0;
      transition: all 0.2s;
      background-color: $c-white;
      box-shadow: 0px 6px 13px 0px rgba($c-black, 0.08);
      opacity: 0;
      visibility: hidden;
    }
  }

  .mega-menu {
    display: flex;
    padding: 0;

    &__col {
      width: 230px;
      padding: 35px 30px;
    }

    &__title {
      padding-bottom: 10px;
      font-size: 15px;
      font-weight: 700;
      line-height: 24px;
    }

    &__list {
      padding-top: 8px;
    }

    .menu-item {
      min-width: 0;
      padding: 5px 0;
    }

    @include menu-size-max {
      flex-direction: column;

      &__col {
        padding: 5px 30px;
      }
    }
  }

  // hamburger
  .hamburger {
    @include menu-size-max {
      padding: 15px 0;
    }
  }

  // Logo
  .karma-logo {
    display: inline-flex;
    padding: 35px 0 40px;

    img {
      width: auto;
      max-height: 40px;
    }

    @include medium-size-max {
      padding: 25px 0;
    }

    @include small-size-all {
      padding: 30px 0;
    }

    @include xs-size-all {
      padding: 20px 0 0;
    }

    @include xs-size-max {
      padding: 15px 0 10px;
    }
  }

  // Socials
  .aheto-socials {
    &__link {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // Multi language
  .multi-lang {
    height: 100%;

    &__active {
      .multi-lang__language-text {
        color: $c-white;
        transition: .3s all;
        &:hover {
          color: #CF2632;
          transition: .3s all;
        }
      }

    }
    .multi-lang__language {
      &-text {
        &:hover {
          color: #CF2632;
          transition: .3s all;
        }
      }
    }

    &__language-text {
      font-weight: bold;
    }

    &__list {
    }
  }
}
