.aht-gal-par {
  padding: 30px 0;
  overflow: hidden;

  &__inner {
    position: relative;
    height: 665px;
  }

  &__holder {
    position: absolute;
    background-position: center;
    background-size: cover;

    &:nth-child(1) {
      top: 0;
      left: 16%;
      width: 355px;
      height: 225px;
      z-index: 5
    }

    &:nth-child(2) {
      top: 21%;
      right: 14%;
      width: 315px;
      height: 200px;
      z-index: 2;
    }

    &:nth-child(3) {
      top: 30%;
      left: 0;
      width: 350px;
      height: 260px;
      z-index: 3;
    }

    &:nth-child(4) {
      top: 42%;
      left: 42%;
      width: 280px;
      height: 180px;
      z-index: 4;
    }

    &:nth-child(5) {
      bottom: 0;
      left: 24%;
      width: 355px;
      height: 225px;
      z-index: 1;
    }
  }


  &--chr {
    .aht-gal-par {
      &__holder {
        border-radius: 5px;
        box-shadow: -20px 0px 30px 0px rgba($c-black, 0.15);

        &:nth-child(3) {
          top: 30%;
          left: 0;
          width: 350px;
          height: 260px;
          box-shadow: 20px 0px 30px 0px rgba($c-black, 0.15);
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .church-margin{
    margin-top: 180px;
  }
}


@media screen and (max-width: 1500px) {
  .aht-gal-par {
    &--chr {
      .aht-gal-par {
        &__holder {
          &:nth-child(1) {
            top: 0;
            left: 12%;
          }

          &:nth-child(2) {
            top: 23%;
            right: 10%;
          }

          &:nth-child(3) {
            top: 30%;
            left: 0;
          }

          &:nth-child(4) {
            top: 50%;
            left: 50%;
          }

          &:nth-child(5) {
            bottom: 0;
            left: 24%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .aht-gal-par {
    &--chr {
      .aht-gal-par {
        &__holder {
          &:nth-child(1) {
            top: 0;
            left: 9%;
          }

          &:nth-child(2) {
            top: 23%;
            right: 0%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .aht-gal-par {
    &--chr {
      .aht-gal-par {
        &__holder {
          &:nth-child(1) {
            top: 0;
            left: 15%;
            right: auto;
          }

          &:nth-child(2) {
            top: 27%;
            right: 4%;
            z-index: 4;
          }

          &:nth-child(3) {
            top: 33%;
            left: 0;
          }

          &:nth-child(4) {
            top: 77%;
            left: auto;
            right: 4%;
          }

          &:nth-child(5) {
            bottom: 0;
            left: 14%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .aht-gal-par {
    &--chr {
      .aht-gal-par {
        &__holder {
          &:nth-child(1) {
            top: 0;
            left: 0;
          }

          &:nth-child(2) {
            top: 0;
            right: 0;
          }

          &:nth-child(3) {
            top: 33%;
            left: calc((100% - 350px)/2);
          }

          &:nth-child(4) {
            top: auto;
            bottom: 0;
            left: auto;
            right: 0;
          }

          &:nth-child(5) {
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .aht-gal-par {
    &--chr {
      .aht-gal-par {
        &__inner {
          height: 100%;
        }

        &__holder {
          position: relative;
          width: 100%;
          margin: 10px 0;

          &:nth-child(3) {
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}