/**
* common variebles
* and style
*/

$link-transition: 0.3s ease-out;

// FIXME: use only for HTML (transparent BUTTON)
.aheto-bg--dark {
  padding: 50px 15px 20px;
  background-color: #222;
}


.typographyText .aheto-btn {
  // FIXME: remove when create shortcode
  margin: 20px 0;
	// FIXME: remove it when create blocks
  + .aheto-btn {
	// for two or more btn in line
	margin-left: 35px;
  }
}

.aheto-btn-container {
  // margin: -10px 0;

  .aheto-btn {
	margin: 0;

	&:last-child {
	  margin-right: 0;
	}
  }
}

// btn default
.aheto-btn {
  display: inline-block;
  position: relative;
  outline: none;
  font-style: normal;
  cursor: pointer;
  i,
  span {
	font-style: normal;
	vertical-align: middle;
  }
  &:hover {
	background-color: transparent;
  }

  &.aheto-btn--underline {
	&:after {
	  transition: 0.3s all;
	}
  }

  // btn inline
  &--inline {
	padding: 0;
	border: 0;
	background-color: transparent;
  }

  // btn underline
  &--underline {
    padding: 0 0 5px !important;
    border: 0 !important;
    background-color: transparent !important;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 15px;
	&:after {
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  height: 1px;
	  content: '';
	}
  }

  // btn with box icon
  &--box {
	padding-left: 0;
  }

  // btn big size (rectangle)
  &--rect {
	max-width: 140px;
	line-height: 1;
	word-spacing: 9999999px;
	word-wrap: break-word;
  }

  // Btn circle
  &--circle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	border-radius: 50%;
	text-align: center;
  }
}

// Icon inside button
.aheto-btn__icon {
  &:before {
	line-height: 14px;
  }

  &--box {
	margin-right: 30px;
	padding: 0 24px;
	  @include medium-size-max {
		  margin-right: 0px;
	  }
	&:before {
	  position: absolute;
	  z-index: 1;
		left: 20px;
	}
	&:after {
	  position: absolute;
	  top: -1px;
	  left: -1px;
	  width: 60px;
	  height: calc(100% + 2px);
	  border-top-right-radius: 0;
	  border-bottom-right-radius: 0;
	  content: '';
	  z-index: 0;
	}
  }
}

// agency line blue button

.aheto-btn-container {
	// margin-top: 80px;
	.aheto-agency__link--dark {
		border: none;
		padding: 0;
		background-color: transparent;
		font-size: 13px;
		letter-spacing: 0;
		text-transform: none;
	}
}

.news-agency {
	.aheto-agency__link--dark {
		position: relative;
		&:after {
			position: absolute;
			top: -1px;
			right: -12px;
			font-size: 15px;
		}
	}
}

// agency line blue button