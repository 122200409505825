.aheto-header-3 {

  &__inner {
    @include menu-size-min {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding-bottom: 15px
    }
    @media screen and (max-width: 1024px){
      position: fixed;
      width: 100%;
      left: 0px;
      right: 0px;
    }
  }
  &__fix {
    background: linear-gradient(140deg, #6c207c 0%, #E32682 100%);
    transition: background 0.5s ease;
    .aheto-header-3__search{
      color: #222222;
    }
    .main-menu{
      > .menu-item{
        &:hover{
         > a {
            color: #222222;
          }
        }
      }
    }
  }
  &__line {
    display: flex;
    padding: 15px 0 0;


    @include menu-size-min {
      justify-content: space-between;
      max-width: 1750px;
      margin: 0 auto;
    }

    @include menu-size-max {
      flex-wrap: wrap;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    @include large-size-max {
      padding-top: 15px;
      padding-bottom: 15px;
    }

  }

  &__logo {
    display: flex;
    align-items: center;
    padding: 13px 15px;

    @include menu-size-min {
      flex: 1 1 0;
    }

    @include menu-size-max {
      padding: 10px 15px;
    }
  }

  &__additional {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;

    @include menu-size-min {
      flex: 1 1 0;
    }

    @include menu-size-max {
      flex-basis: 100%;
      padding: 0 15px 10px;
    }
    @media screen and (max-width: 1024px) {
        position: absolute;
        top: 25px;
        right: 35px;
    }
    @include medium-size-max {
      position: absolute;
      top: 25px;
      right: 35px;
    }

    @include xs-size-max {
      position: relative;
      top: 0px;
      right: 0px;
    }
  }

  &__menu {
    padding: 15px;

    @include menu-size-min {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - 56px) !important;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    @include xs-size-max {
      height: calc(100vh - 108px) !important;
    }
  }

  &__hamburger {
    display: flex;
    align-items: center;
    margin-left: auto;

    @include menu-size-min {
      display: none;
    }
  }

  // Search
  &__search-icon {
    color: $c-white;
    font-size: 22px;
    transition: 0.3s all;
    padding: 0 20px;
    display: inline-block;
  }

  // Dropdown-btn
  .dropdown-btn {
      margin-left: 7px;
      color: $c-white;
  }

  // Menu
  .main-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .menu-item {
      position: relative;
      a {
        display: inline-block;
        font-weight: 500;
      }
      &:hover {
        z-index: 1;
      }
    }

    @include menu-size-min {

      > .menu-item {
        position: relative;
        margin: 0 26px;
        padding: 5px 0;

        > a {
          color: $c-white;
        }

        &:after {
          display: block;
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 0;
          height: 1px;
          transition: all 0.2s;
          background-color: $c-white;
          content: '';
        }

        &:before {
          display: block;
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 100%;
          height: 20px;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          content: '';
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      .menu-item {
        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @include large-size-max {

    > .menu-item {
        margin: 0 12px;

        a {
          font-size: 14px;
        }
    }

    }

    @include menu-size-max {

      .menu-item {
        padding: 8px 0;
        text-align: center;
        a {
          font-size: 20px;
        }
      }
      flex-direction: column;
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min  {
      position: absolute;
      top: 100%;
      left: -20px;
      padding: 30px 0;
      transition: all 0.2s;
      border-radius: 5px;
      background-color: rgba($c-black, 0.8);
      opacity: 0;
      visibility: hidden;
      margin-top:15px;

      .sub-menu {
        top: -30px;
        left: 100%;
      }

      .menu-item {
        min-width: 180px;
        padding: 5px 20px;

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }

        a {
          color: $c-white;
        }
      }

      .dropdown-btn {
        float: right;

        &:before {
          margin-left: 10px;
          content: '\f125';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 15px 25px 0;
      }
    }
  }

  // Menu-menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      padding: 0;

      &__title {
        margin-bottom: 5px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-white, 0.3);
        color: rgba($c-white, 0.75);
        padding-bottom: 10px;
        text-transform: uppercase;
        font-size: 15px;
        }

      &__col {
        padding: 30px;
      }

      .menu-item {
        padding: 5px 0;
      }
    }

    @include menu-size-max {

      &__title {
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-white, 0.3);
        color: $c-white;
        text-transform: uppercase;
        font-size: 15px;
      }

      &__col {
        padding: 15px 25px 0;
      }

      .menu-item {
        padding: 15px 25px 0;
      }
    }
  }

  // Logo
  .logo {
    &__text {
      color: $c-white;
      font-weight: 700;
    }
  }

  // Button
  .aheto-btn {
    margin-left: 30px;
    padding: 19px 37px;
    white-space: nowrap;

    @include menu-size-max {
      padding: 10px 30px;
    }
  }
}
