.blog--grid {
  background: #f6f9ff;
  .border-radius-5{
    border-radius: 5px;
  }
  .border-radius-5t{
    border-radius: 5px 5px 0px 0px;
  }
  .blog_item{
    &:last-child{
      margin-bottom: 0px;
    }
  }
  .post {
    background-color: $c-white;
    box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0);
    position: relative;
    min-height: 335px;
    margin-bottom: 0px;
    transition: 0.2s all;
    height: 100%;

    &:hover {
      box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
      transition: 0.2s all;
    }

    .content-wrapper {
      padding: 40px 30px 45px;
      margin-bottom: 0px;
      .post-title {
        margin: 3px;
        a {
          font-size: 24px;
          color: #222;
          line-height: 1.25;
          display: inline-block;

          &:hover {
            color:#2a74ed;
          }
        }

      }

      .post-date {
        font-size:13px;
        line-height: 16px;
      }
    }

    .content-top-wrapper {
      .image-wrapper {
        img {
          display: block !important;
          width: 100%;
          height: auto;
          position: relative;
        }
      }

      .post-cats {
        a {
          background: #2a74ed;
          color:#fff;
          font-size: 13px;
          line-height: 26px;
          display: inline-block;
          margin-right: 1px;
          border-radius: 3px;
          padding: 0 10px;

          &:hover {
            background: #1954b4;
            color:#fff;
          }
        }
      }
    }
  }

  .format-image {
    .post-cats {
      position: absolute;
      top: 30px;
      left: 30px;
    }
    .aheto-btn {
      position: absolute;
      bottom: 45px;
      left: 30px;
      @media screen and (max-width: 1024px) {
        bottom: 30px;
      }
    }
  }
  .f-18{
    font-size: 18px;
  }
  .format-quote {
    background: #222222;
    position: relative;;
    box-shadow: none;
    padding: 160px 40px 32px 40px;
    min-height: 517px;

    .post-cats {
      position: absolute;
      top: 30px;
      left: 30px;
    }

    &:before {
      content: '”';
      position: absolute;
      font-family: 'Times New Roman';
      font-size: 180px;
      font-weight: bold;
      top: 67px;
      right: 50px;
      color: #3f3e3e;
      line-height: 120px;
    }

    p {
      font-size: 30px;
      font-family: Playfair Display, serif;
      color: #fff;
      font-style: italic;
      line-height: 1.333;
      text-align: left;
    }

    cite {
      color: #2a74ed;
      font-size: 14px;
      line-height: 26px;
      font-style: normal;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1.4px;
      margin-top: 90px;
      display: block;
      @media screen and (max-width: 1024px) {
        position: absolute;
        bottom: 35px;
      }
      @media screen and (max-width: 768px) {
        position: relative;
        bottom: auto;
        margin-top: 50px;
      }
    }
  }

  .format-standard {
    min-height: 505px;
    padding-bottom: 80px;

    .post-cats {
      padding: 30px 30px 0;
    }
    .aheto-btn {
      position: absolute;
      bottom: 45px;
      left: 30px;
      @media screen and (max-width: 1024px) {
        bottom: 30px;
      }
    }
  }

  .format-slider {
    .post-cats {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 44;
    }

    .swiper {
      &-button-prev:before,
      &-button-next:before {
        display: none;
      }

      &-button-prev,
      &-button-prev:hover {
        color: #fff;
        left: 35px;
        font-size: 26px;
        margin-top: 10px;
      }

      &-button-next ,
      &-button-next:hover {
        color: #fff;
        right: 35px;
        font-size: 26px;
        margin-top: 10px;
      }
    }
  }

  .format-video {
    .post-cats {
      position: absolute;
      top: 30px;
      left: 30px;
      z-index: 44;
    }

    .video-wrapper a {
      position: relative;
      display: table;

      &:before {
        content:"\f04b";
        font-family: Fontawesome;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 20px 30px 0px rgba(42, 116, 237, 0.1);
        position: absolute;
        width: 60px;
        height: 60px;
        z-index: 44;
        text-align: center;
        line-height: 60px;
        font-size: 13px;
        top: 50%;
        left: 0;
        right: 0;
        margin: -30px auto 0;
        @media screen and (max-width: 1024px) {
          margin: -25px auto 0;
        }
      }
    }
  }

  .format-audio {
    min-height: 505px;
    padding-bottom: 71px;

    .post-cats {
      padding: 30px 30px 0;
    }

    .aheto-btn {
      position: absolute;
      bottom: 45px;
      left: 30px;
      @media screen and (max-width: 1024px) {
        bottom: 30px;
      }
    }

    .audio-wrapper {
      padding: 30px 30px 0;

      .mejs__container {
        background: #222;
        min-height: 60px;
        width: 100% !important;
        max-width: 100%;
      }

      .mejs__horizontal-volume-slider {
        display:none !important;
      }

      .mejs__controls {
        height: 60px;
        padding:0;
      }

      .mejs__button.mejs__volume-button {
        height: 60px;
        margin: 0;
        width: 60px;
        border-left: 1px solid #3f3e3e;
        text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
      }

      .mejs__button.mejs__playpause-button {
        height: 60px;
        margin: 0;
        width: 60px;
        border-right: 1px solid #3f3e3e;
        text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
      }

      .mejs__time {
        color: #fff;
        font-size: 11px;
        height: 60px;
        padding: 26px 11px 0;
        font-family: Roboto;
        font-weight: 400;

        &-total {
          background: #ffffff;
          margin: 19px 0 0;
          width: 100%;
          border-radius: 0;
          height: 2px;
        }

        &-handle-content {
          border: none;
          border-radius: 0;
          height: 2px;
          width: 10px;
        }

        &-hovered {
          border-radius: 0;
          height: 2px;
        }

        &-buffering {
          border-radius: 0;
          height: 2px;
          margin-top:1px;
        }

        &-current {
          border-radius: 0;
          height: 2px;
          background: #999 !important;
          margin-top:0px;
        }

        &-loaded {
          display: none;
        }

      }
    }
  }

  .blog-pagination-wrapper .pagination {
    .wrap .page-numbers, .prev span {
      color: #222222;
      transition: 0.2s all;
    }
    .next {
      span {
        color: #222222;
        transition: 0.2s all;
      }
      &:hover span {
        color: #2a74ed;
      }
    }
    .prev:hover span {
      color: #2a74ed;
    }
    .wrap .page-numbers {
      &:hover, &.current {
        background: #2a74ed;
        color: #fff;
      }
    }
  }

  @include large-size-all {

    .format-quote {
      p {
        font-size: 28px;
      }
      cite {
        margin-top: 50px;
        display: block;
      }
    }

    .format-audio {
      min-height: 505px;
      padding-bottom: 45px;
      @media screen and (max-width: 1024px) {
        bottom: 30px;
      }
    }

    .format-standard {
      min-height: 498px;
      padding-bottom: 40px;
    }
  }

  @include medium-size-max {
    .format-audio {
      min-height: auto;
      padding-bottom: 64px;
    }

    .format-quote {
      min-height: auto;
    }
  }

  @include small-size-max {
    .format-standard {
      min-height: auto;
      padding-bottom: 25px;
    }

    .format-audio {
      padding-bottom: 25px;
    }

  }

}
.aht-page__content{
  width: 100%;
}
