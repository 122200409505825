.multi-lang {
  display: flex;
  position: relative;
  align-items: center;

  &__active {
    cursor: pointer;

    .multi-lang__language {
      padding: 0;
    }

    .multi-lang__language-text {
      color: $c-white;
    }
  }

  &__item {
    margin-bottom: 20px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__language {
    display: flex;
    align-items: center;
    padding: 0 25px;
  }

  &__language-img {
    width: 31px;
    height: 31px;
    margin-right: 13px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__list {
    display: none;
    position: absolute;
    top: 100%;
    left: -25px;
    padding: 25px 0;
    background-color: $c-white;
  }
}
