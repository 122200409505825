.aht-dest {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    flex-basis: 33.33%;
    margin-bottom: 30px;
    padding: 0 15px;

    @include medium-size-max {
      flex-basis: 50%;
    }

    @include small-size-max {
      flex-basis: 100%;
    }
  }

  &__item-inner {
    position: relative;
    height: 470px;
    transition: all 0.3s ease-out;
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, transparent 60%, $c-black);
      content: '';
      opacity: 0.9;
    }
    
    @include menu-size-min {
      &:hover {
        transform: translate(0, -30px);
        box-shadow: 0px 30px 40px 0px rgba($c-black, 0.2);
      }
    }

    @include large-size-max {
      height: 400px;
    }

    @include small-size-max {
      height: 500px;
    }

    @include xs-size-max {
      height: 400px;
    }
  }

  &__link {
    display: flex;
    position: relative;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding: 34px 30px;
  }

  &__caption {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__title {
    margin: 0;
    color: $c-white;
  }

  &__icon {
    margin-right: 12px;
    color: $c-white;
    font-size: 24px;
  }

  &__number {
    display: inline-block;
  }
}
