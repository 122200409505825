.section_biography{
  background-repeat: no-repeat;
  &-bottom{
    margin-top: -170px;
    z-index: -1;
    position: relative;
    @media only screen and (max-width: 1199px) {
      margin-top: 0px;
    }
    @media only screen and (max-width: 850px) {
      margin-top: 40px;
    }
  }
  .aheto-single-img{
    img{
      border-radius: 5px;
      box-shadow: 0px 27px 47.9px 3.1px rgba(0, 0, 0, 0.25);
    }
  }
  .aheto-heading__desc{
    max-width: 450px;
    padding-top: 30px;
    @media only screen and (max-width: 991px) {
      padding-top: 0px;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    span{
      padding-bottom: 15px;
      display: block;
      @media only screen and (max-width: 991px) {
        padding-bottom: 5px;
      }
    }
  }
  .biography{
    &_container{
      margin-left: 0px;
      margin-right: 0px;
    }
    &_content{
      margin-bottom: 170px;
      @media only screen and (max-width: 1199px) {
        margin-bottom: 0px;
      }
    }
  }
}
