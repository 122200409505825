.coming-soon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__date {
    display: none;
  }

  &__unit {
    display: inline-block;
    min-width: 113px;
    margin: 0 50px;
    text-align: center;
  }

  &__number {
    font-size: 100px;
    line-height: 1;
  }

  &__dots {
    margin-top: 19px;
    font-size: 40px;
    line-height: 100px;
    vertical-align: middle;

    @include large-size-max {
      margin-top: 15px;
    }

    @include medium-size-max {
      margin-top: 4px;
    }

    @include small-size-max {
        margin-top: 6px;
    }

  }

  &--c-white {
    .coming-soon__number,
    .coming-soon__caption,
    .coming-soon__dots {
      color: $c-white;
    }
  }

  &--home-event {
    justify-content: space-between;

    .coming-soon__unit {
      margin: 0 20px;
    }
    .coming-soon__number {
      font-size: 85px;
      margin-bottom: 15px;
    }

    .coming-soon__caption {
      letter-spacing: 3.3px;
    }

    .coming-soon__dots {
      display: none;
    }
  }

  @media screen and (max-width: 1230px) {
    .coming-soon__number {
      font-size: 95px;
    }
  }

  @media screen and (max-width: 992px) {
    .coming-soon__unit {
      min-width: 108px;
      margin: 0 20px;
    }
  }
  @media screen and (max-width: 768px) {

    .coming-soon__unit {
      min-width: 77px;
    }

    .coming-soon__number {
      font-size: 65px;
    }

    .coming-soon__caption {
      font-size: 14px;
    }

    .coming-soon__dots {
      margin-top: 3 px;
      font-size: 30px;
      line-height: 80px;
    }
  }
  @media screen and (max-width: 576px) {
    .coming-soon__number {
      font-size: 43px;
    }
    .coming-soon__unit {
      min-width: 48px;
      margin: 0 4px;
    }
    .coming-soon__dots {
      margin-top: 6px;
      font-size: 20px;
      line-height: 45px;
    }
    .coming-soon__caption {
      margin: 5px 0;
      font-size: 10px;
    }
  }
}
