.aheto-clients {
  display: flex;
  flex-wrap: wrap;

  &__holder {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    width: 150px;
    min-width: 200px;
    height: 130px;
    @media screen and (max-width: 991px) {
      height: 70px;
    }
  }

  &__link {
    display: inline-block;

    &:hover {
      opacity: 1;
    }
  }

  &__img {
    transition: all 0.3s ease-out;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  &--2-in-row {
    .aheto-clients__holder {
      flex-basis: 50%;
    }
  }

  &--3-in-row {
    .aheto-clients__holder {
      flex-basis: 33.33%;
    }
  }

  &--4-in-row {
    .aheto-clients__holder {
      flex-basis: 25%;
    }
  }

  &--5-in-row {
    .aheto-clients__holder {
      flex-basis: 20%;
    }
  }

  &--low-opacity {
    .aheto-clients__img {
      opacity: 0.233;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  &--business {
    .aheto-clients__holder {
      height: 80px;
    }
  }

  &--evts {
    @media screen and (max-width: 991px) {
      .aheto-clients {
        &__holder {
          height: 120px;
        }
      }
    }
  }
}

.aht-clients {

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  &__item {
    flex-basis: 100%;
    min-width: 230px;
    padding: 32px 15px;
    text-align: center;

    @include large-size-max {
      padding: 25px 15px;
    }

    &:before {
      content: none;
    }
  }

  &__img {
    max-width: 100%;
    max-height: 65px;
  }

  &--2 {
    .aht-clients__item {
      flex-basis: 50%;
    }
  }

  &--3 {
    .aht-clients__item {
      flex-basis: 33.33%;
    }
  }

  &--4 {
    .aht-clients__item {
      flex-basis: 25%;
    }
  }

  &--5 {
    .aht-clients__item {
      flex-basis: 20%;
    }
  }

  &--trvl {
    .aht-clients {
      &__link {
        //opacity: 0.15;
       img {
         -webkit-filter: grayscale(100%);
         filter: grayscale(100%);
         -webkit-transition: 1s;
         transition: 1s;
            &:hover {
              -webkit-filter: grayscale(0);
              filter: grayscale(0);
            }
       }
      }
    }
  }

  &--bsns {

    .aht-clients {
      &__link {
        opacity: 0.25;

        &:hover {
          opacity: 1;
        }
      }

      &__img {
        max-height: 45px;
      }
    }
  }
}

