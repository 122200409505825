.aht-grid-1 {

  &--edu {

    .aht-grid-1 {

      &__filter-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 43px;
      }

      &__filter {
        margin-right: 49px;
        padding: 10px 0;
        transition: all 0.3s;
        color: #222;
        font-size: 15px;
        font-weight: 500;
        cursor: pointer;

        &:first-child {
          margin-right: auto;
          padding-right: 49px;

          @include medium-size-max {
            flex-basis: 100%;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &__container {
        margin: -15px;
      }

      &__item {
        width: 50%;
        float: left;
        border: 15px solid transparent;

        @include small-size-max {
          width: 100%;
        }

        &-inner {
          position: relative;
          transition: all 0.3s;
          overflow: hidden;

          @include menu-size-min {
            &:hover {
              .aht-grid-1__item-text {
                transform: none;
              }
            }
          }
        }

        &-img {
          height: 400px;
          background-position: center;
          background-size: cover;

          @include medium-size-max {
            height: 300px;
          }

          @include xs-size-max {
            height: 270px;
          }
        }

        &-link {
          display: block;
          width: 100%;
          height: 100%;
        }

        &-text {
          padding: 32px 40px 38px;
          background-color: $c-white;

          @include medium-size-max {
            padding: 20px 30px;
          }

          @include menu-size-min {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translate(0, 100%);
            transition: all 0.3s;
          }
        }

        &-title {
          margin: 0;
          font-size: 20px;
          font-weight: 700;

          &:hover {
            opacity: 0.7;
          }
        }

        @media screen and (max-width: 1024px) {
          &-title {
            font-size: 18px;
          }
        }

        &-cats {
          margin-top: 9px;
        }

        &-cat {
          font-size: 14px;
          font-weight: 500;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
