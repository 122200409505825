.no-container-md {
  &.container {
    @include menu-size-max {
      width: 100%;
      padding: 0;
    }
  }
}

.aheto-header-10 {

  // Toolbar
  &__toolbar-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px 0;

    @include menu-size-max {
      padding: 5px 0;
    }
  }

  &__toolbar-infos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 15px;
    padding-bottom: 2px;
    @include small-size-max {
      margin-left: 15px;
    }
  }

  &__toolbar-info {
    display: flex;
    align-items: center;
    margin-right: 55px;

    &:last-child {
      margin-right: 0;
    }

    @include menu-size-max {
      padding: 2px 0;
    }
  }

  &__toolbar-icon {
    margin-right: 15px;
    font-family: $f-ionicons;
    font-size: 16px;
    font-style: normal;
  }


  &__toolbar-socials {
    display: flex;
    align-items: center;

    @include menu-size-max {
      padding: 3px 0;
    }
  }

  &__main {
    background-color: $c-white;
  }

  &__main-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0 30px;

    @include menu-size-min {
    }

    @include menu-size-max {
      padding: 10px 0;
    }
  }

  &__logo {
    @include menu-size-max {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  // Details
  &__details-wrap {
    padding: 5px 90px 0 30px;

    @include large-size-max {
      padding: 5px 30px 0;
    }

    @include menu-size-min {
      display: flex;
      flex: 1 1 0;
      justify-content: flex-end;
    }

    @include menu-size-max {
      display: none;
      flex-basis: 100%;
      order: 2;
      padding: 15px 0 10px;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;

    @include small-size-all {
      justify-content: center;
    }
  }

  &__detail {
    display: flex;
    align-items: center;
    margin-right: 120px;

    @include large-size-max {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    @include menu-size-max {
      padding: 5px 0;
    }
  }

  &__detail-title {
    font-weight: 700;
    color: #222;
  }

  &__detail-icon {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border: 2px solid rgba($c-black, 0.1);
    border-radius: 5px;
    font-family: $f-ionicons;
    font-size: 16px;
    font-style: normal;
    line-height: 46px;
    text-align: center;
  }

  &__detail-target {
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
  }

  // Buttons
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;

    @include menu-size-max {
      padding-top: 7px;
      margin-left: auto;
    }
  }

  &__button {
    margin-right: 23px;

    &:last-child {
      margin-right: 0;
    }

    @include menu-size-max {
      margin-right: 15px;
    }
  }

  &__button-link {
    position: relative;
  }

  &__button-icon {
    transition: 0.2s all;

    &:hover {
      transition: 0.2s all;
    }

    &--search {
      &:before {
        font-family: $f-ionicons;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        content: '\f2f5';
      }
    }

    &--basket {
      margin-right: 20px;

      &:before {
        font-family: $f-ionicons;
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        content: "\f3f8";
      }

      @include menu-size-max {
        margin-right: 10px;
      }
    }
  }

  &__button-number {
    position: absolute;
    top: -13px;
    right: 0;
    min-width: 17px;
    height: 17px;
    border-radius: 50%;
    color: $c-white;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    font-weight: 700;
    padding-left: 1px;

    @include menu-size-max {
      top: -9px;
    }
  }

  // Hamburger
  &__hamburger {
    @include menu-size-min {
      display: none;
    }
  }

  // Menu
  &__menu-wrapper {
    position: relative;
  }

  &__menu {

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    @include menu-size-max {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 160px);
      padding: 30px 40px;
      background-color: #fff;
      overflow-y: auto;
      display: none;
    }

    @include xs-size-max {
      max-height: calc(100vh - 220px);
    }

    @include medium-size-max {
      padding: 30px 25px;
    }

    @include small-size-max {
      padding: 15px 15px;
    }
  }

  // Main menu
  .main-menu {
    display: flex;

    @include menu-size-min {
      .menu-item {
        flex: 1 1 0;
        &:first-child a {
          border-bottom-left-radius: 5px;
        }
        &:last-child a {
          border-bottom-right-radius: 5px;
        }
        > a.active {
          color: #EBB113;
          background-color: $c-white;
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      > .menu-item {
        text-align: center;

        &:after {
          display: block;
          position: absolute;
          top: 5%;
          right: 0;
          width: 1px;
          height: 90%;
          background-color: rgba($c-black, 0.1);
          content: '';
        }

        &:last-child {
          border-right: 0;

          &:after {
            content: none;
          }
        }

        > a {
          display: block;
          padding: 18px 10px;
          color: $c-white;
          font-weight: 700;
          font-size: 16px;
          letter-spacing: 0.25px;
        }

        > .dropdown-btn {
          display: none;
        }
      }
    }

    @include menu-size-max {
      flex-direction: column;

      .menu-item {
        &.active > a {
          color: #EBB113;
        }
        a {
          font-weight: 700;
        }
      }

      .sub-menu {
        .menu-item {
          > a.active {
            color: #EBB113;
          }
          a {
            font-weight: 500;
          }
        }
      }

      > .menu-item {
        margin-bottom: 15px;

        > a {
          font-size: 24px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 28px 0;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;
      max-width: 195px;
      background-color: #EBB113;
      border-radius: 0 0 5px 5px;

      .sub-menu {
        top: -28px;
        left: 101%;
      }

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 225px;
        padding: 0 30px;
        text-align: left;
        max-width: 195px;
        > a.active {
          background-color: #EBB113;
          color: #222;
        }

        a {
          flex-grow: 1;
          padding: 8px 0;
          font-weight: 400;
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 10px 30px 0;
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      padding: 0;
      text-align: left;

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-black, 0.1);

        &:last-child {
          border-right: 0;
        }
      }

      &__title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.1);
        font-size: 20px;
      }

      .menu-item {
        min-width: 180px;
        padding: 0;
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        margin: 0 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba($c-black, 0.1);
        font-size: 20px;
        font-weight: 700;
      }

      &__col {
        padding: 10px 0;

        &:last-child {
          padding: 10px 0 0;
        }
      }
    }
  }

  .aht-socials {
    &__icon {
      color: rgba($c-white, 0.3);
    }
  }

  .logo {
    &__img {
      max-height: 55px;
    }

    &__text {
      font-size: 28px;
      font-weight: 700;
    }
  }

  .dropdown-btn {
    margin-left: 10px;
    font-size: 10px;

    &:before {
      content: '\f10a';
    }

    @include menu-size-max {
      &:before {
        content: '\f104';
      }
    }
  }

  .hamburger {
    margin-right: -15px;
  }
}
