.aheto-tab {
  text-align: center;

  &__head {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 110px;
    @media screen and (max-width: 991px) {
      margin-bottom: 50px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;

    &-link {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: 100%;
      padding: 20px 15px;
    }

    &-item {
      padding-left: 0;

      &:before {
        content: none;
      }
    }

    &-ico {
      flex-basis: 100%;
    }
  }

  &__content {
    text-align: left;
  }

  &__box {
    position: relative;
    height: 0;
    transition: visibility, opacity 1s;
    background-position: center;
    background-size: cover;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;

    &.active {
      height: auto;
      opacity: 1;
      visibility: visible;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--overlay {
      &-1 {
        &:before {
          content: '';

          @include medium-size-min {
            width: 50%;
          }
        }
      }

      &-2 {
        &:before {
          content: '';
          opacity: 0.7;
        }
      }

      &-3 {
        &:before {
          content: '';

          @include large-size-max {
            opacity: 0.9;
          }

          @include medium-size-max {
            opacity: 0.8;
          }
        }
      }

      &-4 {
        &:before {
          background-color: rgba($c-black, 0.5);
          content: '';
        }
      }
    }
  }

  &__box-inner {
    position: relative;
  }

  &--simple {
    .aheto-tab {

      &__head {
        border-radius: 5px;
        overflow: hidden;

        @include small-size-max {
          display: flex;
        }
      }

      &__list {
        margin: 0 -1px -1px 0;

        @include small-size-max {
          flex-direction: column;
        }

        &-link {
          text-align: center;


          @include small-size-max {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:hover {
            color: #fff;
          }
        }

        &-item {
          flex: 1 0 192px;
          width: 192px;

          @include medium-size-max {
            flex: 1 0 180px;
            width: 180px;
          }

          @include small-size-max {
            flex: 1;
            width: 100%;
          }

          &.active {
            a {
              color: $c-white;
            }
          }
        }
      }
    }
  }

  &--business {

    .aheto-tab__head {
      width: 100%;
      max-width: 1170px;
      margin: 0 auto;
    }

    .aheto-tab__list {
      width: 100%;
    }

    .aheto-tab__list-item {
      position: relative;
      flex: 1;
      transition: all 0.3s;

      &:last-child {
        border-right: 0;
      }

      @include medium-size-min {
        border-right: 2px solid $c-white;

        &:before {
          position: absolute;
          top: 0;
          left: 50%;
          width: 0;
          height: 0;
          transform: translate(-50%, -100%);
          transition: all 0.3s;
          border-right: 11px solid transparent;
          border-bottom: 18px solid $c-white;
          border-left: 11px solid transparent;
          content: '';
          opacity: 0;
        }
      }

      @include medium-size-max {
        flex-basis: 100%;
        border-bottom: 2px solid $c-white;
      }

      &.active {
        background-color: $c-white;
        box-shadow: 0.5px 4px 62px 0px rgba($c-black, 0.07);

        &:before {
          opacity: 1;
        }
      }
    }

    .aheto-tab__list-link {
      padding: 33px 30px;
      font-size: 18px;

      @media screen and (max-width: 1500px) {
        padding: 25px;
      }

      @include medium-size-max {
        padding: 15px 20px;
      }
    }

    .aheto-tab__box {
      display: flex;
      align-items: center;

      &.active {
        height: 700px;
        min-height: 700px;

        @media screen and (max-width: 1500px) {
          height: 550px;
          min-height: 550px;
        }
      }
    }

    .aheto-tab__box-inner {
      width: 100%;
    }

    .aheto-btn--light.aheto-btn--trans {
      &:hover {
        background-color: $c-white;
      }
    }
  }

  &--construction-home,
  &--construction-news {
    .aheto-tab {

      &__list {
        @include medium-size-max {
          display: flex;
          align-items: center;
          margin-top: 25px;
        }
        display: block;
        margin-top: 55px;
      }

      &__list-item {
        @include medium-size-max {
          margin: 0 15px;
        }
        &:first-of-type {
          @include medium-size-max {
            margin-left: 0;
          }
        }

        &.active {
          .aheto-tab {
            &__list-link {
              position: relative;

              &:after {
                display: block;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 3px;
                content: '';
              }
            }
          }
        }
      }

      &__list-item {
        &.active {
          .aheto-tab__list-link {
            font-weight: 600;
          }
        }
      }

      &__list-link {
        display: inline-block;
        margin-bottom: 25px;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.63;
        @include medium-size-max {
          margin-bottom: 0px;
        }
      }

      &__viewall {
        @include medium-size-max {
          display: inline-block;
          float: right;
          margin-top: 25px;
        }
        @include small-size-max  {
          margin-top: 0px;
        }
        display: block;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
      }

      &__head {
        @include medium-size-max {
          margin-bottom: 15px;
        }
        margin-bottom: 100px;
      }
    }
  }

  &--construction-news {
    .construction-news-menuWrap {
      margin: 0 auto;
      max-width: 500px;
      width: calc(100% - 20px);
    }

    .aheto-tab {
      &__head {
        width: 100%;
        margin-bottom: 25px;
        @include small-size-max  {
          margin-bottom: 0px;
        }
      }
      &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
      }
      &__list-link {
        margin-bottom: 0;
        &:after {
          bottom: -7px !important;
        }
      }
      &__list-item,
      &__list-item:first-of-type {
        @include small-size-max  {
          margin: 12px 15px 0 15px;
        }
      }
    }
  }

  &--event {
    background-color: #fff;

    .aheto-tab {
      &__head {
        width: 100%;
        margin-bottom: 56px;
      }

      &__list {
        //border-bottom: 2px solid #eee;
      }
    }

  }
  &--edu {
    .aheto-tab {
      &__list {
        &-item {
          .event-title {
            font-size: 18px;
            letter-spacing: normal;
          }
          .event-date {
            letter-spacing: 1.2px;
          }
        }
      }
    }
  }
}
