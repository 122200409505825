.main-header__toolbar-wrap {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h6 {
    font-size: 14px;
  }

  .container-fluid {
    max-width: 1750px;
    padding-right: 0;
    padding-left: 0;
  }

  @include menu-size-min {
    .aheto-toolbar {
      > * {
        border-left: 1px solid rgba($c-black, 0.05);

      }
      .border-none{
        border: none;
      }
    }
  }

  .widget,
  .registartion,
  .log-in {
    a {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .registartion {
    margin-left: auto;
    border: none;

    @include menu-size-max {
      margin-left: 0;
    }
  }

  .no-menu {
    display: none;
  }

  &--light {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $c-white;
    }

    .aht-socials {

      &__item {
        a {
          color: $c-white;
          line-height: 1;

          &:hover {
            color: rgba($c-white, 0.7);
          }
        }
      }
    }
  }

  &--transparent {
    background-color: transparent;
  }

  &--translucent-dark {
    background-color: rgba($c-black, 0.5);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $c-white;
    }

    .aht-socials {

      &__item {
        a {
          color: $c-white;
          line-height: 1;

          &:hover {
            color: rgba($c-white, 0.7);
          }
        }
      }
    }
  }
}
