@font-face {
  font-family: 'EducationIcons';
  src: url('../fonts/EducationIcons/font/fontello.eot?11048475');
  src: url('../fonts/EducationIcons/font/fontello.eot?11048475#iefix') format('embedded-opentype'),
       url('../fonts/EducationIcons/font/fontello.woff2?11048475') format('woff2'),
       url('../fonts/EducationIcons/font/fontello.woff?11048475') format('woff'),
       url('../fonts/EducationIcons/font/fontello.ttf?11048475') format('truetype'),
       url('../fonts/EducationIcons/font/fontello.svg?11048475#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?11048475#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "EducationIcons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-141-startup:before { content: '\e800'; } /* '' */
.icon-141-studying:before { content: '\e801'; } /* '' */
.icon-141-telescope:before { content: '\e802'; } /* '' */
.icon-141-test-tube:before { content: '\e803'; } /* '' */
.icon-141-test-tube-1:before { content: '\e804'; } /* '' */
.icon-141-think:before { content: '\e805'; } /* '' */
.icon-141-tic-tac-toe:before { content: '\e806'; } /* '' */
.icon-141-tie:before { content: '\e807'; } /* '' */
.icon-141-trophy:before { content: '\e808'; } /* '' */
.icon-141-trophy-1:before { content: '\e809'; } /* '' */
.icon-141-user:before { content: '\e80a'; } /* '' */
.icon-141-user-1:before { content: '\e80b'; } /* '' */
.icon-141-utensils:before { content: '\e80c'; } /* '' */
.icon-141-abacus:before { content: '\e80d'; } /* '' */
.icon-141-alarm-bell:before { content: '\e80e'; } /* '' */
.icon-141-alarm-bell-1:before { content: '\e80f'; } /* '' */
.icon-141-alarm-bell-2:before { content: '\e810'; } /* '' */
.icon-141-alarm-clock:before { content: '\e811'; } /* '' */
.icon-141-apple:before { content: '\e812'; } /* '' */
.icon-141-archive:before { content: '\e813'; } /* '' */
.icon-141-award:before { content: '\e814'; } /* '' */
.icon-141-backpack:before { content: '\e815'; } /* '' */
.icon-141-basketball:before { content: '\e816'; } /* '' */
.icon-141-bell:before { content: '\e817'; } /* '' */
.icon-141-blackboard:before { content: '\e818'; } /* '' */
.icon-141-book:before { content: '\e819'; } /* '' */
.icon-141-bookshelf:before { content: '\e81a'; } /* '' */
.icon-141-bookshelf-1:before { content: '\e81b'; } /* '' */
.icon-141-brain:before { content: '\e81c'; } /* '' */
.icon-141-briefcase:before { content: '\e81d'; } /* '' */
.icon-141-briefcase-1:before { content: '\e81e'; } /* '' */
.icon-141-browser:before { content: '\e81f'; } /* '' */
.icon-141-bus:before { content: '\e820'; } /* '' */
.icon-141-calculator:before { content: '\e821'; } /* '' */
.icon-141-calculator-1:before { content: '\e822'; } /* '' */
.icon-141-calculator-2:before { content: '\e823'; } /* '' */
.icon-141-calendar:before { content: '\e824'; } /* '' */
.icon-141-cells:before { content: '\e825'; } /* '' */
.icon-141-cells-1:before { content: '\e826'; } /* '' */
.icon-141-certificate:before { content: '\e827'; } /* '' */
.icon-141-check:before { content: '\e828'; } /* '' */
.icon-141-classroom:before { content: '\e829'; } /* '' */
.icon-141-clipboard:before { content: '\e82a'; } /* '' */
.icon-141-clock:before { content: '\e82b'; } /* '' */
.icon-141-compass:before { content: '\e82c'; } /* '' */
.icon-141-computer:before { content: '\e82d'; } /* '' */
.icon-141-cubes:before { content: '\e82e'; } /* '' */
.icon-141-cutter:before { content: '\e82f'; } /* '' */
.icon-141-desk:before { content: '\e830'; } /* '' */
.icon-141-desk-1:before { content: '\e831'; } /* '' */
.icon-141-desk-2:before { content: '\e832'; } /* '' */
.icon-141-desk-3:before { content: '\e833'; } /* '' */
.icon-141-desk-4:before { content: '\e834'; } /* '' */
.icon-141-desk-lamp:before { content: '\e835'; } /* '' */
.icon-141-desk-lamp-1:before { content: '\e836'; } /* '' */
.icon-141-diploma:before { content: '\e837'; } /* '' */
.icon-141-diploma-1:before { content: '\e838'; } /* '' */
.icon-141-diploma-2:before { content: '\e839'; } /* '' */
.icon-141-diploma-3:before { content: '\e83a'; } /* '' */
.icon-141-diploma-4:before { content: '\e83b'; } /* '' */
.icon-141-diploma-5:before { content: '\e83c'; } /* '' */
.icon-141-dna:before { content: '\e83d'; } /* '' */
.icon-141-earth-globe:before { content: '\e83e'; } /* '' */
.icon-141-email:before { content: '\e83f'; } /* '' */
.icon-141-eraser:before { content: '\e840'; } /* '' */
.icon-141-exam:before { content: '\e841'; } /* '' */
.icon-141-exam-1:before { content: '\e842'; } /* '' */
.icon-141-exchange:before { content: '\e843'; } /* '' */
.icon-141-file:before { content: '\e844'; } /* '' */
.icon-141-file-1:before { content: '\e845'; } /* '' */
.icon-141-files:before { content: '\e846'; } /* '' */
.icon-141-flask:before { content: '\e847'; } /* '' */
.icon-141-flask-1:before { content: '\e848'; } /* '' */
.icon-141-folder:before { content: '\e849'; } /* '' */
.icon-141-germs:before { content: '\e84a'; } /* '' */
.icon-141-glasses:before { content: '\e84b'; } /* '' */
.icon-141-glue:before { content: '\e84c'; } /* '' */
.icon-141-graduated:before { content: '\e84d'; } /* '' */
.icon-141-graduated-1:before { content: '\e84e'; } /* '' */
.icon-141-highlighter:before { content: '\e84f'; } /* '' */
.icon-141-id-card:before { content: '\e850'; } /* '' */
.icon-141-id-card-1:before { content: '\e851'; } /* '' */
.icon-141-id-card-2:before { content: '\e852'; } /* '' */
.icon-141-idea:before { content: '\e853'; } /* '' */
.icon-141-keyboard:before { content: '\e854'; } /* '' */
.icon-141-lamp:before { content: '\e855'; } /* '' */
.icon-141-laptop:before { content: '\e856'; } /* '' */
.icon-141-laptop-1:before { content: '\e857'; } /* '' */
.icon-141-lecture:before { content: '\e858'; } /* '' */
.icon-141-lecture-1:before { content: '\e859'; } /* '' */
.icon-141-lecture-2:before { content: '\e85a'; } /* '' */
.icon-141-lecture-3:before { content: '\e85b'; } /* '' */
.icon-141-lecture-4:before { content: '\e85c'; } /* '' */
.icon-141-medal:before { content: '\e85d'; } /* '' */
.icon-141-library:before { content: '\e85e'; } /* '' */
.icon-141-medal-1:before { content: '\e85f'; } /* '' */
.icon-141-medal-2:before { content: '\e860'; } /* '' */
.icon-141-megaphone:before { content: '\e861'; } /* '' */
.icon-141-microscope:before { content: '\e862'; } /* '' */
.icon-141-microscope-1:before { content: '\e863'; } /* '' */
.icon-141-mortarboard:before { content: '\e864'; } /* '' */
.icon-141-mortarboard-1:before { content: '\e865'; } /* '' */
.icon-141-museum:before { content: '\e866'; } /* '' */
.icon-141-networking:before { content: '\e867'; } /* '' */
.icon-141-notebook:before { content: '\e868'; } /* '' */
.icon-141-notebook-1:before { content: '\e869'; } /* '' */
.icon-141-online-education:before { content: '\e86a'; } /* '' */
.icon-141-open-book:before { content: '\e86b'; } /* '' */
.icon-141-open-book-1:before { content: '\e86c'; } /* '' */
.icon-141-open-book-2:before { content: '\e86d'; } /* '' */
.icon-141-paint-brush:before { content: '\e86e'; } /* '' */
.icon-141-paint-palette:before { content: '\e86f'; } /* '' */
.icon-141-paperclip:before { content: '\e870'; } /* '' */
.icon-141-paperclip-1:before { content: '\e871'; } /* '' */
.icon-141-pen:before { content: '\e872'; } /* '' */
.icon-141-pen-1:before { content: '\e873'; } /* '' */
.icon-141-pencil:before { content: '\e874'; } /* '' */
.icon-141-pencil-1:before { content: '\e875'; } /* '' */
.icon-141-pencil-case:before { content: '\e876'; } /* '' */
.icon-141-pendrive:before { content: '\e877'; } /* '' */
.icon-141-phone-call:before { content: '\e878'; } /* '' */
.icon-141-physics:before { content: '\e879'; } /* '' */
.icon-141-presentation:before { content: '\e87a'; } /* '' */
.icon-141-presentation-1:before { content: '\e87b'; } /* '' */
.icon-141-presentation-2:before { content: '\e87c'; } /* '' */
.icon-141-presentation-3:before { content: '\e87d'; } /* '' */
.icon-141-presentation-4:before { content: '\e87e'; } /* '' */
.icon-141-presentation-5:before { content: '\e87f'; } /* '' */
.icon-141-presentation-6:before { content: '\e880'; } /* '' */
.icon-141-printer:before { content: '\e881'; } /* '' */
.icon-141-projector:before { content: '\e882'; } /* '' */
.icon-141-protractor:before { content: '\e883'; } /* '' */
.icon-141-ruler:before { content: '\e884'; } /* '' */
.icon-141-scanner:before { content: '\e885'; } /* '' */
.icon-141-school:before { content: '\e886'; } /* '' */
.icon-141-school-1:before { content: '\e887'; } /* '' */
.icon-141-school-2:before { content: '\e888'; } /* '' */
.icon-141-set-square:before { content: '\e889'; } /* '' */
.icon-141-sharpener:before { content: '\e88a'; } /* '' */
.icon-141-staff:before { content: '\e88b'; } /* '' */