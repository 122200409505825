.aht-ev-det {

  &--edu {
    display: flex;
    align-items: flex-start;

    @include medium-size-max {
      flex-wrap: wrap;
    }

    img {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 10px;
    }

    b {
      font-weight: inherit;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
    }

    .aht-ev-det {

      &__main {
        flex: 1;
      }

      &__about {
        margin-top: 35px;
        margin-bottom: 30px;
      }

      &__content {
        p {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 40px;
        }
      }
      &__prog {
        &-title {
          margin-bottom: 30px;
        }
        &-list {
          margin-bottom: 50px;
        }
        &-item {
          margin-bottom: 15px;
        }
      }

      &__title {
        margin: 0 0 28px;
      }

      &__sched {
        margin-top: 50px;

        &-title {
          margin: 0 0 25px;
        }

        &-unit {
          position: relative;
          padding: 17px 0;
          transition: all 0.3s;
          border-bottom: 1px solid #eee;
          cursor: pointer;

          &.active {
            .aht-ev-det__sched-plus {
              &:before {
                content: '-';
              }
            }
          }
        }

        &-plus {
          position: absolute;
          top: 50%;
          right: 22px;
          transform: translate(0, -50%);
          font-size: 20px;
          font-weight: 700;

          &:before {
            content: '+';
          }
        }

        &-date {
          margin-right: 3px;
          color: #222;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }

        &-day {
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
        &-list {
          display: none;
          margin-top: 20px;
          margin-bottom: 30px;
          padding-left: 20px;
        }

        &-item {
          padding-left: 0;

          &:before {
            content: none;
          }
        }

        &-time {
          margin-right: 70px;
          color: #222;
          line-height: 38px;
          font-weight: 500;
        }

        &-text {
          display: inline-block;
          margin: 0;
        }
      }

      &__speaker {
        margin-top: 95px;

        &-title {
          margin: 0 0 50px;
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
        }

        &-item {
          flex-basis: 50%;
          margin-bottom: 30px;

          &:nth-child(odd) {
            padding-right: 15px;
          }

          &:nth-child(even) {
            padding-left: 15px;
          }

          @include small-size-max {
            flex-basis: 90%;
          }
          @media screen and (max-width: 768px) {
            &:nth-child(odd) {
              padding-right: 0px;
              min-width: 370px;
              margin: 0 auto;
            }

            &:nth-child(even) {
              padding-left: 0px;
              min-width: 370px;
              margin: 0 auto;
              margin-top: 30px;
            }
          }
        }

      }

      @media screen and (max-width: 991px) {
        &__speaker {
          margin-top: 70px;
        }
      }
      @media screen and (max-width: 576px) {
        &__speaker {
          margin-top: 50px;
        }
      }


      &__sb {
        flex-basis: 330px;
        margin-top: 7px;
        margin-left: 30px;
        padding: 28px 30px 40px 40px;

        @include large-size-min {
          margin-left: 70px;
        }

        @include medium-size-max {
          flex-basis: 100%;
          margin: 30px 0;
        }
      }

      &__sb-title {
        margin: 0;
      }

      &__details {
        margin-top: 35px;
        padding-bottom: 36px;
        border-bottom: 1px solid #eee;
      }

      &__detail {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }

        &-icon {
          display: inline-block;
          width: 15px;
          margin-right: 13px;
          font-size: 18px;
          text-align: center;
        }

        &-text {
          font-size: 15px;
        }
      }

      &__buy {
        padding-top: 3px;
      }

      &__label {
        display: block;
        margin-top: 37px;
        margin-bottom: 13px;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__select-wrap {
        position: relative;

        &:before {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0, -50%);
          font-family: $f-ionicons;
          font-size: 10px;
          content: '\f123';
        }
      }

      &__select {
        width: 100%;
        padding: 15px 20px;
        transition: all 0.3s;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &.selected {
          color: #222;
        }

        &::-ms-expand {
          display: none;
        }
      }

      &__number-wrap {
        user-select: none;
      }

      &__number {
        width: 43px;
        border: 0;
        outline: 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }

      &__number-q {
        display: inline-block;
        width: 40px;
        height: 40px;
        transition: all 0.3s;
        border-radius: 5px;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        cursor: pointer;

        &:hover {
          color: $c-white;
        }
      }

      &__price {
        margin: 0;
        font-size: 30px;
        line-height: 1;
      }

      &__submit {
        width: 100%;
        margin-top: 37px;
        padding: 15px 30px;
        transition: all 0.3s;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  &--yoga {
    .aht-ev-det__content {
      p {
        line-height: 1.7;
      }

      .yoga-detail-btn {
        padding: 20px 70px;
      }
    }
  }
}
