.aheto-header-12 {

  // Main menu
  .main-menu {

    .menu-item {
      a {
        display: inline-block;
        font-style: normal;
        font-weight: 600;
      }
    }

    @include menu-size-min {

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          padding: 13px 0;
          font-size: 15px;
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      > .menu-item {
        width: calc(100% + 45px);
        padding-right: 50px;
      }
    }

    @include menu-size-max {
      .menu-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }

      > .menu-item {
        > a {
          padding: 13px 0;
          font-size: 24px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      position: absolute;
      top: -45px;
      left: 100%;
      padding: 45px 0;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;

      .menu-item {
        min-width: 220px;
        padding: 0 50px;
      }
    }

    @include menu-size-max {
      display: none;
      flex-basis: 100%;
      padding-bottom: 13px;

      .menu-item {

        a {
          padding: 10px 0;
          font-size: 20px;
        }
      }

      .sub-menu {
        padding-left: 30px;
      }
    }
  }

  // Mega menu
  .mega-menu {
    @include menu-size-min {
      display: flex;
      padding: 0;

      &__title {
        padding: 13px 0;
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
      }

      &__col {
        position: relative;
        padding: 45px 50px;

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: calc(100% - 110px);
          transform: translate(0, -50%);
          content: '';
        }

        &:last-child {
          &:before {
            content: none;
          }
        }
      }

      .menu-item {
        min-width: 170px;
        padding: 0;
      }
    }

    @include menu-size-max {
      padding-bottom: 10px;

      &__title {
        padding: 10px 0 5px;
        font-weight: 600;
      }

      .menu-item {
        a {
          padding: 8px 0;
          font-size: 16px;
        }
      }
    }
  }

  // Hamburger
  .hamburger {

    &-box {
      width: 25px;
      height: 13px;
    }

    &-inner {
      background-color: transparent;

      &:before,
      &:after {
        width: 25px;
        height: 3px;
      }
    }

    @include menu-size-min {
      &.is-active {
        .hamburger-inner {
          width: 18px;
          height: 2px;

          &:before,
          &:after {
            width: 18px;
            height: 2px;
          }
        }
      }
    }

    @include menu-size-max {
      padding: 15px;
      &.is-active {
        .hamburger-inner {
          width: 25px;
          height: 3px;

          &:before,
          &:after {
            width: 25px;
            height: 3px;
          }
        }
      }
    }
  }

  // Logo
  .logo {

    &__img {
      max-width: 49px;
      max-height: none;
    }

    &__text-holder {
      display: none;
      margin-left: 0;
    }

    &__text {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
    }
  }

  // Search form
  form {
    position: relative;

    label {
      display: none;
    }

    input,
    button {
      border: 0;
      outline: none;
      background-color: transparent;
    }

    input {
      min-width: 0;
      max-width: 205px;
      padding: 20px 25px 20px 0;
      transition: all 0.2s;
      border-radius: 0;
      font-size: 15px;
      font-weight: 600;

      @include menu-size-max {
        width: 100%;
        max-width: none;
      }
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      line-height: 66px;
      padding-right: 0;
      font-size: 24px;
      cursor: pointer;
    }

    i {
      font-size: 24px;
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    margin-left: 10px;
    font-size: 9px;

    &:before {
      content: '\f125';
    }

    @include menu-size-max {
      margin-left: 0;
      padding: 2px 10px;
      transition: all 0.2s;
      border-radius: 5px;
      font-size: 12px;
      cursor: pointer;

      &:before {
        content: '\f123';
      }

      &.is-active {
        &:before {
          transform: rotate(-180deg);
          content: '\f123';
        }
      }
    }
  }

  @include menu-size-min {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;

    &.is-open {
      overflow: visible;

      .hamburger--squeeze .hamburger-inner::before {
        transform: rotate(45deg);
        top:0px;
      }

      .hamburger--squeeze .hamburger-inner::after {
        transform: rotate(-45deg);
        top:0px;
      }

      .aheto-header-12 {

        &__inner {
          width: 300px;
        }

        &__main {
          align-items: flex-start;
          justify-content: flex-start;
          padding: 60px 40px 30px 55px;

          @media screen and (min-width: 1500px) {
            padding: 100px 40px 70px 55px;
          }
        }

        &__hamburger {
          position: absolute;
          top: 30px;
          right: 15px;
        }

        &__cart {
          align-self: center;
          margin-top: 20px;
          margin-right: 20px;
        }
      }

      .logo {

        &__img-holder {
          display: none;
        }

        &__text-holder {
          display: block;
        }
      }
    }
  }

  &__inner {
    background-color: $c-white;
    width: 100px;

    @include menu-size-min {
      transition: all 0.2s;
    }
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include menu-size-min {
      flex-direction: column;
      min-height: 100vh;
      padding: 30px 25px 70px;
    }
  }

  &__search {
    margin-top: 20px;

    @media screen and (min-width: 1500px) {
      margin-top: 95px;
    }
  }

  &__aside {
    display: none;
    position: relative;
    z-index: 2;

    @include menu-size-max {
      position: fixed;
      top: 0;
      left: 0;
      width: 70%;
      min-width: 320px;
      height: 100%;
      padding: 30px 30px 50px;
      transform: translate(-100%, 0);
      transition: all 0.5s;
      background-color: $c-white;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &.is-open {
        transform: translate(0, 0);
      }
    }
  }

  &__aside-overlay {

    @include menu-size-min {
      display: none !important;
    }

    @include menu-size-max {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.5s;
    }
  }

  &__aside-logo {
    margin-top: 60px;

    @include menu-size-min {
      display: none;
    }

    .logo {
      &__text-holder {
        display: flex;
      }

      &__img-holder {
        display: none;
      }
    }
  }

  &__aside-close {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 5px;
    cursor: pointer;

    @include menu-size-min {
      display: none;
    }
  }

  &__aside-close-icon {
    font-size: 24px;
  }

  &__menu {
    align-self: stretch;
    margin-top: 40px;

    @media screen and (min-width: 1500px) {
      margin-top: 85px;
    }
  }

  &__cart {
    @include menu-size-max {
      padding: 15px;
      position: absolute;
      right: 0;
    }
  }

  &__cart-link {
    display: flex;
    position: relative;
    align-items: flex-end;
    width: 32px;
    height: 32px;
  }

  &__cart-icon {
    font-size: 24px;
    line-height: 20px;
  }

  &__cart-number {
    position: absolute;
    top: -3px;
    right: 0;
    font-size: 16px;
    font-style: normal;
  }

  &__hamburger {
    @include menu-size-max {
      order: -1;
    }
  }

  &__logo {
    @include menu-size-max {
      padding: 15px;
    }
  }
}
