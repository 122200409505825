.aheto-404 {

  &__wrap {
    .aheto-heading {
      &__desc {
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  padding: 40px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;

  &__svg {
    width: 80%;
  }

  .fil0 {
    fill: #FEFEFE;
  }
}
