.aheto-header-6 {
  &__toolbar-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1750px;
    margin: 0 auto;
    padding: 5px 0;
  }

  &__toolbar-contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 15px;

    @include menu-size-max {
      order: 2;
    }
  }

  &__toolbar-text {
    padding: 3px 40px 3px 0;
    margin: 0;
    color: $c-white;
    font-size: 14px;

    @include menu-size-max {
      padding-right: 30px;
    }

    @include xs-size-max {
      display: none;
    }
  }

  &__toolbar-tel,
  &__toolbar-email {
    padding: 3px 0;
    color: $c-white;
    font-size: 14px;
    font-weight: 500;

    &:before {
      margin-right: 10px;
      font-family: $f-ionicons;
      font-size: 16px;
    }
  }

  &__toolbar-tel {
    margin-right: 26px;

    &:before {
      content: '\f2d2';
    }

    @include xs-size-max {
      margin-right: 15px;
    }
  }

  &__toolbar-email {

    &:before {
      content: '\f2eb';
    }
  }

  &__toolbar-additional {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 3px 15px;
  }

  &__toolbar-lang {
    margin-right: 30px;

    @media screen and (min-width: 1500px) {
      margin-right: 60px;
    }
  }

  &__main {
    background-color: $c-white;
  }

  &__main-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1750px;
    margin: 0 auto;

    @include menu-size-max {
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    z-index: 1;
  }

  &__right {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1500px) {
      padding: 10px 0;
    }

    @include menu-size-max {
      display: none;
      padding: 0;
    }
  }

  &__logo {
    padding: 15px;

    @media screen and (min-width: 1500px) {
      margin-right: 45px;
    }

    @include menu-size-max {
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 15px;
    }
  }

  &__hamburger {
    display: flex;
    align-items: center;

    @include menu-size-min {
      display: none;
    }
  }

  &__socials {

    @include menu-size-min {
      margin-left: 15px;
      padding: 0 5px;
    }

    @include menu-size-max {
      padding: 5px 15px;
    }
  }

  &__search {
    flex-grow: 1;
    min-width: 180px;
    padding: 0 15px;

    form {
      position: relative;
      max-width: 415px;

      input[type=text] {
          padding-right: 40px;
      }

      button[type="submit"] {
        background: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 53px;
        width: 45px;
        cursor:pointer;
        transition: 0.2s all;
        @include menu-size-max {
          line-height: 43px;
        }
     }

      @media screen and (max-width: 1500px) {
        max-width: none;
      }

      @include menu-size-min {
        width: 100%;
        float: right;
      }
    }

    label {
      display: none;
    }

    input {
      outline: none;

      &[type=text] {
        width: 100%;
        padding: 15px 20px;
        border: 1px solid #eee;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 700;

        &::placeholder {
          font-size: 15px;
          font-weight: 400;
        }

        @include menu-size-max {
          padding: 10px 20px;
        }
      }

      &[type=submit] {
        position: absolute;
        top: 50%;
        right: 15px;
        padding: 2px 0;
        transform: translate(0, -50%);
        border: 0;
        background-color: transparent;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;

        @include xs-size-max {
          font-size: 14px;
        }
      }
    }
  }

  &__menu {
    padding: 0 15px;

    @include menu-size-max {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 15px 15px 100px;
      background-color: $c-white;
      max-height: calc(100vh - 203px);
      overflow: auto;
      display: none;
    }
  }

  // Menu
  .main-menu {
    .menu-item {
      &.active > a {
        color: #ffc10d;
      }
      a.active {
          color: #ffc10d;
      }
      a {
        display: inline-block;
      }
    }

    @include menu-size-min {
      display: flex;

      > .menu-item {
        margin-right: 35px;
        padding: 42px 0;

        @media screen and (max-width: 1500px) {
          padding: 30px 0;
        }

        &:last-child {
          margin-right: 0;
        }

        > a {
          position: relative;
          font-size: 15px;
          font-weight: 700;

          &:before {
            display: block;
            position: absolute;
            top: auto;
            bottom: -3px;
            left: 0;
            width: 0;
            height: 2px;
            transition: all 0.2s;
            content: '';
          }
        }

        &:hover {
          > a {
            &:before {
              width: 100%;
            }
          }
        }
      }

      .menu-item {

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @include menu-size-max {
      > .menu-item {
        padding-bottom: 10px;

        > a {
          font-size: 20px;
        }
      }

      .dropdown-btn {
        display: none;
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 32px 0;
      transition: all 0.2s;
      box-shadow: 0px 0px 5px 0px rgba($c-white, 0.2);
      opacity: 0;
      visibility: hidden;

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 250px;
        padding-right: 30px;
        padding-left: 30px;

        a {
          padding: 10px 0;
          color: $c-white;
          font-weight: 400;
        }
      }

      .sub-menu {
        top: -32px;
        left: 100%;
      }

      .dropdown-btn {
        color: $c-white;

        &:before {
          content: '\f125';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 10px 30px 0;
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      padding: 0;

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-white, 0.2);
      }

      &__title {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-white, 0.2);
        color: $c-white;
      }

      .menu-item {
        padding: 0;
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        margin: 0 30px;
        padding: 10px 0;
        border-bottom: 1px solid rgba($c-black, 0.1);
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    margin-left: 9px;
    font-size: 9px;

    &:before {
      vertical-align: baseline;
    }
  }

  // Logo
  .logo {

    &__img {
      max-height: 47px;
    }

    &__text-holder {
      margin-left: 15px;
    }

    &__text {
      font-size: 25px;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
    }

    &__sub-text {
      margin-top: 3px;
      font-size: 10px;
      letter-spacing: 6px;
      text-transform: uppercase;
    }
  }

  // Authentication
  .authentication {
    display: flex;
    &__log-in,
    &__register {
      padding: 2px 0;
      border: 0;
      outline: none;
      background-color: transparent;
      color: $c-white;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }

    &__register {
      padding-right: 20px;
      border-right: 1px solid rgba($c-white, 0.3);
    }

    &__log-in {
      padding-left: 20px;
    }
  }

  // wpml
  .wpml-ls {
    position: relative;

    &-flag {
      width: 37px;
      height: 20px;
      margin-right: 10px;
      border-radius: 0;
      object-fit: cover;
    }

    &-item-toggle {
      display: flex;
      align-items: center;
      color: $c-white;
      font-size: 14px;
      font-weight: 500;

      &:after {
        display: inline-block;
        height: auto !important;
        margin-left: 10px;
        transform: none !important;
        border: 0 !important;
        font-family: $f-ionicons;
        font-size: 9px;
        content: '\f123';
        vertical-align: middle;
      }
    }

    &:hover {
      .wpml-ls-sub-menu {
        display: block;
      }
    }

    &-sub-menu {
      position: absolute;
      top: 28px;
      left: 0;
      z-index: 22;
      display: none;

      li {
        padding: 5px 10px !important;

        span {
          font-size: 13px;
        }
      }
    }
  }

  // Socials
  .aht-socials.aheto-socials--circle {
    @include menu-size-max {
      flex-wrap: wrap;
      padding: 15px 0 0;
      text-align: center;
    }

    .aht-socials {
      display: flex;
      &__icon {
        font-size: 20px;
      }

      &__link {
        margin-top: 5px;
        margin-bottom: 5px;
        border:1px solid rgba($c-black, 0.1);

        @media screen and (max-width: 1500px) {
          margin-right: 12px;
        }
      }
    }
  }
}
.aheto-header--edu-contact {
  .label {
    letter-spacing: 0.5px;
    font-weight: bold;
  }
}
