.aheto-banner {
  //   display: flex;
  //   align-items: center;
  //   padding: 100px 0 70px;
  //   background: no-repeat center;
  //   background-size: cover;
  //   overflow: hidden;

  //   p {
  //     margin-top: 6px;
  //   }

  &--full-height {
    min-height: 100vh;

    @media screen and (max-width: 1024px) {
      min-height: calc(100vh - 75px);
    }

  }

  &--saas {
    align-items: center;
    overflow: hidden;
    background-size: cover;

    img {
      @media (min-width: 768px) and (max-width: 1280px) {
        margin-left: -200px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &--political {
    h1 {
      font-size: 80px;
      letter-spacing: 4px;
      line-height: 1.13;
      padding-top: 20px;

      @include small-size-max {
        font-size: 46px;
      }
    }
  }

  &--height-600 {
    height: 600px;
  }

  &--height-520 {
    height: 520px;
  }

  &--height-500 {
    height: 500px;
  }

  &--height-450 {
    height: 450px;
  }

  &--bg-position-btm {
    background-position: bottom;
  }

  &--height-mob-400 {
    @media screen and (max-width: 768px) {
      height: 400px;
    }
  }

  &--height-mob-300 {
    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }

  &--height-mob-200 {
    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  &--promo {
    @media screen and (max-width: 768px) {
      background-position: center bottom;
    }

    .aheto-heading {
      p {
        margin-top: 0px;

        b {
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }

    .aheto-banner__store {
      a:first-child {
        margin-right: 20px;

        @media screen and (max-width: 450px) {
          margin-right: 0px;
          margin-bottom: 20px;
          display: block;
        }
      }
    }
  }

  &__rating {
    font-size: 20px;
    color: #ffbe0d;
    letter-spacing: -2px;
  }

  //   &--barbershop {
  //     display: block;
  //     padding: 0;

  //     .aheto-heading {
  //       & > h1,
  //       & > h2 {
  //         font-size: 100px;
  //         font-weight: 800;
  //       }
  //       & > h3 {
  //         font-size: 80px;
  //       }
  //       &__subtitle {
  //         line-height: 50px;
  //         margin-bottom: 0;
  //       }
  //     }
  //     @include medium-size-max {
  //       .aheto-heading {
  //         & > h1,
  //         & > h2 {
  //           font-size: 70px;
  //           line-height: 1;
  //           letter-spacing: 0;
  //         }
  //         &__subtitle {
  //           font-size: 30px;
  //         }
  //       }
  //     }
  //     @include xs-size-max {
  //       .aheto-heading {
  //         & > h1,
  //         & > h2 {
  //           font-size: 40px;
  //           line-height: 1em;
  //         }
  //         & > h3 {
  //           font-size: 40px;
  //         }
  //       }
  //       .aheto-btn {
  //         padding-right: 20px;
  //         padding-left: 20px;
  //       }
  //     }
  //   }

  //   &--bg-top-left {
  //     background-position: top left;
  //   }

  //   &__image {
  //     &--absolute {
  //       position: absolute;
  //       top: -82px;
  //       right: -38.3%;
  //       @include medium-size-max {
  //         position: static;
  //         max-width: 100%;
  //       }
  //     }
  //   }

  //   &__rating {
  //     display: inline-block;
  //     color: $c-alter;
  //     font-size: 18px;
  //   }

  //   &__store {
  //     display: inline-flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //     width: 100%;
  //     max-width: 370px;
  //     font-size: 0;
  //     @include xs-size-max {
  //       flex-direction: column;
  //       align-items: center;
  //     }
  //   }
  //   @include xs-size-max {
  //     h1 {
  //       font-size: $xs-h1-fnt-size;
  //     }
  //   }
}

.aht-banner {
  display: flex;
  position: relative;
  align-items: center;
  padding: 50px 0;
  background-color: $c-white;
  background-position: center;
  background-size: cover;

  b {
    font-weight: inherit;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($c-black, 0.3);

    &--1 {
      background-color: rgba($c-black, 0.4);
    }

    &--2 {
      background-color: transparent;
      opacity: 0.47;
    }

    &--3 {
      background-color: transparent;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 15px;
  }

  &__img {
    width: 100px;
    height: 75px;
    margin: 0 auto;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__sub-title {
    margin: 0 0 -10px;
    font-size: 44px;
    font-weight: 400;
    vertical-align: middle;

    @include medium-size-max {
      font-size: 30px;
    }

    @include xs-size-max {
      margin: 10px 0;
      font-size: 26px;
    }
  }

  &__title {
    margin: 0;
    font-size: 130px;
    letter-spacing: 1px;

    @include medium-size-max {
      font-size: 72px;
    }

    @include xs-size-max {
      font-size: 50px;
    }
  }

  &__desc {
    margin: 5px 0;
    font-size: 20px;

    @include medium-size-max {
      margin-top: 20px;
    }
  }

  &__links {
    margin: 0 -10px -10px;
  }

  &__link {
    margin: 10px;

    @include xs-size-max {
      padding: 10px 30px;
    }
  }

  &--with-promo {
    .aht-banner__holder {
      @include medium-size-min {
        margin-bottom: -10px;
      }
    }

    .aht-banner__sub-title {
      display: inline-block;
      margin: 0 20px;
      padding-left: 120px;
      vertical-align: bottom;

      @include medium-size-max {
        margin: 0 10px;
        padding-left: 0;
      }
    }

    .aht-banner__promo {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      padding: 10px;
      transform: rotate(15deg);
      border-radius: 50%;
      background-size: 400% 400%;
      animation: AnimationName 4s ease infinite;

      @keyframes AnimationName {
        0% {
          background-position: 0% 50%
        }

        50% {
          background-position: 100% 51%
        }

        100% {
          background-position: 0% 50%
        }
      }

      @include medium-size-max {
        width: 60px;
        height: 60px;
      }

      @include xs-size-max {
        width: 45px;
        height: 45px;
      }
    }

    .aht-banner__promo-text {
      margin: 0;

      @include medium-size-max {
        font-size: 14px;
      }

      @include xs-size-max {
        font-size: 12px;
      }
    }
  }

  &--white-text {

    .aht-banner__promo-text,
    .aht-banner__sub-title,
    .aht-banner__title,
    .aht-banner__desc {
      color: $c-white;
    }
  }

  &--height-800 {
    min-height: 700px;

    @media screen and (min-width: 1500px) {
      min-height: 800px;
    }

    @include medium-size-max {
      min-height: 600px;
    }
  }

  &--height-600 {
    min-height: 600px;
  }

  &--full-height {
    height: 100vh;
  }

  &--travel-1 {
    .aht-banner {
      &__sub-title {
        font-family: $f-sign-painter;
      }

      &__link {

        &:not(:hover) {
          border-color: $c-white;
          background-color: $c-white;
        }
      }
    }
  }

  &--travel-2 {
    .aht-banner {
      &__sub-title {
        margin: 0 0 5px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;

        @include medium-size-max {
          font-size: 24px;
        }

        @include xs-size-max {
          font-size: 20px;
        }
      }

      &__title {
        line-height: 1.1;
        text-transform: uppercase;
      }

      &__links {
        margin-top: 40px;
      }

      &__link {
        color: $c-white;

        &:hover {
          border-color: $c-white;
          background-color: $c-white;
        }
      }
    }
  }

  &--travel-3 {

    .aht-banner {
      &__sub-title {
        margin: 0 0 5px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 3px;
        text-transform: uppercase;

        @include medium-size-max {
          font-size: 24px;
        }

        @include xs-size-max {
          font-size: 20px;
        }
      }

      &__title {
        font-family: $f-sign-painter;

        @include large-size-min {
          font-size: 150px;
        }
      }

      &__desc {
        @include large-size-min {
          margin-top: -30px;
        }
      }

      &__links {
        margin-top: 55px;
      }

      &__link {
        color: $c-white;

        &:hover {
          border-color: $c-white;
          background-color: $c-white;
        }
      }
    }
  }

  &--yoga {
    .aht-banner {
      &__title {
        font-size: 80px;
        font-weight: 700;
        letter-spacing: 3px;
        line-height: 1.12;
        text-transform: uppercase;

        @include medium-size-max {
          font-size: 60px;
        }

        @include small-size-max {
          font-size: 34px;
        }
      }

      &__desc {
        margin-top: -8px;
        font-family: $f-dancing-script;
        font-size: 34px;
        letter-spacing: 0.3px;

        @include small-size-max {
          margin-top: 15px;
          font-size: 24px;
        }
      }

      &__links {
        margin-top: 20px;
        margin-bottom: 35px;
      }

      &__link {
        color: $c-white;
      }
    }
  }

  &--yoga-2 {
    .aht-banner {
      &__content {
        padding-top: 105px;
      }

      &__title {
        font-size: 50px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.1;
        text-transform: uppercase;

        @include small-size-max {
          font-size: 36px;
          letter-spacing: 3px;
        }
      }

      &__desc {
        margin-top: 12px;
        font-size: 18px;
      }

      &__links {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &__link {
        color: $c-white;
      }
    }
  }

  &--yoga-3 {
    .aht-banner {

      &__content {
        padding-top: 80px;
      }

      &__content-inner {
        max-width: 650px;
        margin-left: auto;
      }

      &__title {
        font-size: 90px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 1.1;
        text-transform: uppercase;

        @include medium-size-max {
          font-size: 60px;
        }

        @include small-size-max {
          font-size: 34px;
        }
      }

      &__desc {
        margin-top: 25px;
        font-family: $f-dancing-script;
        font-size: 34px;
        letter-spacing: 0.3px;

        @include small-size-max {
          font-size: 24px;
        }
      }

      &__links {
        margin-top: 33px;
      }

      &__link {
        color: $c-white;
      }
    }
  }

  &--construction {
    .aht-banner {
      &__title {
        font-size: 70px;
        font-weight: 600;
        @include medium-size-max {
          font-size: 40px;
        }

        @include small-size-max {
          font-size: 20px;
        }
      }

      &__desc {
        margin-top: 20px;
        font-size: 24px;
        line-height: 1.25;

        @include small-size-max {
          font-size: 16px;
        }
      }

      &__links {
        margin-top: 30px;

        a {
          transition: all 0.3s ease;
          color: $c-white;
          letter-spacing: 2px;
          &:nth-child(odd) {
            &:hover {
              background-color: transparent;
            }
          }

          &:nth-child(even) {
            &:hover {
              background-color: transparent;
              color: $c-white;
            }
          }
        }
      }
    }
  }

  &--edu {
    background-position-x: 70%;
    background-position-y: inherit;

    .aht-banner {

      &__sub-title {
        margin-bottom: 18px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-family: $f-roboto;

        @include medium-size-max {
          font-size: 14px;
        }
      }

      &__title {
        font-size: 80px;
        font-weight: 700;

        @include medium-size-max {
          font-size: 60px;
        }

        @include small-size-max {
          font-size: 46px;
        }
      }

      &__sub-title {
        letter-spacing: 3px;
      }

      &__desc {
        margin-top: 35px;
      }

      &__link {
        margin-top: 45px;

        @include small-size-max {
          margin-top: 35px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .aht-banner__title {
        font-size: 35px;
      }

      .aht-banner__link {
        margin-top: 15px !important;
      }
    }

    &--1 {
      .aht-banner {

        &__content {
          padding-bottom: 110px;
        }
      }
    }

    &--2 {
      .aht-banner {

        &__link {
          margin-top: 45px;
          border-color: $c-white;
          background-color: $c-white;

          &:hover {
            background-color: transparent;
            color: $c-white;
          }
        }
      }
    }

    &--3 {
      .aht-banner {

        &__link {
          margin-top: 45px;

          &:nth-child(odd) {
            border-color: $c-white;
            background-color: $c-white;
          }

          &:hover {
            color: $c-white;
          }
        }
      }
    }
  }

  &--construction {
    padding: 0;
  }
}

.aht-ban {
  position: relative;
  background-position: center;
  background-size: cover;

  &__subtitle,
  &__title,
  &__desc {
    margin: 0;
  }

  &--main {
    display: flex;
    position: relative;
    align-items: center;
    height: 100vh;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $c-black;
      content: '';
      opacity: 0.4;
    }

    .aht-ban {

      &__content {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 15px;
      }

      &__title {
        margin: 0;
        color: #eeeeee;
        font-weight: 300;
      }

      &__desc {
        margin: 32px 0 0;
        color: #eeeeee;
      }

      &__links {
        margin-top: 52px;
      }

      &__video {
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-bottom: 35px;
        border-radius: 50%;
        background-color: $c-white;
        font-size: 24px;
        line-height: 100px;
        text-align: center;
      }
    }

    &-1 {
      .aht-ban {

        &__link-wrap {
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__link-prime {
          &:hover {
            border-color: $c-white;
            background-color: $c-white;
          }
        }

        &__link-second {

          &:after {
            transition: all 0.3s;
          }

          &:before {
            content: 'or';
            color: $c-white;
            position: absolute;
            left: -20px;
          }
        }
      }
    }

    &-3 {
      .aht-ban {
        &__links {
          display: inline-flex;
          flex-wrap: wrap;
          border-radius: 5px;
          overflow: hidden;
        }

        &__link {

          &:hover {
            border-radius: 5px;
            border-color: $c-white;
            background-color: transparent;
            color: $c-white;
          }
        }
      }

    }
  }

  &--events {
    position: relative;
    background-size: cover;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.7;
    }

    .aheto-contact__info {
      font-weight: 600;
    }

  }

  @media screen and (max-width: 576px) {
    &--events {
      height: 85vh;
    }
  }

  &--chr {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;

    @include small-size-min {
      padding: 0 80px;
    }

    @include small-size-max {
      height: 500px;
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $c-black;
      content: '';
      opacity: 0.4;
    }

    b {
      font-style: italic;
      font-weight: inherit;
    }

    .aht-ban {

      &__content {
        position: relative;
        width: 100%;
        max-width: 1200px;
        padding: 100px 15px 50px;

        @include small-size-max {
          padding-bottom: 30px;
        }
      }

      &__img {
        display: inline-block;
        max-width: 90px;
        max-height: 90px;
        margin-bottom: 33px;
      }

      &__subtitle {
        margin-bottom: 17px;
        color: $c-white;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.15em;
        text-transform: uppercase;

        @include small-size-max {
          font-size: 14px;
        }
      }

      &__title {
        color: $c-white;
        font-size: 90px;
        font-weight: bold;
        letter-spacing: 0.055em;

        @media screen and (max-width: 1500px) {
          font-size: 70px;
        }

        @include large-size-max {
          font-size: 50px;
        }

        @include small-size-max {
          font-size: 40px;
        }
      }

      &__desc {
        margin-top: 18px;
        color: $c-white;
        font-size: 18px;
      }

      &__links-wrap {
        margin-top: 72px;
        overflow: hidden;
      }

      &__links {
        margin: -12px;
      }

      &__link {
        display: inline-block;
        margin: 12px;
        color: $c-white;
        font-size: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        &:nth-child(odd) {
          padding: 20px 35px;
          border: 1px solid #eee;
          background-color: transparent;

          &:hover {
            border-color: $c-white;
            background-color: $c-white;
          }
        }

        &:nth-child(even) {
          position: relative;

          &:before {
            display: block;
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 1px;
            transition: background-color 0.3s;
            background-color: $c-white;
            content: '';
          }
        }
      }
    }

    &.aht-ban--full {
      height: 100vh;
    }
  }


  &--rest {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;

    @include small-size-min {
      padding: 0 80px;
    }

    @include small-size-max {
      height: 500px;
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $c-black;
      content: '';
      opacity: 0.4;
    }

    b {
      font-style: italic;
      font-weight: inherit;
    }

    .aht-ban {

      &__content {
        position: relative;
        width: 100%;
        max-width: 1200px;
        padding: 100px 15px 50px;

        @include small-size-max {
          padding-bottom: 30px;
        }
      }

      &__img {
        display: inline-block;
        max-width: 90px;
        max-height: 90px;
        margin-bottom: 33px;
      }

      &__title {
        color: $c-white;
        font-size: 90px;
        font-weight: bold;
        letter-spacing: 0.055em;

        @media screen and (max-width: 1500px) {
          font-size: 70px;
        }
        @include large-size-max {
          font-size: 50px;
        }
        @include small-size-max {
          font-size: 40px;
        }
      }

      &__desc {
        margin-top: 18px;
        color: $c-white;
        font-size: 18px;
      }
      &__links-wrap {
        margin-top: 50px;
      }

      &__link {
        display: inline-block;
        font-size: 14px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        width: 200px;
        height: 60px;
        line-height: 64px;
        background-color: #80261b;
        color: $c-white;
        border-color: #80261b;
        &:hover {
          background-color: #fff;
          color: #80261b;
        }
      }
    }

    &.aht-ban--full {
      height: 100vh;
    }
  }

  &--rest-first {
    justify-content: flex-end;
    align-items: flex-end;
    .aht-ban {
      &__content {
        background-color: #fff;
        max-width: 445px;
        padding: 60px 54px 70px 54px;
        margin-bottom: 130px;
        margin-right: 20px;
        box-sizing: border-box;
      }
      &__absoluteItem {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -44px;
        right: 40px;
        height: 88px;
        width: 88px;
        border-radius: 44px;
        font-family: $f-playfair-display;
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        color: #fff;
        -webkit-font-smoothing: antialiased;
        background-color: #80261b;
        box-sizing: border-box;
        padding-bottom: 4px;
      }
      &__title {
        color: #222;
        font-family: $f-playfair-display;
        font-size: 40px;
        font-weight: 700;
        font-style: italic;
        letter-spacing: normal;
      }
      &__undertitle {
        margin-top: 16px;
        color: #999;
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1.4px;
      }
      &__desc {
        color: #999;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 25px;
      }
    }
  }

  &--rest-second {
    justify-content: flex-end;
    .aht-ban {
      &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 460px;
        padding: 50px;
        box-sizing: border-box;
        margin-right: 12%;
        //background-color: rgba(0, 0, 0, 0.74);
        //background-color: #302F33;
        //background-color: #222;
        background-color: #070707;
        border: 3px solid #80261B;
        @include large-size-max {
          margin-right: 0;
        }
        @include xs-size-max {
          padding: 20px;

        }
      }
      &__title {
        font-size: 72px;
        font-weight: 700;
        font-style: italic;
        font-family: $f-playfair-display;
        text-align: left;
        -webkit-font-smoothing: antialiased;
        letter-spacing: normal;
        @include xs-size-all {
          font-size: 80px;
        }
        @include xs-size-max {
          font-size: 50px;
        }
      }
      &__desc {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #999;
        text-align: left;
      }
    }
    @include xs-size-all {
      padding: 0 20px;
    }
  }


  &--rest-third {
    padding: 0;
    .aht-ban {
      &__content {
        padding: 20px 30px;
      }
      &__title {
        font-family: unset;
        letter-spacing: normal;
        font-size: 100px;
        -webkit-font-smoothing: antialiased;
        @include xs-size-all {
          font-size: 80px;
        }
        @include xs-size-max {
          font-size: 60px;
        }
      }
      &__desc {
        font-size: 16px;
        margin-top: 0;
      }
      &__links-wrap {
        margin-top: 44px;
      }
    }
  }

}

@media (min-width: 991px) and (max-width: 1300px) {
  .aht-ban--main-3 {
    padding-left: 90px;
  }
}

.fl-column {
  flex-direction: column;
}


.about-church-slider {
  .aht-ban {
    &--chr {
      .aht-ban {
        &__content {
          padding: 0px 15px 60px;

          @include small-size-max {
            padding-bottom: 10px;
          }
        }

        &__subtitle {
          margin-bottom: 17px;
        }

        &__title {
          letter-spacing: 0.026em;
        }

        &__desc {
          margin-top: 27px;
          line-height: normal;
        }
      }
    }
  }
}



.church-news-banner-wrap,
.church-ministries-banner-wrap,
.church-events-banner-wrap,
.church-contact-us-banner-wrap,
.church-gallery-banner-wrap,
.church-sermons-banner-wrap {
  .aht-ban {
    &--chr {
      .aht-ban {
        &__content {
          padding-top: 0;
        }

        &__subtitle {
          margin-bottom: 12px;
        }

        &__title {
          letter-spacing: 0.026em;
          line-height: normal;
        }

        &__desc {
          line-height: normal;
          margin-top: 22px;
        }
      }
    }
  }
}

.church-contact-us-banner-wrap {
  .aht-ban {
    &--chr {
      .aht-ban {
        &__content {
          @include small-size-max {
            padding-bottom: 90px;
          }
        }
      }
    }
  }
}

.church-sermons-det-banner-wrap,
.church-ministry-det-banner-wrap {
  .aht-ban {
    &--chr {
      height: 446px;

      .aht-ban {
        &__content {
          padding-top: 127px;

          @include small-size-max {
            padding-top: 0;
          }
        }

        &__subtitle {
          margin-bottom: 10px;
        }

        &__title {
          letter-spacing: 0.026em;
          line-height: normal;
        }
      }
    }
  }
}



// home creative agency
.about-us-agency__title {
  .aht-ban__subtitle {
    padding-bottom: 56px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    @include xs-size-max {
      padding-bottom: 30px;
    }
  }
  .aht-ban__title {
    padding-bottom: 24px;
    font-size: 40px;
    font-weight: bold;
    @include xs-size-max {
      font-size: 30px;
    }
  }
}

// home creative agency end
