.aheto-logo {
  display: flex;
  align-items: center;

  &__image {
    width: auto;
    max-height: 35px;
    margin-right: 14px;

    &--mobile {
      display: none;
    }

    @include small-size-max {
      margin-right: 10px;
    }
  }

  &__text {
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
  }

  &__img-dark {
    // hide elem
    position: absolute;
    height: 0;
  }
}
.logo_white{
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 55px;
  .aheto-logo__text{
    color: $c-white;
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
}