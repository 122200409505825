* {
  box-sizing: border-box;
}

// Text modificators
.t-light {
  font-weight: 300;
}
.t-regular {
  font-weight: 400;
}
.t-medium {
  font-weight: 500;
}

.t-semibold {
  font-weight: 600;
}

.t-bold {
  font-weight: 700;
}

.t-extrabold {
  font-weight: 800;
}

.text-center,
.t-center {
  text-align: center;
}

.text-left,
.t-left {
  text-align: left;
}

.text-right,
.t-right {
  text-align: right;
}

.t-uppercase {
  text-transform: uppercase;
}

.t-white {
  color: #fff;
}

.l-spacing {
  letter-spacing: 1px;
  &--2 {
	letter-spacing: 2px;
  }
  // 1d3 === 1.3
  &--1d3 {
    letter-spacing: 1.3px;
  }
  &--1d6{
    letter-spacing: 1.6px;
  }
  &--3 {
	letter-spacing: 3px;
  }

  &--4 {
	letter-spacing: 4px;
  }

  &--5 {
	letter-spacing: 5px;
  }
}

.l-height {
  line-height: 1em;
  &--163 {
	line-height: 1.63em;
  }
  &--137 {
    line-height: 1.37em;
  }
}

@include medium-size-max {
  .md-t-center {
	text-align: center;
  }
}

@include small-size-max {
  .sm-t-center {
	text-align: center;
  }
}

.text-uppercase {
  text-transform: uppercase;
}
.font-style {
  &-italic{
    font-style: italic;
  }
}
// Blockquote
// Mixins below describe style for quote symbol on background
@mixin quote-symbol {
  position: absolute;
  top: -35px;
  left: 50%;
  width: 100px;
  transform: translate(-50%, 0);
  font-family: 'Times New Roman';
  font-size: 180px;
  font-weight: bold;
  content: '”';
  opacity: 0.1;
}

@mixin quote-symbol-right {
  @include quote-symbol;
  top: -27px;
  right: 3%;
  left: auto;
  transform: translate(0, 0);
}

@mixin quote-symbol-hide {
  content: none;
}
blockquote {
	&:before {
		  @include quote-symbol;
	}

	&.q-smb-right {
		  &:before {
			@include quote-symbol-right;
		  }
	}

	&.q-smb-hide {
		  &:before {
			@include quote-symbol-hide;
		  }
	}
}
