.aht-serm-det {
    &--chr {
        // padding-top: 110px;
        padding-bottom: 20px;

        p {
            margin: 24px 0;
        }

        .aht-serm-det {

            &__title {
                margin: 0;
                font-size: 30px;
                line-height: 1.33;
                letter-spacing: -0.8px;
            }

            &__details {
                margin-bottom: 46px;
                margin-top: 6px;
            }

            &__detail {
                display: inline-block;
                margin-right: 25px;
            }

            &__detail-val {
                margin-right: 5px;
                font-size: 16px;
            }

            &__detail-text {
                font-size: 16px;
            }

            &__img-holder {
                position: relative;
                margin-top: 50px;
            }
        
            &__content {
                margin-top: 43px;
            }
        }
    }
}


.aht-sermon-det {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    min-height: 500px;
    padding: 40px 30px;
    background-position: center;
    background-size: cover;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, transparent 20%, $c-black);
        content: '';
        opacity: 0.8;
    }

    &:hover {
        .aht-sermon-det__icon-links {
            width: 30px;
            margin-right: 48px;
        }
    }

    &__content {
        position: relative;
    }

    &__icon-links {
        width: 0;
        margin-top: 10px;
        margin-right: 0;
        transition: all 0.5s;
        overflow: hidden;
        text-align: center;
    }

    &__icon-link {
        margin-bottom: 21px;
        color: $c-white;
        font-size: 30px;
        display: block;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        color: inherit;
    }

    &__link-wrap {
        height: 0;
        margin-top: 0;
        transition: all 0.5s;
        overflow: hidden;
    }

    .aht-sermon-det__link {
        color: $c-white;
        font-size: 12px;
    }
}

@media screen and (min-width: 1200px) {
    .padding-sermons-det{ 
        padding-top: 110px;
    }
}