// Header (15 theme)
.main-header {

  li {
    &:before {
      content: none;
    }
  }

  a {
    transition: all 0.2s ease;
  }

  .container {
    padding: 0;
  }

  .widgets-area {
    &__title {
      margin: 0;
      text-align: center;
    }
  }
}

// Header base
.aheto-header {
  position: relative;
  z-index: 10;

  a {
    transition: all 0.2s ease;
  }

  ul {
    margin: 0;
    padding-left: 0;
  }

  li {
    padding-left: 0;
    line-height: 24px;

    &:before {
      content: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    letter-spacing: 0;
  }

  .hamburger {
    outline: 0;

    &:hover {
      opacity: 1;
    }
  }

  .menu-item {
    position: relative;
  }

  .hamburger-box {
    vertical-align: middle;
  }

  .dropdown-btn {
    font-size: 7px;
    vertical-align: middle;

    &:before {
      display: inline-block;
      transition: all 0.2s;
      font-family: $f-ionicons;
      content: '\f123';
      vertical-align: middle;
    }
  }
}

button {
  transition: 0.2s all;
  &:hover {
    transition: 0.2s all;
  }
}
