.aht-author {

  &__img-holder {
    margin-bottom: 32px;
  }

  &__img {
    max-width: 100%;
  }

  &__caption {
  }

  &__name {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  &__pos {
    display: inline-block;
    margin-left: 7px;
    font-size: 15px;
    font-weight: 400;
    text-transform: none;

    &:before {
      content: '/';
    }
  }

  &--rest-home {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 26px;
    .aht-author {
      &__img-holder {
        margin-right: 4px;
      }
      &__name {
        font-size: 18px;
        text-transform: none;

      }
      &__pos {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.aheto-signature--restaurant-home {
  position: relative;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto;
  .bg-text {
    position: absolute;
    color: #f7f7f7;
    font-size: 150px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 15px;
    top: 14px;
  }

  .signature-image-bock {
    max-width: 530px;
    padding-top: 10px;
    position: relative;
    @include large-size-max {
      padding-left: 30px;
    }
    @include medium-size-max {
      padding-top: 150px;
    }
    @include small-size-max {
      padding-left: 0;
      max-width: unset;
      padding-top: 0;
    }
    .aheto-single-img {
      position: relative;
      z-index: 2;
    }
    .signature-circle-bg {
      position: absolute;
      content: '';
      width: 420px;
      height: 420px;
      border-radius: 210px;
      background-color: #fff;
      box-shadow: 0 3px 59px rgba(0, 0, 0, 0.04);
      left: -220px;
      bottom: -70px;
      //border: 1px solid red;
    }

  }
  .signature-block {
    padding: 0 30px 0 70px;
    @include large-size-max {
      padding: 0 30px 0 60px;
    }
    @include medium-size-max {
      padding: 0;
    }
  }
}

