.aheto-single {
  &-img {
    width: 100%;
    font-size: 0;
    &__img {
      max-width: 100%;

      &--h100vh {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }

      $step: 20;
      $count: 50;
      @for $i from 0 through $count {
        &--h#{$step*$i} {
          width: 100%;
          height: (0px+$step*$i);
          object-fit: cover;
          @media screen and (max-width: 991px) {
            height: (0px+$step*$i)/1.5;
          }
          @media screen and (max-width: 767px) {
            height: (0px+$step*$i)/2;
          }
          @media screen and (max-width: 480px) {
            height: (0px+$step*$i)/2.5;
          }
        }
      }


    }
  }

  &-box {
    padding: 65px 60px;
    background-color: $c-white;

    @include medium-size-max {
      padding: 50px 30px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }
  }

  &-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol {
      margin: 0;
      padding: 0;
    }

    &--light {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $c-white;
      }

      p,
      ul,
      ol {
        color: #666;
      }
    }

    &--white {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ul,
      ol {
        color: $c-white;
      }
    }

    &--ltr-spng-3 {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      ul,
      ol {
        letter-spacing: 3px;
      }
    }
  }

  &-item {
    position: relative;
    padding-left: 75px;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 300;
      content: attr(data-index) '.';
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 15px;
    }

    p {
      margin-top: 0;
    }

    &__ico {
      display: block;
      width: 104px;
      height: 104px;
      margin-right: auto;
      margin-bottom: 25px;
      margin-left: auto;
      transition: 0.5s;
      border-radius: 50%;
      background-color: $c-white;
      font-size: 30px;
      line-height: 113px;
      text-align: center;

      @include xs-size-max {
        width: 80px;
        height: 80px;
        font-size: 24px;
        line-height: 85px;
      }

      &:hover {
        color: $c-white;
      }
    }

    &--withIco {
      padding-left: 0;

      &:before {
        display: none;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 25px;

        @include medium-size-max {
          margin-bottom: 20px;
        }

        @include small-size-max {
          margin-bottom: 15px;
        }

        &:before {
          content: attr(data-index) '. ';
        }
      }
    }

    &--bgIco {
      position: relative;
      padding: 65px 30px 100px;
      transition: 500ms;
      background-color: $c-white;

      @include medium-size-max {
        padding: 65px 30px 70px;
      }

      @include small-size-max {
        padding: 65px 15px 60px;
      }

      @include xs-size-max {
        padding: 35px 15px 30px;
      }

      .swiper-slide & {
        height: 100%;
      }

      &:before {
        position: relative;
        margin-bottom: 30px;
        z-index: 2;

        @include medium-size-max {
          margin-bottom: 20px;
        }

        @include small-size-max {
          margin-bottom: 15px;
        }
      }

      .aheto-single-item__ico {
        position: absolute;
        top: 93px;
        left: 50%;
        width: auto;
        height: auto;
        transform: translateX(-50%);
        transition: 500ms;
        background: none;
        color: #f7f7f7;
        font-size: 120px;
        box-shadow: none;
        z-index: 1;

        @include xs-size-max {
          top: 60px;
          font-size: 80px;
        }
      }

      &:before,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        transition: 500ms;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 35px;

        @include medium-size-max {
          margin-bottom: 20px;
        }

        @include small-size-max {
          margin-bottom: 15px;
        }
      }

      &:hover {
        &:before,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          color: $c-white;
        }

        .aheto-single-item__ico {
          color: rgba($c-white, 0.05);
        }
      }

      .aheto-single-item__content {
        position: relative;
        z-index: 2;
      }
    }
  }
}
.bgsize-cover{
  background-size: cover;
}
