.aht-table {
    &-classes {
        width: 100%;
        .aht-table {
            &__header {
                caption-side: top;
                font-size: 24px;
                padding-top: 65px;
                padding-bottom: 53px;
                padding-left: 75px;
                padding-right: 75px;
            }
            &__head {
                &-item:first-child {
                    padding-left: 45px;
                    padding-right: 45px;
                }
                &-item {
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            }
            &__body {
                &-content {
                    min-width: 105px;
                }
                &-cell {
                    border: 1px solid #eee;
                    padding-left: 0!important;
                    padding-right: 0!important;
                }
            }
            @media screen and (min-width: 768px) {
                &__title {
                    text-align: center;
                }
            }
            @media screen and (max-width: 768px) {
                &__title {
                    text-align: left;
                    margin-left: 0px;
                }
            }
            @media screen and (max-width: 576px) {
                &__header {
                    caption-side: top;
                    font-size: 24px;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
        }
        
    }

    
}
.table-responsive {
    width: 100%;
} 
@media screen and (max-width: 1024px) {
    .table-responsive {
        display: block;
        width: 100%;
        overflow-x: auto;
    }
}
