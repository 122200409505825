.aht-td {
  overflow: hidden;

  &__tb {
    display: flex;
    align-items: flex-end;
    height: 500px;
    background-position: center;
    background-size: cover;

    &-content {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 48px 15px;

      @include medium-size-min {
        max-width: 1230px;
        padding-right: 30px;
        padding-left: 30px;
      }
    }

    &-title {
      margin: 0;
      color: $c-white;
    }

    &-rating {
      display: flex;
      align-items: center;
      margin-top: 23px;
    }

    &-stars {
      margin-right: 10px;
    }

    &-star {
      font-size: 21px;
    }

    &-rate {
      margin: 0;
      color: $c-white;
    }
  }

  &__main {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include medium-size-min {
      max-width: 1230px;
      padding: 0 15px;
    }

    @include medium-size-max {
      flex-direction: column;
    }
  }

  &__content {
    padding: 0 15px;

    @include medium-size-min {
      flex: 1 1 0;
      width: calc(100% - 470px);
    }
  }

  &__specs {
    display: flex;
    flex-wrap: wrap;
    padding: 89px 0 77px;

    @include medium-size-max {
      padding: 50px 0;
    }
  }

  &__spec {
    display: flex;
    flex-basis: 33.33%;
    align-items: center;
    padding: 10px 0;

    @include large-size-min {
      flex-basis: 30%;
    }

    @include medium-size-max {
      flex-basis: 50%;
    }

    @include xs-size-max {
      flex-basis: 100%;
    }

    &-icon {
      display: inline-block;
      width: 28px;
      font-size: 20px;
    }

    &-text {
      flex: 1 1 0;
      font-size: 13px;
      font-weight: 600;
    }
  }

  &__menu {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      right: -15px;
      width: 100vw;
      height: 100%;
      content: '';

      @include medium-size-min {
        right: -70px;
      }
    }

    &-list {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      margin: 0;
    }

    &-item {
      padding-left: 0;

      @include medium-size-min {
        margin-right: 38px;
      }

      &:before {
        content: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-text {
      display: inline-block;
      position: relative;
      padding: 24px 8px;
      transition: all 0.3s;
      font-size: 15px;
      font-weight: 700;
      cursor: pointer;

      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 4px;
        transform: translate(-50%, 0);
        transition: all 0.3s;
        content: '';
      }

      &:hover,
      &.active {
        &:before {
          width: 100%;
        }
      }
    }
  }

  &__units {
    padding-bottom: 130px;
  }

  &__unit {
    margin-top: 80px;

    &-title {
      margin: 0;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    &--comments {
      margin-top: 0;
    }
  }

  &__desc {
    padding: 6px 0;

    p {
      margin: 24px 0;
      line-height: 24px;
    }
  }

  &__details {
  }

  &__detail {
    display: flex;
    padding: 21px 0;
    border-bottom: 1px solid #eee;

    @include small-size-max {
      flex-direction: column;
    }

    &:last-child {
      border-bottom: 0;
    }

    &-left {
      width: 57%;
    }

    &-right {
      width: 43%;
    }

    &-info {
      display: flex;
      align-items: center;
      padding: 5px 0;
    }

    &-title {
      margin: 0;
      padding: 6px 0 4px;
      font-size: 18px;
      line-height: 22px;

      @include small-size-max {
        margin-bottom: 8px;
      }
    }

    &-text {
      flex: 1;
      margin: 0;
    }

    &-check,
    &-close {
      display: inline-block;
      width: 22px;
      font-size: 15px;
    }
  }

  &__routes {
    margin-top: 38px;
  }

  &__route {

    &-caption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 20px;
      padding: 20px 35px;
      border-radius: 5px;
      background-color: $c-white;
      box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
      cursor: pointer;
      user-select: none;

      &.active {
        .aht-td__route-icon {
          transform: rotate(180deg);
        }
      }
    }

    &-title {
      margin: 0 20px 0 0;
      transition: all 0.2s ease-out;
      font-size: 18px;

      @include xs-size-max {
        font-size: 16px;
      }
    }

    &-icon {
      transition: all 0.3s;
      font-size: 12px;
    }

    &-content {
      display: none;
      margin-bottom: 20px;
      border-radius: 5px;
      background-color: $c-white;
      box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
      overflow: hidden;
    }

    &-name {
      position: relative;
      margin: 0;
      color: $c-white;
      z-index: 2;

      @include xs-size-max {
        font-size: 20px;
      }
    }

    &-img {
      display: flex;
      position: relative;
      align-items: flex-end;
      height: 270px;
      padding: 25px 35px;
      background-position: center;
      background-size: cover;

      @include medium-size-max {
        height: 450px;
      }

      @include small-size-max {
        height: 350px;
      }

      @include xs-size-max {
        height: 300px;
      }

      &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        background-image: linear-gradient(to bottom, transparent, transparent, $c-black);
        content: '';
        opacity: 0.7;
        z-index: 1;
      }
    }

    &-text {
      padding: 35px 35px 45px;
    }

    &-desc {
      margin: 0;
    }
  }

  &__map {
    margin-top: 40px;
    border-radius: 5px;
    box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
    overflow: hidden;
  }

  &__photos {
    position: relative;
    margin-top: 38px;

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 460px;
      border-radius: 5px;
      box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
      content: '';

      @include medium-size-max {
        height: 530px;
      }

      @include small-size-max {
        height: 460px;
      }

      @include xs-size-max {
        height: 320px;
      }
    }
  }

  &__photo {
    height: 460px;
    border-radius: 5px;
    background-position: center;
    background-size: cover;

    @include medium-size-max {
      height: 530px;
    }

    @include small-size-max {
      height: 460px;
    }

    @include xs-size-max {
      height: 320px;
    }
  }

  &__comments {
    padding-top: 13px;
  }

  // Sidebar
  &__sb {
    position: relative;
    padding: 0 15px;
    
    @include medium-size-min {
      flex-basis: 430px;
      margin-left: 40px;
    }

    @include large-size-max {
      flex-basis: 370px;
      margin-left: 0;
      padding-bottom: 100px;
    }
  }

  &__book {
    position: relative;

    @include medium-size-min {
      margin-top: -90px;
    }

    &-caption {
      height: 90px;
      color: $c-white;
      text-align: center;

      &--discount {
        &:before {
          display: block;
          position: absolute;
          top: -40px;
          left: 50%;
          width: 100px;
          height: 100px;
          transform: translate(-50%, 0);
          border-radius: 50%;
          content: '';
        }

        .aht-td__book-cost {
          padding: 33px 15px 24px;
        }
      }
    }
    
    &-cost {
      position: relative;
      height: 100%;
      padding: 28px 15px 29px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      z-index: 2;
    }

    &-discount {
      display: flex;
      position: absolute;
      top: -40px;
      left: 50%;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 70px;
      transform: translate(-50%, 0);
      font-size: 16px;
      font-weight: bold;
      word-spacing: 100px;
      z-index: 3;
    }

    &-from {
      margin-right: 10px;
      font-weight: 700;
    }

    &-price {
      font-size: 30px;
      font-weight: 700;
    }

    &-icon {
      margin-right: 10px;
      font-size: 20px;
    }

    &-content {
      background-color: $c-white;
      box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
    }
  }

  &__btns {
    display: flex;
    border-bottom: 1px solid #eee;
  }

  &__btn {
    flex: 1;
    padding: 23px 10px;
    transition: all 0.3s ease-out;
    border: 0;
    border-right: 1px solid #eee;
    outline: none;
    background-color: $c-white;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;

    &:last-child {
      border-right: 0;
    }
  }

  &__form-wrap {
    display: none;

    &.active {
      display: block;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px;
    text-align: center;

    @include small-size-max {
      padding: 40px 30px;
    }

    textarea {
      flex-basis: 100%;
      height: 200px;
      resize: none;
    }
  }

  &__element {
    width: 100%;
    margin-bottom: 20px;
    padding: 9px 20px;
    transition: all 0.3s;
    border: 1px solid #eee;
    border-radius: 25px;
    outline: none;
    background-color: $c-white;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;

    @include small-size-min {
      &--half {
        width: 47%;
      }
    }
  }

  &__submit {
    margin: 0 auto;
    padding: 18px 50px;
    transition: all 0.3s ease-out;
    border: 1px solid #eee;
    border-radius: 25px;
    background-color: $c-white;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.2em;
    cursor: pointer;

    &:hover {
      color: $c-white;
    }
  }

  &__infos {
    display: flex;
    border-top: 1px solid #eee;
    text-align: center;
  }

  &__info {
    flex: 1;
    padding: 15px 10px;
    border-right: 1px solid #eee;

    &:last-child {
      border-right: 0;
    }

    &-icon {
      margin-right: 8px;
    }
  }

  &__why {
    margin-top: 50px;
    background-color: $c-white;
    box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);

    &-title {
      margin: 0;
      padding: 26px 30px 0;
      font-size: 18px;
    }

    &-list {
      margin: 0;
      padding: 12px 0;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 11px 30px;
      border-bottom: 1px solid #eee;

      &:last-child {
        border-bottom: 0;
      }

      &:before {
        content: none;
      }
    }

    &-icon {
      display: inline-block;
      width: 25px;
    }

    &-text {
      margin: 0;
    }
  }

  &__widgets {
    margin-top: 50px;
  }
}
