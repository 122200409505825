.aheto-toolbar {
  display: flex;
  flex-wrap: wrap;

  .registartion,
  .log-in {
    display: flex;
    align-items: center;
    padding: 8px 25px;
  }

  .widget {
    display: flex;
    align-items: center;
    padding: 8px 33px 8px 24px;
  }

  .aht-socials {
    padding: 11px 25px;

    &__item {
      margin-right: 20px;
      margin-bottom: 0;
      float: left;      

      &:last-child {
        margin-right: 0;
      }

      a {
        width: auto;
        height: auto;
        border: 0;
        line-height: 1;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
