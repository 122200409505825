.aht-filter-trvl {

  &__form {
    display: flex;
    border-radius: 5px;
    box-shadow: 0px 14px 24px 0px rgba($c-black, 0.07);
    overflow: hidden;

    @include small-size-max {
      flex-direction: column;
    }
  }

  &__fields {
    display: flex;
    flex: 95;
    border: 1px solid #eee;

    @include xs-size-max {
      flex-direction: column;
    }
  }

  &__field {
    position: relative;
    flex: 1;

    @include xs-size-min {
      border-right: 1px solid #eee;
    }

    @include xs-size-max {
      border-bottom: 1px solid #eee;
    }

    &:last-child {
      border: 0;
    }

    &--month,
    &--type {
      &:after {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        font-family: $f-ionicons;
        font-size: 9px;
        content: '\f123';
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(0, -50%);
    font-size: 16px;
  }

  &__select {
    width: 100%;
    padding: 24px 25px 24px 45px;
    border: 0;
    outline: none;
    background-color: $c-white;
    font-size: 15px;
    font-weight: 700;
    appearance: none;

    option {
      &[disabled] {
        display: none;
      }
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 24px 25px 24px 45px;
    border: 0;
    outline: none;
    font-size: 15px;
    font-weight: bold;
  }

  &__submit {
    flex: 17;
    padding: 25px;
    transition: all 0.3s;
    border: 0;
    color: $c-white;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 3px;
    cursor: pointer;
  }
}
