.aheto-contact {
  $k: &;
  position: relative;

  .aheto-contact__loc {
    font-weight: 500;
    margin: 40px 0 20px;
    font-size: 20px;
    @media screen and (max-width: 768px) {
      margin: 20px 0 10px;
    }
  }

  // Default modificator
  &--default {
    .aheto-contact__img {
      width: 100%;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .aheto-contact__holder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .aheto-contact__info {
      margin: 0;
    }

    .aheto-contact__icon {
      flex-basis: 15px;
      align-self: flex-start;
      margin-right: 10px;
      font-size: 16px;
      line-height: 24px;
    }

    .aheto-contact__link-map {
      margin-top: 40px;
      margin-bottom: 0;
    }
  }

  &--banner {
    .aheto-contact {
      &__holder {
        display: inline-block;
        margin-bottom: 0;

        & + .aheto-contact__holder {
          margin-left: 40px;
        }
      }

      &__icon {
        font-size: 18px;
      }

      &__info {
        display: inline-block;
        font-size: 18px;
        letter-spacing: 4px;
      }
    }

    @include small-size-max {
      .aheto-contact {

        &__holder {
          display: block;

          + .aheto-contact__holder {
            margin-left: 0;
          }
        }

        &__info {
          font-size: 16px;
          letter-spacing: 2px;
        }

        &__icon {
          font-size: 16px;
        }
      }
    }
  }

  &--home-event {
    position: relative;
    &__text {
      .aheto-contact__holder {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .aheto-contact__info {
        margin: 0;
      }


      .aheto-contact__icon {
        flex-basis: 15px;
        align-self: flex-start;
        margin-right: 20px;
        font-size: 16px;
        line-height: 24px;
      }

      .aheto-contact__link {
        transition: all 0.2s;
        font-weight: 400;
        &:hover {
          font-weight: bold;
        }
      }

    }
    &__content {
        position: absolute;
        left: 375px;
        width: 470px;
        top: 113px;
        .aheto-btn {
          font-weight: 500;
        }
    }
    @media screen and (max-width: 992px) {
      &__content {
          left: 160px;
      }
    }
    @media screen and (max-width: 768px) {
      &__content {
          position: static;
          width: 100%;
      }
    }


  }

  // Simple modificator
  &--simple {
    .aheto-contact {
      &__icon {
        font-size: 56px;
      }

      &__type {
        margin-top: 20px;
        margin-bottom: 8px;
        font-size: 14px;
        @media screen and (max-width: 768px) {
          margin-top: 0px;
        }
      }

      &__info {
        display: block;
        max-width: 230px;
        margin: 0 auto;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }

  // Modern and Sidebar modificator (they are the same)
  &--modern,
  &--sidebar {
    .aheto-contact__type {
      margin: 0;
      margin-bottom: 30px;
      line-height: 1;
      font-size: 14px;
      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }

    .aheto-contact__info {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 10px;
      line-height: 40px;

      @include medium-size-max {
        font-size: 22px;
        line-height: 30px;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 0px;
      }
    }

    .aheto-contact__link {
      font-size: 30px;
      line-height: 50px;
      display: table;
      @include medium-size-max {
        font-size: 22px;
        line-height: 40px;
      }
    }

    .aheto-contact__link-dir {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &--political{
    .aheto-contact{
      &__type{
        font-size: 12px;
        font-weight: 900;
        line-height: 4;
        letter-spacing: 1.2px;
        margin-bottom: 0px;
      }
      &__info{
        font-size: 30px;
        line-height: 1.6;
        max-width: 260px;
        text-transform: uppercase;
      }
      &__link{
        font-size: 30px;
        font-weight: 600;
        line-height: 1.6;
        font-family: 'Oswald';
        text-transform: uppercase;
        display: block;
      }

    }
  }
  // Multi location modificator
  &--multi-loc {
    .aheto-contact__img {
      width: 100%;
      height: 250px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .aheto-contact__holder {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .aheto-contact__icon {
      flex-basis: 15px;
      align-self: flex-start;
      margin-right: 10px;
      font-size: 16px;
      line-height: 24px;
    }

    .aheto-contact__info {
      margin: 0;
    }

    .aheto-contact__link {
      font-size: 16px;
      line-height: 26px;
    }

    .aheto-contact__link-map {
      margin-top: 35px;
      margin-bottom: 0;
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }
    }
  }

  // Author Bio modificator
  &--author-bio {
    .aheto-contact__title {
      margin-top: 0;
      margin-bottom: 40px;


      @include medium-size-max {
        margin-bottom: 20px;
      }
    }

    .aheto-contact__mail {
      display: block;
      font-size: 20px;
    }

    .aheto-contact__tel {
      display: block;
      margin-top: 25px;
      font-size: 20px;

      @include medium-size-max {
        display: table;
        margin-top: 10px;
      }
    }
  }

  // Divider line modificator
  &--dvder {
    &::after {
      display: block;
      position: absolute;
      top: 20px;
      right: -15px;
      height: 125px;
      border-right: 1px solid #eee;
      content: '';
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 0px;

      &::after {
        top: auto;
        right: auto;
        bottom: -23px;
        left: 50%;
        width: 225px;
        height: 0;
        transform: translate(-50%, 0);
        border-right: 0;
        border-bottom: 1px solid #eee;
      }
    }
  }

  // Color white modificator
  &--c-white {

    .aheto-contact__info,
    .aheto-contact__icon,
    .aheto-contact__link-map {
      color: $c-white;
    }
  }

  &--business {
    display: flex;
    flex-direction: column;

    .aheto-contact__title {
      align-self: flex-start;
      margin: 0 0 41px;
      transition: all 0.3s;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;

      @include medium-size-max {
        margin-bottom: 25px;
      }
    }

    .aheto-contact__link-map {
      align-self: flex-start;
      margin: 45px 0 0;
      padding-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      cursor: pointer;

      &:after {
        display: none;
      }

      @include medium-size-max {
        margin-top: 10px;
      }
    }

    .aheto-contact__holder {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .aheto-contact__icon {
      display: inline-block;
      width: 15px;
      margin-right: 15px;
      font-size: 18px;
    }

    .aheto-contact__info {
      margin: 0;
      font-weight: 400;
    }

    .aheto-contact__link {
      font-weight: 400;
      font-size: 16px;
      line-height: 2.13;
    }
  }
}

.contact-deskw{
  @media (min-width: 992px) and (max-width: 1920px) {
    max-width: 480px !important;
  }
}

.aht-contact {

  &--edu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .aht-contact {
      &__subtitle {
        margin: 0 0 32px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__address,
      &__email,
      &__tel {
        display: block;
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        line-height: 34px;
      }
      @media screen and (max-width: 768px) {
        &__address,
        &__email,
        &__tel {
          display: block;
          margin: 0;
          font-size: 22px;
          font-weight: bold;
          line-height: 34px;
        }
      }

      &__email,
      &__tel {
        &:hover {
          opacity: 0.7;
        }
      }

      &__link {
        display: block;
        margin-top: 30px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }
  }

  &--yoga {
    display: flex;
    flex-wrap: wrap;
    background-color: $c-white;
    text-align: center;

    @include small-size-max {
      flex-direction: column;
    }

    .aht-contact {

      &__item {
        position: relative;
        flex: 1;
        margin-right: 30px;
        padding: 85px 30px;

        @include large-size-min {
          padding-right: 50px;
          padding-left: 50px;
        }

        @include small-size-max {
          margin-right: 0;
          padding: 50px 15px;
        }

        &:after {
          display: block;
          position: absolute;
          top: 50%;
          right: -15px;
          width: 1px;
          height: 37%;
          transform: translate(0, -50%);
          background-color: rgba($c-black, 0.07);
          content: '';
        }

        &:last-child {
          margin-right: 0;

          &:after {
            content: none;
          }
        }
      }

      &__icon {
        margin-bottom: 23px;
      }

      &__icon-inner {
        font-size: 56px;
        font-style: normal;
      }

      &__title {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__touch {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14px;
      }

      &__touch-inner {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  &--trvl {
    .aht-contact {

      &__img {
        height: 250px;
        margin-bottom: 41px;
        background-position: center;
        background-size: cover;
      }

      &__title {
        margin: 0;
        line-height: 26px;
      }

      &__details {
        margin-top: 24px;
      }

      &__detail {
        display: flex;
        align-items: center;
        margin-bottom: 17px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__icon {
        display: inline-block;
        width: 25px;
        padding-top: 1px;
      }

      &__text {
        margin: 0;
        font-weight: 400;
      }

      &__link,
      &__marker {
        margin-top: 39px;
        transition: all 0.3s;

        &:after {
          transition: all 0.3s;
        }
      }
      &__marker {
        font-size: 15px;
        letter-spacing: 3px;
      }
    }
  }


  &--restaurant-contact-right {
    margin-top: 10px;
    .aht-contact {
      &__item {
        display: flex;
        margin-bottom: 25px;
      }

      &__icon-wrap {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        flex: 0 0 50px;
      }
      &__detail {
        margin-left: 27px;
        max-width: 220px;
      }
      &__title,
      &__data {
        margin: 0;
        font-size: 18px;
        letter-spacing: 0.9px;
        line-height: 24px;
      }
      &__title {
        font-weight: 700;
        text-transform: uppercase;
      }
      &__data {
        font-weight: 400;
      }
    }
  }

}

// Church contact-us page
.church-contact-us-wrap {
  .aheto-contact {
    &--simple {
      .aheto-contact {
        &__icon {
          line-height: 0.43;
        }

        &__type {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1.4px;
          margin-top: 37px;
          margin-bottom: 10px;
        }

        &__link {
          font-weight: normal;
        }
      }
    }
  }
}
.chr-form-container {
  input,
  textarea {
    -webkit-appearance: none;
  }

  input {
    outline: 0 !important;
  }
}


// Construction
.construction-contact {
  .aheto-contact {
    &__type {
      font-weight: 700;
      letter-spacing: 1.4px;
    }
    &__link {
      font-weight: 400;
    }
  }
}

.construction-contact-form{
  .aheto-heading {
    &__title {
      font-weight: 600;
      @include xs-size-max {
        font-size: 22px;
      }
    }
  }
}

