@import '../../../colors/colors-theme-13';
@import '../../../fonts/fonts-theme-13';

.aht-comm-trvl {

  .comment__rating {
    .comment__rate-icon {
      color: $c-alter;
    }
  }

  h2 {
    b {
      color: #FA6742;
    }
  }

  .author {
    color: $c-dark;
    font-family: $fnt-family-2;
  }

  .date-post {
    font-family: $f-open-sans;
    font-size: 13px;
    font-style: italic;
    color: $c-grey;
  }

  p {
    font-family: $f-roboto;
  }

  .comment-reply-link {
    font-family: $f-open-sans;
    line-height: 1.71;
    color: #bbbbbb;

    &:hover {
      color: $c-dark;
    }
  }

  .comment {
    &-reply-title {
      b {
        color: #FA6742;
      }
    }

    &-form {
      textarea {
        color: $c-dark;
        font-family: $f-roboto;

        &::placeholder {
          color: $c-grey;
          font-family: $f-roboto;
        }

        &:focus {
          border: 1px solid $c-dark;
          box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
        }
      }
    }
  }

  .column {
    input {
    color: $c-dark;
    font-family: $f-roboto;

      &::placeholder {
        color: $c-grey;
        font-family: $f-roboto;
      }

      &:focus {
        border: 1px solid $c-dark;
        box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
      }
    }
  }

  .submit-btn {
    background-color: $c-dark;
    font-family: $f-roboto;
    letter-spacing: 1.4px;

    &:hover {
      background-color: #FA6742;
      color: $c-dark;
    }
  }
}
.aht-comm-trvl {

  p {
    &:empty {
      display: none;
    }
  }

  h2 {
    font-family: $fnt-family-1;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: $c-dark;

    b {
      font-weight: inherit;
      color: inherit;
    }
  }
  @include small-size-max {
    h2 {
      margin: 0 0 40px;
    }
  }

  ul {
    margin: 0;
  }

  li {
    padding-left: 0;

    &:before {
      content: none;
    }
  }

  .comment {
    position: relative;
    min-height: 170px;
    margin-bottom: 30px;
    padding: 35px 30px 30px;
    border: 1px solid #eee;
  }

  .comm-block {
    display: flex;

    @include small-size-max {
      flex-direction: column;
    }
  }

  .comm-img {
    margin-right: 30px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      object-fit: cover;

      @include small-size-max {
        display: block;
        margin: 0 auto 30px;
      }
    }
  }

  .author {
    display: inline-block;
    margin: 0 10px 31px 0;
    font-size: 18px;
    line-height: 1;
  }

  .date-post {
    display: inline-block;

    p {
      margin: 0;
      line-height: 18px;
      font-family: $f-open-sans;
      font-size: 13px;

      @include small-size-min {
        &:before {
          margin-right: 10px;
          content: '•';
        }
      }
    }

    @include small-size-max {
      display: block;
      margin-top: 5px;
    }
  }

  .children {
    margin-left: 15px;

    @include small-size-min {
      margin-left: 70px;
    }
  }

  .comment-reply-link {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;

    @include small-size-min {
      position: absolute;
      top: 120px;
      left: 30px;
    }

    @include small-size-max {
      margin-left: 15px;
    }

    &:before {
      margin-right: 10px;
      font-family: $f-ionicons;
      content: '\f21e';
    }
  }


  .comm-txt {
    margin-top: -5px;

    p {
      margin-bottom: 0;
    }

    .author {
      font-family: $fnt-family-1;
    }
    
  }
  .text + p {
    margin-top: 19px;
    font-family: $f-open-sans;
    font-size: 15px;
    line-height: 1.6;
    text-align: left;
    color: $c-grey;
  }

  // Respond
  .comment {
    &-respond {
      padding: 75px 0 120px;

      label {
        display: none;
      }
    }

    @include small-size-max {
      &-respond {
        padding: 20px 0 40px;
      }
      
    }

    &-reply-title {
      margin: 0 0 50px;
      font-weight: bold;
      text-align: center;

      b {
        font-weight: inherit;
      }
    }

    &-form {
      display: flex;
      flex-wrap: wrap;

      textarea {
        height: 140px;
        padding: 17px 20px;
        transition: all 0.3s ease-out;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;
        background-color: $c-white;
        font-size: 16px;
        line-height: 26px;
        resize: none;
      }
    }
  }

  .wrapper-column {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;

    @include small-size-max {
      flex-direction: column;
    }
  }

  .column {
    flex: 1;

    @include small-size-min {
      padding-right: 30px;

      &:last-child {
        padding-right: 0;
      }
    }

    @include small-size-max {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    input {
      width: 100%;
      padding: 17px 20px;
      transition: all 0.3s ease-out;
      border: 1px solid #eee;
      border-radius: 5px;
      outline: none;
      background-color: $c-white;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .input-comment {
    flex-basis: 100%;
    margin-top: 30px;
  }

  .submit-btn {
    margin: 40px auto 0;
    padding: 21px 38px;
    transition: all 0.3s ease-out;
    border: 0;
    border-radius: 5px;
    color: $c-white;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    cursor: pointer;
  }
  
  
}