.aheto-pop-up-btn {
  cursor: pointer;
}

.aheto-pop-up {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.5);
  overflow: auto;
  z-index: 2;

  &__window {
    position: relative;
    width: 100%;
    max-width: 770px;
    margin: auto;
    background-color: $c-white;
    background-repeat: no-repeat;
    background-position: center top;
  }

  &__close {
    position: absolute;
    top: 29px;
    right: 29px;
    padding: 1px;
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;
  }

  &__icon {
    display: block;
    font-size: 48px;
    line-height: 24px;
    &::before {
      line-height: 24px;
    }
  }


  // Modificator home-event
  &--home-event {

    .aheto-pop-up__window {
      padding: 110px 60px 80px;
    }

    .aheto-pop-up__title {
      margin: 0;
    }

    @include medium-size-max {
      .aheto-pop-up__window {
        padding: 60px 30px 40px;
      }
    }
  }
}

.aht-ban--events {
  .aheto-pop-up--home-event {
    .aheto-pop-up__window {
      background-color: transparent;
      iframe {
        width: 576px;
        height: 320px;
      }
    }
  } 
}
