$aheto-time-scale-padding: 70px;
$aheto-time-scale-gutter: $grid-gutter-width;
$aheto-time-scale-circle-size: 24px;
$aheto-time-scale-border-color: #eee;

.aheto-time-scale {

  &__item {
    display: flex;
    position: relative;
    align-items: center;

    &:before,
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      border-radius: 50%;
      content: '';
    }

    &:before {
      width: $aheto-time-scale-circle-size;
      height: $aheto-time-scale-circle-size;
      transform: translateX(-50%);
    }

    &:after {
      width: $aheto-time-scale-circle-size / 2;
      height: $aheto-time-scale-circle-size / 2;
      transform: translate(-50%, $aheto-time-scale-circle-size / 4);
    }

    &.reversed {
      flex-direction: row-reverse;

      .aheto-time-scale__content {
        padding-right: 0;
        padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
        border-right: 0;
        border-left: 1px solid $aheto-time-scale-border-color;
        text-align: left;
      }

      .aheto-time-scale__info {
        padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
        padding-left: 0;
        text-align: right;
      }
    }

    &:first-child {
      .aheto-time-scale__content {
        padding-top: 30px;

        @include xs-size-max {
          padding: 60px 25px 65px;
          margin: -70px 0;
        }
      }
      .aheto-time-scale__info {
        margin-top: 30px;
        @media screen and (max-width: 480px) {
          margin-top: 0px;
          padding-top: 0px;
          padding-bottom: 20px;
        }
      }
      &:before {
        top: 30px;
        @media screen and (max-width: 480px) {
          top: 40px;
        }
      }

      &:after {
        top: 30px;
        @media screen and (max-width: 480px) {
          top: 40px;
        }
      }
    }

    &:last-child {
      .aheto-time-scale__content {
        padding-bottom: 30px;
      }
      .aheto-time-scale__info {
        margin-bottom: 30px;

      }
      &:before {
        bottom: 30px;
      }

      &:after {
        bottom: 30px;
      }
    }
  }

  &__title {
    margin-top: 3px;
    margin-bottom: 40px;
    font-size: 14px;
    letter-spacing: 1.4px;
  }

  &__content {
    flex: 1;
    padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
    border-right: 1px solid $aheto-time-scale-border-color;
    text-align: right;

    p {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  &__info {
    flex: 1;
    padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
    text-align: left;
  }

  &__date {
    color: #ccc;
    font-size: 40px;
    letter-spacing: -2px;
    line-height: 1.3;
  }

  &__photo {
    height: 200px;
    background-position: center;
    background-size: cover;
  }
}

@include medium-size-max {
  $aheto-time-scale-padding: 40px;

  .aheto-time-scale {
    &__content {
      padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
    }

    &__info {
      padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
    }

    &__item {
      &.reversed > & {
        .aheto-time-scale__content {
          padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
        }

        .aheto-time-scale__info {
          padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
        }
      }
    }
  }
}

@include small-size-max {
  $aheto-time-scale-padding: 20px;

  .aheto-time-scale {

    &__item {
      flex-direction: row-reverse;
      &:before,
      &:after {
        top: 30px;
        left: 150px;
      }

      &:after {
        transform: translate(-50%, $aheto-time-scale-circle-size / 4);
      }

      &:before {
        transform: translateX(-50%);
      }

      &.reversed {
        .aheto-time-scale__content {
          padding-right: 0;
          padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
        }

        .aheto-time-scale__info {
          padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
          padding-left: 0;
          text-align: center;
        }
      }
    }

    &__content {
      padding-top: 30px;
      padding-right: 0;
      padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
      border-right: 0;
      border-left: 1px solid $aheto-time-scale-border-color;
      text-align: left;
    }

    &__info {
      flex: 0 0 150px;
      padding-top: 30px;
      padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
      padding-left: 0;
      text-align: center;
    }

    &__date {
      font-size: 32px;
      word-spacing: 9999999px;
      word-wrap: break-word;
    }
  }
}

@include xs-size-max {
  $aheto-time-scale-padding: 10px;

  .aheto-time-scale {

    &__item {
      flex-direction: column;

      &:before,
      &:after {
        left: 0;
        top: 80px;

      }

      &.reversed {
        flex-direction: column;

        .aheto-time-scale__info {
          display: flex;
          flex: 0;
          order: -1;
          margin-right: auto;
          padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
          margin-bottom: 0px;

        }

        .aheto-time-scale__content {
          padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
          padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
        }
      }
    }

    &__title {
      margin-bottom: 25px;
    }

    &__info {
      display: flex;
      flex: 0;
      order: -1;
      margin-right: auto;
      padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
      border-left: 1px solid #eee;
    }

    &__date {
      font-size: 27px;
      word-spacing: 0;
    }

    &__content {
      padding-top: 15px;
      padding-right: $aheto-time-scale-padding + $grid-gutter-width / 2;
      padding-left: $aheto-time-scale-padding + $grid-gutter-width / 2;
    }
  }
}
