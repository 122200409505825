.aheto-services {
  $p: &;

  max-width: 400px;
  height: 100%;
  margin: auto;
  padding: 90px 45px 60px;

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-position: center center;
    background-size: cover;
  }

  &__caption {
    margin-top: 55px;
  }

  &__title,
  &__price {
    margin: 0;
    letter-spacing: 1.7px;
    text-transform: uppercase;
  }

  &__text {
    padding: 0 15px;
  }

  &__link {
    display: inline-block;
    margin-top: 1.7em;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;

    [class^='ion'] {
      position: relative;
      right: 0;
      transition: right 0.2s ease-out;
      font-size: 0.9em;
    }

    &:hover {
      .ion-arrow-right-c {
        right: -4px;
      }
    }
  }

  &__label {
    font-size: 15px;
  }

  &__time {
    padding: 5px 13px;
    font-size: 15px;
    font-weight: bold;
  }

  &--column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &--events {
    position: relative;
    padding: 0;
    background: $c-white;
    box-shadow: 0px 4px 50px 0px rgba($c-black, 0.07);

    #{$p}__image {
      height: 235px;
    }

    #{$p}__content {
      padding: 30px 35px 35px;
    }

    #{$p}__title {
      margin-top: 8px;
      line-height: 31px;
    }

    #{$p}__link {
      margin-top: 5px;
      transition: 0.2s ease-out;
    }

    #{$p}__time {
      position: absolute;
      top: 0;
      left: 38px;
      transform: translateY(-50%);
      text-transform: uppercase;
    }

    #{$p}__caption {
      min-height: 62px;
      margin-top: 10px;
      margin-bottom: 25px;
    }
  }

  &--simple {
    position: relative;
    top: 0;
    min-height: 485px;
    padding: 70px 40px 60px;
    transition: all 0.3s;
    background-color: $c-white;
    box-shadow: 0px 4px 50px 0 rgba($c-black, 0.07);

    .aheto-services__title {
      line-height: 28px;
    }

    .aheto-services__text {
      max-width: 245px;
      margin: 15px auto 0;
      padding: 0;
    }

    .aheto-services__price {
      margin-left: 10px;
      font-weight: 800;
      line-height: 28px;
    }

    .aheto-services__caption {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 245px;
      min-height: 56px;
      margin-right: auto;
      margin-left: auto;
    }

    .aheto-services__link {
      margin-top: 38px;
    }

    @include large-size-max {
      padding: 70px 20px 60px;
    }

    @include medium-size-min {
      &:hover {
        top: -30px;
        box-shadow: 0px 35px 50px 0 rgba($c-black, 0.11);
      }
    }
  }

  &--busns-event {
    position: relative;
    padding: 0;
    transition: all 0.5s;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: $c-white;

    &:hover {
      box-shadow: 0px 4px 50px 0px rgba($c-black, 0.07);
    }

    .aheto-services {

      &__image {
        height: 235px;
        border-radius: 5px;
      }

      &__time {
        position: absolute;
        top: 0;
        left: 43px;
        padding: 7px 13px;
        transform: translate(0, -40%);
        border-radius: 5px;
        color: $c-white;
        font-size: 13px;
        letter-spacing: 2px;
      }

      &__content {
        padding: 35px;
      }

      &__label {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }

      &__caption {
        margin-top: 0;
      }

      &__title {
        font-size: 24px;
        letter-spacing: -0.6px;
        line-height: 1.25;
        text-transform: none;
      }

      &__link {
        margin-top: 25px;
        font-size: 14px;
        letter-spacing: 1.4px;
      }
    }
  }

  &--busns-shadow {


    &:hover {
      box-shadow: 0px 15px 30px 0px rgba(207, 38, 50, 0.1);
    }

  }

  &--construction {
    padding: 0;

    &:hover {
      .aheto-services {
        &__content {
          transform: translateY(-70px);
          box-shadow: 0px 8px 15px 0px rgba($c-black, 0.05);
          @include small-size-max {
            transform: translateY(-35px);
          }
        }
      }
    }

    .aheto-services {
      &__content {
        position: relative;
        padding: 45px 40px;
        max-width: calc(100% - 120px);
        margin: auto;
        transform: translateY(-50px);
        border-radius: 5px;
        background-color: $c-white;
        transition: all 0.3s ease;
        @include large-size-max {
          padding: 35px 30px;
        }
        @include small-size-max {
          max-width: calc(100% - 80px);
          transform: translateY(-25px);
        }
      }

      &__image {
        height: 250px;
      }

      &__label {
        position: absolute;
        top: 5px;
        right: 20px;
        font-size: 40px;
        font-weight: bold;
        line-height: 1.75;
        opacity: 0.1;
      }

      &__caption {
        margin: 0;
      }

      &__title {
        font-size: 20px;
        text-transform: capitalize;
        letter-spacing: 0;
      }

      &__text {
        margin-bottom: 21px;
        padding: 0;
        font-size: 16px;
      }

      &__link {
        margin: 0;
        font-size: 14px;
        letter-spacing: 2px;
      }
    }
  }



  &--construction-news {
    padding: 0;

    @include small-size-max {
      margin-bottom: 30px;
    }

    .aheto-services {
      &__image {
        @include medium-size-max {
          height: 300px;
        }
        @include small-size-max {
          height: 250px;
        }
        height: 350px;
        border-radius: 5px;
      }

      &__label {
        display: inline-block;
        font-weight: 700;
        margin: 15px 0px 5px;
        line-height: 1.63;
      }

      &__caption {
        margin: 0;
      }

      &__title {
        @include medium-size-max {
          padding-right: 0;
        }
        font-weight: 600;
        font-size: 20px;
        text-transform: none;
        letter-spacing: normal;
        padding-right: 25px;
      }
    }
  }


  &--construction_servPage {
    .aheto-services {
      &__content {
        max-width: calc(100% - 20px);
      }
    }
  }


  &--chess {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    max-width: none;
    height: 370px;
    padding: 0;
    text-align: center;

    img {
      width: 0;
      height: 0;
    }

    .aheto-services {

      &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &__caption {
        margin: 0;
      }

      &__content {
        position: relative;
        padding: 35px;
        z-index: 2;

        @include small-size-max {
          padding: 20px;
        }
      }

      &__label {
        display: inline-block;
        margin-bottom: 13px;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__title {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
      }

      &__text {
        margin-top: 25px;
        padding: 0;
      }

      &__link {
        margin-top: 13px;
        padding: 18px 35px;
        border-radius: 25px;
        color: $c-white;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }

  // agency home
  &--agency {
    padding: 60px 30px 70px;
    min-height: 450px;
    border-bottom: 3px solid;
  
    .aheto-services__caption {
      position: relative;
      margin-top: 0;
      margin-bottom: 35px;
      padding: 25px 0;
      width: 100%;
      border-bottom: 1px solid;
      min-height: auto;
      max-width: 100%;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        display: block;
        width: 50px;
        height: 3px;
        background-color: $c-alter;
      }
    }
  
    .aheto-services__title {
      margin: 0 auto;
      font-size: 24px;
      text-transform: none;
    }

    .aheto-services__text {
      margin: 0 auto;
      max-width: 100%;
    }

    .aheto-services__link {
      position: relative;
      font-size: 13px;
      text-transform: none;
      letter-spacing: 0;
      &:after {
        content: '>';
        position: absolute;
        top: -1px;
        right: -12px;
        font-size: 15px;
      }
    }
  }
  // agency home end
}

.aht-service {

  &--edu {
    .aht-service {
      &__img {
        max-width: 100%;
        max-height: 105px;
        margin-bottom: 47px;
      }

      &__icon {
        margin-bottom: 35px;
        font-size: 72px;
      }

      &__title {
        margin: 0;
        font-weight: 700;
      }

      &__desc {
        margin: 25px 0 0;

        @include medium-size-min {
          padding: 0 25px;
        }
      }

      &__link {
        display: inline-block;
        margin-top: 5px;
        text-transform: uppercase;
        background-color: transparent;
        border-style: none;
        font-family: $f-roboto;
      }
    }
  }

  &--trvl {

    .aht-service {
      &__img {
        max-width: 80px;
        max-height: 80px;
      }

      &__title {
        margin: 19px 0 0;
      }

      &__desc {
        margin: 20px 0 0;
        line-height: 24px;
      }

      &__link {
        margin-top: 28px;
      }
    }
  }

  &--chr {

    &:hover {
      .aht-service {
        &__top {
          &:before {
            transform: translate(0, 40px);
            opacity: .5;
          }
        }
      }
    }

    .aht-service {

      &__top {
        display: inline-block;
        position: relative;
        margin-bottom: 34px;

        &:before {
          display: block;
          width: 119px;
          height: 119px;
          margin-bottom: -60px;
          transition: all 0.5s;
          border-radius: 50%;
          background-image: linear-gradient(0deg, $c-white 0%, rgb(254, 238, 227) 100%);
          content: '';
        }
      }

      &__img-holder {
        position: relative;
        width: 120px;
        height: 80px;
        text-align: center;
      }

      &__img {
        max-width: 100%;
        max-height: 80px;
      }

      &__title {
        margin: 0;
        font-weight: bold;
        line-height: 2;
        letter-spacing: -0.5px;
      }

      &__desc {
        margin: 10px 0 0;
      }

      &__link {
        // margin-top: 26px;
        margin-top: 9px;
        background-color: transparent;
        border: none;

        // &:hover{
        //   color: #5E5E5E;
        // }
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  .church-about-margin-services {
    margin-bottom: 80px;
  }
  .church-ministries-padding-services {
    padding-bottom: 90px;
  }
  .church-ministries-padding-blog{
    padding-top: 124px;
  }
}

.church-ministries-wrap {
  .aheto-services {
    max-width: 100%;
    box-shadow: 0px 38px 49px 0 rgba($c-black, 0.07);

    &__content {
      padding: 13px 73px 23px 46px;
    }

    &__image {
      height: 262px;
    }

    &__title {
      font-size: 30px;
      line-height: 1.33;
      letter-spacing: -0.8px;
      text-transform: capitalize;
    }

    &__text {
      padding: 0;
      margin-bottom: 1.5rem;
    }

    &__caption {
      min-height: 0;
      margin-bottom: 20px;
      margin-top: 0;
    }
  }
}

.church-ministries-wrap {
  .aheto-services {
    &__link {
      letter-spacing: 1.4px;
    }
  }
}

.construction-news-contentWrap {
  .aheto-services {
    &__label {
      font-weight: 600;
      font-size: 16px;
    }
    @include small-size-max {
      margin-bottom: 10px;
    }
    &__title,
    &__image {
      transition: 0.3s;
    }
  }
}

.aheto-services--construction-news:hover .aheto-services__image {
  opacity: 0.8;
}

.aheto-services--construction-news:hover .aheto-services__title {
  color: #EBB113;
}
