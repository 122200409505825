// Source --> https://jonsuh.com/hamburgers/

$hamburger-padding-x                       : 15px;
$hamburger-padding-y                       : 15px;
$hamburger-layer-width                     : 18px;
$hamburger-layer-height                    : 2px;
$hamburger-layer-spacing                   : 4px;
$hamburger-layer-color                     : $c-white;
$hamburger-layer-border-radius             : 1.5px;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter    : opacity(50%);

// Types (Remove or comment out what you don’t need)
$hamburger-types: (
  // 3dx,
  // 3dx-r,
  // 3dy,
  // 3dy-r,
  // arrow,
  // arrow-r,
  // arrowalt,
  // arrowalt-r,
  // boring,
  // collapse,
  // collapse-r,
  // elastic,
  // elastic-r,
  // emphatic,
  // emphatic-r,
  // slider,
  // slider-r,
  // spring,
  // spring-r,
  // stand,
  // stand-r,
  // spin,
  // spin-r,
  squeeze,
  // vortex,
  // vortex-r
);
