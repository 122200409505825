.search-education {
    flex-grow: 1;
    min-width: 180px;
    // padding: 0 15px;

    &-form {
      position: relative;
      max-width: 100%;
      margin: auto;

      input[type=text] {
          padding-right: 40px;
      }

      button[type="submit"] {
        background: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 53px;
        width: 45px;
        cursor:pointer;
        transition: 0.2s all;
        @include menu-size-max {
          line-height: 43px;
        }
     }
     &__input {
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 26px;
        letter-spacing: normal;
        text-align: left;
     }
     
    }

    label {
      display: none;
    }

    input {
      outline: none;

      &[type=text] {
        width: 100%;
        padding: 15px 20px;
        border: 1px solid #eee;
        border-radius: 5px;

        &::placeholder {
          font-size: 15px;
          font-weight: 400;
        }

        @include menu-size-max {
          padding: 10px 20px;
        }
      }

      &[type=submit] {
        position: absolute;
        top: 50%;
        right: 15px;
        padding: 2px 0;
        transform: translate(0, -50%);
        border: 0;
        background-color: transparent;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;

        @include xs-size-max {
          font-size: 14px;
        }
      }
    }
  }
