.aheto-gallery {
  $p: &;

  &--2-in-row {
    .aheto-gallery__item {
      flex-basis: 50%;
    }
  }

  &--3-in-row {
    .aheto-gallery__item {
      flex-basis: 33.333%;
    }
  }

  &--4-in-row {
    .aheto-gallery__item {
      flex-basis: 25%;
    }
  }

  &--5-in-row {
    .aheto-gallery__item {
      flex-basis: 20%;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .aheto-gallery__item {
      @include small-size-max {
        flex-basis: 100%;
      }
    }
  }

  &__item {
    flex: 1 1 0;
    min-width: 320px;
    padding-left: 0;
    cursor: pointer;

    &:before {
      content: none;
    }

    @include small-size-max {
      min-width: 290px;
    }

    &--height {
      height: 480px;

      @media screen and (max-width: 1600px) {
        height: 380px;
      }

      @media screen and (max-width: 1400px) {
        height: 320px;
      }

      @include large-size-max {
        height: 300px;
      }

      @include medium-size-max {
        height: 350px;
      }

      @include small-size-max {
        height: 400px;
      }

      @include xs-size-max {
        height: 350px;
      }
    }
  }

  &__item-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // Modal window
  &__modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($c-black, 0.9);
    overflow: auto;
    z-index: 100;
  }

  &__modal-content {
    display: block;
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    animation-name: zoom;
    animation-duration: 0.6s;

    @include small-size-max {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @keyframes zoom {
    from {transform: scale(0)}
    to {transform: scale(1)}
  }


  &__close {
    display: block;
    position: absolute;
    top: 35px;
    right: 35px;
    padding: 7px 5px 0;
    transition: 0.3s;
    color: rgba($c-white, 0.8);
    font-size: 50px;
    font-weight: bold;
    line-height: 20px;
    text-shadow: 0px 0px 10px rgba($c-black, 0.7);
    z-index: 2;

    @include small-size-max {
      top: 25px;
      right: 25px;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  &--mosaics {
    .aheto-gallery {
      &__item {
        flex-basis: 25%;
        height: 50%;

        &:nth-child(6n + 3),
        &:nth-child(6n + 4) {
          flex-basis: 50%;
        }

        @media screen and (max-width: 1500px) {
          height: 350px;
        }

        @include large-size-max {
          min-width: 350px;
          height: 380px;
        }

        @include medium-size-max {
          height: 350px;
        }

        @include small-size-max {
          flex-basis: 100%;
          height: 450px;

          &:nth-child(6n + 3),
          &:nth-child(6n + 4) {
            flex-basis: 100%;
          }
        }

        @include xs-size-max {
          min-width: 270px;
          height: 350px;
        }
      }
    }
  }

  &--animation {
    position: relative;
    #{$p}__item {
      position: absolute;
      width: auto;
      min-width: auto;
      height: auto;
    }
    @include small-size-max {
      display: none;
    }
  }
}
.section-gallery {
  .gallery {
    display: block;

    &-item {
      background-size: cover;
      padding-bottom: 25%;
      width: 25%;
      margin: 0px;
      cursor: pointer;
      position: relative;
      @media screen and (max-width: 991px) {
        width: 50%;
        padding-bottom: 50%;
      }
      @include small-size-max {
        width: 100%;
        padding-bottom: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba($c-black, 0.7);
        opacity: 0;
        transition: opacity .25s ease-in-out;
      }

      &:after {
        content: "\f2c7";
        font-family: "Ionicons";
        position: absolute;
        color: $c-white;
        font-size: 50px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity .25s ease-in-out;
      }

      &:hover {
        &:after {
          opacity: 1;
        }

        &:before {
          opacity: 1;
        }
      }

      &-big {
        width: 50%;
        padding-bottom: 50%;
        @media screen and (max-width: 991px) {
          width: 100%;
          padding-bottom: 100%;
        }
      }

      &-size {
        width: 25%;
        @media screen and (max-width: 991px) {
          width: 50%;
          padding-bottom: 50%;
        }
        @include small-size-max {
          width: 100%;
          padding-bottom: 100%;
        }

      }
    }
  }
}
.home-event--gallery {
  .aheto-gallery {
    &__item:hover {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent, #000);
        opacity: 0.4;
      }
    }
  }
}

.church-event--gallery{
  .aheto-gallery {
    &__item:hover {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent, #000);
        opacity: 0.4;
      }
    }
  }
}

.gallery-yoga {
  .aheto-gallery__item {
    &:hover {
      opacity: 0.8;
    }
  }
}
