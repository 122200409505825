// Declaring vars, mixins

$member-transition: all 0.5s ease-in-out;
$member-transition-slow: all 0.7s ease;

// Styles
.aheto-member {
  position: relative;
  $k: &;

  &__img {
    border-radius: 50%;
  }

  &__contact {
    display: flex;
    justify-content: center;
  }

  &__link {
    margin: 0 10px;
  }

  &__icon {
    font-size: 18px;
    transition: .3s all;
  }

  // Modificator --home-page
  &--home-page {
    margin: 0 16px;

    .aheto-member__img {
      max-width: 100%;
      max-height: 200px;
    }

    .aheto-member__name {
      margin-top: 22px;
      margin-bottom: 4px;
      font-size: 18px;
    }

    .aheto-member__position {
      margin: 0;
    }

    .aheto-member__contact {
      position: absolute;
      bottom: 50px;
      width: 100%;
      padding: 10px 0;
      transition: $member-transition;
      border-radius: 6px;
      background-color: $c-white;
      opacity: 0;
    }

    &:hover {
      .aheto-member__contact {
        bottom: 75px;
        opacity: 1;
      }
    }

    @media screen and (max-width: 768px) {
      margin: 0;
      .aheto-member__contact {
        position: static;
        box-shadow: none;
        opacity: 1;
      }

      .aheto-member__link {
        margin: 0 20px;
      }
    }
  }

  // Modificator --classic
  &--classic {
    padding: 40px 20px;
    transition: $member-transition;

    .aheto-member__img {
      max-width: 188px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .aheto-member__name {
      margin-top: 30px;
      margin-bottom: 0;
    }

    .aheto-member__position {
      margin-top: 0;
      margin-bottom: 35px;
    }

    .aheto-member__desc {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 1229px) {
      padding: 40px 20px;
    }
    @media screen and (max-width: 767px) {
      margin: 0 auto;

      .aheto-member__img {
        max-height: 280px;
      }
    }
  }

  // Modificator --simple
  &--simple,
  &--simple-reversed {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: 0 -15px;

    .aheto-member__img-holder {
      position: relative;
      flex: 1;
      flex-basis: 50%;

      &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        height: 60px;
        transform: translate(0, -50%);
        border-top: 40px solid transparent;
        border-right: 30px solid $c-white;
        border-bottom: 40px solid transparent;
        content: '';
        box-sizing: border-box;
      }
    }

    .aheto-member__img {
      width: 100%;
      height: 100%;
      border-radius: 0;
      object-fit: cover;
    }

    .aheto-member__text {
      display: flex;
      flex: 1;
      flex-basis: 50%;
      flex-direction: column;
      padding: 80px 60px 55px;
    }

    .aheto-member__name {
      margin: 0;
      font-weight: 300;
    }

    .aheto-member__position {
      margin: 0;
    }

    .aheto-member__desc {
      margin-top: 45px;
    }

    .aheto-member__contact {
      justify-content: flex-start;
      margin-top: auto;
    }

    .aheto-member__link {
      margin-right: 15px;
      margin-left: 0;
    }

    @media screen and (max-width: 1500px) {
      .aheto-member__text {
        padding: 40px 60px;
      }
    }

    @media screen and (max-width: 1229px) {
      .aheto-member__text {
        padding: 40px 30px 30px;
      }
    }

    @media screen and (max-width: 991px) {
      margin: 0;
      .aheto-member__img-holder {
        min-width: 250px;
      }
      .aheto-member__desc {
        margin: 40px 0;
      }
    }

    @media screen and (max-width: 546px) {
      .aheto-member__img-holder {
        &::after {
          top: auto;
          right: auto;
          bottom: 0;
          left: 50%;
          height: 30px;
          transform: translate(-50%, 0);
          border-right: 40px solid transparent;
          border-bottom: 30px solid $c-white;
          border-left: 40px solid transparent;
        }
      }
    }
  }

  &--simple-reversed {
    .aheto-member__img-holder {
      order: 2;
      &::after {
        right: auto;
        left: 0;
        border-right: 0;
        border-left: 30px solid $c-white;
      }
    }

    .aheto-member__text {
      order: 1;
    }

    @media screen and (max-width: 991px) {
      .aheto-member__img-holder {
        order: 1;
        &::after {
          right: 0;
          left: auto;
          border-right: 30px solid $c-white;
          border-left: 0;
        }
      }
    }

    @media screen and (max-width: 546px) {
      .aheto-member__img-holder {
        &::after {
          top: auto;
          right: auto;
          bottom: 0;
          left: 50%;
          height: 30px;
          transform: translate(-50%, 0);
          border-right: 40px solid transparent;
          border-bottom: 30px solid $c-white;
          border-left: 40px solid transparent;
        }
      }
    }
  }

  &--modern {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 40px 50px;
    transition: $member-transition;
    background-color: $c-white;

    .aheto-member__img-holder {
      flex: 1;
      min-width: 160px;
      text-align: center;
    }

    .aheto-member__img {
      max-width: 100%;
    }

    .aheto-member__text {
      display: flex;
      flex: 2;
      flex-direction: column;
      padding-left: 50px;
    }

    .aheto-member__name {
      margin: 7px 0 0;
      font-size: 18px;
      font-weight: bold;
    }

    .aheto-member__position {
      margin: 0;
    }

    .aheto-member__desc {
      margin-top: 32px;
    }

    .aheto-member__contact {
      justify-content: flex-start;
      margin-top: 45px;
    }

    .aheto-member__link {
      margin-right: 18px;
      margin-left: 0;
    }

    @media screen and (max-width: 1500px) {

      .aheto-member__contact {
        margin-top: 15px;
      }

      .aheto-member__text {
        padding-left: 20px;
      }
    }

    @media screen and (max-width: 1229px) {
      flex-direction: column;

      .aheto-member__img-holder {
        margin-bottom: 30px;
      }

      .aheto-member__img {
        max-height: 250px;
      }

      .aheto-member__text {
        padding-left: 0;
      }
    }
  }
  &--business{

  }
  &--home-event {

    &.left {
      margin-right: 20px;
    }

    &.center {
      margin-right: 10px;
      margin-left: 10px;
    }

    &.right {
      margin-left: 20px;
    }

    .aheto-member__img-holder {
      position: relative;
      height: 350px;

      &:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $member-transition-slow;
        content: '';
        opacity: 0;
      }
    }

    .aheto-member__img {
      width: 100%;
      height: 100%;
      border-radius: 0;
      object-fit: cover;
    }

    .aheto-member__text {
      margin-top: 34px;
    }

    .aheto-member__name {
      margin-bottom: 15px;
      font-weight: 600;
    }

    .aheto-member__position {
      font-size: 12px;
      letter-spacing: 2px;
      font-weight: 600;
    }

    .aheto-member__contact {
      position: absolute;
      bottom: 170px;
      left: 30px;
      transition: $member-transition-slow;
      opacity: 0;
    }

    .aheto-member__link {
      margin: 0 11px;
    }

    .aheto-member__icon {
      color: $c-white;
      font-size: 22px;
    }

    .aheto-member__link-plus {
      display: inline-block;
      position: absolute;
      top: 45px;
      right: 45px;
      transition: $member-transition-slow;
      opacity: 0;
    }

    .aheto-member__icon-plus {
      display: block;
      height: 28px;
      color: $c-white;

      &:before {
        font-size: 50px;
        line-height: 28px;
      }
    }

    &:hover {
      .aheto-member__img-holder {
        &:after {
          opacity: 1;
        }
      }
      .aheto-member__contact {
        bottom: 135px;
        opacity: 1;
      }

      .aheto-member__link-plus {
        transform: rotate(90deg);
        opacity: 1;
      }
    }

    @include medium-size-max {
      .aheto-member__img-holder {
        height: 250px;
      }

      .aheto-member__contact {
        left: 20px;
      }

      .aheto-member__link-plus {
        right: auto;
        left: 30px;
      }
    }

    @include small-size-max {
      &.left {
        margin-right: 0;
      }

      &.center {
        margin-right: 0;
        margin-left: 0;
      }

      &.right {
        margin-left: 0;
      }

      .aheto-member__img-holder {
        max-width: 350px;
        height: 350px;
        margin: 0 auto;

        &:after {
          content: none;
        }
      }

      .aheto-member__contact {
        position: static;
        opacity: 1;
      }

      .aheto-member__link {
        margin: 0 15px;
      }

      .aheto-member__link-plus {
        position: static;
        margin-top: 5px;
        margin-bottom: 17px;
        opacity: 1;
      }

      &:hover {
        .aheto-member__link-plus {
          transform: none;
        }
      }
    }
  }

  &--home-education {
    display: flex;
    margin-top: 45px;
    border-radius: 5px;
    background-color: $c-white;
    overflow: initial;

    .aheto-member__img-holder {
      position: relative;
      top: -45px;
      left: 0;
      flex-basis: 270px;
      max-height: 335px;
      margin-bottom: 35px;
    }

    .aheto-member__img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }

    .aheto-member__text {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 50px 35px 40px 30px;
    }

    .aheto-member__position {
      order: -1;
      margin: 0;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 1;
    }

    .aheto-member__name {
      margin: 10px 0 0;
      font-weight: 700;
    }

    .aheto-member__desc {
      margin-top: 35px;
      margin-bottom: 20px;
    }

    .aheto-member__contact {
      position: absolute;
      top: 315px;
      left: 60px;
    }

    .aheto-member__link {
      margin: 0 12px;
      &:hover {
        opacity: 1;
      }
    }

    .aheto-member__icon {
      font-size: 20px;
    }

    .aheto-member__link-plus {
      display: flex;
      width: 45px;
      height: 45px;
      margin-top: auto;
      margin-left: auto;
      border: 2px solid rgba($c-black, 0.07);
      border-radius: 50%;
      &:hover {
        color: $c-white;
        opacity: 1;
      }
    }

    .aheto-member__icon-plus {
      margin: auto;
      font-size: 24px;
    }

    @media screen and (max-width: 1229px ) {
      .aheto-member__img-holder {
        flex-basis: 220px;
        max-height: 260px;
      }

      .aheto-member__contact {
        top: 245px;
        left: 30px;
      }
    }

    @media screen and (max-width: 575px ) {
      flex-direction: column;
      margin-top: 0;

      .aheto-member__img-holder {
        position: static;
        flex-basis: auto;
        max-height: none;
        margin-bottom: 0;
      }

      .aheto-member__desc {
        margin: 15px 0 10px;
      }

      .aheto-member__contact {
        position: static;
      }

      .aheto-member__link {
        margin-top: 10px;
      }

      .aheto-member__link-plus {
        order: 10;
        width: 35px;
        height: 35px;
        margin: 15px auto 0 auto;
        color: $c-white;
        line-height: 35px;
      }
    }
  }

  &--saas {
    .aheto-member {
      &__img-holder {
        position: relative;
        font-size: 0;
      }
      &__img {
        width: 100%;
        border-radius: 0;
      }
      &__name {
        margin: 25px 0 5px;
      }
      &__position {
        margin: 5px 0;
        font-size: 12px;
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }
      &__contact {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        z-index: 1;
        &:hover {
          #{$k}__link {
            margin-top: 0;
            margin-bottom: 0;
            opacity: 1;
          }
          &:before,
          &:after {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200%;
            height: 200%;
            opacity: 1;
          }
        }
        &:after,
        &:before {
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          content: '';
          opacity: .3;
          z-index: 2;
          transition: .4s ease-in-out;
        }
        &:before {
          top: 0;
          left: 0;
        }
        &:after {
          right: 0;
          bottom: 0;
        }
      }
      &__link {
        position: relative;
        color: $c-white;
        opacity: 0;
        transition: 0.3s ease-out;
        z-index: 3;
        &:nth-child(odd) {
          margin-top: 60%;
        }
        &:nth-child(even) {
          margin-bottom: 60%;
        }
      }
    }
    @media (max-width: 768px) {
      max-width: 270px;
      margin: auto;
    }
  }

  &--barbershop {
    display: flex;
    position: relative;
    top: 0;
    flex-direction: column;
    padding: 0 64px 50px;
    transition: 0.2s ease-out;
    background: rgba($c-white, 0.05);

    .aheto-member {
      &__img-holder {
        transform: translateY(-50%);
        text-align: center;
        font-size: 0;
        & > img {
          width: 100%;
          max-width: 160px;
        }
      }
      &__text {
        margin-top: -39px;
      }
      &__name {
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 1.4px;
      }
      &__position {
        margin: 0 0 33px;
      }
      &__position,
      &__desc {
        color: $c-white;
      }
      &__contact {
        margin-top: 45px;
      }
      &__link,
      &__desc {
        transition: 0.2s ease-out;
        opacity: 0.5;
      }
      &__link:hover {
        opacity: 1;
      }
    }
    &:hover {
      top: -30px;
      background: rgba($c-white, 0.11);
      .aheto-member {
        &__desc {
          opacity: 0.7;
        }
      }
    }
    @include xs-size-max {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  &--dark {
    border-radius: 0;
    background: rgba($c-white, 0.05);
    #{$k}__position {
      color: $c-white;
      font-size: 15px;
      font-weight: normal;
    }
    #{$k}__name {
      font-size: 17px;
      font-weight: bold;
      letter-spacing: 1.4px;
    }
    #{$k}__desc {
      color: rgba($c-white, 0.5);
    }
    #{$k}__img {
      border-radius: 0;
    }
    #{$k}__link {
      color: rgba($c-white, 0.5);
      &:hover {
        color: $c-white;
      }
    }
    #{$k}__link-plus {
      border-color: rgba($c-white, 0.3);
      color: rgba($c-white, 0.3);
    }
  }

  &--border {
    border: 1px solid #eee;
    margin: 5px 0 0;
    &:hover {
        border: 1px solid transparent;

        &::after {
          opacity: 1;
        }
      }

      &::after {
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        transition: $member-transition;
        content: '';
        opacity: 0;
      }
  }

  &--border-2 {
    border: 1px solid #eee;

    @include small-size-min {
      &:hover {
        border: 1px solid transparent;
        &::after {
          opacity: 1;
        }
      }

      &::after {
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 8px;
        transition: $member-transition;
        content: '';
        opacity: 0;
      }
    }
  }
  &--border-3 {
    &::after {
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 7px;
      transition: $member-transition;
      content: '';
      opacity: 0;
    }
  }

  &--business {
    display: flex;
    min-height: 370px;
    margin-top: 75px;
    margin-left: 35px;
    transition: all 0.3s;

    @include large-size-min {
      &:hover {
        border: 1px solid transparent;
      }
    }

    @include large-size-max {
      flex-direction: column;
      max-width: 460px;
      margin: 0 auto;
    }

    .aheto-member__img-holder {
      margin-top: -75px;
      margin-left: -35px;

      @include large-size-max {
        margin: 0;
        padding: 30px 30px 0;
      }
    }

    .aheto-member__img {
      width: 270px;
      height: 350px;
      transition: box-shadow 0.3s;
      border-radius: 0;
      object-fit: cover;

      @include large-size-max {
        width: 100%;
        height: 400px;
        object-position: top;
      }

      @include medium-size-max {
        height: 300px;
      }
    }

    .aheto-member__text {
      display: flex;
      flex-direction: column;
      padding: 42px 27px 30px;

      @include large-size-max {
        padding-top: 30px;
      }
    }

    .aheto-member__position {
      order: -1;
      margin: 0;
      font-weight: 700;
      letter-spacing: 3px;
      text-transform: uppercase;
    }

    .aheto-member__name {
      margin: 0;
      font-size: 30px;
      font-weight: 700;
      letter-spacing: -0.5px;
    }

    .aheto-member__desc {
      margin: 25px 0 15px;
    }

    .aheto-member__link-plus {
      align-self: flex-end;
      width: 48px;
      height: 48px;
      margin: auto 10px 0;
      transition: all 0.3s;
      border-radius: 50%;
      line-height: 48px;
      text-align: center;

      &:hover {
        .aheto-member__icon-plus {
          color: $c-white;
        }
      }

      @include large-size-max {
        align-self: center;
        margin-bottom: 15px;
      }
    }

    .aheto-member__icon-plus {
      transition: all 0.3s;
      font-size: 24px;
      line-height: 17px;

      &:before {
        content: '\f217';
      }
    }

    .aheto-member__contact {
      @include menu-size-min {
        position: absolute;
        top: 305px;
        left: 45px;
      }
    }

    .aheto-member__link {
      margin: 0 25px 0 0;
    }

    .aheto-member__icon {
      font-size: 20px;
    }
  }

  &--yoga {
    transition: all 0.3s;
    text-align: center;

    @include menu-size-min {
      &:hover {
        transform: translate(0, -30px);

        .aheto-member__text {
          box-shadow: 0px 11px 15px 0px rgba($c-black, 0.14);
        }
      }
    }

    .aheto-member {
      &__img-holder {
        width: 160px;
        height: 160px;
        margin: 0 auto -75px;
      }

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__text {
        padding: 120px 35px 45px;
        transition: all 0.3s;
        border: 1px solid rgba($c-black, 0.07);
      }

      &__name {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__position {
        display: inline-block;
        margin: 12px auto 0;
        padding: 4px 12px;
        border-radius: 11px;
        color: $c-white;
        font-size: 9px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__desc {
        margin: 35px 0 0;
        font-weight: 500;
      }

      &__contact {
        margin: 45px 0 0;
      }

      &__link {
        margin-right: 15px;
        margin-left: 0;
        color: rgba($c-black, 0.2);

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: rgba($c-black, 0.55);
        }
      }
    }
  }

  &--restaurant {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .aheto-member {
      &__img-holder {
        height: 286px;
        width: 286px;
        border-radius: 143px;
        box-shadow: 0px 3px 59px 0 rgba(0, 0, 0, 0.08);
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: end;
        transition: transform 0.4s linear;
      }
      &__img {
        border-radius: 0px;
        position: relative;
        top: -50px;
        left: 9px;
      }
      &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__name {
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        transition: transform 0.4s linear;
      }
      &__position {
        font-family: $f-playfair-display;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-style: italic;
        line-height: 1.33;
        position: relative;
        transition: transform 0.4s linear;
        &:after {
          opacity: 0;
          content: '';
          position: absolute;
          bottom: -12px;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          border-top: 1px solid #eee;
          width: 78px;
        }
      }
      &__contact {
        margin-top: 10px;
        opacity: 0;
        transition: transform 0.4s linear;
      }
      &__link {
        margin: 0 16px;
        color: rgba(153, 153, 153, 0.45) !important;
        transition: transform 0.4s linear;
      }
    }
  }


  &--restaurant:hover {

    transition: ease 0.4s;
    cursor: pointer;
    .aheto-member {
      &__img-holder {
        transform: scale(1.1, 1.1);
        margin-bottom: 30px;
        background-color: $c-active;
        box-shadow: 0px 3px 35px 0 rgba(0, 0, 0, 0.18);
        transition: transform 0.4s linear;
      }
      &__position {
        font-weight: 600;
        transition: transform 0.5s linear;
      }
      &__position:after {
        opacity: 1;
        transition: transform 0.5s linear;
      }
      &__name {
        font-size: 24px;
        transition: transform 0.4s linear;
      }
      &__contact {
        opacity: 1;
        transition: transform 0.5s linear;
      }
    }
  }

  &--restaurant.rest-chef-2 {
    .aheto-member {
      &__img {
        height: 390px;
        top: -101px;
        left: 8px;
      }
    }
  }

  &--restaurant.rest-chef-3 {
    .aheto-member {
      &__img {
        top: -21px;
        left: -34px;
      }
    }
  }
}

.restaurant-aheto-members-wrap {
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
  .bg-text {
    position: absolute;
    left: -20px;
    top: -53px;
    color: $c-light;
    font-size: 150px;
    font-weight: 900;
    letter-spacing: 11.25px;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .rel-container {
    //border: 1px solid red;
    height: 400px;
  }
}

@include xs-size-max {
  .aheto-member--restaurant {
    .aheto-member {
      &__img-holder {
        transform: scale(0.7, 0.7);
      }
      &__name {
        margin-top: -20px;
      }
      &__img-holder {
        margin-bottom: 20px;
      }
    }
  }
  .aheto-member--restaurant:hover {
    .aheto-member {
      &__img-holder {
        transform: scale(0.8, 0.8);
      }
    }
  }
}





.aht-team {
  &--edu,
  &--construction {
    .aht-team {
      &__pos {
        font-weight: 500;
      }
    }
    &:hover {
      .aht-team {
        &__img {
          &:before {
            background-color: rgba($c-black, 0.2);
          }
        }

        &__name {
          color: $c-white;
        }

        &__socials {
          opacity: 1;
        }
      }
    }

    .aht-team {

      &__img {
        position: relative;
        max-width: 370px;
        height: 370px;
        background-position: center;
        background-size: cover;
        overflow: hidden;

        &:before {
          display: block;
          width: 100%;
          height: 100%;
          transition: all 0.3s ease-out;
          content: '';
        }
      }

      &__socials {
        position: absolute;
        top: 28px;
        right: 29px;
        transition: all 0.3s ease-out;
        opacity: 0;
      }

      &__caption {
        position: relative;
        margin-top: -100px;
        margin-left: 35px;
        padding: 30px 25px 30px;
        transition: all 0.3s ease-out;
        background-color: $c-white;
        z-index: 1;
        @include small-size-max {
          margin-left: 0;
        }

        @include large-size-max {
          box-shadow: 0px 10px 30px 0px rgba($c-black, 0.05);
        }
      }

      &__pos {
        display: inline-block;
        margin-bottom: 14px;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &__name {
        margin: 0;
        transition: all 0.3s ease-out;
        font-weight: 700;
      }

      &__desc {
        margin: 13px 0 0;
      }
    }
  }

  &--t1 {
    display: flex;
    position: relative;
    padding: 40px 30px 45px;
    transition: all 0.3s;
    border: 1px solid #eee;
    background-color: $c-white;

    @media screen and (min-width: 1600px) {
      padding: 40px 35px 45px 40px;
    }

    @include xs-size-max {
      flex-direction: column;
      text-align: center;
    }

    &:before {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      transition: all 0.3s;
      content: '';
      opacity: 0;
    }

    &:hover {
      border-color: transparent;
      box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);

      &:before {
        opacity: 1;
      }
    }

    .aht-team {
      &__img {
        width: 160px;
        height: 160px;
        margin-right: 30px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;

        @media screen and (min-width: 1600px) {
          margin-right: 53px;
        }

        @include xs-size-max {
          margin: 0 auto 30px;
        }
      }

      &__caption {
        flex: 1;
      }

      &__name {
        margin: 12px 0 0;
      }

      &__pos {
        margin: -2px 0 0;
      }

      &__desc {
        margin: 28px 0 0;
        line-height: 24px;
      }

      &__socials {
        margin-top: 54px;
      }
    }
  }

  &-more {
    background-color: #fff;
    border: solid 1px #eeeeee;
    .aht-team {
      &__header {
        &-number {
          font-size: 110px;
        }
        &-plus {
          display: inline-block;
          transform: translateY(-40px);
          font-size: 40px;
        }
        &-text {
          font-size: 18px;
        }
      }
      &__details {
        &-link {
          display: inline-block;
          font-size: 14px;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #e0e0e0;
          }
        }
      }

      @media screen  and (max-width: 992px) {
        &__header {
          flex-direction: column;
        }
      }

    }

  }
}

.about-church-team-wrap-1,
.church-ministries-wrap,
.church-events-wrap {
  .aheto-member {
    &--home-page {
      &:hover {
        .aheto-member__contact {
          bottom: 70px;
        }
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  .church-about-margin-team {
    margin-top: 130px;
    margin-bottom: 129px;
  }

  .church-ministries-margin-team,
  .church-events-margin-team {
    margin-top: 120px;
    margin-bottom: 115px;
  }
}

