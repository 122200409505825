.aheto-portfolio-single-item {

	&--construction {

		@include medium-size-max {
			height: 500px;
		}

		@include small-size-max {
			height: 300px;
		}

		height: 600px;
		border-radius: 5px;
		padding: 45px 40px;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		display: flex;
		align-items: flex-end;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 47%,rgba(0,0,0,1) 120%,rgba(0,0,0,1) 100%);
		}

		.aheto-portfolio-single-item {
			&__content {
				z-index: 3;
			}
			&__title,
			&__subtitle {
				margin: 0;
			}
			&__title {
				font-size: 16px;
			}
			&__subtitle {
				font-size: 20px;
				font-weight: 400;
				color: $c-white;
				line-height: 1.6;
			}
			&__cover {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(17, 21, 57, 0.9);
				visibility: hidden;
				opacity: 0;
				transition: all 0.3s ease;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 2;
			}
			&__cover-icon {
				display: flex;
				width: 50px;
				height: 50px;
				border-radius: 5px;
				justify-content: center;
				align-items: center;
				font-size: 20px;
				line-height: 1;
			}
		}
		&:hover {
			.aheto-portfolio-single-item {
				&__cover {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}
.text_margin{
	@media screen and (max-width: 1024px) {
		margin-top: 0px;
	}
}
