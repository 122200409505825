.forum--main {
  background: #fff;
  legend{
    display: none;
  }
  .forum {
    &--content {
      padding: 110px 0 120px;
      @media screen and (max-width: 991px) {
        padding: 80px 0px;
      }
      @media screen and (max-width: 450px) {
        padding: 50px 0px 0px;
      }
      #bbpress-forums ul.bbp-lead-topic,
      #bbpress-forums ul.bbp-topics,
      #bbpress-forums ul.bbp-forums,
      #bbpress-forums ul.bbp-replies,
      #bbpress-forums ul.bbp-search-results {
          border: 1px solid #eee;
      }

      #bbpress-forums li.bbp-body ul.forum,
      #bbpress-forums li.bbp-body ul.topic {
          border-top: 1px solid #eee;
      }

      #bbpress-forums {
        font-family: Roboto;
        margin-bottom: 0;

        li.bbp-header ul {
            overflow: hidden;
            background: #f6f9ff;
            margin-top: -1px;
            display: flex;
        }

        a:hover {
            color: #007bff !important;
        }

        li.bbp-header {
          background: #f6f9ff;
          font-family: Roboto;
          color: #2a74ed;
          font-weight: 600;
          font-size: 14px;
          padding: 0;
          line-height: 50px;
          text-align: center;
          border-top: 1px solid #eee;
        }

        .forum-titles li.bbp-topic-title,
        .forum-titles li.bbp-forum-info {
          padding: 0 30px;
        }

        li.bbp-forum-info,
        li.bbp-topic-title {
            padding: 30px;
        }

        a.bbp-forum-title {
          font-size: 20px;
          line-height: 1.5;
          color: #222;
          font-weight: 400;
        }

        .bbp-forum-info .bbp-forum-content {
            font-size: 16px;
            margin: 15px 0 5px;
            padding: 0;
            word-wrap: break-word;
            line-height: 1.63;
            color: #999;
        }

        li.bbp-body ul.forum {
            padding: 0;
        }

        .forum-titles {
          li {
            overflow: hidden;
            position: relative;
            margin-top: -1px;
            font-size: 16px;
            font-weight: 500;
            &:after {
              content:"";
              background: #eee;
              position: absolute;
              top:0;
              right: 0;
              width: 1px;
              height: 10000%;
            }
            &:last-child:after {
              display: none;
            }
          }
        }

        .bbp-body {
          .bbp-topic-reply-count,
          .bbp-topic-voice-count,
          .bbp-forum-topic-count,
          .bbp-forum-reply-count {
              font-weight: 500;
              font-size: 16px;
              padding: 35px 30px;
              text-align: center;
              @media screen and (max-width: 991px) {
                float: left;
              }
          }

          .bbp-topic-title {
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            padding-left: 92px;
            position: relative;;
            min-height: 40px;

            a {
              color:#222;
              font-size: 20px;
              line-height: 1.3;
              padding-bottom: 10px;
            }
            p{
              font-weight: 400;
              padding-top: 10px;
            }
            img {
              position: absolute;
              top: 30px;
              left: 30px;
            }
          }

          .bbp-forum-freshness {
            padding: 33px 30px;
            > a {
              color: #999999;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 15px;
              display: table;
              line-height: 1.3;
              @media screen and (max-width: 991px) {
                margin-bottom: 12px;
              }
            }
            .bbp-author-name {
              color: #222;
              font-size: 14px;
              font-weight: 600;
              line-height: 1.86;
            }
          }

          ul {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            li {
              overflow: hidden;
              position: relative;
              background: #fff;

              &:after {
                content:"";
                background: #eee;
                position: absolute;
                top:0;
                right: 0;
                width: 1px;
                height: 10000%;
              }
              &:last-child:after {
                display: none;
              }
            }
          }
        }

        li.bbp-forum-info,
        li.bbp-topic-title {
        	width: 46%;
        }
        li.bbp-forum-topic-count,
        li.bbp-topic-voice-count,
        li.bbp-forum-reply-count,
        li.bbp-topic-reply-count {
        	width: 13%;
        }

        li.bbp-forum-freshness,
        li.bbp-topic-freshness {
          width: 28%;
          padding: 0 30px;
        }

        p.bbp-topic-meta img.avatar,
        ul.bbp-reply-revision-log img.avatar,
        ul.bbp-topic-revision-log img.avatar,
        div.bbp-template-notice img.avatar,
        .widget_display_topics img.avatar,
        .widget_display_replies img.avatar {
            float: none;
            margin-bottom: 0;
            border: none;
            width: 35px;
            height: 35px;
            margin-right: 8px;
        }
      }
    }

    .sidebar_item {
      font-family: Roboto;
      margin-bottom: 70px;
      font-size: 12px;
      padding-left: 30px;
      @media screen and (max-width: 1280px) {
        padding-left: 0px;
      }
      @media screen and (max-width: 991px) {
        margin-bottom: 50px;
      }

      h2.widget {
        font-weight: 300;
        font-size: 30px;
        color: #333;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
        margin-bottom: 60px;
        line-height: 1.6;
        @media screen and (max-width: 450px) {
          margin-bottom: 25px;
        }
      }

      ul {
        li {
            margin-bottom: 45px;
            padding-left: 60px;
            position: relative;
            min-height: 40px;
            font-size: 16px;

            img.avatar {
              width: 50px;
              height: 50px;
              position: absolute;
              left: 0;
              top: 0;
            }

            &:last-child {
              margin-bottom: 0px;
            }
        }
      }
      .bbp-author-name {
        font-weight: 500;
        color: #999;
        font-size: 16px;
      }

      .bbp-reply-topic-title {
        font-weight: 500;
        color: #000;
        font-size: 16px;

        + div {
          font-style:italic;
          color:#999;
          font-weight: 500;
          margin-top: 6px;
          font-size: 13px;
        }
      }

      .bbp-login-form {
          background-color: #fff;
          box-shadow: 0px 0px 34px 0px rgba(42, 116, 237, 0.09);
          padding: 30px 35px 50px;

          input[type="text"] ,
          input[type="password"] {
            border: 1px solid #f2f2f2;
            height: 47px;
            padding: 0 20px;
            width: 100%;
            border-radius: 4px;
            background-color: #fff;

            &:focus {
                border-width: 0.781px;
                border-color: rgb(42, 116, 237);
                border-style: solid;
                box-shadow: 0px 15px 23px 0px rgba(42, 116, 237, 0.1);
            }
          }

          label:not([for="rememberme"]) {
            width: 100%;
            display: inline-block;
            color: #999;
            font-weight: 500;
            font-size: 16px;
            padding-top: 20px;
          }

          label[for="rememberme"] {
            display: inline-block;
            color: #222;
            font-family: Roboto;
            font-weight: 500;
            font-size: 13px;
            line-height: 3.69;
          }

          .bbp-remember-me {
            padding: 10px 0px;
            input {
              margin-right: 4px;
              position: relative;
              top: 2px;
              width: 17px;
              height: 16px;
              border-radius: 3px;
              border: solid 1px #e0e0e0;
              background-color: #efefef;
            }
          }

          .bbp-submit-wrapper {
            text-align: center;
            float: none;
            padding-top: 10px;

            button {
              background: #2a74ed;
              font-size: 14px;
              font-weight: 500;
              line-height: 38px;
              letter-spacing: 1.4px;
              padding: 6px 45px;
              color: #fff;
              border-radius: 4px;
              border: none;
              text-transform: uppercase;
              font-family: Roboto;
              cursor: pointer;
              box-shadow: 1.2px 3.8px 15.7px 2.3px rgba(42, 116, 237, 0.2);

              &:hover {
                background: #2668d3;
                color:#fff;
              }
            }
          }
      }

      .social-menu {
        a {
          background: #fff;
          border: 1px solid #eeeeee;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
          color: #2a74ed;
          font-size: 16px;
          text-align: center;
          line-height: 50px;
          -webkit-transition: 0.3s all;
          transition: 0.3s all;
          @media screen and (max-width: 1024px) {
            width: 40px;
            height: 40px;
            font-size: 14px;
            line-height: 40px;
          }
          &:hover {
            background:#2a74ed;
            border: 1px solid #2a74ed;
            color:#fff;
            transition: 0.3s all;
          }
        }
      }
    }
  }

  .aheto-titlebar {
    font-family: Roboto;

    &__input form input[type=submit] {
      background: #222;
    }

    &__title {
      color:#fff;
      font-weight: 300;
      text-align: center;
      font-size: 40px;
      margin: 0;
      line-height: 1.3;

      span {
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin: 15px 0 -15px;
      }
    }
  }

  &__detail {
    background: #fff;
    font-family: Roboto;

    &.single {

      h1.entry-title {
          margin: 0 0 50px;
      }

      #bbpress-forums {
        li.bbp-header {
          overflow: hidden;
          background: #f6f9ff;
          margin-top: -1px;
          display: flex;

          div.bbp-reply-author {
            text-align: center;
            padding: 0 30px;
            @media screen and (max-width: 767px) {
              display: none;
            }
          }

          div.bbp-reply-content {
            padding: 0px 40px;
          }
        }

        div.bbp-reply-author {
            width: 20%;
            padding: 40px 20px;
            font-size: 16px;
            @media screen and (max-width: 767px) {
              padding: 20px 10px;
              width: 100%;
            }

            .bbp-author-avatar {
              width: 70px;
              height: 70px;
              display: table;
              margin: 0 auto;
              @media screen and (max-width: 767px) {
                width: 50px;
                height: 50px;
                margin-right: 20px;
                float: left;
              }
              img {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                @media screen and (max-width: 767px) {
                  width: 50px;
                  height: 50px;
                }
              }
            }

            .bbp-author-name {
              color: #222;
              font-size: 16px;
              font-weight: 500;
              display: inline-block;
              width: 100%;
              text-align: center;
              padding-top: 20px;
              @media screen and (max-width: 767px) {
                padding-top: 0px;
                width: calc(100% - 70px);
                text-align: left;
              }
            }

            .bbp-author-role {
              font-size: 14px;
              color: #999;
              display: inline-block;
              width: 100%;
              text-align: center;
              padding-top: 5px;
              font-style: italic;
              @media screen and (max-width: 767px) {
                padding-top: 0px;
                width: calc(100% - 70px);
                text-align: left;
              }
            }
        }

        li.bbp-body {
          div.hentry {
            padding: 0;
            border-top:1px solid #eee;
            display: flex;
            @media screen and (max-width: 767px) {
              flex-direction: column;
              display: block;
            }
          }

          div.bbp-reply-header {
              clear: inherit;
              background: transparent;
              border: none;
              padding: 0 0 30px;
              font-size: 13px;
              font-style: italic;
              color: #999;

              .bbp-reply-permalink {
                padding-right: 1px;
                color: #999;
                float: right;
                font-size: 13px;
                font-style: italic;
              }

              + p {
                margin-top:0px;
              }
          }
        }

        div.bbp-reply-content {
          width: 80%;
          padding: 40px 40px 10px;
          border-left:1px solid #eee;
          @media screen and (max-width: 767px) {
            width: 100%;
            border-left: none;
            padding: 0px 15px;
          }
          img{
            width: 100%;
          }

          p {
            margin: 20px 0 35px;
            font-size: 16px;
            line-height: 1.63;
            color: #999;
          }
        }
      }
    }

    .forum .sidebar_item h2.widget {
        border-bottom: 1px solid #eee;
    }

    h1.entry-title {
      font-size: 40px;
      font-weight: 300;
      color:#222;
      margin: -10px 0 15px;
      line-height: 1.3;
    }

    .single-forum-description {
      color:#999999;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 50px;
    }

    #bbpress-forums {
      li.bbp-body ul.forum,
      li.bbp-body ul.topic {
        padding: 0;
      }

      .bbp-forum-info .bbp-forum-content,
      p.bbp-topic-meta {
          margin: 5px 0 0;
      }
    }

    .bbp-pagination-count {
        font-size: 16px;
        color: #999;
        margin: 10px 0 14px;
    }

    .bbp-template-notice {
        background: #f9f9f9;
        border-radius:0px;
        border:none;
        border-top:1px solid #2a74ed;
        padding: 15px 15px 20px;
        margin-bottom: 0;

        p {
          margin: 0 !important;
          padding: 0;
          font-size: 16px;
          line-height: 1.2;
          color: #222;

          i {
              color:#2a74ed;
              font-size: 19px;
              margin-top: -9px;
              display: inline-block;
              margin-right: 4px;
              position: relative;
              top: 2px;
          }
        }
    }
  }


}
.forum--content{
  .container-form{
    @media screen and (min-width: 1281px) {
      max-width: 1650px !important;
      padding: 0px 50px;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 991px) {
  .bbp-topic-meta{
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 768px) {
  .scroll-inner {
    overflow-x: scroll;
    .bbp-forums{
      width: 700px;
    }
    .bbp-topics{
      width: 700px;
    }
  }
}
