.aht-blog-chr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__item {
    flex-basis: 33.33%;
    padding: 0;
  }

  &__item-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 60px 40px 70px 49px;
    background-color: $c-white;
    box-shadow: -30px 0 30px 0 rgba(0, 0, 0, 0.05);
  }

  &__date {
    display: inline-block;
    padding: 10px;
    border-radius: 3px;
    line-height: 14px;
    color: $c-white;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.15em;
    box-shadow: 0 0 32px 0 rgba(207, 38, 50, 0.3);
  }

  &__title {
    width: 100%;
    margin: 20px 0 0;
    font-weight: 700;
    letter-spacing: -0.027em;
    line-height: 40px;
  }

  &__desc-wrap {
    flex: 1 1 0;
    width: 100%;
    margin-top: 20px;
  }

  &__desc {
    margin: 0;
  }

  .aht-blog-chr__link {
    margin-top: 31px;
    line-height: normal;
    letter-spacing: 1.4px;
  }
}

.blog--church-wrap{
  .reset-margin{
    margin-left: 0;
    margin-right: 0;
  }

  .reset-padding{
    padding-left: 0;
    padding-right: 0;
  }

  .blog--church-title{
    height: 100%;
    padding: 115px 100px 130px;
    text-align: right;
  }
}

.church-news-blog-wrap {
  .church-news-blog-btn {
    .aheto-btn-container {
      .aheto-btn {
        border-color: #eeeeee;
      }
    }
  }

  .aht-blog-chr {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    margin-bottom: -30px;

    &__item {
      padding: 0 15px;
      margin-bottom: 30px;
    }


    &__item-inner {
      padding: 66px 45px 42px 49px;
      box-shadow: -12.5px 14.3px 92px 0 rgba($c-black, 0.05);
    }
  }
}

@media screen and (max-width: 1350px) {
  .blog--church-wrap {
    .blog--church-title {
      padding: 100px 70px;
    }
  }

  .aht-blog-chr {
    
    &__item-inner {
      padding: 30px 40px 30px 40px;
    }

    &__title {
      margin: 15px 0 0;
      line-height: 30px;
    }

    &__desc-wrap {
      margin-top: 15px;
    }

    .aht-blog-chr__link {
      margin-top: 20px;
    }
  }
}


@media screen and (max-width: 778px) {
  .church-news-blog-wrap {
    .aht-blog-chr {
      &__item {
        flex-basis: 50%;
      }
    }
  }
}


@media screen and (max-width: 450px) {
  .blog--church-wrap {
    .blog--church-title {
      padding: 50px 70px;
    }
  }

  .aht-blog-chr {
    &__item {
      flex-basis: 100%;
      margin-bottom: 0;
    }
  }

  .church-news-blog-wrap{
    .aht-blog-chr {
      &__item {
        flex-basis: 100%;
        margin-bottom: 0;
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  .church-news-margin-blog {
    margin-top: 130px;
  }

  .church-news-margin-blog-b{
    margin-bottom: 130px;
  }

  .church-ministries-margin-blog{
    padding-top: 130px;
  }
}



