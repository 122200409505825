.aheto-titlebar {
  display: flex;
  position: relative;
  flex-wrap: wrap;


  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
	margin: 0;

	b {
	  font-weight: inherit;
	}
  }

  p {
	&.mrgn-top {
	  margin-top: 15px;
	}
  }

  &__subtitle {
	letter-spacing: normal;
  }

  &__text {
	.aheto-titlebar__subtitle {
	  margin-bottom: 5px;
	}
  }

  &__title {
	&.large-size {
	  font-size: 60px;

	  @include small-size-max {
		font-size: 50px;
	  }
	}
  }

  &__info {
	flex: 2;
	padding: 100px 50px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	@include large-size-max {
	  flex-basis: 100%;
	}
  }

  &__info-text {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
  }

  // Elements
  &__main {
	display: flex;
	position: relative;
	flex: 7;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 110px 0 100px;
	background-repeat: no-repeat;
	background-position: center;
  	background-size: cover;
    height: 100%;

	@media screen and (max-width: 991px) {
		padding: 70px 0;
	}
	  @media screen and (max-width: 768px) {
		  padding: 45px 0;
	  }
  }

  &__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $c-black;
	opacity: 0.6;
	&--1 {
	  opacity: 0.1;
	}
	&--2 {
	  opacity: 0.2;
	}
	&--3 {
	  opacity: 0.3;
	}
	&--4 {
	  opacity: 0.4;
	}
	&--5 {
	  opacity: 0.5;
	}
	&--6 {
	  opacity: 0.6;
	}
	&--7 {
	  opacity: 0.7;
	}
	&--8 {
	  opacity: 0.8;
	}
	&--9 {
	  opacity: 0.9;
	}
  }

  &__content {
	display: flex;
	position: relative;
	flex: 1;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	padding: 0 15px;
  }

  &__text {
	flex: 1;
  }

  &__input {
	flex-basis: 100%;
	margin-bottom: auto;

	form {
	  display: flex;
	  flex-wrap: wrap;
	  border-radius: 5px;
	  overflow: hidden;

	  label {
		display: none;
	  }
	  input {
		&[type=text] {
		  flex: 1;
		  padding: 21px 27px;
		  border: 0;
		  outline: none;
		  background-color: $c-white;
		}

		&[type=submit] {
		  padding: 20px 55px;
		  border: 0;
		  outline: none;
		  color: $c-white;
		  font-weight: 500;
		  letter-spacing: normal;
		  text-transform: uppercase;
		  cursor: pointer;

		  @include xs-size-max {
			flex-basis: 100%;
		  }
		}
	  }
	}
  }

  &__breadcrumbs {
	width: 100%;
	max-width: 1200px;
	height: 0;
	padding: 0 15px;
	position: relative;

	&.t-white {
	  a {
		color: $c-white;
	  	font-size: 15px;
		&:hover {
		  color: fade-out($c-white, 0.5)
		}
	  }

	  li {
		color: $c-white;

		&.current {
		  &:before {
			color: $c-white;
		  }
		}

		&:before {
		  color: $c-white;
		}
	  }
	}


	ul {
	  margin: 0;
	  padding-left: 0;
	}

	li {
	  display: inline-block;
	  margin-right: 10px;
	  padding-left: 0;
		font-size: 15px;

	  &:first-child {
		&:before {
		  content: none;
		}
	  }

	  &:last-child {
		margin-right: 0;
		  color: #999;
	  }

	  &:before {
		position: static;
		margin-right: 15px;
		content: '\f105';
		font-family: Fontawesome;
		font-size: 12px;
	  }
	}
  }

  &__arrow-down {
	position: absolute;
	bottom: 15px;
	left: 50%;
	transform: translate(-50%, 0);
	cursor: pointer;

	i {
	  color: $c-white;
	  font-size: 36px;
	}
  }

  .w-1200 {
	max-width: 1200px;
	margin: 0 auto;
  }
  .w-1100 {
	max-width: 1100px;
	margin: 0 auto;
  }
  .w-1000 {
	max-width: 1000px;
	margin: 0 auto;
  }
  .w-900 {
	max-width: 900px;
	margin: 0 auto;
  }
  .w-800 {
	max-width: 800px;
	margin: 0 auto;
  }
  .w-700 {
	max-width: 700px;
	margin: 0 auto;
  }
  .w-600 {
	max-width: 600px;
	margin: 0 auto;
  }

  // Modificators
  &--height-full {
	height: 100vh;
  }

	&--height-730 {
		height: 730px;
		min-height: 730px;

		.swiper-wrapper {
			height: 730px;
			min-height: 730px;
		}
	}

  &--height-600 {
	height: 600px;
	min-height: 600px;

	.swiper-wrapper {
	  height: 600px;
	  min-height: 600px;
	}
  }

  &--height-550 {
	height: 550px;
	min-height: 550px;

	.swiper-wrapper {
	  height: 550px;
	  min-height: 550px;
	}
  }

  &--height-500 {
	height: 500px;
	min-height: 500px;

	.swiper-wrapper {
	  height: 500px;
	  min-height: 500px;
	}
  }

  &--height-450 {
	height: 450px;
	min-height: 450px;

	.swiper-wrapper {
	  height: 450px;
	  min-height: 450px;
	}
  }

  &--height-400 {
	height: 400px;
	min-height: 400px;

	.swiper-wrapper {
	  height: 400px;
	  min-height: 400px;
	}
  }

  &--height-350 {
	height: 350px;
	min-height: 350px;

	.swiper-wrapper {
	  height: 350px;
	  min-height: 350px;
	}
  }

  &--height-300 {
	height: 300px;
	min-height: 300px;

	.swiper-wrapper {
	  height: 300px;
	  min-height: 300px;
	}
  }

  &--height-250 {
	height: 250px;
	min-height: 250px;

	.swiper-wrapper {
	  height: 250px;
	  min-height: 250px;
	}

	.aheto-titlebar__main {
	  padding: 15px 0;
	}

	.aheto-titlebar__breadcrumbs {
	  height: auto;
	}
  }

  &--height-200 {
	height: 200px;
	min-height: 200px;

	.swiper-wrapper {
	  height: 200px;
	  min-height: 200px;
	}

	.aheto-titlebar__main {
	  padding: 15px 0;
	}

	.aheto-titlebar__breadcrumbs {
	  height: auto;
	}
  }

  &--height-150 {
	height: 150px;
	min-height: 150px;

	.swiper-wrapper {
	  height: 150px;
	  min-height: 150px;
	}

	.aheto-titlebar__main {
	  padding: 15px 0;
	}

	.aheto-titlebar__breadcrumbs {
	  height: auto;
	}
  }
	&--med_height-200 {
		@media screen and (max-width: 991px) {
			height: 200px;
			min-height: 200px;
		}
	}
	&--mob_height-150 {
		@media screen and (max-width: 768px) {
			height: 150px;
			min-height: 150px;
		}
	}
	&--mob_height-250 {
		@media screen and (max-width: 991px) {
			height: 250px;
			min-height: 250px;
		}
	}
  // Intro block
  &__intro {
	position: absolute;
	bottom: 0;
	left: 50%;
	padding: 12px 57px;
	transform: translate(-50%, 50%);
	text-align: center;

	> * {
	  letter-spacing: 1.5px;
	}

	@include small-size-max {
	  left: 0;
	  width: 100%;
	  transform: translate(0, 50%);
	}
  }

  &--yoga {

	h1 {
	  font-size: 80px;
	  letter-spacing: 2px;

	  @include medium-size-max {
		font-size: 60px;
	  }

	  @include small-size-max {
		font-size: 40px;
	  }
	}

	li {
	  margin-right: 12px;
	  font-size: 15px;
	  font-weight: 500;

	  &:before {
		margin-right: 12px;
		font-size: 12px;
	  }
	}

	.aheto-titlebar {

	  &__breadcrumbs {

		&.t-right {
		  @include medium-size-min {
			padding-right: 155px;
		  }
		}

		&.t-center {
		  height: auto;
		}

		a {
		  font-size: 15px;
		  font-weight: 500;
		}
	  }
	}
  }
	&--bussines{
		.aheto-titlebar__overlay {
			background-color: #CF2632;
		}
	}
	&--political{
		.aheto-titlebar{
			&__subtitle{
				font-family: $f-playfair-display;
				font-size: 24px;
				font-weight: bold;
			}
		}
		h2{
			letter-spacing: 2.5px;
			@include small-size-max{
				line-height: normal;
			}
		}
	}
	&--edu {
		.aheto-titlebar__main {
			padding-top: 55px;
			padding-bottom: 55px;
		}

		@media screen and (max-width: 768px) {
			.aheto-titlebar__main {
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
	}

	&--restaurant {
		.aheto-titlebar {
			&__content {
				z-index: 1;
			}
			&__title {
				text-transform: capitalize;
				letter-spacing: 0.6px;
				-webkit-font-smoothing: antialiased;
				@include xs-size-max {
					font-size: 40px;
				}
			}
			&__subtitle {
				letter-spacing: 1.8px;
				font-size: 18px;
				margin-bottom: 15px;
			}
		}
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

  &--restaurant-reservations {
    .aheto-titlebar {
      &__main {
        padding: 80px 0 160px;
      }
    }
  }

}

.padd-banner{
	padding: 250px 0 165px;
}

.aheto-titlebar--yoga {
	.aheto-titlebar__overlay  {
		background: linear-gradient(36deg, #000 0%, #ffd145 100%);
	}

	@include small-size-max {
		.aheto-titlebar__text,
		.testbreadcrumbs  {
			text-align: center;
		}
	}
}

.about-yoga {
  img {
    max-width: 660px;
  }

  @include small-size-max {
  	img {
  		margin-top: 80px !important;
  	}
  }
}
