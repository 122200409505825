.aheto-pf {
  $p: &;

  &__filter-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__filter {
    margin: 10px 25px;
    transition: color 0.3s;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    &:first-child {
      margin-right: auto;
      margin-left: 15px;
    }

    &:last-child {
      margin-right: 15px;
    }

    @include small-size-max {
      margin: 10px 15px;

      &:first-child {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
  }

  &__modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 70px;
    background-color: rgba($c-black, 0.9);
    overflow: auto;
    z-index: 999;

    @include small-size-max {
      padding: 70px 15px;
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0)
    }
    to {
      transform: scale(1)
    }
  }

  &__modal-content {
    display: block;
    min-width: 1px;
    max-width: 100%;
    height: auto;
    min-height: 0;
    max-height: 100%;
    margin: auto;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 35px;
    transition: 0.3s;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }

  &__ajax-pag {
    display: none;
    @media screen and (max-width: 991px) {
      margin-top: 60px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }

    &.loading {
      display: block;
    }
  }

  &__ajax-loading {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 24px;

    i {
      display: inline-block;
      margin-right: 15px;
      font-size: 24px;
      vertical-align: middle;
      animation: rotate 1s infinite;
      animation-timing-function: linear;

      &:before {
        position: relative;
        left: 1px;
        width: 24px;
        height: 24px;
      }

      @keyframes rotate {
        from {
          transform: rotate(-360deg);
        }
        to {
          transform: rotate(0deg)
        }
      }
    }
  }

  &__content {
    // &--barbershop {
    //   #{$p}__cat-wrap {
    //     margin: 18px 0;
    //     color: rgba($c-alter, 0.7);
    //     font-family: $f-open-sans;
    //     font-size: 15px;
    //   }
    //   #{$p}__title {
    //     font-size: 21;
    //     letter-spacing: 1.7px;
    //     text-transform: uppercase;
    //   }
    //   #{$p}__text {
    //     position: relative;
    //     z-index: 2;
    //   }
    // }
  }

  &__text {
    &--full {
      width: 100%;
      height: 100%;
    }

    &--center {
      text-align: center;
    }

    &--column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  &--metro {
    overflow: hidden;

    .masonry_hover {
      display: none;
    }

    .aheto-pf {

      &__container {
        margin-top: 20px;
        margin-bottom: 80px;
        @media screen and (max-width: 991px) {
          margin-bottom: 50px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }

      &__content {
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }

      &__item {
        width: 25%;
        height: 440px;
        float: left;
        border: 15px solid transparent;

        &--height {
          height: 880px;
        }

        &--width {
          width: 50%;
        }

        @media screen and (max-width: 1500px) {
          height: 350px;

          &--height {
            height: 700px;
          }
        }

        @include large-size-max {
          width: 50%;
          height: 480px;

          &--height {
            height: 480px;
          }

          &--width {
            width: 50%;
          }
        }

        @include medium-size-max {
          height: 380px;

          &--height {
            height: 380px;
          }
        }

        @include small-size-max {
          width: 100%;
          height: 480px;

          &--height {
            height: 480px;
          }

          &--width {
            width: 100%;
          }
        }

        @include xs-size-max {
          height: 380px;

          &--height {
            height: 380px;
          }
        }
      }

      &__content {
        position: relative;
        width: 100%;
        height: 100%;

        &:hover {

          &:before,
          .aheto-pf__text {
            opacity: 1;
          }

          .aheto-pf__title {
            top: 0;
          }

          .aheto-pf__cat-wrap {
            top: 0;
          }
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: opacity 0.3s;
          content: '';
          opacity: 0;
        }

        &--barbershop {
          #{$p}__img {
            & > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;

        img {
          width: 0;
          height: 0;
        }
      }

      &__text {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 42px 40px;
        transition: opacity 0.3s;
        opacity: 0;
      }

      &__title {
        display: block;
        position: relative;
        top: 30px;
        transition: top 0.3s;
        color: $c-white;
        font-size: 20px;
      }

      &__cat-wrap {
        display: block;
        position: relative;
        top: -30px;
        margin-top: 5px;
        transition: top 0.3s;
      }

      &__cat {
        display: inline-block;
        margin-right: 5px;

        p {
          margin: 0;
          font-size: 15px;
        }
      }
    }
  }

  &--grid {

    .aheto-pf {

      &__container {
        margin-top: 30px;

        @include medium-size-max {
          margin-top: 0px;
        }

      }

      &__item {
        width: 50%;
        height: 430px;
        float: left;
        border: 15px solid transparent;


        @include large-size-max {
          height: 380px;
        }

        @include medium-size-max {
          height: 300px;
        }

        @include small-size-max {
          width: 100%;
          height: 400px;
        }

        @include xs-size-max {
          height: 300px;
        }
      }

      &__content {
        position: relative;
        width: 100%;
        height: 100%;
        transition: box-shadow 0.3s;
        overflow: hidden;

        &:hover {
          .aheto-pf__text {
            transform: translate(0, 0);
          }
        }
      }

      &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;

        img {
          width: 0;
          height: 0;
        }
      }

      &__text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 32px 40px 37px;
        transform: translate(0, 100%);
        transition: transform 0.3s;
        background-color: $c-white;
      }

      &__title {
        margin: 0;
        font-size: 20px;
      }

      &__cat-wrap {
        display: block;
        margin-top: 8px;
      }

      &__cat {
        display: inline-block;
        margin-right: 5px;

        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }
    &-political{
      .aheto-pf{
        &__container {
          margin-top: 0px;
          @include small-size-max {
            margin-top: 10px;
          }
        }
        &__title {
          color: #0536a5;
        }
        &__filter{
          font-family: 'Lato';
          font-size: 16px;
          font-weight: bold;
          line-height: 4.38;
          @include small-size-max {
            line-height: 1;
          }
        }
      }
    }
  }

&--masonry {

    .aheto-pf {

      &__container {
        margin-top: 25px;
        @include small-size-max {
          margin-top: 0px;
        }
      }

      &__item {
        width: 33.33%;
        float: left;
        border: 15px solid transparent;

        @include small-size-max {
          width: 100%;
        }

      }

      &__content {
        position: relative;
        width: 100%;
        height: 100%;

      }

      &__img {
        position: relative;

        &:hover {
          .masonry_hover {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: auto;
        }

        .masonry_hover {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 124px 122px;
          border-color: transparent transparent #2A74ED transparent;
          position: absolute;
          right: 0px;
          bottom: 0px;
          opacity: 0;
          transition: opacity .3s ease-in-out;
          cursor: pointer;

          i {
            color: #ffffff;
            font-size: 24px;
            color: #ffffff;
            font-size: 24px;
            position: absolute;
            left: -45px;
            top: 70px;
          }
        }
      }

      &__text {
        padding: 20px 0 20px;
        @media screen and (max-width: 991px) {
          padding: 20px 0 0px;
        }
      }

      &__title {
        font-size: 20px;
      }

      &__cat-wrap {
        display: block;
        margin-top: 3px;
      }

      &__cat {
        display: inline-block;
        margin-right: 5px;

        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }

&--new {

    .aheto-pf {

      &__container {
      }

      &__item {
        display: flex;
        width: 50%;
        min-height: 680px;
        float: left;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;

        &:nth-child(4n + 1),
        &:nth-child(4n + 4) {
          align-items: center;

          .aheto-pf__img {
            height: 400px;
          }
        }

        &:nth-child(4n + 2) {
          justify-content: flex-end;

          .aheto-pf__content {
            flex-basis: 400px;
          }
        }

        &:nth-child(4n + 3) {
          .aheto-pf__content {
            flex-basis: 400px;
          }
        }

        @include small-size-max {
          width: 100%;
          min-height: 0;
          margin-bottom: 20px;

          &:nth-child(4n + 2),
          &:nth-child(4n + 3) {
            .aheto-pf__content {
              flex-basis: 100%;
            }
          }

          .aheto-pf__text {
            padding-top: 25px;
            padding-bottom: 25px;
          }
        }

        @include xs-size-max {
          .aheto-pf__img {
            height: 400px;
          }

          &:nth-child(4n + 1),
          &:nth-child(4n + 4) {
            align-items: center;

            .aheto-pf__img {
              height: 280px;
            }
          }
        }
      }

      &__content {
        flex-basis: 100%;
      }

      &__img {
        position: relative;
        height: 570px;
        background-position: center;
        background-size: cover;
        cursor: pointer;

        img {
          visibility: hidden;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }

        &:before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 70px;
          height: 70px;
          transform: translate(-50%, -50%);
          transition: opacity 0.3s;
          border-radius: 50%;
          background-color: $c-white;
          font-family: $f-ionicons;
          font-size: 24px;
          line-height: 70px;
          text-align: center;
          content: '\f2f5';
          opacity: 0;
        }
      }

      &__text {
        padding: 30px 15px 0;
        text-align: center;
      }

      &__title {
        font-size: 30px;
        font-weight: 300;
      }

      &__cat {
        display: inline-block;
        margin-right: 5px;

        p {
          margin: 0;
        }
      }
    }
  }

&--construction {
    .aheto-pf {
      &__filter-wrap {
        justify-content: center;
      }

      &__filter {
        position: relative;
        font-weight: bold;
        font-size: 16px;

        &:first-child {
          @include medium-size-max {
            flex-basis: auto;
          }
          margin-right: 25px;
        }

        &.active {
          color: #222222;

          &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            bottom: -11px;
            left: 0;
          }
        }
      }
    }
  }

.aheto-pf__filter-wrap__construction {
    .aheto-pf {
      &__filter {
        font-size: 16px;
        font-weight: 600;
        &.active {
          //color: $c-active;
        }
      }
    }
  }








  &--grid-restaurant {
    .aheto-pf {
      &__filter-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
          @include medium-size-max {
            flex-direction: column;
          }
      }
      &__filter-wrap {
        @include medium-size-max {
          justify-content: center;
        }
      }
      &__filter-search {
        margin-right: 20px;
        position: relative;
        input {
          box-sizing: border-box;
          border: 1px solid #eee;
          width: 270px;
          height: 44px;
          padding: 2px 50px 0 10px;
          color: #222;
          &:focus {
            border: 1px solid #22222280;
          }
        }
        @include medium-size-max {
          margin-top: 10px;
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
      &__filter-search:after {
        content: '\f2f5';
        position: absolute;
        font-family: $f-ionicons;
        top: 4px;
        right: 20px;
        color: #222;
        opacity: 0.5;
        font-size: 24px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
      }
      &__filter {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        letter-spacing: 1.8px;
        margin: 10px 25px;
        &:first-child {
          margin: 10px 25px;
        }
        &.active {
          font-weight: 700;
        }

      }
      &__container {
        margin-top: 5px;
      }
      &__content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &:hover .aheto-portfolio-single-item__cover {
          //opacity: 1;
        }
        &:before {

        }
      }
      &__text {
        width: inherit;
        display: flex;
        align-items: center;
        padding: 52px;
        flex-wrap: wrap;
        @include large-size-max {
          padding: 40px;
        }
      }
      &__title {
        font-family: $f-playfair-display;
        font-size: 30px;
        font-weight: 700;
        margin-right: auto;
        font-style: italic;
        -webkit-font-smoothing: antialiased;
        margin-top: 20px;

      }
      &__buttons-wrap {
        display: flex;
        align-items: center;
        margin-top: 20px;
      }


      &__zoom-button,
      &__like-button,
      &__share-button {
        width: 28px;
        height: 28px;
        display: block;
        margin: 0 10px;
      }
      &__share-button {
        width: 30px;
        height: 30px;
      }

      &__zoom-icon,
      &__like-icon,
      &__share-icon {
        display: inline-block;
        width: inherit;
        background-size: cover;
        //filter: invert(100%) sepia(0%) saturate(7479%) hue-rotate(179deg) brightness(119%) contrast(100%);
        stroke: #fff;
        opacity: 0.5;
      }

      &__zoom-icon:hover,
      &__like-icon:hover,
      &__share-icon:hover,
      &__zoom-icon:focus,
      &__like-icon:focus,
      &__share-icon:focus{
        opacity: 1;
        cursor: pointer;
      }

    }
  }

}

.construction-projects-wrap {
  .aheto-pf {
    &__content {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &:hover .aheto-portfolio-single-item__cover {
        opacity: 1;
      }
    }

    .aheto-portfolio-single-item {
      &__cover {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.3s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      }
      &__cover:hover {
        opacity: 1;
      }
      &__cover-icon {
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        line-height: 1;
        &:before {
          font-family: 'ElegantIcons';
          content: '\55';
          font-size: 20px;
        }
      }
    }
  }

  .aheto-pf__text {
    .aheto-pf__cat {
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.portfolio {
  &-new {
    .w-100 {
      width: auto;
    }

    .aheto {
      &-single-img {
        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: center;
        }
        @include large-size-max {
          padding-left: 15px;
        }
        @include xs-size-max {
          padding-left: 0px;
        }
      }

      &-heading {
        padding-left: 15px;

        &__desc {
          font-size: 14px;
          font-weight: bold;
        }

        &__title {
          @include xs-size-max {
            font-size: 38px;
            padding-bottom: 60px;
            position: relative;
            b {
              position: absolute;
              left: 0px;
              bottom: 10px;
            }
          }

          @include medium-size-max {
            br {
              display: none;
            }
          }
        }
      }
    }
  }
}

.portfolio-nav__dir--prev {
  width: 33.333%;
  display: flex;
  justify-content: center;
  border-right: 1px solid #eeeeee;
  height: 100%;
  align-items: center;
}

.portfolio-nav__list {
  width: 33.333%;
  display: flex;
  justify-content: center;
}

.portfolio-nav__dir--next {
  width: 33.333%;
  display: flex;
  justify-content: center;
  border-left: 1px solid #eeeeee;
  height: 100%;
  align-items: center
}

.detail_text {
  max-width: 570px !important;
  @media screen and (max-width: 991px) {
    max-width: 100% !important;
    padding: 0px 30px !important;
  }
}

.single {
  &__portfolio {
    &--3,
    &--4,
    &--5 {

      .aheto-single-img {
        position: relative;

        .overlay {
          background: fade-out($c-white, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: 0.3s all;

          &:hover {
            opacity: 1;
            transition: 0.3s all;
          }

          p {
            margin: 0;
            padding: 20px;
            color: $c-black;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      blockquote:before {
        top: -60px;
        font-size: 145px;
      }

      .aheto-blockquote__title {
        font-style: italic;
      }

      .aheto-single-img {
        &__img--h360 {
          @include medium-size-max {
            height: 220px;
          }
        }

        &__img--h520 {
          @include medium-size-max {
            height: 220px;
          }
        }
      }
    }

    &--4 {
      .bg-wrap-c-dark {
        .aheto-heading {
          padding: 0 100px;

          @include small-size-max {
            padding: 100px 50px;
          }

          p {
            margin-top: 18px;
          }
        }
      }

      @include medium-size-max {
        .aheto-single-img__img--h740 {
          height: 300px;
        }

        .aheto-single-img__img--h900 {
          height: 578px;
        }
      }
    }

    &--2 {
      .aheto-heading {
        p {
          margin-top: 10px;
        }
      }
    }

    &--7 {
      .aheto {
        &-heading {
          &__desc {
            margin-top: 20px;

            @include small-size-max {
              margin: 10px 0 20px;
            }
          }
        }
      }

      @include large-size-max {
        .aheto-socials--circle .aht-socials__link {
          width: 47px;
          height: 47px;
          line-height: 47px;
        }
      }

      .col-lg-8.offset-lg-1 {
        @include large-size-max {
          position: relative;
          margin: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0 30px;
        }
      }

      .retreat--0.container-fluid {
        > .row > [class*="col-md-6"]:last-child {
          padding: 0 15px;
        }
      }
    }
  }
}

.margin-lerge_30t {
  @media screen and (max-width: 1500px) {
    margin-top: 30px;
  }
}

.portfolio--load-more {
  cursor: pointer;

  img {
    height: 14px;
    margin-top: -2px;
    margin-left: -16px;
    opacity: 0;
  }
}

.load {
  img {
    opacity: 1;
  }
}

.portfolio-new {
  .masonry_hover {
    display: none;
  }
}

.font-playfair {
  font-family: $f-playfair-display;
  font-weight: 700;
}

.hide {
  display: none;
}

.aheto-pf__ajax-pag.loading.hide {
  display: none;
}

.aheto-single-img__img--h900 {
  @include large-size-max {
    height: auto !important;
  }
}

// church gallery
.church-gallery-wrap {
  .aheto-pf {
    &__filter {
      font-size: 16px;
      font-weight: bold;

    }

    &__ajax-loading {
      letter-spacing: 1.4px;
      font-family: Roboto;
      margin-top: 75px;
      padding-bottom: 8px;

      @media screen and (max-width: 991px) {
        margin-top: 50px;
      }

      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }
    }

    &--metro {
      .aheto-pf {
        &__container {
          margin-top: 16px;
          // margin-bottom: 75px;
          margin-bottom: 0;

          @media screen and (max-width: 991px) {
            margin-bottom: 0;
          }

          @media screen and (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        &__title {
          font-size: 24px;
          letter-spacing: -0.6px;
        }

        &__cat-wrap {
          margin-top: 0;
        }

        &__cat {
          p {
            font-family: Roboto;
            font-size: 12px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}







@media screen and (min-width: 1200px) {
  .margin-church-gallery-b {
    margin-bottom: 113px;
  }

  .margin-church-gallery-t {
    margin-top: 127px;
  }

  .padding-church-gallery-b{
    padding-bottom: 120px;
  }
}
