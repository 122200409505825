.edu {
  &.aht-page {

    &--dual-sb {

      .aht-page {
        &__inner {
          display: flex;
          max-width: 1665px;
          margin: 0 auto;
          padding-top: 100px;
          padding-bottom: 100px;

          @include large-size-max {
            flex-wrap: wrap;
          }

          @include medium-size-max {
            padding-top: 70px;
            padding-bottom: 70px;
          }

          @include small-size-max {
            flex-direction: column;
            padding-top: 40px;
            padding-bottom: 40px;
          }
        }

        &__sb {
          flex: 1 1 19%;
          min-width: 330px; 

          // @include large-size-max {
          //   margin-bottom: 0px;
          // }
          @include medium-size-max {
            margin-bottom: 50px;
          }
          @include small-size-max {
            margin-bottom: 40px;
          }
        }

        &__sb-inner {
          padding: 0 15px;
        }

        &__content {
          @include large-size-max {
            flex-basis: 100%;
            order: 2;
          }
        }

        &__content-inner {
          width: 100%;
          padding: 0 15px;

          // @include large-size-min {
          //   max-width: 1000px;
          // }
        }
      }
    }
    .aht-sb {
      &-edu {
        @media screen and (min-width: 1230px) {
          .d-xl-block {
            display: block;
          }
        }
      }
    }

    &--right-sb,
    &--left-sb {
      overflow: hidden;

      .aht-page {
        &__inner {
          display: flex;
          max-width: 1430px;
          margin: 0 auto;

          @include medium-size-max {
            flex-direction: column;
          }
        }

        &__sb {
          position: relative;
          width: 360px;
          padding-top: 0px;
          padding-bottom: 0px;

          @include large-size-max {
            width: 330px;
          }

          @include medium-size-max {
            order: -1;
            width: auto;
          }

          @media screen and (min-width: 1500px) {
            width: 400px;
          }
        }

        &__sb-inner {
          position: relative;
          padding: 0 10px;

          // @include medium-size-max {
          //   padding: 0 15px;
          // }
        }

        &__content {
          flex: 1 1 0;
          padding-top: 0px;
          padding-bottom: 0px;
        }

        &__content-inner {
          margin: 0 15px;
          padding: 0 15px;

          @include medium-size-max {
            margin: 0;
          }
        }
      }
    }

    &--right-sb {
      .aht-page {
        &__sb {

          @media screen and (min-width: 1500px) {
            padding-left: 40px;
          }

          &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            background-color: transparent;
            content: '';
          }
        }
      }
    }
  } 
}
