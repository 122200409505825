.aht-blog-bsns {

  @include small-size-max {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  @include xs-size-max {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  img {
    width: 100%;
  }

  &__item {
    position: relative;
    background-color: $c-white;
    margin-bottom: 30px;
    width: calc(33.333% - 20px);
    @include medium-size-max {
      width: calc(50% - 15px);
    }
    @include small-size-max {
      width: 100%;
    }
    &--gallery{
      .aht-blog-bsns__img {
        width: 100%;
        height: 200px;
        background-size: cover;
        @include medium-size-max {
          height: 300px;
        }
        @include xs-size-max {
          height: 200px;
        }
      }
    }
    &--quote {
      background-color: #222;
      .aht-blog-bsns__bq{
        &:before{
          content: '”';
          position: absolute;
          font-family: 'Times New Roman';
          font-size: 180px;
          font-weight: bold;
          top: 67px;
          right: 50px;
          color: #3f3e3e;
          line-height: 120px;
          font-style: normal;
        }
      }
      .aht-blog-bsns__bq-content{
        padding: 125px 35px 52px;
      }
    }
    &--empty {
      padding-top: 40px;
    }
  }

  &__imgs {
    width: 100%;
  }

  &__img {
    width: 100%;
  }
  .swiper-button-next,
  .swiper-button-prev{
    color: #FFFFFF;
    &:hover{
      color: #FFFFFF;
    }
  }
  &__cats {

    &--static {
      position: static;
      margin-bottom: 12px;
    }
  }

  &__cat {
    color: #999;
    font-size: 14px;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 30px 45px;
  }

  &__date {
    position: absolute;
    top: 0;
    left: 43px;
    padding: 7px 13px;
    transform: translate(0, -40%);
    color: #fff;
    font-size: 13px;
    letter-spacing: 2px;
    background-color: #CF2632;
    box-shadow: 0px 0px 32px 0px rgba(207, 38, 50, 0.3);
    z-index: 11;
  }

  &__title {
    margin: 15px 0 0;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
  }

  &__desc {
    margin: 17px 0 0;
  }

  &__link {
    display: inline-block;
    margin-top: auto;
    padding-top: 31px;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }


  // Video
  &__video {
    position: relative;
    width: 100%;
  }

  &__video-link {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    outline: none;
    background-color: $c-white;
    line-height: 60px;
    text-align: center;
  }

  &__video-play {
    color: inherit;
  }


  // Audio
  &__audio {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    padding-top: 40px;
  }

  // Quote
  &__bq-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 52px 35px;
  }

  &__bq-holder {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 40px 0 110px;
  }

  &__bq {
    color: #fff;
    font-size: 30px;
    line-height: 40px;
    font-style: italic;
    font-family: "Karla", sans-serif;
  }

  &__author {
    margin: 0;
    color: $c-white;
    font-size: 14px;
    letter-spacing: 1.5px;
    line-height: 26px;
    text-transform: uppercase;
  }
  .aht-blog-bsns__audio {

    .mejs__container {
      background: #222;
      min-height: 60px;
      width: 100% !important;
      max-width: 100%;
    }

    .mejs__horizontal-volume-slider {
      display:none !important;
    }

    .mejs__controls {
      height: 60px;
      padding:0;
    }

    .mejs__button.mejs__volume-button {
      height: 60px;
      margin: 0;
      width: 60px;
      border-left: 1px solid #3f3e3e;
      text-align: center;

      button {
        margin:20px;
        transform: scale(0.8);
      }
    }

    .mejs__button.mejs__playpause-button {
      height: 60px;
      margin: 0;
      width: 60px;
      border-right: 1px solid #3f3e3e;
      text-align: center;

      button {
        margin:20px;
        transform: scale(0.8);
      }
    }

    .mejs__time {
      color: #fff;
      font-size: 11px;
      height: 60px;
      padding: 26px 11px 0;
      font-family: Roboto;
      font-weight: 400;

      &-total {
        background: #ffffff;
        margin: 19px 0 0;
        width: 100%;
        border-radius: 0;
        height: 2px;
      }

      &-handle-content {
        border: none;
        border-radius: 0;
        height: 2px;
        width: 10px;
      }

      &-hovered {
        border-radius: 0;
        height: 2px;
      }

      &-buffering {
        border-radius: 0;
        height: 2px;
        margin-top:1px;
      }

      &-current {
        border-radius: 0;
        height: 2px;
        background: #999 !important;
        margin-top:0px;
      }

      &-loaded {
        display: none;
      }

    }
  }
}
