.authentication {
  &__sign-in,
  &__sign-up {
    padding: 14px 32px;
    border: 0;
    outline: none;
    cursor: pointer;
  }

  &__log-in,
  &__register {
    padding: 5px 10px;
    border: 0;
    outline: none;
    cursor: pointer;
  }
}
