.aheto-map {
  height: 600px;

  @include medium-size-max {
    height: 400px;
  }

  h5 {
    margin: 10px 0;
  }

  p {
    margin: 0;
  }

  // Modificators
  &--margins {
    margin: 0 -15px;
  }

  &--height-full {
    height: 100vh;
  }

  &--height-1000 {
    height: 100%;

    @include medium-size-max {
      height: 400px;
    }
  }
  &--height-770 {
    height: 770px;
  }
  &--height-500 {
    height: 500px;
  }

  &--height-460 {
    height: 460px;
  }

  &--height-430 {
    height: 430px;
  }

  &--height-400 {
    height: 400px;
  }

  &--height-300 {
    height: 300px;
  }

  &--height-200 {
    height: 200px;
  }

}

.google-marker {
  cursor: pointer;
}
.gm-style-iw {
  width: 500px !important;


  @include small-size-max {
    width: 290px !important;
  }
}


.aheto-map--restaurant {
  .locContainer {
    display: flex;
    .locImg {
      @include xs-size-all {
        display: none;
      }
    }
    .locContent {
      padding-left: 26px;
      .locTitle {
        color: #222;
        font-size: 20px;
        font-weight: 600;
        margin: 18px 0 12px 0;
      }
      .locDesc,
      .locTel {
        color: #999;
        font-size: 16px;
        font-weight: 400;
      }
      .locTel {
        line-height: 30px;
        font-weight: 600;
        padding-top: 4px;
      }
    }
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 0 !important;
    width: 370px !important;
    height: 150px !important;
    @include xs-size-all {
      height: 200px !important;
    }
  }
}