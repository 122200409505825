/*event political*/
.section-event {
  background-color: #eff4ff;

  .event {
    background-color: $c-white;
    position: relative;
    min-height: 335px;
    margin-bottom: 0px;
    transition: 0.2s all;
    height: 100%;
    padding: 105px 30px 30px;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      box-shadow: 0px 26px 55.5px 3.5px rgba(0, 0, 0, 0.17);
      transition: 0.2s all;
      transform: translateY(-20px)
    }

    .content-wrapper {
      margin-bottom: 0px;

      .event-title {
        margin-bottom: 0px;

        a {
          font-size: 24px;
          font-weight: 500;
          line-height: 1.25;
          display: inline-block;
          font-family: $f-oswald;
        }
      }

      p {
        padding-top: 15px;
      }

      .event {
        &-date {
          display: block;
          width: 70px;
          position: absolute;
          top: -20px;
          left: 30px;
        }

        &-number {
          font-family: 'Oswald';
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          line-height: 1.6;
          color: #fff;
          display: block;
          background: #0536a5;
          padding: 6px 0px;
          width: 100%;
          border-radius: 5px 5px 0px 0px;
        }

        &-month {
          font-family: 'Lato';
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          background: #dd2932;
          display: block;
          text-align: center;
          border-radius: 0px 0px 5px 5px;
          padding-bottom: 5px;
        }
      }
    }

    .content-bottom-wrapper {
      margin: auto auto 0;
      width: 100%;

      .image-wrapper {
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

    }

  }
}

/*event datail*/
.aht{
  &--event-detail {
    margin-right: 0px!important;
    margin-left: 0px!important;
  }
  &-ev-det {
    &--political {

      img {
        width: 100%;
      }

      p {
        margin: 20px 0;
      }

      .aht-ev-det {

        &__title {
          margin: 0;
        }

        &__details {
          margin-top: 20px;
        }

        &__detail {
          display: inline-block;
          margin-right: 25px;
        }

        &__detail-icon {
          margin-right: 5px;
          font-size: 16px;
        }

        &__detail-text {
          font-size: 15px;
          line-height: 1.6;
        }

        &__img-holder {
          position: relative;
          margin-top: 50px;
          @include small-size-max{
            margin-top: 40px;
          }
        }

        &__date {
          position: absolute;
          top: -20px;
          left: 30px;
          width: 70px;
          border-radius: 0px 0px 5px 5px;
        }

        &__date-text {
          font-size: 16px;
          font-weight: bold;
          display: block;
          text-align: center;
          border-radius: 5px;
          padding-bottom: 5px;
        }

        &__date-number {
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          line-height: 1.6;
          display: block;
          padding: 6px 0px;
          width: 100%;
          border-radius: 5px 5px 0px 0px;
        }

        &__content {
          margin-top: 60px;
          @include small-size-max{
            margin-top: 40px;
          }
          p {
            &:first-child {
              padding-bottom: 20px;
              @include small-size-max{
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}

// news agency

.news-agency {
  background-color: #f7f6fd;
  .agency-news__item__img {
    width: 100%;
    max-width: 570px;
    height: 359px;
  }

  .agency-news__item__content {
    position: relative;
    padding: 40px 35px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 20px 50px 0 rgba(39, 56, 154, 0.07);
  }

  .agency-news__title {
    font-size: 24px;
    font-weight: bold;
    color: #222222;
    text-align: left;
  }

  .agency-news__text {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #bbbbbb;
  }

  .agency-news__date {
    position: absolute;
    top: 0;
    right: 40px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-image: linear-gradient(330deg, #1200ff, #d800ff);
    &-day {
      font-size: 25px;
      color: #fff;
    }

    &-month {
      font-size: 12px;
      color: #fff;
    }
  }
}

// news agency end