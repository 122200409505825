.aheto-blockquote {

  &__quote {
    width: 100%;
  }

  &__title {
    position: relative;
    z-index: 2;
  }

  &.italic {
    h1 {
      font-style: italic;
    }
  }

  @media screen and (max-width: 768px) {

    &__quote {
      padding: 45px 15px;
    }

    h1 {
      font-size: 30px;
    }

    h2 {
      font-size: 30px;
    }
  }

  &--author-bio {

    .aheto-blockquote__quote {
      padding: 120px 0px 85px 0px;
      max-width: 465px;
      margin: 0 auto;
      @media screen and (max-width: 1280px) {
        padding: 100px 80px;
        max-width: 100%;
      }
      @media screen and (max-width: 991px) {
        padding: 80px 50px 80px;
        max-width: 100%;
      }
      &:before {
        top: -70px;
        left: -30px;
        font-size: 350px;
        @media screen and (max-width: 1280px) {
          top: -90px;
          left: 45px;
        }
        @media screen and (max-width: 991px) {
          top: -50px;
          font-size: 250px;
          left: 45px;
        }
      }
    }

    .aheto-blockquote__title {
      color: $c-white;
    }

    .aheto-blockquote__author {
      margin-top: 80px;
      color: $c-white;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.4px;
    }


    @media screen and (max-width: 575px) {

      .aheto-blockquote__quote {
        padding: 50px 30px;

        &:before {
          top: -30px;
          left: 50px;
          font-size: 150px;
        }
      }

      .aheto-blockquote__title {
        font-size: 34px;
      }

      .aheto-blockquote__author {
        margin-top: 25px;
        font-size: 12px;
      }
    }
  }

  &--portfolio-01 {
    .aheto-blockquote__quote {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 720px;
      padding: 260px 110px 80px;

      @include large-size-max {
        padding: 260px 70px 80px;
      }

      &::before {
        top: 110px;
        right: 120px;
        left: auto;
        transform: none;
        font-size: 250px;
        line-height: 0.75;
      }
    }

    .aheto-blockquote__title {
      color: $c-white;
      line-height: 52px;
      font-style: italic;
    }

    .aheto-blockquote__author {
      margin-top: 140px;
      color: $c-white;
      font-size: 14px;

      @include medium-size-max {
        margin-top:60px;
      }
    }

    @media screen and (max-width: 1500px) {
      .aheto-blockquote__quote {
        max-width: none;
      }
    }

    @media screen and (max-width: 767px) {
      .aheto-blockquote__quote {
        padding: 150px 50px 50px;
        &:before {
          top: 50px;
          right: 80px;
          font-size: 220px;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .aheto-blockquote__quote {
        padding: 130px 30px 50px;

        &:before {
          top: 50px;
          right: 30px;
          font-size: 180px;
        }
      }

      .aheto-blockquote__title {
        font-size: 32px;
        line-height: 1.2;
      }

      .aheto-blockquote__author {
        margin-top: 70px;
        font-size: 12px;
      }
    }
  }

  &--portfolio-06 {
    .aheto-blockquote__title {
        margin: 60px 0 30px;
        font-style: italic;
    }
  }
  &--political{
    position: relative;
    .aheto-single-img{
      width: 59%;
      img{
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 27px 47.9px 3.1px rgba(0, 0, 0, 0.25);
      }
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    blockquote{
      position: absolute;
      width: 50%;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      border-radius: 5px;
      padding: 70px 100px;
      @media screen and (max-width: 991px) {
        top: 0px;
        position: relative;
        margin-top: -25%;
        width: 90%;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
      @media screen and (max-width: 768px) {
        padding: 20px 25px;
      }
      &.q-bg{
         h3{
           font-family: 'Oswald';
         }
          p{
            font-weight: 400;
          }
      }
      h4{
        line-height: 1.5;
        max-width: 310px;
        @media screen and (max-width: 991px) {
          max-width: 100%;
        }
      }
      &:before{
        display: none;
      }
      .aheto-btn{
        width: 100%;
        text-align: right;
        color: #fff;
        display: inline-block;
        padding: 0px;
        background: transparent;
        &:hover{
          transform: translateX(10px);
        }
      }
    }
  }
  &__political{
    blockquote{
      padding: 0px 50px 25px 0px;
      @media only screen and (max-width: 1199px) {
        padding-bottom: 0px;
      }
      @media only screen and (max-width: 991px) {
        padding-right: 20px;
      }
      &:before{
        display: none;
      }
      p{
        margin-top: 0px;
      }
    }
    .aheto-blockquote{
      &__title{
        color: #999999;
        font-family: 'Playfair Display';
        line-height: 1.5;
        font-style: italic;
        padding-bottom: 35px;
        @media only screen and (max-width: 768px) {
          padding-bottom: 20px;
        }
      }
      &__author{
        span{
          font-size: 14px;
          color: #999999;
          font-weight: 400;
        }
      }
    }
  }

  &--restaurant {
      background-repeat: no-repeat;
      @include large-size-all {
        padding-bottom: 50px;
      }

    .double_item_wrap {
      display: flex;
    }
    .aheto-single-img {
      margin-bottom: 80px;
      max-width: 670px;
      z-index: 2;
      img {
        box-shadow: 0px 3px 59px 0 rgba(0, 0, 0, 0.04);
      }
      @include medium-size-max {
        margin-bottom: 0;
      }
    }
    @include xs-size-max {
      .aheto-single-img {
        //margin-bottom: 50px;
      }
    }

    blockquote {
      margin-left: -80px;
      margin-top: 60px;
      width: 50%;
      padding: 104px 66px 96px 110px;
      box-shadow: 0 3px 59px 0 rgba(0, 0, 0, 0.04);
    }
    blockquote:before {
      content: none;
    }

    .aheto-heading--double_item_top {
      .aheto-heading__subtitle {
        text-transform: uppercase;
        font-family: "Catamaran", sans-serif;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.4px;
        text-align: center;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: -4px;
          left: 50%;
          transform: translate(-50%, 0);
          border-top: 1px solid #eee;
          width: 75px;
        }
      }
      .aheto-heading__title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: "Catamaran", sans-serif;
        font-size: 40px;
        text-align: center;
        font-weight: 400;
      }
      .aheto-heading__desc {
        font-family: $f-playfair-display;
        font-size: 18px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        font-style: italic;
        text-transform: none;
        line-height: 1.5;
      }
    }
    .aheto-heading--double_item_bottom {
      max-width: 360px;
      margin: 0 auto;
      .aheto-heading__desc {
        margin-top: 34px;
        font-family: "Catamaran", sans-serif;
        font-size: 16px;
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        letter-spacing: normal;
        text-align: center;
        text-transform: none;
      }
    }
    .double_item_wrap_reverse {
      blockquote {
        margin: 0 -80px 80px 0;
        width: 50%;
        padding: 104px 110px 96px 66px;
        box-shadow: 0 3px 59px 0 rgba(0, 0, 0, 0.04);
      }

      @include xs-size-max {
        blockquote {
          margin: 0;
        }
      }
      .aheto-single-img {
        margin-top: 86px;
        margin-bottom: 0;
      }
    }
  }

  &--restaurant-awards {
    position: relative;
    overflow: hidden;
    .bg-text {
      position: absolute;
      top: -10px;
      right: -100px;
      color: #F7F7F7;
      font-size: 150px;
      font-weight: 800;
      letter-spacing: 15px;
      text-transform: uppercase;
    }
    .bg-text-left {
      left: -340px;
      top: -40px;
    }
    .awards-wrap {
      display: flex;
      align-items: center;
      @include small-size-max {
        flex-direction: column;
      }
    }
    .blockquote-img-awards {
      height: 100%;
      width: 55%;
      .aheto-single-img {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      @media screen and (max-width: 1439px) {
        width: 80%;
      }
      @include small-size-max {
        width: 100%;
        margin-left: unset;
        padding: 0;
      }
    }

    .awards-info-wrap {
      padding: 50px 0;
      max-width: 700px;
      transform: translateX(-100px);
      @media screen and (max-width: 1439px) {
        margin-left: -440px;
        transform: none;
      }

      @include small-size-max {
        margin-left: unset;
        padding: 0;
        max-width: unset;
        width: 100%;
      }
    }

    blockquote {
      padding: 94px 100px 70px 100px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

        @media screen and (max-width: 768px) {
          padding: 70px 100px 60px 100px;
        }
        @include small-size-max {

        }
        @include xs-size-all {
          padding: 40px 80px;
          top: 0;
        }
        @include xs-size-max {
          padding: 40px;
        }
      &:before {
        display: none;
      }
    }
  }


  .aheto-single-img {
    width: 57%;
  }

}
.rest-about-sec-2 {
  position: relative;
  .bg-text {
    position: absolute;
    top: 30px;
    right: -50px;
    color: $c-light;
    font-size: 150px;
    font-weight: 900;
    letter-spacing: 11.25px;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
.rest-about-sec-3 {
  background-position: right bottom;
}

@include large-size-max {
  .aheto-blockquote--restaurant {
    .aheto-single-img {
      max-width: 570px;
    }
    blockquote {
      margin-top: 0;
    }
  }
}

@include medium-size-max {
  .aheto-blockquote--restaurant {
    .aheto-single-img {
      max-width: unset;
      width: 100%;
      margin-top: 0;
    }
    .double_item_wrap {
      flex-direction: column;
      align-items: center;
    }
    blockquote,
    .double_item_wrap_reverse blockquote {
      margin-left: 0;
      margin-right: 0;
      width: calc(100% - 40px);
      padding: 10px;
      padding-top: 30px;
    }
    .double_item_wrap_reverse {
      .aheto-single-img {
        order: -1;
        margin-top: 0;
      }
    }
  }
}

@include xs-size-max {
  .aheto-blockquote--restaurant {
    blockquote,
    .double_item_wrap_reverse blockquote {
      width: 100%;
    }
  }
}
