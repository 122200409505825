.aheto-image-slider {
  width: 1470px;

  padding: 0 15px;
  transform: translateX(-50%);
  left: 50%;

  &--mobile-view {
    position: relative;

    &:after {
      position: absolute;
      top: -65px;
      left: 50%;
      width: calc((100% / 5) + 3px);
      height: 100%;
      transform: translateX(-50%);
      background: url('../img/saas/mobile.png') no-repeat center 0;
      background-size: 100%;
      content: '';
      z-index: 1;
      pointer-events: none;
      //@media (max-width: 1470px) {
      //  top: -3.385417vw;
      //}
    }

    .swiper-slide {
      img {
        width: 100%;
      }
    }

    .swiper-pagination {
      margin-top: 120px;
      @media screen and (max-width: 768px) {
        margin-top: 80px;
      }
    }

    .swiper-container {
      &:before,
      &:after {
        display: block;
        position: absolute;
        top: 0px;
        width: 40%;
        height: 470px;
        background: linear-gradient(to bottom, rgba($c-white, 0.7) 0%, rgba($c-white, 0.7) 60%, rgba($c-white , 1) 100%);
        content: '';
        z-index: 2;
      }
      &:before {
        left: 0;
      }
      &:after {
        right: 0;
      }
    }
    @include medium-size-max {
      width: 993px;
      &:after {
        top: -53px;
        width: calc((100% / 4) - 5px);
      }
      .swiper-container {
        &:after,
        &:before {
          width: calc(76% / 2);
          height: 390px;
        }
      }
    }
  }
}
