// Contact form 7 syles
.aheto-form {

  // Default form
  &--default {
    .wpcf7-form {
      & > p {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: -14px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    // Span wrap
    .wpcf7-form-control-wrap {
      display: block;
      flex: 1;
      min-width: 180px;
      margin: 14px;
    }

    // Input
    .wpcf7-form-control {
      width: 100%;
      padding: 20px;
      transition: all 0.3s ease;
      border: 1px solid #eee;
      border-radius: 5px;
      outline: none;
      -webkit-appearance: none;

      &:focus {
        border-radius: 5px;
      }
    }

    // Select
    .wpcf7-form-control.wpcf7-select {
        width: 100%;
        height: 66px;
        padding: 20px 20px 20px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;
      }

    // input alert (for example if field is required)
    .wpcf7-not-valid-tip {
      font-size: 14px;
    }

    // Textarea
    .wpcf7-form-control.wpcf7-textarea {
      height: 200px;
      resize: none;
    }

    // Submit button
    .wpcf7-form-control.wpcf7-submit {
      display: block;
      width: auto;
      margin: 55px auto 0;
      padding: 21px 45px;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      outline: none;
      color: $c-white;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 1.5px;
      line-height: 1.15;
      text-decoration: none;
      cursor: pointer;
      @media screen and (max-width: 991px) {
        margin-top: 30px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 20px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    .wpcf7-response-output {
      border: 1px solid #eee;
    }
  }

  // Submit button aligns to left
  &--btn-left {
    .wpcf7-form {
      .wpcf7-form-control.wpcf7-submit {
        margin: 40px auto 0 14px;
        @media screen and (max-width: 991px) {
          margin-top: 30px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }
      }
    }
  }

  // Form for Pop up (page Home Event)
  &--pop-up {
    .wpcf7-form {
      & > p {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -14px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 10px;
      }

      // Span wrap
      .wpcf7-form-control-wrap {
        flex: 1;
        margin: 14px;
      }

      // Input
      .wpcf7-form-control {
        width: 100%;
        min-width: 200px;
        padding: 10px 0;
        transition: all 0.3s ease;
        border: 0;
        border-bottom: 1px solid #eee;
        outline: none;
        background-color: transparent;
        font-weight: 500;

      }

      // input alert (for example if field is required)
      .wpcf7-not-valid-tip {
        font-size: 14px;
      }

      // Select
      .wpcf7-form-control.wpcf7-select {
        width: 100%;
        height: 39px;
        padding: 0;
        transition: all 0.3s ease;
        border: 0;
        border-bottom: 1px solid #eee;
        outline: none;
        background-color: transparent;
      }

      // Textarea
      .wpcf7-form-control.wpcf7-textarea {
        height: 90px;
        padding-top: 0;
        resize: none;
      }

      // Submit button
      .wpcf7-form-control.wpcf7-submit {
        display: block;
        position: relative;
        width: auto;
        min-width: 0;
        margin: 30px auto 0;
        padding: 21px 55px;
        transition: 0.3s ease-in;
        border-width: 1px;
        border-style: solid;
        border-radius: 0;
        outline: none;
        color: $c-white;
        font-family: $f-poppins, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 1.5px;
        line-height: 1.15;
        cursor: pointer;
      }

      .wpcf7-response-output {
        border: 1px solid #eee;
      }
    }
  }

  &--business {

    .wpcf7-form-control {
      border-radius: 0;

      &:focus {
        border-radius: 0;
      }

      &.wpcf7-submit {
        padding-right: 30px;
        padding-left: 30px;
        border-radius: 0;
        font-weight: bold;
      }
    }

    .wpcf7-form-control.wpcf7-select {
      border-radius: 0;
    }
  }

  &--yoga {
    .wpcf7-form-control {
      border-radius: 30px;
      font-weight: 500;

      &::placeholder {
        font-weight: 500;
      }

      &:focus {
        border-radius: 30px;
      }

      &.wpcf7-textarea {
        border-radius: 5px;
      }

      &.wpcf7-select {
        border-radius: 30px;
      }

      &.wpcf7-submit {
        padding-right: 60px;
        padding-left: 60px;
        border-radius: 30px;
        font-weight: bold;
        letter-spacing: 0;
      }
    }
  }

  &--restaurant {
    .wpcf7-form-control {
      padding: 17px 20px;
      border-radius: 0;
      &:focus {
        border-radius: 0;
      }
    }
    .wpcf7-text,
    .wpcf7-textarea {
      letter-spacing: 0.8px;
    }
    .wpcf7-form-control.wpcf7-textarea {
      height: 100px;
    }

    .form-bth-holder {
      .wpcf7-form-control.wpcf7-submit {
        margin: 0 30px 16px 12px;
        height: 58px;
        width: 170px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        padding: 4px 0 0 0;
        border-radius: 0;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .wpcf7-form-control-wrap {
      margin: 10px;
    }
  }

}

// Email form
.aheto-form-email {


  // Default styles
  .mc4wp-form {
    &-fields {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      input {

        &[type=email] {
          flex: 1;
          padding: 21px 27px;
          border: 0;
          outline: none;
        }

        &[type=submit] {
          padding: 20px;
          border: 0;
          outline: none;
          cursor: pointer;

          @include xs-size-max {
            flex-basis: 100%;
          }
        }
      }
    }

    .mc4wp-response {
      & * {
        font-size: 14px;
      }
    }
  }

  // Email form for page Comming Soon
  &--countdown {
    .mc4wp-form {
      .mc4wp-form-fields {
        border-radius: 5px;
        input {
          &[type=email] {
            background-color: rgba($c-black, 0.07);
            color: $c-white;

            &::placeholder {
              color: $c-white;
            }
          }

          &[type=submit] {
            padding: 23px 43px 23px 53px;
            background-color: $c-white;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  // Email form for page Home Event
  &--event {
    .mc4wp-form {
      .mc4wp-form-fields {
        input {
          &[type=submit] {
            padding: 23px 35px;
            color: $c-white;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  // Email form for page Home Education
  &--education {
    .mc4wp-form {
      .mc4wp-form-fields {
        border-radius: 5px;
        input {
          &[type=email] {
            padding: 21px 23px;
          }

          &[type=submit] {
            padding: 23px 42px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 2px;
            font-family: $f-roboto;
          }
        }
      }
    }
  }

  &--business {
    .mc4wp-form-fields {
      position: relative;
      input {
        background-color: transparent;

        &[type=submit] {
          position: relative;
          padding: 20px 20px 20px 20px;
          font-weight: 700;
          z-index: 2;

          @include small-size-max {
            padding: 20px;
          }
        }
      }

      @include small-size-min {
        &:after {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translate(0, -50%);
          font-family: $f-ionicons;
          font-size: 18px;
          content: '\f10b';
        }
      }
    }
  }
}

.aht-form {
  &--edu {
    .wpcf7 {

      br {
        display: none;
      }

      &-form {
        overflow: hidden;

        > p {
          display: flex;
          flex-wrap: wrap;
          margin: -14px;
        }
      }

      &-form-control-wrap {
        flex: 1;
        min-width: 350px;
        margin: 14px;

        @include xs-size-max {
          min-width: 200px;
        }
      }

      &-form-control {
        width: 100%;
        height: 100%;
        padding: 20px;
        transition: all 0.2s ease-out;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;
        background-color: $c-white;
        color: #222;
      }

      &-textarea {
        height: 200px;
        resize: none;
      }

      &-submit {
        width: auto;
        margin: 32px 14px 14px;
        padding: 22px 40px;
        color: $c-white;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  &--trvl {
    .wpcf7 {

      br {
        display: none;
      }

      &-form {

        > p {
          display: flex;
          flex-wrap: wrap;
          margin: -14px;
        }
      }

      &-form-control-wrap {
        flex: 1;
        min-width: 250px;
        margin: 14px;

        @include large-size-max {
          min-width: 200px;
        }

        @include medium-size-max {
          flex-basis: 100%;
        }
      }

      &-form-control {
        width: 100%;
        padding: 18px 20px;
        transition: all 0.2s ease-out;
        border: 1px solid #eee;
        border-radius: 5px;
        outline: none;
        background-color: $c-white;
        font-size: 15px;
      }

      &-textarea {
        height: 200px;
        resize: none;
      }

      &-select {
        option {
          &[disabled] {
            display: none;
          }
        }
      }

      &-submit {
        width: auto;
        margin: 47px auto 14px;
        padding: 18px 35px;
        border-radius: 30px;
        outline: none;
        color: $c-white;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
}
.color_blue {
  .aheto-form-email--countdown {
    .mc4wp-form {
      .mc4wp-form-fields {
        border-radius: 5px;

        input {
          &[type=submit] {
            color: #2a74ed;
          }
        }
      }
    }
  }
}

// Church contact-us page
.church-contact-us-wrap {
  .aheto-form  {
    &--default {
      .wpcf7-form-control {
        line-height: 20px;
        box-sizing: border-box;
      }

      .Budget {
        position: relative;

        &::after{
          font-family: Ionicons;
          content: '\f123';
          font-size: 10px;
          position: absolute;
          top: 25px;
          right: 23px;
          color: #999999;
          pointer-events: none;
        }
      }

      .wpcf7-form-control.wpcf7-select {
        height: 60px;
        line-height: 60px;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .wpcf7-form-control.wpcf7-submit{
        margin-top: 40px;

        @media screen and (max-width: 991px) {
          margin-top: 30px;
        }

        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }
      }
    }
  }
}

.church-sermons-det-wrap {
  .aheto-form {
    &--default {
      .wpcf7-form-control {
        line-height: 20px;
        box-sizing: border-box;
      }

      .wpcf7-form-control.wpcf7-textarea {
        height: 140px;
      }

      .wpcf7-form-control.wpcf7-select {
        height: 60px;
        line-height: 60px;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      .wpcf7-form-control.wpcf7-submit {
        margin-top: 30px;

        @media screen and (max-width: 991px) {
          margin-top: 20px;
        }

        @media screen and (max-width: 768px) {
          margin-top: 10px;
        }
      }
    }
  }
}


@media (min-width: 1200px) {
  .margin-church-contact{
    margin-top: 125px;
  }
  .margin-sermon-det-contact {
    margin-top: 90px;
  }
}

//construction
.construction-contact-form{
  .aheto-form {
    .wpcf7-select {
      appearance: none;
    }
    .wpcf7-submit {
      font-size: 16px;
      font-weight: 700;
    }
    .Budget {
      position: relative;
      &:after {
        position: absolute;
        content: '\f123';
        font-family: $f-ionicons;
        font-size: 10px;
        top: 25px;
        right: 23px;
        pointer-events: none;
      }
    }
  }
}

// Restaurant
.rest-contacts-wrap {
  .contact-left {
    background-color: #f7f7f7;
    padding-right: 70px;
    padding-left: 70px;
    background-repeat: no-repeat;
    background-position: left 87%;
    .form-wrap {
      max-width: 700px;
      width: 100%;
      float: right;
    }
  }
  .contact-right {
    background-repeat: no-repeat;
    background-position: right 16px;
    padding-left: 60px;
  }


  @include large-size-max {
    .contact-right {
      background-position: 176% 16px;
    }
  }
  @media screen and (max-width: 870px) {
    .contact-right {
      background-position: 220% 16px;
    }
  }

  @include medium-size-all {
    .contact-left {
      padding-right: 30px;
      padding-left: 30px;
    }
    .contact-right {
      padding-left: 30px;
    }
  }
  @include small-size-all {
    .contact-right {
      background-position: 120% 16px;
    }
    .contact-right,
    .contact-left {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @include xs-size-all {
    .contact-right {
      background-position: 2220% 16px;
    }
    .contact-left,
    .contact-right {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

// Restaurant reservation
.rest-reservation-home {
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 280px;
  @include medium-size-max {
    padding-top: 100px;
  }
  @include small-size-max {
    padding-top: 80px;
  }
  @include xs-size-max {
    padding-top: 50px;
  }
}
.rest-reservation-container {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: -130px;
  padding: 106px 50px 100px 50px;
  background-color: #f7f7f7;
  box-shadow: 0 15px 38px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  @include medium-size-max {
    padding: 70px 30px;
  }
  @include xs-size-max {
    padding: 50px 15px;
  }
  .rest-reservation-order {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 108px 40px;
    background-color: #f7f7f7;
    display: none;
    flex-direction: column;
    align-items: center;
    .aheto-heading__desc {
      max-width: 580px;
    }
    .aheto-single-img {
      max-width: 340px;
      margin-top: 15px;
      img {
        width: 100%
      }
    }
    @include small-size-max {
      justify-content: center;
      padding: 0 40px;

    }
  }
}
.rest-reservation-container_bottom {
  margin-bottom: -70px;
  z-index: 2;
  @include medium-size-max {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.form-rest-reservation-wrap {

  .aheto-form--rest-reservation {
    form {
      .wpcf7-form-control-wrap {
        margin: 0;
        margin-top: 20px;
        position: relative;
        flex: unset;
        min-width: unset;
        &.input-icon:after {
          position: absolute;
          font-family: $f-ionicons;
          top: 0;
          right: 15px;
          color: #80261b;
          font-size: 20px;
          font-weight: 400;
          cursor: pointer;
          width: 60px;
          height: 60px;
          border-left: 1px solid #eee;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.input-icon-persons:after {
          content: '\f47e';
        }
        &.input-icon-time:after {
          content: '\f403';
        }
        &.input-icon-date:after,
        &.input-icon-phone:after {
          content: '\f117';
        }
        .wpcf7-form-control {
          height: 60px;
          padding-right: 70px;
          &[name="Name"] {
            padding-right: 20px;
          }
          &.wpcf7-submit {
            padding-right: 0;
            text-align: center;
            line-height: 52px;
          }
          &.wpcf7-select {
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
            border-radius:0;
            color: #222;
          }
          &::placeholder {
            color: #222;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.8px;
          }

        }
      }
      .wpcf7-form-control.wpcf7-submit {
        width: 100%;
        margin: 0;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}
