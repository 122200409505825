.aheto-content-block {
  $p: &;
  overflow: hidden;

  &__img {
    height: 250px;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
    }

    @include small-size-max {
      height: 50vw;
      min-height: 200px;
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  &__title-holder {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0px;

  }

  &__ico {
    display: block;
    width: 25px;
    margin-right: 15px;
    font-size: 24px;
    position: relative;
    top: 2px;

    &--lg {
      width: auto;
      margin: 0 auto 25px;
      font-size: 40px;
    }
  }

  &.t-center {
    .aheto-content-block__title-holder {
      justify-content: center;
    }
  }

  &.t-right {
    .aheto-content-block__title-holder {
      justify-content: flex-end;
    }
  }


  &--chess {
    margin-right: -15px;
    margin-left: -15px;
    padding: 105px 30px 90px;
    background-color: $c-white;

    &.chess-bg {
      background-color: #f6f9ff;
      @include medium-size-all {
        background-color: $c-white;
      }
    }

    &.chess-bg-medium {
      @include medium-size-all {
        background-color: #f6f9ff;
      }
    }

    @include small-size-max {
      padding: 40px 15px;
    }

    .aheto-content-block__descr {
      max-width: 390px;
      margin-right: auto;
      margin-left: auto;
    }

    .aheto-content-block__link {
      margin-top: 55px;

      @include small-size-max {
        margin-top: 30px;
      }
    }
  }

  &--light {
    .aheto-content-block__ico,
    .aheto-content-block__title,
    p,
    a {
      color: #fff;
    }
  }

  &--bgImg {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    height: 470px;
    margin-bottom: 30px;
    padding: 70px 30px 50px;
    background-position: 50%;
    background-size: cover;

    .aheto-content-block__ico {
      margin-top: -20px;
    }

    .aheto-content-block__title {
      margin-top: 25px;
      margin-bottom: 50px;

      @include medium-size-max {
        margin-bottom: 20px;
      }
    }

    .aheto-content-block__link {
      margin-top: 70px;

      @include medium-size-max {
        margin-top: 0;
      }
    }

    @include medium-size-max {
      height: auto;
      padding: 50px 15px 30px;

      .aheto-content-block__info {
        display: block !important;
        height: auto !important;

        p {
          margin-bottom: 0px;
        }
      }
    }

    .aheto-content-block__descr {
      position: relative;
      max-width: 390px;
      margin-right: auto;
      margin-left: auto;
      z-index: 2;
    }

    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 200ms;
      background-color: rgba($c-black, 0.7);
      content: '';
      z-index: 1;
    }

    .aheto-content-block__info {
      display: none;

      p {
        margin-top: 0;
      }
    }

    &:hover {
      &:after {

        @include medium-size-max {
          background-color: rgba($c-black, 0.7);
        }
      }
    }
  }

  &--slider {
    position: relative;
    min-height: 270px;
    padding: 70px 25px;
    background-color: $c-white;
    transition: 0.3s all;

    @media screen and (max-width: 991px) {
      min-height: 200px;
    }

    &:hover {
      transition: 0.3s all;
      cursor: pointer;
    }

    &:before {
      font-size: 40px;
      font-weight: 300;
      content: attr(data-index) '.';
    }

    .aheto-content-block__ico {
      position: absolute;
      top: 100px;
      left: 50%;
      transform: translate(-50%, 0);
      color: rgba($c-black, 0.03);

      &--lg {
        font-size: 120px;
      }
    }

    .aheto-content-block__title {
      margin-top: 35px;
    }

    .aheto-content-block__info-text {
      margin-top: 35px;
    }
  }

  &--feature {
    .aheto-content-block__ico {
      width: 42px;
      margin-right: 10px;
      font-size: 30px;
      position: relative;
      left: 1px;
      margin-top: 15px;
    }

    .aheto-content-block__info-text {
      margin-top: 0px;
      padding-left: 52px;
      line-height: 26px;
      margin-bottom: 0px;
    }
  }

  &--img-h80 {
    padding: 0 30px;

    .aheto-content-block__img {
      height: 80px;
      background-size: contain;
      margin-bottom: 35px;
    }

    .aheto-content-block__info-text {
      margin-top: 20px;
    }

    @include medium-size-max {
      padding: 0;
    }

    @include small-size-max {
      .aheto-content-block__img {
        min-height: 80px;
      }
    }
  }

  &--list {
    position: relative;
    padding-left: 75px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 40px;
      font-weight: 300;
      line-height: 1;
      content: attr(data-index) '.';
    }

    .aheto-content-block__title {
      margin: 0;
    }

    .aheto-content-block__info-text {
      margin-top: 15px;
    }
  }

  &--list-icon {

    &:hover {
      .aheto-content-block__ico {
        color: $c-white;
      }
    }

    .aheto-content-block__ico {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 105px;
      height: 105px;
      transition: all 0.3s;
      border-radius: 50%;
      background-color: $c-white;
      font-size: 30px;
      box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
      margin-left: auto;
    }

    .aheto-content-block__title {
      &:before {
        margin-right: 5px;
        font-size: 20px;
        content: attr(data-index) '.';
      }
    }

    .aheto-content-block__info-text {
      margin-top: 25px;
      line-height: 26px;
      @media screen and (max-width: 768px) {
        margin-top: 15px;
      }
    }
  }

  &--saas {
    #{$p}__ico {
      margin: 0;
      font-size: 50px;
    }
  }

  &--business {
    .aheto-content-block__img {
      height: 50px;
      margin: 0 0 15px;
      background-position: left;
      background-size: auto;

      @include small-size-max {
        min-height: 0;
      }
    }

    .aheto-content-block__info-text {
      margin: 17px 0 0px;
    }
  }

  &--construction {
    position: relative;
    padding: 40px 0 35px;
    cursor: pointer;
    @include small-size-max{
      padding-bottom: 10px;
      padding-top: 25px;
    }
    &.active {
      &:after {
        opacity: 1;
      }
    }
    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      content: '';
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    .aheto-content-block {
      &__img {
        @include small-size-max {
          min-height: 0;
          margin-bottom: 20px;
        }
        height: 50px;
        margin: 0 0 35px;
        background-position: left;
        background-size: auto;
      }

      &__title {
        font-size: 20px;
        color: #222222;
      }

      &__info {
        margin-top: 20px;
        @include small-size-max{
          margin-top: 10px;
        }
      }

      &__info-text {
        font-family: $f-source-sans-pro;
      }
    }
  }

  &--restaurant {
    padding: 20px;
    .aheto-content-block {
      &__img {
        margin-bottom: 84px;
        height: 210px;
      }
      &__title-holder {
        position: relative;
      }
      &__title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        -webkit-font-smoothing: antialiased;
      }
      &__ico {
        position: absolute;
        top: -130px;
        left: 50%;
        transform: translate(-50%);
        height: 90px;
        width: 90px;
        border-radius: 45px;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__info {
        margin-top: 26px;
        padding: 0 10px;
      }
      &__info-text {
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}

.restaurant-services-section-wrap {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto;
  position: relative;
  overflow: hidden;
  .bg-text {
    position: absolute;
    right: -10px;
    top: -53px;
    color: #F7F7F7;
    font-size: 150px;
    font-weight: 900;
    letter-spacing: 11.25px;
    text-transform: uppercase;
    @include small-size-max {
      display: none;
    }
  }
  .content-block-wrap:nth-child(4) .aheto-content-block__ico,
  .content-block-wrap:nth-child(5) .aheto-content-block__ico {
    background-position: 50% 43%;
  }
}
.restaurant-services-section-wrap.rest-serv-page {
  background-position: left 160px;
  background-size: auto;
  .bg-text {
    left: -44px;
  }
}

.restaurant-services-section-wrap.rest-home-page {
  background-position: bottom;
  background-size: auto;
  .bg-text {
    left: 0;
  }
}

@include large-size-max {
  .restaurant-services-section-wrap {
    background-size: auto;
  }
}
