
.radio{
  p{
    float: left;
  }
  input[type=radio] {display: none;}
  input[type=radio] + label{
    content: '';
    color: #0c609c;
    width: 120px;
    height: 50px;
    border-radius: 5px;
    background-color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  input[type=radio]:checked + label {
    border: solid 2px #0536a5;
  }
  input {
    display: none;
  }

}
