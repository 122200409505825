.aht-post-yoga {

  &__title {
    margin: 0;
    font-size: 40px;
    font-weight: bold;
    line-height: 52px;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
  }

  &__detail {
    display: flex;
    align-items: center;
    margin-right: 43px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__detail-icon {
    margin-right: 12px;
    font-size: 16px;
  }

  &__detail-text {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
  }

  &__thumbnail {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  &__thumbnail-img {
    max-width: 100%;
  }

  &__content {
    margin-bottom: 63px;

    p {
      margin: 23px 0;
    }
  }

  &__additional {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include small-size-max {
      flex-direction: column;
    }
  }

  &__cats {
    margin-right: 30px;

    @include small-size-max {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__cats-name {
    margin-right: 7px;
    font-size: 16px;
    font-weight: bold;
  }

  &__cats-list {
    font-weight: 500;
  }

  &__cats-link {
    transition: none;
    font-weight: inherit;
    font-size: 16px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__likes {
    padding: 15px 25px 15px 20px;
    transition: all 0.2s ease-out;
    border: 1px solid #eee;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
  }

  &__likes-icon {
    margin-right: 5px;
    color: #EE2865;
    font-size: 14px;
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding: 50px 50px 60px 70px;
    border: 1px solid #eee;

    @include small-size-max {
      padding: 50px 30px;
    }
  }

  &__author-img-holder {
    margin-right: 40px;

    @include small-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__author-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    @include small-size-max {
      margin: 0 auto;
    }
  }

  &__author-img-inner {
    width: 0;
    height: 0;
  }

  &__author-about {
    flex: 1;
  }

  &__author-title {
    margin: -3px 0 0;
    font-weight: bold;
    line-height: 1.63;
  }

  &__author-desc {
    margin-top: 32px;
    line-height: 1.63;
  }

  &__author-more {
    margin: 45px 0 0;
  }

  &__author-link {
    font-weight: 500;
    text-decoration: underline !important;
  }

  &__socials {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}
