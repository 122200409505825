.aht-course {

  &--edu {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .aht-course {

      &__item {
        flex-basis: 25%;
        margin-bottom: 30px;
        padding: 0 15px;

        @include large-size-max {
          flex-basis: 33.33%;
        }

        @include medium-size-max {
          flex-basis: 50%;
        }

        @include small-size-max {
          flex-basis: 100%;
        }
      }

      &__item-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: all 0.2s ease-out;
        border-radius: 5px;
        // box-shadow: 0px 4px 20px 0px rgba($c-black, 0.05);

        &:hover {      
          .aht-course__ovrl {
            opacity: 1;
          }
        }

        @include large-size-min {
          &:hover {
            // transform: translate(0, -10px);
            box-shadow: 0px 5px 10px 0px rgba($c-black, 0.14);
          }
        }
      }

      &__price {
        display: inline-block;
        position: absolute;
        top: 18px;
        left: -10px;
        min-width: 60px;
        padding: 7px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        z-index: 1;

        &--free {
          background-color: #42B345;
          color: $c-white;
        }
      }

      &__img {
        position: relative;
        height: 230px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-position: center;
        background-size: cover;

        @include small-size-max {
          height: 320px;
        }

        @include xs-size-max {
          height: 230px;
        }
      }

      &__ovrl {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.2s ease-out;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        opacity: 0;
      }

      &__content {
        flex: 1;
        padding: 24px 20px 32px;
      }

      &__title {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
      }

      &__author {
        margin: 14px 0 0;
        font-size: 14px;
        font-weight: 500;
      }

      &__link {
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 15px 30px;
        transform: translate(-50%, -50%);
        border: 2px solid $c-white;
        border-radius: 5px;
        color: $c-white;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        font-size: 14px;
        white-space: nowrap;

        &:hover {
          background-color: $c-white;
        }
      }

      &__info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px 15px;
        border-top: 1px solid #eee;
      }

      &__details {
        display: flex;
        flex-wrap: wrap;
      }

      &__detail {
        display: flex;
        align-items: center;
        margin-right: 5px;
        padding: 5px;

        &:last-child {
          margin-right: 0;
        }
      }

      &__icon {
        display: inline-block;
        margin-right: 7px;
      }

      &__text {
        font-size: 14px;
        font-weight: 500;
      }

      &__stars {
        display: flex;
        align-items: center;
      }

      &__star {
        margin-right: 1.5px;
      }
    }

    &-1 {
      .aht-course {
        &__item-inner {
          display: flex;
          flex-direction: column;
          height: 100%;
          transition: all 0.2s ease-out;
          border-radius: 5px;
          box-shadow: 0px 4px 59px 0 rgba($c-black, 0.05);
  
          &:hover {      
            .aht-course__ovrl {
              opacity: 1;
            }
          }
  
          @include large-size-min {
            &:hover {
              transform: translate(0, -10px);
              box-shadow: 0px 39px 54px 0 rgba($c-black, 0.14);
            }
          }
        }
      }
    }
    &-2 {
      .aht-course {
        &__item-inner {
          box-shadow: 0px 4px 20px 0 rgba($c-black, 0.05);
  
          @include large-size-min {
            &:hover {
              transform: translate(0, -10px);
              box-shadow: 0px 6px 20px 0 rgba($c-black, 0.14);
            }
          }
        }
      }
    }
  }

  &--single {
    margin: 0;

    .aht-course {
      &__item {
        flex-basis: 100%;
        margin-bottom: 0;
        padding: 0;
        
        @include large-size-max {
          flex-basis: 100%;
        }
      }
    }
  }
}
