.aht-calendar {
    &__header {
        caption-side: top;
        font-size: 24px;
        padding-top: 65px;
        padding-bottom: 53px;
        padding-left: 75px;
        padding-right: 75px;
    }
    &__title {
        text-align: center;
    }
    &__head {
        &-item:first-child {
            padding-left: 0px;
        }
        &-item {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    &__body {
        &-content {
            min-width: 105px;
        }
        &-cell {
            position: relative;
            border: 1px solid #eee;
        }
        &-row:last-child {
            .aht-calendar {
                &__number {
                    opacity: 0.5;
                }
            }
        }
    }
    &__cat {
        position: absolute;
        top: 57px;
        width: 100%;
        left: 0;
    }
    &__number {
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 16px;
    }
    @media screen and (min-width: 769px) {
        &__title {
            text-align: center;
        }
    }
    @media screen and (max-width: 768px) {
        &__title {
            text-align: left;
            margin-left: 0px;
        }
    }
    @media screen and (max-width: 576px) {
        &__header {
            caption-side: top;
            font-size: 24px;
            padding-top: 25px;
            padding-bottom: 25px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}