@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Medium.eot');
  src: url('../fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
  url('../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy.eot');
  src: url('../fonts/Gilroy/Gilroy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy/Gilroy.woff') format('woff'),
  url('../fonts/Gilroy/Gilroy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Bold.eot');
  src: url('../fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
  url('../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('../fonts/Futura/FuturaLT-Bold.eot');
  src: url('../fonts/Futura/FuturaLT-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Futura/FuturaLT-Bold.woff') format('woff'),
  url('../fonts/Futura/FuturaLT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('../fonts/Futura/FuturaStd-Medium.eot');
  src: url('../fonts/Futura/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Futura/FuturaStd-Medium.woff') format('woff'),
  url('../fonts/Futura/FuturaStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('../fonts/Droid Serif/DroidSerif-Italic.eot');
  src: url('../fonts/Droid Serif/DroidSerif-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Droid Serif/DroidSerif-Italic.woff') format('woff'),
  url('../fonts/Droid Serif/DroidSerif-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('../fonts/Droid Serif/DroidSerif-Bold.eot');
  src: url('../fonts/Droid Serif/DroidSerif-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Droid Serif/DroidSerif-Bold.woff') format('woff'),
  url('../fonts/Droid Serif/DroidSerif-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Droid Serif';
  src: url('../fonts/Droid Serif/DroidSerif-Regular.eot');
  src: url('../fonts/Droid Serif/DroidSerif-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Droid Serif/DroidSerif-Regular.woff') format('woff'),
  url('../fonts/Droid Serif/DroidSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SignPainter';
  src: url('../fonts/SignPainter/SignPainter-HouseScript.eot');
  src: url('../fonts/SignPainter/SignPainter-HouseScript.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SignPainter/SignPainter-HouseScript.woff') format('woff'),
  url('../fonts/SignPainter/SignPainter-HouseScript.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
