.aht-text {
  overflow: hidden;

  &--trvl {
    .aht-text {
      &__text {
        color: #eee;
        font-size: 10vw;
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: 1;
        white-space: nowrap;
      }
    }
  }

  &--classic {
    .aht-text {
      &__text {
        display: inline-block;
        color: $c-white;
        font-size: 16vw;
        font-weight: bold;
        line-height: 1;
        text-shadow: 0px 0px 50px rgba($c-black, 0.08);
      }
    }
  }
}

.list {
  li {
      margin-bottom: 20px;
      padding-left: 25px;
      position: relative;
      @media screen and (max-width: 991px) {
        margin-bottom: 10px;
      }
      &:before {
        content: "";
        background: #2A74ED;
        width: 8px;
        height: 8px;
        position: absolute;
        top: 8px;
        left: 0;
        border-radius: 50%;
    }
  }
}

.construction-about-blocks-list {
  li {
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      top: 10px;
    }
  }
}

.coming-soon-wrap {
  p {
    margin-top: 20px;
    opacity: 0.78;
    font-weight: 400;
  }
}

.main-home-cb {
  img {
    display: block !important;
    width: 100%;
    height: auto;
    position: relative;
    opacity: 1;
  }
  .aht-cb__caption {
    margin: 38px 0 17px;
  }
  .aht-cb__icon {
    float: left;
    color: #2a74ed;
    font-size: 24px;
    margin-right: 25px;
    margin-top: -5px;
  }
}

.aheto-tab__content {
    .aheto-btn-container {

      a {
        margin-right: 25px;
        @media screen and (max-width: 500px) {
          margin-bottom: 20px;
        }
      }
    }
}
