.blog--single {
    &__full {

        .aht-page {
          max-width: 100% !important;
          margin: 0;
          padding: 0;
        }

        .post-head {
          height: 100vh;
          background-size:cover !important;
          position: relative;
          display: flex;
          align-items: flex-end;
          @media screen and (max-width: 1024px) {
            height: calc(100vh - 125px);
          }

          &:after {
              content:"";
              background: #000;
              position: absolute;
              top:0;
              left:0;
              width: 100%;
              height: 100%;
              opacity: 0.54;
          }

          .container {
            position: relative;
            z-index: 44;
          }
          .post-author {
            margin-top: 200px;
            margin-bottom: 50px;

            @include medium-size-max {
              margin-top:100px;
            }

            @include small-size-max {
              margin-top:60px;
            }

          }

          .post-title {
            color:$c-white;
            margin: 25px 0 10px;
          }

          .post-cats {
            a {
              background: #2a74ed;
              color:#fff;
              font-size: 13px;
              line-height: 26px;
              display: inline-block;
              margin-right: 1px;
              border-radius: 3px;
              padding: 0 10px;
              &:hover {
                background: #1954b4;
                color:#fff;
              }
            }
          }

          .post-date {
            span {
              font-size: 16px;
              color:$c-white;
              margin: 0 15px;
              position: relative;

              &:not(:last-child):after {
                  content:"";
                  width:4px;
                  height: 4px;
                  background: #fff;
                  border-radius: 50%;
                  position: absolute;
                  top: 7px;
                  right: -17px;
              }
            }
          }

          .post-author {
            h6 {
              color:$c-white;
              margin-top: 18px;
              font-weight: 500;
              a {
                color:#ffffff;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.63;
                &:hover {
                    color:#fff;
                    opacity:0.6;
                }
              }
            }
          }

        }

        .post-content {

            .container {
              max-width: 1000px !important;
              padding: 120px 15px 110px;
              margin: 0 auto;
              @media screen and (max-width: 1024px) {
                padding: 80px 15px 70px;
              }
              @media screen and (max-width: 768px) {
                padding: 50px 15px 40px;
              }
              > p {
                padding: 0 100px;

                @include small-size-max {
                  padding: 0;
                }
              }

              @include small-size-max {
                padding: 50px 15px 30px;
              }

              blockquote {
                p {
                  font-family: Playfair Display;
                  color: #222;
                  text-transform: none;
                  font-size: 30px;
                  line-height: 1.2;
                  font-weight: 400;
                  font-style: italic;
                  margin-bottom: 20px;
                  margin-top: 60px;
                }

                @include small-size-max {
                    padding: 70px 0px 40px;
                }
              }
            }

            p {
              margin-bottom: 25px;
              line-height: 1.63;

              &.head {
                color: #222;
                margin: 42px 0 12px;
                font-size: 18px;
                line-height: 1.44;
              }

              &.lead {
                margin: 0 0 30px;
                font-size: 30px;
                font-weight: 300;
                line-height: 1.33;
                color: #222222;
              }
            }

            img {
              &.align-none {
                margin: 25px 0;
              }
            }


        }
    }

    .post-meta {
      margin: 50px 0 70px;
      @include small-size-max {
        margin: 10px 0 50px;
      }
      .tags {
        font-size: 16px;
        span {
          font-weight: 500;
          padding-right: 10px;
          line-height: 2;
        }
        a{
          font-size: 16px;
        }
      }

      .likes {
        padding: 0 30px;
        line-height: 49px;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        float: right;
        font-size: 15px;
        margin-top: -10px;
        color:#222;
        display: table;
        transition: 0.3s all;

        i {
          color:#ee2865;
          margin-right: 8px;
          transition: 0.3s all;
        }

        @include small-size-max {
          float: none;
          margin-top: 10px;
          display: table;
        }

        &:hover {
          border-color:#ee2865;
          color:#ee2865;
          transition: 0.3s all;
        }
      }
    }

    .post-author-info {
      border: 1px solid #eee;
      padding: 60px 50px 50px 174px;
      position: relative;
      margin-bottom: 50px;
      a{
        font-size: 16px;
        border-bottom: 1px solid #3776ea;
      }
      @media screen and (max-width: 991px) {
        padding-left: 130px;
      }
      @include small-size-max {
          padding: 125px 40px 40px 40px;
      }

      img {
        position: absolute;
        top:50px;
        left: 70px;
        @media screen and (max-width: 991px) {
          left: 50px;
        }
        @include small-size-max {
            left:40px;
            top:40px;
        }

      }

      p {
        b {
          color:#222;
          font-weight: 500;
        }

        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0px;
          margin-top: 37px;
        }
      }
    }

    &__sidebar {
      .aht-page__sb-inner {
        padding: 100px 0 0;
      }

      .widget_categories {
        ul {
          margin: 0;

          li {
            color:#999;
            margin: 15px 0;
            position: relative;
            padding-left: 15px;

            a {
              color: #999;
              font-size: 16px;
              &:hover {
                color:#2A74ED;
              }
            }
            &:before {
              content: "";
              width: 4px;
              height: 4px;
              background: #ddd;
              position: absolute;
              top: 10px;
              left: 0;
              border-radius: 50%;
            }
          }
          li.active{
            color: #2a74ed;
            a{
              color: #2a74ed;
            }
          }
        }
        &--restaurant {
          margin-top: 60px;
          .widget-title {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            margin-bottom: 15px;
            max-width: 270px;
            @include small-size-max {
              max-width: unset;
            }
          }
          .cat-item {
            a {
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
            }
            &:before {
              background: #999;
              width: 5px;
              height: 5px;
              top: 8px;
            }
            &:hover a{
              color: #80261b;
              font-weight: 600;
            }
            &:hover:before {
              background: #80261b;
            }
          }
        }
      }

      .widget {
        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          margin: 0 0 25px;
          font-size: 16px;
          font-weight: 500;
          color: #222;
        }
      }

      .aht-twitter {
          background-color: #fff;
          box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
          text-align: center;
          margin-top: 80px;

          &__link {
            background: #2a74ed;
            width: 60px;
            height: 60px;
            display: inline-block;
            color: #fff;
            line-height: 60px;
            border-radius: 50%;
            position: relative;
            top: -30px;
            font-size: 19px;
          }

          &__content {
          padding: 15px 25px 35px;
          }

          &__text {
            margin: 0;
          }

          &__post,
          &__hashtag {
            color:#222;
          }

          &__date {
            margin: 23px 0 27px;
            color: #bbb;
            font-size: 14px;
          }

          &__follow {
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              line-height: 1;
              letter-spacing: 0.1rem;
          }
          .btn{
            display: flex;
            border-top: 1px solid #eeeeee ;
            &-left{
              padding: 15px 0px;
              border-right: 1px solid #eeeeee ;
              width: 50%;
              cursor: pointer;
              &:hover{
                i{
                  color: #2a74ed;
                }
              }
            }
            &-right{
              padding: 15px 0px;
              width: 50%;
              cursor: pointer;
              &:hover{
                i{
                  color: #2a74ed;
                }
              }
            }
          }
      }

      .swiper-button-prev:before, .swiper-button-next:before {
          font-size: 10px;
      }

      .aht-page__sb .widget_mc4wp_form_widget {
        background: #2a74ed url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
        padding: 50px 30px 95px;

        .widget-title {
          margin: 0 0 5px;
          color: #fff;
          font-weight: 500;
          font-size: 20px;
        }

        p {
          margin: 0 0 40px;
          color:#fff;
          opacity: 0.51;
          font-size: 14px;
        }

        input[type="email"] {
            background: #1452b7;
            border: none;
            width: 100%;
            border-radius: 5px;
            margin-bottom: 25px;
            color:#fff;
            height: 50px;
            padding: 0 20px;
            font-size: 15px;

            &::placeholder {
        		  color: $c-white;
        		}
        }

        input[type="submit"] {
          border: 1px solid rgba(255, 255, 255, 0.5);
          background-color: #fff;
          color: #2A74ED;
          border-radius: 5px;
          display: inline-block;
          position: relative;
          padding: 15px 35px;
          border-width: 1px;
          outline: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: 1.5px;
          line-height: 1.15;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.3s all;

          &:hover {
            background: transparent;
            color:#fff;
            border:1px solid #fff;
            transition: 0.3s all;
          }
        }
      }

      // Tags widget
      .widget_tags {

        ul {
          display: flex;
          flex-wrap: wrap;
          margin-top: 35px;
          margin-bottom: 0;
        }

        li {
          display: inline-block;
          margin-right: 6px;
          margin-bottom: 4px;
          padding-left: 0;
          line-height: 18px;

          &:before {
            content: none;
          }
        }

        a {
          display: inline-block;
          padding: 3px 18px;
          border: 1px solid #eee;
          background: #fff;
          border-radius: 3px;
          font-size: 13px;
          font-weight: 500;
          line-height: 18px;

          &:hover {
            color: $c-white;
          }
        }
      }


      // Search widget
      .search {

        &-form {
          position: relative;
        }

        &-field,
        &-submit {
          border: 0;
          outline: 0;
        }

        &-field {
          width: 100%;
          padding: 20px;
          transition: all 0.2s ease-out;
          border: 1px solid #eee;
          border-radius: 5px;
          background-color: $c-white;
          font-weight: 500;
        }

        &-submit {
          position: absolute;
          top: 50%;
          right: 15px;
          padding: 5px;
          transform: translate(0, -50%);
          background-color: $c-white;
          cursor: pointer;
        }

        .screen-reader-text {
          display: none;
        }
      }

      // Advertisement
      .aht-widget-advert {
        height: 300px;
        padding: 28px 30px 35px;
        background-position: center;
        background-size: cover;
        text-align: center;

        &__subtitle {
          margin: 0 0 12px;
          font-size: 14px;
          font-weight: 500;
        }

        &__title {
          margin-top: 0;
          margin-bottom: 15px;
          letter-spacing: 1.5px;
        }

        &__desc-wrap {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          min-height: 100px;
        }

        &__desc {
          margin: 23px 0;
          letter-spacing: 1.5px;
        }

        &__link {
          &:hover {
            color: $c-white;
          }
        }
        &--restaurant {
          background-color: #80261b;
          padding: 25px 12px 30px 12px;
          height: auto;
          background-size: 240px;
          background-repeat: no-repeat;
          background-position: 86% 104%;
          max-width: 270px;
          @include small-size-max {
            max-width: unset;
            background-position: 55% 104%;
          }
          .aht-widget-advert {
            &__img {
              img {
                width: 100%;
                max-width: 250px;
                @include small-size-max {

                }
              }
            }
            &__subtitle {
              display: none;
            }
            &__title {
              display: none;
            }
            &__phone-wrap {
              display: flex;
              justify-content: center;
              margin-top: 24px;
            }
            &__phone {
              font-weight: 700;
              -webkit-font-smoothing: antialiased;
              position: relative;
              color: #ffffff;
              font-size: 16px;
              letter-spacing: 1.4px;
              margin-left: 12px;
              margin-bottom: 2px;
            }
            &__phone-ico {
              width: 20px;
              height: 20px;
              margin-top: 1px;
            }
            &__link {
              font-weight: 500;
              letter-spacing: 1.4px;
              font-family: $f-roboto;
              color: #92020e;
              padding: 16px 25px;
              margin-top: 50px;
              &:hover {
                background-color: #80261b;
                border: 1px solid #fff;
                color: #fff;
              }
            }
          }
        }
      }

      // Quotes
      .aht-widget-quotes {
        height: 300px;
        padding: 56px 5px 66px 22px;
        background-position: center;
        background-size: cover;
        text-align: center;

        &__desc {
          font-size: 30px;
          line-height: 1.33;
          letter-spacing: -0.75px;
          text-align: left;
        }

        &__author {
          font-size: 14px;
          line-height: normal;
          text-align: left;
          margin-top: 45px;
          text-transform: uppercase;
        }
      }

      // Posts
      .aht-widget-posts {

        &__buttons {
          display: flex;
          box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
        }

        &__button {
          flex: 1;
          padding: 17px 5px 13px;
          transition: all 0.3s ease-out;
          border-top: 0;
          border-right: 1px solid #eee;
          border-bottom: 2px solid $c-white;
          border-left: 0;
          outline: none;
          background-color: $c-white;
         font-size: 16px;
          font-weight: 700;
          cursor: pointer;

          &:last-child {
            border-right: 0;
          }
        }

        &__list {
          display: none;
          margin-top: 40px;
          margin-bottom: 0;

          &.active {
            display: block;
          }
        }

        &__item {
          margin-bottom: 30px;
          padding-left: 0;

          &:last-child {
            margin-bottom: 0;
          }

          &:before {
            content: '';
          }
        }

        &__item-inner {
          display: flex;

        }

        &__img {
          width: 80px;
          height: 80px;
          margin-right: 19px;
          background-position: center;
          background-size: cover;
        }

        &__img-link {
          display: inline-block;
          width: 100%;
          height: 100%;
        }

        &__text {
          flex: 1;
          line-height: 20px;
        }

        &__title {
          display: inline-block;
          margin: -3px 0 0;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
        }

        &__date {
          margin: 8px 0 0;
          font-size: 13px;
        }

        &--restaurant {
         .aht-widget-posts {
           &__main-title {
             text-transform: uppercase;
             font-size: 24px;
             font-weight: 700;
             border-bottom: 1px solid #eee;
             padding-bottom: 20px;
             max-width: 270px;
             @include small-size-max {
               max-width: unset;
             }
           }
           &__list {
             margin-top: 20px;
           }
           &__title {
             font-weight: 600;
             &:hover {
               color: #80261b;
             }
           }
           &__date {
             font-weight: 400;
           }
         }
        }

      }
      .widget_tags {
         a {
           color: #999;
           &:hover {
             border-color: #2a74ed;
             background-color: #2a74ed;
           }
         }
       }

       .search {

         &-field {
           color: #222;
           &::placeholder {
             color: #999;
           }
           &:focus {
             border: 1px solid #2a74ed;
             box-shadow: 0px 15px 30px 0px rgba(#2a74ed, 0.1);
           }
         }
       }

       .aht-widget-advert {
         &__link {
           &:hover {
             border-color: #2a74ed;
             background-color: #2a74ed;
           }
         }
       }

       .aht-widget-posts {
         &__button {
           color: #222;
           &.active {
             border-bottom: 2px solid #2a74ed;
             color: #2a74ed;
           }
         }
         &__title {
           color: #222;
           &:hover {
             color: #2a74ed;
           }
         }
       }


      .aht-page {
        max-width: 100% !important;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }

      .aht-page--dual-sb {

        @include small-size-max {
          .post-meta .likes {
            float: none !important;
            margin-top: 10px !important;
            display: table;
          }
        }
        .container {
            width: 100% !important;
            max-width: 1690px !important;
        }
        .widget_mc4wp_form_widget{
          @media screen and (max-width: 1200px) {
            margin-bottom: 120px;
          }
          @media screen and (max-width: 991px) {
            margin-bottom: 80px;
          }
          @media screen and (max-width: 768px) {
            margin-bottom: 0px!important;
          }
        }
        .aht-page {
          &__content {
            padding: 120px 0 110px;
            @media screen and (max-width: 1200px) {
              padding-bottom: 0px;
            }
            @media screen and (max-width: 991px) {
              padding-top: 80px;
            }
            @media screen and (max-width: 768px) {
              padding-top: 50px;
            }
            .aht-page__sb-inner{
              @media screen and (max-width: 991px) {
                padding-top: 70px;
              }
            }
            ul {
              margin: 30px 0;

              li {
                color:#999;
                margin: 10px 0;
                position: relative;
                padding-left: 15px;

                &:before {
                  content: "";
                  width: 4px;
                  height: 4px;
                  background: #ddd;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  border-radius: 50%;
                }
              }
            }
            .post-title {
              margin: 15px 0;
              font-size: 40px;
            }

            .post-cats {
              a {
                background: #2a74ed;
                color:#fff;
                font-size: 13px;
                line-height: 26px;
                display: inline-block;
                margin-right: 1px;
                border-radius: 3px;
                padding: 0 10px;
                &:hover {
                  background: #1954b4;
                  color:#fff;
                }
              }
            }

            .post-date {
              margin-bottom: 60px;
              @media screen and (max-width: 991px) {
                margin-bottom: 40px;
              }
              @media screen and (max-width: 768px) {
                margin-bottom: 20px;
              }
              span {
                font-size: 16px;
                color:#999;
                margin: 0 15px;
                position: relative;

                &:not(:last-child):after {
                    content:"";
                    width:4px;
                    height: 4px;
                    background: #999;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    right: -17px;
                }
              }
            }

            blockquote {
                padding: 130px 65px 40px 65px;
                @media screen and (max-width: 768px) {
                  padding: 110px 25px 30px 25px;
                }
                h2 {
                  font-family: 'Playfair Display';
                  font-size: 30px;
                  font-style: italic;
                  line-height: 1.33;
                  color: #262527;
                  font-weight: normal;
                }
                h3 {
                  font-style: italic;
                  font-size:28.5px;
                }
            }
            .text_padd{
              padding: 0px 65px;
              @media screen and (max-width: 768px) {
                padding: 0px;
              }
            }
            h6{
              font-size: 18px;
            }
          }
        }
      }

      .aht-page--right-sb {
        .aht-page {
          &__inner {
            display: flex;
            width: 100%;
            max-width: 1380px;
            margin: 0 auto;
          }

          &__sb {
            width: 380px;
            padding-left: 70px;
            padding-right:15px;
            background: #f6f9ff;
            position: relative;
            float: left;
            @media screen and (max-width: 1024px) {
              padding-left: 35px;
            }

            &:before {
              content:"";
              background: #f6f9ff;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 1000%;
              z-index: -1;
            }
          }

          &__content {
            padding-top: 95px;
            padding-bottom: 100px;
            width:calc(100% - 380px);
            padding-right:30px;
            padding-left:15px;
            float: left;

            .row {
                margin-right: -10px;
                margin-left: -10px;
            }

            ul {
              margin: 30px 0;

              li {
                color:#999;
                margin: 10px 0;
                position: relative;
                padding-left: 15px;

                &:before {
                  content: "";
                  width: 4px;
                  height: 4px;
                  background: #ddd;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  border-radius: 50%;
                }
              }
            }

            .post-title {
              margin: 15px 0;
              font-size: 40px;
            }
            p{
              margin-bottom: 10px;
            }
            .post-cats {
              a {
                background: #2a74ed;
                color:#fff;
                font-size: 13px;
                line-height: 26px;
                display: inline-block;
                margin-right: 1px;
                border-radius: 3px;
                padding: 0 10px;
                &:hover {
                  background: #1954b4;
                  color:#fff;
                }
              }
            }

            .post-date {
              margin-bottom: 60px;
              @media screen and (max-width: 991px) {
                margin-bottom: 40px;
              }

              span {
                font-size: 16px;
                color:#999;
                margin: 0 15px;
                position: relative;

                &:not(:last-child):after {
                    content:"";
                    width:4px;
                    height: 4px;
                    background: #999;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    right: -17px;
                }
              }
            }

            blockquote {
              padding: 0px 50px 0px 30px;
              border-left: 2px solid #2a74ed;
              @media screen and (max-width: 768px) {
                padding-right: 0px;
              }
                h3 {
                  font-size: 30px;
                  font-style: italic;
                  line-height: 1.2;
                }
                p{
                  margin-top: 0px;
                }
                &::before{
                  display: none;
                }
            }
            .img_text_container{
              h6{
                font-size: 18px;
                line-height: 1.44;
              }
              .img-content{
                float: left;
                padding-right: 30px;
                @media screen and (max-width: 1024px) {
                  width: 100%;
                  padding-right: 30px;
                }
                img{
                  @media screen and (max-width: 1024px) {
                    width: 100%;
                  }
                }
                h6{
                  font-size: 16px;
                  font-style: italic;
                  line-height: 1.63;
                }
              }
            }
          }
        }
      }

      .aht-page--left-sb {
        .aht-page {
          &__inner {
            display: flex;
            width: 100%;
            max-width: 1330px;
            margin: 0 auto;
          }
          &__sb {
            width: 380px;
            padding-right: 70px;
            padding-left:15px;
            background: #f6f9ff;
            position: relative;
            @media screen and (max-width: 768px) {
              width: 100%;
              padding-right: 0;
              padding-left: 0px;
            }

            &:before {
              content:"";
              background: #f6f9ff;
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 1000%;
              z-index: -1;
            }
          }

          &__content {
            padding-top: 120px;
            padding-bottom: 100px;
            width:calc(100% - 380px);
            padding-left:30px;
            padding-right:15px;

            .row {
                margin-right: -10px;
                margin-left: -10px;
            }

            ul {
              margin: 25px 0 20px;

              li {
                color:#999;
                margin: 10px 0;
                position: relative;
                padding-left: 15px;

                &:before {
                  content: "";
                  width: 4px;
                  height: 4px;
                  background: #ddd;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  border-radius: 50%;
                }
              }
            }

            .post-title {
              margin: 15px 0;
              font-size: 40px;
            }

            .post-cats {
              a {
                background: #2a74ed;
                color:#fff;
                font-size: 13px;
                line-height: 26px;
                display: inline-block;
                margin-right: 1px;
                border-radius: 3px;
                padding: 0 10px;
                &:hover {
                  background: #1954b4;
                  color:#fff;
                }
              }
            }

            .post-date {
              margin-bottom: 60px;
              @media screen and (max-width: 768px) {
                margin-bottom: 40px;
              }
              span {
                font-size: 16px;
                color:#999;
                margin: 0 15px;
                position: relative;

                &:not(:last-child):after {
                    content:"";
                    width:4px;
                    height: 4px;
                    background: #999;
                    border-radius: 50%;
                    position: absolute;
                    top: 7px;
                    right: -17px;
                }
              }
            }

            blockquote {
                padding: 120px 80px 40px 50px;
                @media screen and (max-width: 768px) {
                  padding: 120px 40px 40px 40px;
                }
                h3 {
                  font-style: italic;
                }
            }
          }
        }
        .content_img, .content_text{
          width: 50%;
          padding: 0px 15px;
          @media screen and (max-width: 1100px) {
            width: 100%;
          }
        }
        .content_img{
          @media screen and (max-width: 1100px) {
            display: flex;
            padding-bottom: 50px;
            justify-content: center;
          }
          @media screen and (max-width: 991px) {
            img{
              width: 100%;
            }
          }

        }
        .h-100 {
          height: 100%;
        }
        h6{
          font-size: 18px;
          font-weight: 500;
          line-height: 1.33;
        }
      }

    }

    &__chr {
      .aht-widget-posts {

        &__main-title {
          font-size: 24px;
          line-height: 1.25;
          text-align: left;
          font-weight: bold;
          letter-spacing: -0.6px;
        }

        &__list {
          margin-top: 53px;
        }
      }

      .widget_categories {
        .widget-title {
          font-size: 24px;
          line-height: 1.25;
          text-align: left;
          font-weight: bold;
          letter-spacing: -0.6px;
          margin-bottom: 28px;
        }
      }

      .aht-page--right-sb {
        .aht-page {
          &__sb {
            padding-right: 10px;
          }

          &__content {
            padding-top: 105px;

            blockquote {
              margin-top: 0;

              h3 {
                font-style: normal;
                letter-spacing: -0.75px;
                line-height: 1.33;
                font-family: 'SourceSansPro';
              }

              p {
                letter-spacing: 1.4px;
              }
            }

            .aheto-form--default {
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .f-30 {
        h2 {
          letter-spacing: -0.75px;
        }
      }

      .aht-page__sb-inner {
        padding-top: 110px;
      }
    }

    @include large-size-all {
      &__chr {
        .aht-page--right-sb {
          .aht-page {
            &__sb {
              padding-right: 15px;
            }

            &__content {
              padding-top: 85px;
              padding-bottom: 80px;
            }
          }

          .aht-page__sb-inner {
            padding-top: 90px;
          }
        }
      }
    }

    @include medium-size-all {
      &__sidebar .aht-page--left-sb .aht-page__sb ,
      &__sidebar .aht-page--right-sb .aht-page__sb {
          width: 35%;
          padding-left: 15px;
          padding-right: 15px;
      }

      &__sidebar .aht-page--left-sb .aht-page__content ,
      &__sidebar .aht-page--right-sb .aht-page__content {
          padding-top: 80px;
          padding-bottom: 60px;
          width: calc(100% - 35%);
          padding-right: 15px;
          padding-left:15px;
          float: left;
      }

      &__chr {
        .aht-page--right-sb {
          .aht-page {
            &__content {
              padding-top: 65px;
            }
          }

          .aht-page__sb-inner {
            padding-top: 73px;
          }
        }
      }
    }

    @include small-size-all {
      &__sidebar .aht-page--right-sb .aht-page__inner ,
      &__sidebar .aht-page--left-sb .aht-page__inner {
          display: block;
          width: 100%;
      }

      &__sidebar .aht-page--right-sb .aht-page__content ,
      &__sidebar .aht-page--left-sb .aht-page__content {
          padding-top: 60px;
          padding-bottom: 60px;
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
      }

      &__sidebar .aht-page__sb-inner {
          padding: 60px 15px 0;
      }

      &__sidebar .aht-page--right-sb .aht-page__sb {
          width: 100%;
          padding-left: 0;
          padding-right: 0px;
      }
      .post-meta .likes {
        float: none;
        margin-top: 20px;
      }


    }

    @include xs-size-all {
      &__sidebar .aht-page--right-sb .aht-page__inner ,
      &__sidebar .aht-page--left-sb .aht-page__inner {
          display: block;
          width: 100%;
      }

      &__sidebar .aht-page--right-sb .aht-page__content ,
      &__sidebar .aht-page--left-sb .aht-page__content {
          padding-top: 60px;
          padding-bottom: 60px;
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
      }

      &__sidebar .aht-page__sb-inner {
          padding: 60px 15px 0;
      }

      &__sidebar .aht-page--right-sb .aht-page__sb {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
      }

      &__chr {
        .aht-page--right-sb {

          .aht-page__sb-inner {
            padding-top: 43px;
          }
          .aht-page {
            &__content {
              padding-top: 34px;
              padding-bottom: 30px;
            }
          }
        }

        .aht-widget-posts {
          &__list {
            margin-top: 45px;
          }
        }

        // Quotes
        .aht-widget-quotes {
          margin-bottom: 50px;
        }
      }
    }

    &__edu {
      .aht-widget-advert {
        margin-top: 30px;
      }
    }

}

.page--single {

&__full {
  .page-content {
      padding: 120px 0 100px;
      @media screen and (max-width: 991px) {
        padding: 80px 0 60px;
      }
      @media screen and (max-width: 768px) {
        padding: 50px 0 30px;
      }
      .container {
        max-width: 900px !important;
      }
  }
}
.page-content {

.page-title {
  margin: 15px 0 10px;
  font-size: 40px;
}

.post-cats {
  a {
    background: #2a74ed;
    color:#fff;
    font-size: 13px;
    line-height: 26px;
    display: inline-block;
    margin-right: 1px;
    border-radius: 3px;
    padding: 0 10px;
    &:hover {
      background: #1954b4;
      color:#fff;
    }
  }
}

.post-date {
  margin-bottom: 60px;
  margin-left: -15px;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  span {
    font-size: 16px;
    color:#999;
    margin: 0 15px;
    position: relative;

    &:not(:last-child):after {
        content:"";
        width:4px;
        height: 4px;
        background: #999;
        border-radius: 50%;
        position: absolute;
        top: 7px;
        right: -17px;
    }
  }
}

}

.aht-page__sb-inner {
  .widget {
    margin-bottom: 50px;
    @include small-size-max {
      margin-bottom: 40px;
    }
    h6{
      font-weight: 500;
    }

    form {
      position: relative;

      input {
          border: 1px solid #eee;
          border-radius: 5px;
          background-color: #fff;
          width: 100%;
          height: 58px;
          padding: 0 40px 0 20px;
        }

      button {
        padding: 0;
        background: transparent;
        border: none;
        position: absolute;
        top: 0;
        right: 20px;
        line-height: 58px;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          transition: 0.3s all;
          color:#2A74ED;
        }
      }
    }
  }

  h6 {
    margin: 0 0 30px;
    @include small-size-max {
      margin: 0 0 15px;
    }
  }
  ul {
    margin: 0;

    li {
      color:#999;
      margin: 10px 0;
      position: relative;
      padding-left: 15px;

      a {
        color: #999;
        font-size: 16px;
        &:hover {
          color:#2A74ED;
        }
      }
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        background: #ddd;
        position: absolute;
        top: 10px;
        left: 0;
        border-radius: 50%;
      }
    }
  }
}
}

.related-posts {
  background: #f6f9ff;
  padding: 110px 0;

  &.alt {
    padding: 0;
    background: transparent;
    width: 100%;
  }

  @include small-size-max {
    padding: 50px 0;
  }

  .aheto-heading {
    margin-bottom: 50px;

    @include small-size-max {
      margin-bottom: 20px;
    }
  }

  .post {
    background-color: $c-white;
    box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0);
    position: relative;
    transition: 0.2s all;
    margin-bottom: 30px;
    margin-top:30px;
    &.background-transparent{
      background: transparent;
    }
    &:hover {
      box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
      transition: 0.2s all;
    }

    .content-wrapper {
      padding: 30px 30px 40px;
      .post-title {
        a {
          font-size: 24px;
          color: #222;
          line-height: 1.25;
          display: inline-block;
        }
      }

      .post-date {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 30px;
      }

    }

    .content-top-wrapper {

                .post-cats {
                  position: absolute;
                  top:30px;
                  left:30px;
                  a {
                    background: #2a74ed;
                    color:#fff;
                    font-size: 13px;
                    line-height: 26px;
                    display: inline-block;
                    margin-right: 1px;
                    border-radius: 3px;
                    padding: 0 10px;
                    &:hover {
                      background: #1954b4;
                      color:#fff;
                    }
                  }
                }
      .image-wrapper {
        img {
          display: block !important;
          width: 100%;
          height: auto;
          position: relative;
        }
      }

    }
  }

  .swiper-pagination-clickable .swiper-pagination-bullet {
      cursor: pointer;
      margin: 0 5px;
  }
}

.blog--single .widget {
.tweets {
  li {
    color: #999;
  }
  .date{
    font-size: 14px;
  }
  span{
    color:#999;

    a {
      color: #222;
      display: block;
    }
  }
}

}
.f-30{
  h2{
    font-size: 30px;
  }
}
.left-sidebar{
  padding-top: 10px;
  .widget_tags{
    padding-bottom: 0px;
    margin-top: 0px;
  }
}
.right-sidebar{
  padding-top: 20px;
  .aht-widget-advert{
    margin-top: 30px;
  }
}
@media screen and (min-width: 1051px) {


.blog--single__sidebar .aht-page--right-lb .aht-page__content {
    padding-right: 50px;
}

}

.home-blog {
  background: #f6f9ff;
}

.blog--single__sidebar {

  .post-head {
    padding: 130px 0;
    background-size:cover !important;
    position: relative;

    @include small-size-max {
      text-align: center;
      padding: 70px 0;
    }

    &:after {
        content:"";
        background: #000;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        opacity: 0.54;
    }

    .container {
      position: relative;
      z-index: 44;
    }
    .post-author {
      margin-top: 21px;

      @include small-size-max {
        margin-top: 50px;
      }

    }

    .post-title {
      color:$c-white;
      margin: 15px 0;
      font-size: 40px;
    }

    .post-cats {
      a {
        background: #2a74ed;
        color:#fff;
        font-size: 13px;
        line-height: 26px;
        display: inline-block;
        margin-right: 1px;
        border-radius: 3px;
        padding: 0 10px;
        &:hover {
          background: #1954b4;
          color:#fff;
        }
      }
    }

    .post-date {
      margin-left: -15px;
      margin-bottom: 0px;

      span {
        font-size: 16px;
        color:$c-white;
        margin: 0 15px;
        position: relative;

        &:not(:last-child):after {
            content:"";
            width:4px;
            height: 4px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            top: 7px;
            right: -17px;
        }
      }
    }

    .post-author {
      h6 {
        color:$c-white;
        margin-top: 18px;
        font-weight: 500;
        a {
          color:#ffffff;
          font-size: 16px;
          &:hover {
              color:#fff;
              opacity:0.6;
          }
        }
      }
    }

  }

  .related-posts .post .content-wrapper .post-date {
      margin-bottom: -20px;
  }

  .related-posts {
      background: transparent;
      padding: 60px 0 0;
  }
  .news-posts{
    padding: 0px;
  }
}
.blog--single__sidebar_two {
  .related-posts{
    padding: 30px 0 0;
    @media screen and (max-width: 991px) {
      padding: 10px 0 0;
    }
  }
  .aht-page__sb-inner{
    @media screen and (max-width: 758px) {
      padding: 40px 0 0;
    }
  }
  .related-posts {
    .post {
      .content-wrapper {
        .post-date {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.aht-page__sb-inner .widget_mc4wp_form_widget {
  @media screen and (max-width: 768px) {
    margin-bottom: 50px !important;
  }
}
.img-height{
  img{
    height: 600px;
    width: 100%;
    @media screen and (max-width: 991px) {
      height: 450px;
    }
    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }
}

.comment {
  max-width: 970px;
  margin: 0 auto;
  $this: &;
  &-item {
    border: solid 1px #eeeeee;
    background-color: $c-white;
    margin-bottom: 30px;
    padding: 30px;
    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
      padding: 25px;
    }
    @media screen and (max-width: 450px) {
      margin-bottom: 15px;
      padding: 15px 10px;
    }
  }
  &-children{
     margin-left: 70px;
      @media screen and (max-width: 768px) {
        margin-left: 15px;
      }
  }
  &-author{
    @media screen and (max-width: 768px) {
      padding-right: 10px;
    }
    .author-photo{
      width: 50px;
      height: 50px;
      margin-left: 10px;
    }
    .btn-reply{
      font-size: 14px;
      color: #bbbbbb;
      line-height: 1.71;
      display: inline-block;
      padding-top: 20px;
      &:hover{
        color: #222222;
        i{
          color: #222222;
        }
      }
      i{
        padding-right: 10px;
        font-size: 18px;
      }
    }
  }
  &-content{
    .author-name{
      font-size: 16px;
      font-weight: 500;
      color:#333;
      padding-right: 35px;
    }
    .comment-date{
      font-size: 14px;
      color: #bbbbbb;
      position: relative;
      &::before{
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #e0e0e0;
        position: absolute;
        top: 6px;
        left: -20px;
      }
    }
    .comment-text{
      font-size: 16px;
      line-height: 1.63;
      color: #999999;
      width: 100%;
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
}
// Single post with right sidebar
.related-posts.single_post_slider{
  padding-top: 0px;
  .post{
    box-shadow: none;
    .content-wrapper{
      padding: 40px 0px 0px;
      .post-date{
        margin-bottom: 10px;
      }
    }
  }
}
.comment-author{
  @media screen and (max-width: 1024px) {
    padding-right: 20px;
  }
  @media screen and (max-width: 991px) {
    padding-right: 10px;
  }
}
.sidebar-right{
  .widget{
    &:last-child{
      @media screen and (max-width: 768px) {
        margin-bottom: 0px;
      }
    }
  }
}
// calendar
.calendar{
  background: #fff;
  box-shadow: 0px 15px 30px 0 rgba(42, 116, 237, 0.1);
  background-color: #ffffff;
  padding: 30px 25px;
  @media screen and (max-width: 1024px) {
    max-width: 300px;
  }
  @media screen and (max-width: 768px) {
    margin-right: auto;
    margin-left: auto;
  }
  table {
    width: 100%;
    text-align: center;

    tr {
      th {
        text-transform: uppercase;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 2.29;
        color: #333333;
        padding: 10px 0px;
      }

      th:first-child {
        color: #e04040;
      }

      td {
        font-family: Roboto;
        font-size: 13px;
        letter-spacing: 0.3px;
        padding: 8px 0px;
      }
      td.active{
          background: #2a74ed;
          border-radius: 50%;
          color: #fff;
          width: 35px;
      }

      td:first-child {
        color: #e04040;
      }
    }

    tr:first-child {
      border-bottom: 1px solid #eeeeee;
    }

    tr.calendar-first-row {
      td {
        padding-top: 20px;
      }
    }
  }
  .calendar_header{
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    color: #cccccc;
    align-items: center;
    .month{
      font-size: 20px;
      font-weight: 500;
      line-height: 1.6;
      color: #333333;
      i{
        color: #cccccc;
        padding-right: 5px;
      }
    }
  }

}
// page-2-sidebars
.page_with_two_sidebars{
  .container {
    width: 100% !important;
    max-width: 1500px !important;
  }
}

// page post saas
.blog--single__post {
  .post-content {
    .container {
      max-width: 1000px !important;
      padding: 0px 15px;
      margin: 0 auto;
    }
    blockquote {
      padding: 0px 50px 0px 30px;
      border-left: 2px solid #2a74ed;
      @media screen and (max-width: 768px) {
        padding-right: 0px;
      }
      h3 {
        font-size: 30px;
        font-style: italic;
        line-height: 1.2;
      }
      p{
        margin-top: 0px;
      }
      &::before{
        display: none;
      }
    }
    .img_text_container{
      h6 {
        font-size: 18px;
        line-height: 1.44;
      }
      .img-content {
        float: left;
        padding-right: 30px;
        h6 {
          font-size: 16px;
          font-style: italic;
          line-height: 1.63;
        }
      }
    }
  }
}


.church-post-wrap {
  .church-post-wrap-1{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 282px;
    padding: 40px 30px;
    background-position: center;
    background-size: cover;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }

    &:hover {
      .church-post-wrap-1__icon-links {
        height: 50px;
      }
    }

    &__content {
      position: relative;

    }

    &__icon-links {
      height: 0;
      transition: all 0.5s;
      overflow: hidden;
    }

    &__icon-link {
      color: $c-white;
      font-size: 40px;
      display: block;
    }

    &__icon {
      color: inherit;
    }

    &__link-wrap {
      height: 0;
      margin-top: 0;
      transition: all 0.5s;
      overflow: hidden;
    }

    .church-post-wrap-1__link {
      color: $c-white;
      font-size: 12px;
    }
  }

  .head{
    font-size: 24px;
    font-weight: bold;
    line-height: 1.08;
    letter-spacing: -0.6px;
    margin-top: 46px;
    margin-bottom: 25px;
  }
}


.blog--single__restaurant {

  .post-head {
    padding: 140px 0 200px 0;
    z-index: 1;
    @include xs-size-all {
      padding: 100px 0;
    }
    .post-cats {
      a {
        background-color: #80261b;
        font-family: $f-roboto;
        font-weight: 400;
        padding: 0 12px;
        -webkit-font-smoothing: antialiased;
        &:hover {
          background-color: #61251b;
        }
      }
    }
    .post-title {
      font-size: 60px;
      letter-spacing: 0.6px;
      margin: 20px 0;
      -webkit-font-smoothing: antialiased;
      @include xs-size-max {
        font-size: 50px;
      }
    }
    .post-date {
      font-weight: 500;
      -webkit-font-smoothing: antialiased;
      span:not(:last-child):after {
        top: 11px;
      }
    }
    .post-author-wrap {
      display: flex;
      justify-content: flex-end;

      @include small-size-max {
        display: block;
      }
      .post-author {
        margin-top: 34px;
      }
    }
  }

  .post-head:after {
    opacity: 0.7;
  }
  .aht-page--right-sb {
    .aht-page {
      &__inner {
        max-width: 1215px;
      }
      &__sb {
        background-color: #fff;
        width: 335px;
        padding-left: 30px;
        &:before {
          content: none;
        }
        @include medium-size-all {
          width: 35%;
        }
        @include small-size-max {
          width: 100%;
          padding-left: 0;
          padding-right: 0px;
          }
      }
      &__sb-inner {
        @media screen and (max-width: 768px) {
          padding: 80px 0 0;
        }
        @include small-size-max {
          padding: 0 15px 80px;
        }
        @include xs-size-max {
          padding: 0 15px 50px;
        }
        .widget_search {
          max-width: 270px;
          @include small-size-max {
            max-width: unset;
          }
          .search-submit {
            top: 52%;
            padding-bottom: 4px;
          }
          .search-field {
            border: 1px solid #eeeeee;
            border-radius: 0;
            height: 44px;

            &::-webkit-input-placeholder {font-size: 0;}
            &::-moz-placeholder          {font-size: 0;}/* Firefox 19+ */
            &:-moz-placeholder           {font-size: 0;}/* Firefox 18- */
            &:-ms-input-placeholder      {font-size: 0;}
            &:focus {
              box-shadow: none;
              border: 1px solid #80261b;
            }
          }
          .icon_search:before {
            content: '\f2f5';
            font-family: $f-ionicons;
            font-size: 24px;
            color: #222;
            opacity: 0.5;
          }
        }
        .widget_tags {
          .widget-title {
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 20px;
            border-bottom: 1px solid #eee;
            max-width: 270px;
            @include small-size-max {
              max-width: unset;
            }
          }
          ul {
            margin-top: 25px;
          }
          .cat-item {
            a {
              padding: 6px 13px 4px 13px;
              font-weight: 400;
              -webkit-font-smoothing: antialiased;
              &:hover {
                background-color: #80261b;
                border: 1px solid #80261b;
              }
            }
          }
        }
      }
      &__content {
        padding: 100px 30px 80px 30px;
        max-width: 900px;
        width: 100%;
        .post-quote {
          padding-left: 70px;
          padding-right: 0;
          @include xs-size-all {
            padding-left: 20px;
          }
        }
        @include large-size-all {
          padding: 100px 30px 80px 15px;
        }

        @include medium-size-max {
          padding: 80px 15px 80px 15px;
        }
        @media screen and (max-width: 768px) {
          padding: 80px 15px 35px 15px;
        }
        @include xs-size-max {
          padding: 50px 15px 30px 15px;
        }
        blockquote {
          border-left: 2px solid #80261b;
          margin-top: 60px;
          padding: 0 0 0 30px;
          h3 {
            font-weight: 700;
            -webkit-font-smoothing: antialiased;
          }
          p {
            margin-top: 34px;
            color: #80261b;
          }
        }
        .img_text_container {
          .img_text_container-bg-wrap {
            width: 378px;
            position: absolute;
            z-index: -1;
            left: -65px;
            @include xs-size-max {
              width: 280px;
            }
            img {
              width: 100%;
            }
          }
          .img-content {
            padding-top: 42px;
          }
          .first-subtitle {
            b {
              font-size: 18px;
              font-weight: 500;
              padding-left: 14%;
              @media screen and (max-width: 1024px) {
                padding-left: unset;
              }
            }
          }
          .second-subtitle {
            b {
              font-size: 16px;
              font-style: normal;
            }
          }
        }
        .post-meta {
          margin: 60px 0 40px;
          @include xs-size-max {
            margin: 30px 0 50px;
          }
          .post-likes-button {
            padding-right: 10px;
            .likes {
              padding: 0 24px;
            }
          }
        }
        .post-author-info {
          p:last-child {
            color: #80261B;
            a {
              text-decoration: none!important;
              border-bottom: none;
              position: relative;
              &:after {
                position: absolute;
                content: '';
                border-top: 1px solid #80261b;
                bottom: 4px;
                left: 0;
                width: 100%;
              }
              &:hover {
                color: #80261B;
              }
            }
          }
        }
        .text-center {
          .aht-socials {
            &__link {
              color: #999;
              &:hover {
                color: #fff;
              }
            }
          }
        }
        .aht-course--comments {
          .aht-page__content-inner {
            .comment {
              &-content {
                .author-name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #333;
                }
              }
            }
          }

        }
        .aht-course--comments,
        .reply-heading {
          .aheto-heading__title {

            b {
              color: #222;

            }
          }
        }
        .aheto-form {
          .wpcf7-form {
            p {
              margin-bottom: 0;
            }
          }
          input, textarea {
            border-radius: 0;
          }
          input:focus, textarea:focus {
            box-shadow: none;
          }
          input[type="submit"] {
            text-transform: uppercase;
            box-shadow: none;
            padding-top: 22px;
            margin: 30px auto 0;
            &:hover {
              border: 1px solid #80261B;
            }
          }
        }
      }
    }
  }
}