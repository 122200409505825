.aheto-header-8 {

  &__inner {

    @include menu-size-min {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__line {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1750px;
    margin: 0 auto;

    @include menu-size-max {
      padding-bottom: 6px;
    }

    @include menu-size-min {
      padding-top: 25px;
      padding-bottom: 0px;
    }
  }

  // Buttons
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (min-width: 1500px) {
      flex: 1 1 0;
    }

    @include menu-size-max {
      max-width: 284px;
      position: absolute;
      top: 8px;
      right: 36px;
    }

    @include xs-size-max {
      max-width: 100%;
      position: relative;
      top: 0px;
      right: 0px;
    }

    @include menu-size-max {
      flex-basis: 100%;
      justify-content: space-between;
      padding-bottom: 10px;
    }
  }

  &__button {
    padding: 0 15px;
  }

  // Logo
  &__logo {
    padding: 15px;

    @media screen and (min-width: 1500px) {
      flex: 1 1 0;
    }

    @include menu-size-max {
      padding: 5px 15px;
    }
  }

  // Search
  &__search {

    @include menu-size-max {
      order: 2;
      padding: 0 10px;
    }
  }

  &__search-link {
    display: inline-block;
    padding: 15px;

    @include menu-size-max {
    }

    @include menu-size-max {
      padding: 5px;
    }
  }

  &__search-icon {
    color: $c-white;
    font-size: 24px;
    transition: 0.2s all;
    &:hover {
      transition: 0.2s all;
    }
  }

  &__hamburger {
    margin-left: auto;

    @include menu-size-min {
      display: none;
    }
  }

  // Menu
  &__menu {
    padding: 0 15px;

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 0 15px 30px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: calc(100vh - 54px)
    }

    @include xs-size-max {
        height: calc(100vh - 110px)
    }

  }

  // Main menu
  .main-menu {
    display: flex;

    @include menu-size-min {
      .menu-item {
        a {
          display: inline-block;
          font-size: 15px;
        }

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      > .menu-item {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        > a {
          padding: 13px 0;
          color: $c-white;
        }

        @media screen and (min-width: 1500px) {
          margin-right: 35px;
        }
      }
    }

    @include menu-size-max {
      flex-direction: column;

      .menu-item {
        a {
          color: $c-white;
          font-size: 15px;
        }
      }

      > .menu-item {
        margin-top: 15px;

        > a {
          font-size: 21px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 30px 0;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;

      .sub-menu {
        top: -30px;
        left: 102%;
      }

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 230px;
        padding: 0 30px;

        a {
          padding: 8px 0;
          font-weight: 400;
        }
      }

      .dropdown-btn {
        padding-bottom: 0;

        &:before {
          content: '\f10a';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 10px 30px 0;
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      padding: 0;

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-black, 0.1);
      }

      &__title {
        margin-bottom: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba($c-black, 0.1);
        font-size: 20px;
        font-weight: 400;
      }

      .menu-item {
        padding: 0;
      }
    }

    @include menu-size-max {
      &__col {
        margin-top: 10px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        display: inline-block;
        margin: 0 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba($c-white, 0.3);
        color: darken($c-white, 10%);
        font-size: 20px;
        font-weight: 700;
      }
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    display: inline-block;
    margin-left: 10px;
    padding-bottom: 3px;
    color: $c-white;
    font-size: 11px;

    &:before {
      content: '\f104';
    }
  }

  // Button
  .aheto-btn {
    padding: 20px 35px;
    background-color: $c-white;
    font-size: 14px;
    font-weight: 700;

    @media screen and (max-width: 1500px) {
      padding: 15px 20px;
    }

    @include menu-size-max {
      padding: 8px 20px;
    }

    &:hover {
      color: $c-white;
    }

  }

  // logo
  .logo {
    &__text {
      color: $c-white;
      font-weight: 700;
      letter-spacing: 2px;
    }
  }
}
