.tm {

  &__avatar {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }

  &--default {
    position: relative;
    height: 100%;
    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
      opacity: 0;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    .tm__author {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .tm__avatar {
      margin-right: 10px;
      margin-left: 2px;
      border-radius: 50%;
      background-size: cover;
    }

    .tm__info {
      flex: 1;
      padding-top: 5px;
    }

    .tm__position {
      margin: 3px 0 0;
      line-height: 1.25;
    }

    .tm__name {
      margin: 0;
      line-height: 1.3;
    }

    .tm__stars {
      display: flex;
      margin-top: 20px;

      i {
        display: inline-block;
        margin-right: 1px;

        &.ion-ios-star {
          position: relative;
          top: -1px;
        }
      }
    }
  }

  &--home-event {
    text-align: center;

    .tm__avatar {
      width: 110px;
      height: 110px;
      margin: 0 auto;
      border-radius: 50%;
      background-size: cover;
    }

    .tm__info {
      display: flex;
    }

    .tm__name {
      display: inline-block;
      flex-basis: 50%;
      margin: 0;
      padding: 7px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
    }

    .tm__position {
      display: inline-block;
      flex-basis: 50%;
      margin: 0;
      padding: 7px;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
    }

    .tm__text {
      max-width: 830px;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
      line-height: 36px;
    }

    .tm__stars {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      i {
        display: inline-block;
        margin-right: 1px;
        font-size: 22px;

        &.ion-ios-star {
          position: relative;
          top: -1px;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .tm__avatar {
        width: 80px;
        height: 80px;
      }
      .tm__text {
        font-size: 16px;
      }
    }
  }

  &--barbershop {
    display: flex;
    flex-direction: column;
    margin: 0 15px;

    .tm {
      &__text {
        margin-top: 10px;
        margin-bottom: 0;
      }
      &__content {
        position: relative;
        order: -1;
        padding: 30px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAA+CAYAAAC875yHAAADIUlEQVR4nOWc4VLEIAyEt9y9/xN7V3+cUeRo2UACqe5M1XEUtl9C2qPQDTolAFt25Norx2ptlSNX7vXZ2wHzN+nr0EhMdRkb1AbgBu78colfOvitDm7Qgyu1A3hgTkb2BrvUEy/PVIdHv++J4Jke8M3GDcDduM0PNAJ/OzBiDQ/4qZ8emZhgD0/aBU48lwC94OXtW0P0yLxcpxBLgHf4wRNZQvSGJzqEmBdbz8yr9Tta6IF6CfJSlY+cxAabE9JoNGBSU2fqLWCp+D5bIxm0wrNcI95MrALYm/krsq/at1UtGlFP/6vgiVL+w2ozPVkYJugRAAIxPGiV5EsE85qMiuAXyABGURQwrDYgFsBIXlhtCTEmPoHrZSDwBfBqihJw4MIZGMZzwpop9yOxEMN4lgyMElFWUfx+18AwESXV/RTNWjnAKFFlFQogQD6FCiR52rdUtfm4lbc22qzaUX9gPk0lQBnGqyD2DMuVEPdaBspVeQSitKE9qd4haRH4Ls9nU+pP9EX2gZ+VCNr/H7kw5AC0N+WyEkESh/3/agbWTImOGi5N5GKzwurWJL+jOAMh62BKz5oA7Mwz1fJqV1vhZCHL2yjx/EAdxllfGh8UwJEO/nK7ALBHmo2JciOvqp/eAK84w8LqCfjf711udgWKCwgQZxo9Uvapgu4NkG0/SgaqR4wnwL88fKcAvFr2AZznXx8uVmdgpHlI9hPIr4B7Aewys1hs9k0ByKz7i5R9AMfiLeAeANnsWz6bnIkJeHWywwMgYyYSPHZ5XdWzNUBmLm3V9q8jsfCq5cYS4Nv64YpCPAjKxKzQPQ24JcArDt3hgFsBZLYszNpwyMok4BYAmWHgvdFQKybgzY2GIBppKaEdyYjwWgGn4AHjO4X+NTygHyADT2VkglrwujaG9wB0MeIoZgvvjlfAuxq3NEJvlZ8k9zLDAmRqR6R6x2bd8EhpAWQiGCnr2JdOmAX7CCADLlKtY8GZey4Bsq85iTRcmdcUuAW7XNrBfByLAk7UqnOusz/s4qJoU1AtTSsvZwAj1ThW6lc3jaoEKNl2pb0jS0fIJ7nl2Hmlq1xeAAAAAElFTkSuQmCC') no-repeat top right;
        box-shadow: 0px 4px 35px 0 rgba(0, 0, 0, 0.10);
        &:after {
          content: '';
          position: absolute;
          left: 45px;
          bottom: -25px;
          border: 10px solid transparent;
          border-top: 15px solid $c-white;
        }
      }
      &__author {
        display: flex;
        padding-left: 23px;
        margin-top: 40px;
      }
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;
      }
      &__name {
        margin: 0 0 5px;
        font-size: 17px;
        font-weight: 800;
        letter-spacing: 1.4px;
        text-transform: uppercase;
      }
      &__position {
        margin: 0;
        line-height: 1.3;
        &:before {
          content: '/';
          margin-right: 5px;
        }
      }
      &__avatar {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
        overflow: hidden;
      }
    }
  }

  &--business {
    display: flex;
    flex-direction: column;

    .tm__author {
      display: flex;
      align-items: center;
      order: 2;
      margin-top: 35px;
      padding: 0 25px;
    }

    .tm__content {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 35px 25px 40px;
      border-radius: 5px;
      background: $c-white;

      &:after {
        position: absolute;
        bottom: -16px;
        left: 50px;
        width: 0;
        height: 0;
        border-top: 16px solid $c-white;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        content: '';
      }
    }

    .tm__avatar {
      margin-right: 15px;
    }

    .tm__name {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }

    .tm__position {
      margin: 0 0 5px;
      font-size: 14px;
      font-weight: bold;
    }

    .tm__stars {
      order: -1;
      margin-bottom: 10px;
      color: #FFC015;
      font-size: 18px;
    }

    .tm__text {
      margin: 0;
      font-size: 15px;
      line-height: 1.6;
    }
  }

  &--yoga {
    display: flex;
    flex-direction: column;

    .tm {

      &__author {
        display: flex;
        margin-top: 50px;
        padding: 0 25px;
      }

      &__avatar {
        margin-right: 20px;
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &__name {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__position {
        margin: 7px 0 0 -5px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1px;

        &:before {
          content: '/ ';
        }
      }

      &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        order: -1;
        padding: 30px 30px 25px;
        background-color: $c-white;
        box-shadow: 0px 4px 30px 0px rgba($c-black, 0.1);

        &:before {
          display: block;
          position: absolute;
          top: 1px;
          right: 0;
          width: 89px;
          height: 62px;
          font-family: 'Times New Roman';
          font-size: 200px;
          font-weight: bold;
          line-height: 137px;
          content: '”';
          opacity: 0.05;
          overflow: hidden;
        }

        &:after {
          display: block;
          position: absolute;
          bottom: -18px;
          left: 53px;
          width: 0;
          height: 0;
          border-top: 18px solid $c-white;
          border-right: 11px solid transparent;
          border-left: 11px solid transparent;
          content: '';
        }
      }

      &__stars {
        order: -1;
        color: #FFC015;

        i {
          font-size: 18px;
        }
      }
    }
  }

  &--edu {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    padding: 80px 30px 70px;
    transition: all 0.3s ease-out;
    background-color: $c-white;
    box-shadow: 0px 5px 15px 3px rgba($c-black, 0.05);

    @include medium-size-max {
      justify-content: space-around;
    }

    @include small-size-min {
      // min-height: 465px;
      min-height: 265px;
      padding-right: 45px;
      padding-left: 45px;
    }



    &:before {
      display: block;
      position: absolute;
      top: -20px;
      left: 40px;
      transition: all 0.3s ease-out;
      color: #222;
      font-family: 'Arial';
      font-size: 85px;
      font-weight: bold;
      line-height: 65px;
      content: '”';
    }

    @include menu-size-min {
      &:hover {
        transform: translate(0, -24px);

        .tm {
          &__name,
          &__position,
          &__text {
            color: $c-white;
          }
        }
      }
    }

    .tm {

      &__author {
        display: flex;
        align-items: center;
        margin-top: 45px;
      }

      &__avatar {
        margin-right: 15px;
        box-shadow: 0px 10px 20px 0px rgba($c-black, 0.1);
      }

      &__name {
        margin: 0;
        transition: all 0.3s ease-out;
        font-size: 18px;
        font-weight: bold;
      }

      &__position {
        margin: 5px 0 0;
        transition: all 0.3s ease-out;
        font-size: 14px;
        font-weight: 500;
      }

      &__content {
        order: -1;
      }

      &__text {
        margin: 0;
        transition: all 0.3s ease-out;
        font-family: $f-roboto;
      }
    }
  }

  @media screen and (max-width: 480px) {
    &--edu {
      min-height: 320px;
    }

  }


  &--edu-2 {
    display: flex;
    flex-direction: column;

    .tm__author {
      display: flex;
      align-items: center;
      order: 2;
      margin-top: 35px;
      padding: 0 25px;
    }

    .tm__content {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 35px 25px 40px;
      border-radius: 5px;
      background: $c-white;

      &:after {
        position: absolute;
        bottom: -16px;
        left: 50px;
        width: 0;
        height: 0;
        border-top: 16px solid $c-white;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        content: '';
      }
    }

    .tm__avatar {
      margin-right: 15px;
    }

    .tm__name {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }

    .tm__position {
      margin: 0 0 5px;
      font-size: 14px;
      font-weight: 500;
    }

    .tm__stars {
      display: flex;
      order: -1;
      margin-bottom: 7px;
      color: #FFC015;
      font-size: 18px;
    }

    .tm__text {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--construction {
    display: flex;
    flex-direction: column;
    max-width: 470px;
    min-height: 300px;
    border-radius: 5px;
    background: $c-white;

    .tm {
      &__author {
        display: flex;
        align-items: center;
        order: 2;
        margin-bottom: 35px;
        padding: 0 35px;
      }
      &__content {
        display: flex;
        position: relative;
        flex-direction: column;
        padding: 45px 35px;
      }
      &__avatar {
        margin-right: 22px;
      }
      &__name {
        margin: 0;
        font-size: 20px;
        font-weight: bold;

      }
      &__position {
        margin: 0 0 5px;
        font-size: 16px;
        font-weight: 400;
      }
      &__stars {
        order: -1;
        margin-bottom: 10px;
        color: #FFC015;
        font-size: 20px;
      }
      &__text {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
      }
      &__info {
        padding-top: 15px;
      }
    }
  }


  &--restaurant {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .tm {
      &__content {
        order: -1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
      }
      &__text {
        order: 2;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 24px;
        margin-bottom: 0;
        -webkit-font-smoothing: antialiased;
      }
      &__author {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__stars {
        margin-bottom: 26px;
        font-size: 26px;
        letter-spacing: 7.8px;
        display: flex;
        justify-content: center;
      }
      &__info {
        margin-top: 20px;
      }
      &__name {
        font-size: 18px;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
      }
      &__position {
        display: none;
      }
    }
  }
}

.rest-testimonials-section {
  background-position: left;
  background-repeat: no-repeat;
  max-width: 1920px;
}
@media screen and (max-width: 1200px) {
  .rest-testimonials-section {
    background-position: center;
    background-size: cover;
  }
}

.rest-reservation-members-section {
  background-repeat: no-repeat;
  background-position: right bottom;
}

.aht-tm {

  &__img {
    border-radius: 50%;
    background-position: center;
    background-size: cover;
  }

  &--trvl {
    .aht-tm {

      &__content {
        &:before {
          display: block;
          position: absolute;
          top: 10px;
          right: 25px;
          color: rgba($c-black, 0.05);
          font-family: 'Arial';
          font-size: 105px;
          font-weight: bold;
          line-height: 65px;
          content: '”';
        }
      }

      &__title {
        margin: 14px 0 0;
      }

      &__desc {
        margin: 20px 0 0;
        line-height: 24px;
      }

      &__author {
        display: flex;
        margin-top: 33px;
      }

      &__img {
        width: 70px;
        height: 70px;
        margin-right: 18px;
      }

      &__text {
        padding-top: 20px;
      }

      &__name {
        margin: 0;
        font-size: 18px;
      }

      &__pos {
        margin: 0;
        font-size: 13px;
        font-style: italic;

        &:before {
          content: '/ '
        }
      }
    }
  }

}

// agency

.aht-tm {
  &--agency {
    position: relative;
    padding: 80px 50px 50px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 270px;
    box-shadow: 0px 5px 20px 0 rgba(39, 56, 154, 0.07);
    transition: all .3s ease;
    &:before {
      display: block;
      position: absolute;
      top: -10px;
      left: 25px;
      color: $c-black;
      font-family: 'Arial';
      font-size: 105px;
      font-weight: bold;
      line-height: 65px;
      content: '”';
    }
    @include xs-size-max {
      padding: 40px 40px 50px 40px;
    }
    
    .aht-tm {
      
      &__title {
        margin: 14px 0 0;
      }
      
      &__desc {
        margin: 20px 0 0;
        line-height: 24px;
        transition: all .3s ease;
      }
      
      &__author {
        display: flex;
        margin-top: 33px;
      }
      
      &__img {
        width: 70px;
        height: 70px;
        margin-right: 18px;
      }
      
      &__text {
        padding-top: 20px;
      }
      
      &__name {
        margin: 0;
        font-size: 18px;
        transition: all .3s ease;
      }
      
      &__pos {
        margin: 0;
        font-size: 13px;
        transition: all .3s ease;
      }
    }
  }
}

.swiper {

  &--agency {

    .swiper-container {
      padding: 0 15px;
      margin: 0 -15px;
    }
    .swiper-slide {
      padding-top: 50px;
      padding-bottom: 20px;
  
      &:hover {
        .aht-tm--agency {
          top: -30px;
          transition: all .3s ease;
        }
      }
    }

  }
}