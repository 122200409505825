.aht-event {

  &--chr {
    position: relative;
    background-color: $c-white;

    .aht-event {

      &__title {
        margin: 0;
        font-weight: bold;
      }

      &__date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        color: $c-white;
        text-align: center;
      }

      &__day {
        font-size: 30px;
        line-height: 1.3;
      }

      &__month {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 1;
        text-transform: uppercase;
      }

      &__detail {
        display: flex;
      }

      &__icon {
        display: inline-block;
        margin-right: 15px;
        // padding-top: 3px;
      }

      &__text {
        flex: 1;
        margin: 0;
        font-weight: normal;
        line-height: 1.5;
      }
    }
  }

  &--chr-1 {
    padding: 23px 70px 25px 70px;
    transition: box-shadow 0.3s;
    box-shadow: 0px 10px 40px 0px rgba($c-black, 0.05);
    z-index: 1; 

    &:hover {
      box-shadow: 0px 0px 73px 0px rgba($c-black, 0.07);
    }

    @include medium-size-min {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 160px;
    }

    @include medium-size-max {
      padding: 30px;
    }

    .aht-event {
      &__date {
        position: absolute;
        top: -45px;
        left: 65px;
      }

      &__title {
        flex: 3 1 0;
        margin-top: 25px;
        margin-right: 126px;
        line-height: 40px;
        letter-spacing: -0.8px;

        @include medium-size-max {
          margin-bottom: 30px;
        }
      }

      &__details {
        display: flex;
        flex: 5 1 0;

        @include large-size-min {
          align-items: center;
        }

        @include large-size-max {
          flex-direction: column;
          flex-grow: 4;
          margin-right: 15px;
        }

        @include medium-size-max {
          margin-bottom: 30px;
        }
      }

      &__detail {
        @include large-size-min {
          flex: 1 1 0;
          margin-right: 15px;
        }

        @include large-size-max {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__icon {
        font-size: 22px;
      }

      &__link {
        border-color: #eee;
        width: 111px;
        height: 111px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        // box-sizing: border-box;
        
        &:hover {
          color: $c-white;
        }
      }
    }
  }

  &--chr-2 {
    margin-top: 40px;
    height: 410px;
    padding: 0px 40px 40px 40px;
    box-shadow: 0px 30px 40px 0px rgba($c-black, 0.07);
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    

    .aht-event {
      &__date {
        position: absolute;
        top: -40px;
        left: 38px;
      }

      &__title {
        font-size: 24px;
        line-height: 30px;
      }

      &__details {
        margin-top: 40px;
      }

      &__detail {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__icon {
        margin-top: 3px;
        margin-right: 15px;
        font-size: 22px;
      }

      &__text {
        line-height: 24px;
      }

      &__link {
        margin-top: 49px;

        &:hover {
          color: $c-white;
        }
      }
    }
  }
}

.church-wrap-3 {
  .aht-event {
    &--chr {
      .aht-event {
        &__text {
          font-weight: bold;
        }
      }
    }
  }
}

//church-events page
.church-events-wrap {
  .aht-event {
    &--chr{
      .aht-event{
        &__month {
          letter-spacing: normal;
        }
      }
    }

    &--chr-2 {
      height: 449px;
      width: 100%;
      padding: 87px 63px 43px 40px;
      box-shadow: 0px 38px 49px 0 rgba($c-black, 0.07);

      .aht-event {
        &__title {
          letter-spacing: -0.6px;
        }

        &__details {
          margin-top: 32px;
        }

        &__icon {
          margin-top: 0;
          margin-right: 18px;
        }

        &__link {
          margin-top: 45px;
        }
      }
    }
  }
}

@media screen and (min-width: 999px) and (max-width: 1665px) {
  .aht-event {
    &--chr-2 {
      .aht-event {
        &__details {
          margin-top: 30px;
        }

        &__detail {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__link {
          margin-top: 30px;
        }
      }
    }
  }
}

@media screen and (min-width: 1665px) and (max-width: 1820px) {
  .aht-event {
    &--chr-2 {
      padding: 0px 20px 40px 30px;
    }
  }
}


//church events page
@media screen and (min-width: 1200px) {
  .church-events-padding-t {
    padding-top: 124px;
  }

  .church-events-padding-b {
    padding-bottom: 130px;
  }

  .padding-sermons-chr-2 {
    padding-top: 70px;
    padding-bottom: 115px;
  }
}


@media screen and (min-width: 992px) and (max-width: 1100px) {
  .church-events-wrap {
    .aht-event {
      &--chr-2 {
        padding-right: 20px;

        .aht-event{
          &__details {
            margin-top: 45px;
          }
        }
      }
    }
  }
}

