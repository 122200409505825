.blog--centered {
    background: #f6f9ff;

    .aht-page__content-inner {
      max-width: 972px;
      margin: 0 auto;
    }
.post {
  background-color: $c-white;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0);
  position: relative;
  min-height: 335px;
  margin-bottom: 30px;
  transition: 0.2s all;

.post-cats {
  margin-bottom: -10px;
}
  &:hover {
    box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
    transition: 0.2s all;
  }

  .content-top-wrapper {
    padding: 40px 50px;
    .post-title {
      a {
        font-size: 24px;
        color: #222;
        line-height: 1.25;
        display: inline-block;
      }
    }

    .post-date {
      font-size:13px;
      line-height: 16px;
      margin-bottom: 0px;
    }
  }

  .content-wrapper {
    padding: 45px 50px;
    display: table;
    width: 100%;
}

  .post-info {
    border-top: 1px solid #eeeeee;
    padding: 0 50px;
    display: table;
    width: 100%;

    &__item {
      width: 33.33333%;
      float: left;
      line-height: 59px;
      border-right: 1px solid #eee;
      text-align: center;
      padding: 0 30px;
      font-size: 14px;

      i {
        color:#cccccc;
        margin-right: 10px;
      }

      &:last-child {
        border-right: none;
      }

      @include xs-size-max {
        width: 100%;
        float: none;
      }

      &.post-author {
        text-align: left;
        min-height: 60px;
        img {
          display: inline-block;
        }

        h6 {
          font-size: 14px;
          padding-left: 15px;
          display: inline-block;
          line-height: 35px;

          a {
            color:#222;

            @include large-size-max {
              font-size: 13px;
            }

            @include small-size-max {
              font-size: 13px;
            }

            @include xs-size-max {
              font-size: 13px;
            }

            &:hover {
              color:#2a74ed;
            }
          }
        }
      }

    }

  }

  .image-wrapper {
    img {
      display: block !important;
      width: 100%;
      height: auto;
      position: relative;
    }
  }

  .content-top-wrapper {
    .post-cats {
      a {
        background: #2a74ed;
        color:#fff;
        font-size: 13px;
        line-height: 26px;
        display: inline-block;
        margin-right: 1px;
        border-radius: 3px;
        padding: 0 10px;
        &:hover {
          background: #1954b4;
          color:#fff;
        }
      }
    }
  }
}

.format-gallery {
  .gallery-wrapper {
    .gallery-image {
        width:33.333333%;
        float: left;
        min-height: 250px;
        background-size: cover !important;
        position: relative;
        background-position: center;
        .gallery-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.7);
          opacity:0;

          i {
            color: #fff;
            font-size: 24px;
            position: relative;
            top: 50%;
            display: block;
            text-align: center;
            transform: translateY(-50%);
          }
        }

        &:hover {
          .gallery-overlay {
            opacity: 1;
          }
        }
    }
  }
}

.format-quote {

  .content-quote-wrapper {
    text-align: center;
    background: #222222;
    position: relative;;
    box-shadow: none;
    padding: 80px 50px 40px 50px;

  &:before {
    content: '”';
    position: absolute;
    font-family: 'Times New Roman';
    font-size: 180px;
    font-weight: bold;
    top: 24px;
    right: 46px;
    color: #3f3e3e;
    line-height: 120px;
  }

  p {
      font-size: 30px;
      font-family: Playfair Display, serif;
      color: #fff;
      font-style: italic;
      line-height: 1.333;
  }

  cite {
    color: #2a74ed;
    font-size: 14px;
    line-height: 26px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-top: 40px;
    display: block;
  }
  }
}

.format-standard {
  .content-wrapper {
      padding: 20px 50px 45px;
  }
}

.format-slider {

  .swiper {
    &-button-prev:before,
    &-button-next:before {
    display: none;
    }

    &-button-prev,
    &-button-prev:hover {
      color: #fff;
      left: 35px;
      font-size: 30px;
      margin-top: 10px;
    }

    &-button-next ,
    &-button-next:hover {
      color: #fff;
      right: 35px;
      font-size: 30px;
      margin-top: 10px;
    }
  }
}

.format-video {

  .video-wrapper a {
    position: relative;
    display: table;

    &:before {
        content:"\f04b";
        font-family: Fontawesome;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 20px 30px 0px rgba(42, 116, 237, 0.1);
        position: absolute;
        width: 100px;
        height: 100px;
        z-index: 44;
        text-align: center;
        line-height: 100px;
        font-size: 20px;
        top: 50%;
        left: 0;
        right: 0;
        margin: -50px auto 0;
    }
  }
}

.format-audio {
  .audio-wrapper {
    padding: 0 50px 0;
  	.mejs__container {
  		background: #222;
  		min-height: 60px;
  		width: 100% !important;
  		max-width: 100%;
  	}

  	.mejs__horizontal-volume-slider {
  		display:none !important;
  	}

  	.mejs__controls {
  		height: 60px;
  		padding:0;
  	}

    .mejs__button.mejs__volume-button {
        height: 60px;
        margin: 0;
        width: 60px;
        border-left: 1px solid #3f3e3e;
        text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
    }

  	.mejs__button.mejs__playpause-button {
  	    height: 60px;
  		  margin: 0;
  		  width: 60px;
  		  border-right: 1px solid #3f3e3e;
  		  text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
  	}

    .mejs__time {
        color: #fff;
        font-size: 11px;
        height: 60px;
        padding: 26px 11px 0;
        font-family: Roboto;
        font-weight: 400;

        &-total {
            background: #ffffff;
            margin: 19px 0 0;
            width: 100%;
            border-radius: 0;
            height: 2px;
        }

        &-handle-content {
            border: none;
            border-radius: 0;
            height: 2px;
            width: 10px;
        }

        &-hovered {
            border-radius: 0;
            height: 2px;
        }

        &-buffering {
          border-radius: 0;
          height: 2px;
          margin-top:1px;
        }

        &-current {
            border-radius: 0;
            height: 2px;
            background: #999 !important;
            margin-top:0px;
        }

        &-loaded {
            display: none;
        }
    }
  }
}

.blog-pagination-wrapper .pagination {
  .wrap .page-numbers, .prev span {
    color: #222222;
    transition: 0.2s all;
  }
  .next {
    span {
      color: #222222;
      transition: 0.2s all;
    }
    &:hover span {
      color: #2a74ed;
    }
  }
  .prev:hover span {
    color: #2a74ed;
  }
  .wrap .page-numbers {
    &:hover, &.current {
      background: #2a74ed;
      color: #fff;
    }
  }
}

@include small-size-max {

  .post {
  .content-top-wrapper {
    padding: 30px 30px;
  }

  .content-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .post-info {
    padding: 0;
}

&.format-gallery .gallery-wrapper .gallery-image {
    min-height: 150px;
}

&.format-audio .audio-wrapper {
      padding: 0 30px 0;
  }

  }
}

@include xs-size-all {

  .post {

    .post-info {
      padding: 0;
    &__item {
        border: none;
        border-bottom: 1px solid #eee;
    }
  }

    .content-top-wrapper {
      padding: 30px 30px;
      .post-title a {
          font-size: 22px;
          &:hover {
            color:#2a74ed;
          }
      }
    }
    .post-cats {
        margin-bottom: 15px;
    }

    .post-info__item {
      width: 100%;
      &.post-author {
        text-align: center;
      }
    }

    &.format-gallery .gallery-wrapper .gallery-image {
        width: 50%;
        min-height: 100px;
    }

  }
}


}

.lity {
    background: rgba(0,0,0,0.7);
}

.lity-content:after {
    -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.2);
    box-shadow: 0 0 12px rgba(0,0,0,0.2);
}
