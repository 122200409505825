.aht-pag-bussines {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    a{
      color: #222222;
      text-transform: uppercase;
      font-size: 14px;
    }
    .main_blog{
      &:hover{
        color: #cf2632;
      }
      @include xs-size-max {
        padding-top: 15px;
      }
    }
  }

  .wrap {
    margin: 0 25px;

    .page-numbers {
      display: inline-block;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      font-weight: 700;
      line-height: 33px;
      text-align: center;

      &.current,
      &:hover {
        color: $c-white;
      }
    }

    @include xs-size-max {
      flex-basis: 100%;
      order: -1;
      text-align: center;
    }
  }

  .prev,
  .next {
    span {
      color: inherit;
      font-size: 14px;
      letter-spacing: 1.5px;
      font-weight: 400;

    }

    i {
      display: inline-block;
      transition: all 0.3s ease-out;
    }

    @include xs-size-max {
      margin: 15px 15px 0;
    }
  }

  .prev {
    i {
      margin-right: 10px;
    }

    &:hover {
      i {
        transform: translate(-5px, 0);
      }
    }
  }

  .next {
    i {
      margin-left: 10px;
    }

    &:hover {
      i {
        transform: translate(5px, 0);
      }
    }
  }
}
