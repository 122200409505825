.single-box {
  padding: 50px;
  background: $c-white;

  @include medium-size-max {
    padding: 30px 25px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  ul,
  ol {
    padding: 0;
  }
}
