@keyframes fade-zoom-in {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-zoom-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

.site-search {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $c-white;
  z-index: 100;
  animation: fade-zoom-in 0.3s forwards;

  &.open {
    display: block;
  }

  &.closing {
    animation: fade-zoom-out 0.3s forwards;
  }

  .close-btn {
    position: absolute;
    top: 48px;
    right: 48px;
    border: 0;
    outline: none;
    background-color: transparent;
    font-size: 28px;
    cursor: pointer;
    opacity: 1;

    .close-icon {
      width: 18px;
      height: 18px;
    }

    @include small-size-max {
      top:20px;
      right: 20px;
    }
  }

  .form-container {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -32px;
  }

  .search-form {
    max-width: 540px;
    margin: 0 auto;
  }

  .search-field {
    width: 100%;
    padding: 8px 12px;
    border: 0;
    background-color: transparent;
    font-size: 68px;
    font-style: normal;
    font-weight: 700;
    text-align: center;

    @include placeholder {
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      font-size: 32px;
      font-weight: 400;
      letter-spacing: -2px;
    }

    &:focus,
    &.active {
      outline: none;
      box-shadow: none;
    }

  }

  .search-description {
    text-align: center;
  }
}
