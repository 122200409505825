.aheto-header-7 {
  .hamburger--squeeze {
    background: transparent;
    border: none;
    margin-right:10px;
    cursor:pointer;
    display: none;
    @media screen and (max-width: 1024px) {
      display: inline-block;
    }
  }

  // Logo
  .logo {
    padding: 15px;

    &__text-holder {
      margin-left: 15px;
    }

    &__text {
      color: $c-white;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 2px;
      margin:0;
    }
  }

  &__line {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 1750px;
    margin: 0 auto;
  }

  &__aside {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translate(100%, 0);
    transition: all 0.5s ease-out;
    background-color: #302F33;
    -webkit-overflow-scrolling: touch;

    @include menu-size-min {
      max-width: 480px;
    }

    @include menu-size-max {
      overflow: auto;
    }

    &.js-aside-opened {
      transform: translate(0, 0);
    }
  }

  &__aside-close {
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 15px;
    line-height: 16px;
    cursor: pointer;
    z-index: 1;

    &:before {
      display: inline-block;
      font-family: $f-ionicons;
      font-size: 24px;
      line-height: 16px;
      content: '\f2d7';
    }
  }

  &__menu-text-btn {
    padding: 15px;
    border: 0;
    outline: none;
    background-color: transparent;
    color: $c-white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    padding: 35px 30px 60px;
    transition: all 0.3s;
    background-color: #302F33;
    overflow: auto;
    .logo {
      margin-bottom: 60px;
      padding: 0;
      @include menu-size-max {
          padding: 0 0 0 15px;
      }
    }

    @include menu-size-min {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 480px;
      height: 100%;
      padding: 145px 100px;

      .logo {
        margin-bottom: 160px;
      }
    }

    .aheto-header-7__logo {
      flex: 0 0 0;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &__email-title,
  &__tel-title,
  &__hours-title,
  &__address-title {
    margin-bottom: 15px !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.5px !important;
    text-transform: uppercase;
  }

  &__hours-holder {
    margin-top: 55px;
  }

  &__email-holder,
  &__address-holder {
    margin-top: 50px;
  }

  &__email,
  &__tel {
    display: block;
    padding: 6px 0;
    font-weight: 600;
  }

  &__hours {
    padding: 5px 0;
    font-weight: 600;
  }

  &__address {
    max-width: 220px;
    font-weight: 600;
    line-height: 30px;
  }

  &__logo {
    @media screen and (min-width: 1500px) {
      flex: 1 1 0;
    }

    @include menu-size-max {
      padding-right: 0;
      //margin-left: -20px;
    }
  }

  &__menu-btn {
    @include menu-size-min {
      display: none;
    }
  }

  &__hamburger-aside {
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 1500px) {
      flex: 1 1 0;
    }
  }

  // Menu
  &__menu {
    padding: 0 15px;

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 64px);
      padding: 15px 15px 30px;
      //overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: rgba($c-white, 0.2);
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    @include small-size-max {
      height: calc(100vh - 64px);
    }
  }

  // Main menu
  .main-menu {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;

    .menu-item {
      a {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        line-height: 1.2;
        text-transform: uppercase;
        &.active {
          color: #fff;
        }
      }

    }

    @include menu-size-min {
      .menu-item {
        a {
          padding: 28px 0 27px;
        }
        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-white;
          }
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      > .menu-item {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @include menu-size-max {
      flex-direction: column;

      .menu-item {
        a {
          padding: 10px 0;
          color: $c-grey;
          &:hover {
            color: $c-white;
          }
        }
      }

      .dropdown-btn {
        color: $c-white;
      }

      > .menu-item {
        > a {
          font-size: 18px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: -30px;
      padding: 20px 0;
      transition: all 0.2s;
      box-shadow: 0px 0px 5px 0px rgba($c-white, 0.15);
      opacity: 0;
      visibility: hidden;
      z-index: 1;

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 200px;
        padding: 0 30px;

        a {
          padding: 10px 0;

          &:hover {
            color: $c-white;
          }
        }
      }

      .dropdown-btn {
        &:before {
          content: '\f125';
        }
      }

      .sub-menu {
        top: -20px;
        left: 100%;
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 0 30px;
        a {
          font-size: 16px;
        }
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      display: flex;
      padding: 0;

      &__col {
        padding: 30px;
        box-shadow: 0px 0px 2px 0px rgba($c-white, 0.15);
      }

      &__title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-white, 0.2);
        color: $c-white;
        font-weight: 400;
      }

      .menu-item {
        padding: 0;
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        margin: 10px 30px 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid rgba($c-white, 0.2);
        color: rgba($c-white, 0.7);
        font-size: 24px;
        text-transform: uppercase;
      }

      &__col {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // Dropdown btn
  .dropdown-btn {
    display: inline-block;
    margin-left: 10px;
    padding-bottom: 4px;
    font-size: 10px;
  }

  .aheto-copyright {
    margin-top: auto;
    padding: 50px 0 0;

    p {
      max-width: 220px;
      font-size: 16px;
    }
  }

  .hamburger-inner {
      background: $c-white;
  }



  .hamburger--squeeze-desktop {
    background: transparent;
    border: none;
    margin-right:10px;
    cursor:pointer;
    @media screen and (max-width: 1024px) {
      display: none;
    }
    //.hamburger-inner {
    //  background: #0c609c;
    //}
  }

  &__aside-socials {
    margin-top: 30px;
  }
  &__aside-menu {
    li {
      text-align: right;
      padding: 20px 0;
      a {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #fff;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}
