.aht-tour {

  &--trvl {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @include small-size-min {
      &.aht-tour--2 {
        .aht-tour__item {
          flex-basis: 50%;
        }
      }

      &.aht-tour--3 {
        .aht-tour__item {
          flex-basis: 33.33%;

          @include large-size-max {
            flex-basis: 50%;
          }
        }
      }

      &.aht-tour--4 {
        .aht-tour__item {
          flex-basis: 25%;

          @media screen and (max-width: 1500px) {
            flex-basis: 33.33%;
          }

          @include large-size-max {
            flex-basis: 50%;
          }
        }
      }
    }

    .aht-tour {

      &__item {
        flex-basis: 100%;
        margin-bottom: 30px;
        padding: 0 15px;

        @include small-size-max {
          flex-basis: 100%;
        }
      }

      &__item-inner {
        height: 100%;
        transition: all 0.3s;
        border-radius: 5px;
        background-color: $c-white;
        box-shadow: 0px 0px 27px 0px rgba($c-black, 0.08);
        overflow: hidden;

        @include large-size-min {
          &:hover {
            transform: translate(0, -30px);
            box-shadow: 0px 12px 11px 0px rgba($c-black, 0.14);
          }
        }
      }

      &__caption {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 270px;
        padding: 28px 35px;
        background-position: center;
        background-size: cover;
        box-shadow: inset 0px -99px 110px -34px rgba($c-black, 0.85);

        @include small-size-max {
          @include xs-size-min {
            min-height: 350px;
          }
        }
      }

      &__img-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &__title {
        margin: 0;
        color: $c-white;
        font-weight: 400;
      }

      &__rating {
        display: flex;
        flex-wrap: wrap;
        margin-top: 7px;
      }

      &__rate-icon {
        color: #FFBB49;
        line-height: 20px;

        &:before {
          vertical-align: middle;
        }
      }

      &__stars {
        display: flex;
      }

      &__rate {
        margin: 0 0 0 10px;
        color: $c-white;
      }

      &__main {
        text-align: center;
      }

      &__details {
        display: flex;
        border-bottom: 1px solid rgba($c-black, 0.07);

        @include large-size-max {
          padding: 0 10px;
        }
      }

      &__detail {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 15px 10px;
        border-right: 1px solid rgba($c-black, 0.07);

        &:last-child {
          border-right: 0;
        }
      }

      &__icon {
        margin-right: 8px;
        &:before {
          vertical-align: middle;
        }
      }

      &__detail-text {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.2;
      }

      &__info {
        padding: 28px 30px 60px;
      }

      &__cost {
      }

      &__price {
        display: inline-block;
        margin: 0;
      }

      &__from {
        display: inline-block;
        margin: 0;
        font-weight: bold;
      }

      &__per {
        display: inline-block;
        margin: 0;
      }

      &__desc {
        margin-top: 20px;
        line-height: 24px;
      }

      &__link {
        margin-top: 18px;
        border-color: rgba($c-black, 0.1);

        &:hover {
          color: $c-white;
        }
      }
    }
  }
  
  &--trvl-2 {

    // &:hover {
    //   .aht-tour__promo {
    //     background-position: left bottom;
    //   }
    // }

    .aht-tour {

      &__img {
        width: 220px;
        height: 220px;
        margin: 0 auto;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
      }

      &__promo {
        display: flex;
        position: relative;
        top: 0;
        right: -20px;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        padding: 10px;
        float: right;
        transform: rotate(15deg);
        transition: all 0.5s;
        border-radius: 50%;
        background-position: right bottom;
        background-size: 200% 100%;
      }

      &__promo-text {
        margin: 0;
        color: $c-white;
        font-size: 15px;
        font-weight: bold;
      }

      &__content {
        margin-top: 43px;
      }

      &__title {
        margin: 0;
      }

      &__desc {
        margin: 26px 0 0;
        line-height: 24px;
      }

      &__link {
        margin-top: 36px;

        &:hover {
          border-color: #eee;
          background-color: transparent;
        }
      }
    }
  }
}
