.aht-breadcrumbs {

  &__list {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    color: #999999;
  }

  &__item {
    display: inline-block;
    margin-right: 9px;
    padding-left: 0;
    font-size: 15px;
    line-height: 24px;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      display: inline-block;
      position: static;
      margin-right: 10px;
      font-size: 8px;
      content: '\f125';
      vertical-align: top;
      font-family: $f-ionicons;
    }

    &:first-child {
      padding-left: 0;

      &:before {
        content: none;
      }
    }
  }

  &__link {
    transition: all 0.1s ease-out;

    &:hover {
      opacity: 0.8;
    }
  }
  .button_back{
    color: #999999;
    font-size: 15px;
    &:before{
      display: inline-block;
      position: static;
      margin-right: 10px;
      font-size: 8px;
      content: '\f124';
      vertical-align: top;
      font-family: $f-ionicons;
    }
  }
  &--with-border {
    padding-top: 18px;
    padding-bottom: 17px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
  }

  &--yoga {

    .aht-breadcrumbs {

     &__link {
        font-weight: 400;
      }
    }
  }

  &--edu {
    .aht-breadcrumbs {
      &__link {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}
