.gradient-wrapp {
  background-image: linear-gradient(to bottom, $c-white, rgb(240, 245, 255));

  .w-100 {

    width: auto;

  }

  .img-widht {
    .w-100 {
      width: 100%;
    }
  }
}

.text-color {
  &--grey {

    color: #999999 !important;

  }
}

.f {
  &-14 {
    font-size: 14px;
  }

  &-30 {
    font-size: 30px;
  }

  &-38 {
    font-size: 38px;
  }

  &-40 {
    font-size: 40px;
  }

  &-60 {
    font-size: 60px;
  }

  &-70 {
    font-size: 70px;
  }



  &-style-italic {
    font-style: italic;
  }
}

.about-counter-wrapp {
  padding: 100px 0 120px;
  background: #f6f9ff;
  @media screen and (max-width: 991px) {
    padding: 80px 0 80px;
  }
  @media screen and (max-width: 768px) {
    padding: 50px 0 50px;
  }
}

.testimonials__wrapp {
  position: relative;
  background: #f6f9ff;

  @include large-size-max {
    padding: 0 50px;
  }

  @include medium-size-max {
    padding: 0 30px;
  }

  @include small-size-max {
    padding: 0 20px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-top: 28px solid $c-white;
    border-right: 41px solid transparent;
    border-bottom: 28px solid transparent;
    border-left: 41px solid transparent;
    content: '';
  }
}

.h-100 {
  height: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.f-18px {
  font-size: 18px;
}

.tab-gradien-wrapp {
  background-image: linear-gradient(0deg, rgb(246, 249, 255) 0%, rgb(255, 255, 255) 59%);
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-5t {
  border-radius: 5px 5px 0px 0px;
}

.f-18 {
  font-size: 18px;
}

.let-spasing {
  letter-spacing: 2.8px;
}

.service-wrapp {
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
}

.full_height_wrapp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 137px);
  margin-right: -15px;
  margin-left: -15px;
  background-size: cover;
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 127px);
  }
}

.breadcrumbs__border {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.contact-sidebar-contact-wrap {
  padding: 120px 95px;

  @media screen and (max-width: 768px) {
    padding: 60px 30px;
  }
}

.margin-30t {
  @media screen and (max-width: 1500px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 765px) {
    margin-top: 0px;
  }
}

.coming-soon-wrap {
  min-height: 100vh;
  background: url('../img/inner-pages/additional-page/coming-soon-bg.png'), linear-gradient(to bottom, #0FCAE6, #2A75EE);
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom;
  background-size: auto, auto;
}

.faq-wrapper {
  background-color: #F7F7F7;
}

.contact-single-wrap {
  position: relative;

  &__contacts {
    background: $c-white;
    position: relative;
    margin-top: -125px;

    &--saas {
      & > .container {
        box-shadow: 0 0 73.1px 10.9px rgba(0, 0, 0, 0.09);
      }
    }
  }

  @include small-size-max {
    .contact-single-wrap__contacts {
      transform: none;
    }
  }
}

// Home event
.home-event-wrap-subs {
  background-image: url('../img/home/Mask.png');
  background-repeat: no-repeat;
  background-position: (50% 0%);
  background-size: cover;
  @media screen and (max-width: 576px) {
    .aheto-single-text {
      h1 {
        font-size: 35px;
      }

    }
  }
}

.home-event-wrap-testimonials {
  background-image: url('../img/event/feedback/bg-image.jpg');

  .swiper--home-event {
    .swiper-button {
      &-prev {
        &::before {
          content: '\f108';
        }
      }

      &-next {
        transform: rotate(180deg);

        &::before {
          content: '\f108';
        }
      }

      &-next, &-prev {
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #222222;
          transform: translate(-50%, -50%);
        }
      }

      &-next:hover, &-prev:hover {
        &::before {
          color: #ffffff;
        }
      }
    }
  }
}

.home-event-wrap-tickets {
  background-image: url('../img/home/home-event/schedule-bg.png');
  background-repeat: repeat-x;

}

.home-event-wrap-about-block {
  background-image: url('../img/home/home-event/A.png');
  background-position: 100% 10%;
  background-repeat: no-repeat;
  &--img {
    background-image: url('../img/home/home-event/about-bg.png');
    background-repeat: no-repeat;
    background-position: 65% 50%;
    background-size: contain;
    height: 896px;
  }
  .aheto-heading__title {
    line-height: 1.63em;
  }
  .aheto-heading__desc {
    padding-right: 180px;
  }
  @media screen and (max-width: 1600px) {
    .aheto-heading__desc {
      padding-right: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    &--img {
      height: 550px;
    }
  }
}

.home-event-wrap-upc-event {
  .aheto-btn--light.aheto-btn--shadow:not(.aheto-btn--circle) {
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: #E32682;
      border-color: #E32682;
    }
  }
  @media screen and (max-width: 576px) {
    .aheto-single-text {
      h1 {
        font-size: 30px;
      }
    }
  }

}

.home-event-wrap-letter-s {
  background-image: url('../img/home/home-event/S.png'), url('../img/home/home-event/team-bg.png');
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
}

// Education
.home-edu-wrap-1 {
  background: url('../img/education/bg-1.png');
  background-repeat: no-repeat;
  background-position: center 150%;
}

.home-edu-wrap-2 {
  background: url('../img/education/bg-3.png');
  background-repeat: no-repeat;
  background-position: center 190px;
  background-size: cover;
  &.background-top {
    background-position: center 0px;
  }
}


.home-edu-wrap-cources {
  background-image: url('../img/home/home-edu-cources.png');
  background-repeat: no-repeat;
  background-size: cover;
  .aheto {
    &-btn {
      font-family: $f-roboto;
      letter-spacing: 1.4px;
    }
    &-heading {
      &__title {
        line-height: normal;
      }
      @media screen and (max-width: 1024px) {
        &__title {
          font-size: 30px;
        }
      }
    }

  }
}

.home-edu-wrap-mail-form {
  margin: 0 -15px;
  padding: 0 15px;
  background-image: url('../img/home/home-education-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: auto;
  padding-bottom: 20px;
}

.home-edu-wrap-team {
  background-image: url('../img/home/home-edu-team.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.home-edu-wrap-look {
  background-image: url('../img/education/gallery-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.home-edu-wrap-pricing {
  background-repeat: no-repeat, no-repeat;
  background-position: 0% 0%, center 800px;
  background-size: 100% 450px, auto;

  &--heading {
    padding-bottom: 263px;
  }

  &--content {
    margin-top: -264px;
  }
  @media screen and (max-width: 576px) {
    &--heading {
      padding-bottom: 220px;
    }
    &--content {
      margin-top: -250px;
    }
  }
}

.classes-edu-pricing {
  &--heading {
    padding-bottom: 263px;
  }

  &--content {
    margin-top: -284px;
  }
}

.home-edu-wrap-service {
  @media screen and (max-width: 576px){
    .aheto-heading__title {
      font-size: 30px;
    }
  }

}

.author-bio-wrap {
  display: flex;
  flex-wrap: wrap;

  &__blockquote {
    flex: 5;
    min-width: 460px;
    @media screen and (max-width: 767px) {
      flex-basis: 100%;
      min-width: 0;
    }
  }

  &__skills {
    flex: 5;
    min-width: 500px;
    padding: 60px 130px;
    background-color: $c-white;
    @media screen and (max-width: 991px) {
      padding: 30px 80px;
    }
    @media screen and (max-width: 767px) {
      min-width: 0;
      padding: 20px 40px;
    }
  }
}

.cart-related-products-wrap {
  border-top: 1px solid #eee;
}

.portfolio-nav-wrap {
  background-color: #F8F8F8;
}

.portfolio-03-title-bg-wrap {
  display: flex;
  align-items: center;
  height: 100vh;
  @media screen and (max-width: 1025px) {
    height: calc(100vh - 108px);;
  }
}

.portfolio-03-title-wrap {
  padding: 65px 30px 60px;
  background-color: $c-white;
}

.portfolio-03-rec-prj-wrap {
  background-color: #f7f7f7;
}

.wrap-1270px {
  max-width: 1270px;
  margin: 0 auto;
}

.wrap-1720px {
  max-width: 1720px;
  margin: 0 auto;
}

.wrap-1750px {
  max-width: 1750px;
  margin: 0 auto;
  width: 100%;
}

.wrap-1290px {
  max-width: 1290px;
  margin: 0 auto;
}

.large-container-wrap {
  max-width: 1750px;
  margin: 0 auto;
  padding: 0 15px;
}

.wrap-1920 {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 15px;
}

.wrap-1820px {
  max-width: 1820px;
  margin: 0 auto;
}

.wrap-1320px {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 15px;
}
.container-1300px {
  max-width: 1300px;
  margin: 0 auto;
}

.portfolio-06-nav-wrap {
  border-top: 1px solid #eee;
}

hr {
  height: 1px;
  border: 0;
}


.travel-tours-wrap {
  background-image: url('../img/travel/home-page/bg-1.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: (100%, auto);
}

.business-services-wrap {
  background-image: url('../img/business/home-page/bg-1.png');
  background-repeat: no-repeat;
  background-position: top left;
}

.wrap-yoga-1 {
  border-bottom: 1px solid #eee;
}

.wrap-yoga-2 {
  background: url('../img/yoga/home-yoga/bg-1.png') left top no-repeat, url('../img/yoga/home-yoga/bg-2.png') right bottom no-repeat;

  @include medium-size-max {
    .aht-cta--simple {
      text-align: center;
    }
  }
}

.wrap-yoga-3 {
  background-color: #f9f9f9;
}

.wrap-yoga-7 {
  box-shadow: 0px 0px 65px 0px rgba($c-black, 0.08);
}

.bg-wrap {
  background-position: center;
  background-size: cover;
}

.construction-cta-home-bg {
  background-image: url('../img/construction/constrution-cta-home.jpg');
  background-position: center;
  background-size: cover;
}

.construction-cta-testimonials-bg {
  background-image: url('../img/construction/construction-testimonials.jpg');
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1230px) {
    padding-bottom: 0px ;
  }
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.edu-wrap-1 {
  background-repeat: no-repeat;
  background-position: 78.5% center;
  .aheto {
    &__heading {
      &_title {
        line-height: normal;
      }
    }
  }
  .ol--lg-nums {
    li {
      position: relative;
      font-family: $f-roboto;
      padding-left: 55px;
      &::before {
        font-family: $f-roboto-slab;
        font-size: 30px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .aheto-heading__title {
      font-size: 30px;
    }
  }
}

.edu-wrap-2 {
  background-repeat: no-repeat;
  background-size: 100% 455px;
}

.trvl-wrap-1 {
  background-image: linear-gradient(90deg, rgb(255, 250, 246) 0%, rgb(255, 255, 255) 100%);
}
.trvl-wrap-2 {
  background-color: #fffcfb;
}

.church-wrap-1 {
  background-repeat: no-repeat;
  background-position: top right;
}


.church-wrap-2 {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.church-wrap-3 {
  margin-top: -20px;

  @media screen and (min-width: 1500px) {
    margin-top: -50px;
  }
}

.church-wrap-3.about-church-wrap-3 {

  @media screen and (min-width: 1500px) {
    margin-top: -47px;
  }
}

.section_donate {
  background-position: bottom !important;
  background-repeat: no-repeat !important;
}

.ul {
  &--dotted {
    li {
      font-size: 16px;
      color: #999;
      padding: 5px 0px;

      &:before {
        content: "\f111";
        font-family: 'FontAwesome';
        font-size: 6px;
        position: relative;
        top: -4px;
        padding-right: 15px;
        color: #3776ea;
      }
    }
  }

  &--bussines {
    li {
      font-size: 16px;
      color: #222222;
      padding: 5px 0px;

      &:before {
        content: "\f111";
        font-family: 'FontAwesome';
        font-size: 6px;
        position: relative;
        top: -4px;
        padding-right: 15px;
        color: #cf2632;
      }
    }
  }
}
