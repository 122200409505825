.aheto-header-9 {
  &__inner {
    @include menu-size-min {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  &__cr-text{
   font-size: 15px;
  }
  &__line {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 1750px;
    margin: 0 auto;
    padding: 40px 15px 0;

    @include large-size-max {
      padding: 15px;
    }

    @include menu-size-max {
      flex-wrap: wrap;
      padding: 0;
      -ms-justify-content: flex-end;
    }
  }

  &__logo {

    @include menu-size-min {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
    }

    @include menu-size-max {
      padding: 10px 15px;
    }
  }

  &__menu {
    display: contents;
    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: calc(100vh - 75px);
      padding: 15px 15px 30px;

    }

    @include small-size-max {
      height: calc(100vh - 75px);
    }

    @include xs-size-max {
      height: calc(100vh - 75px);
    }
  }

  &__authentication {
    display: flex;

    @include menu-size-min {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-end;
      -ms-justify-content: flex-end;
    }

    @include menu-size-max {
      position: relative;
      left: 0px;
      width: 100%;
      padding-left: 0px;
      padding-bottom: 30px;
    }

    @include xs-size-max {
      position: relative;
      top: 0px;
      right: 0px;
    }

  }

  &__hamburger {
    margin-left: auto;

    @include menu-size-min {
      display: none;
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    margin-left: 10px;

    @include menu-size-max {
      display: none;
    }
  }

  // Menu
  .main-menu {
    display: flex;
    flex-wrap: wrap;

    .menu-item {
      a {
        font-weight: 500;
      }
    }

    @include menu-size-min {
      justify-content: center;

      > .menu-item {
        margin: 10px 22px;
        &.active{
          border-bottom: 1px solid #fff;
        }
        > a {
          color: $c-white;
          font-weight: 500;
          font-size: 16px;
        }
        > a.active{
          padding-bottom: 5px;
          border-bottom: 1px solid #fff;
        }
        > span {
          color: $c-white;
        }

      }

      .menu-item {

        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    @include menu-size-max {
      flex-direction: column;

      > .menu-item {
        padding-top: 5px;

        a {
          color: $c-white;
          font-size: 18px;
        }

        > a {
          display: inline-block;
          padding: 7px 0;
          font-size: 20px;
          letter-spacing: 1px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      position: absolute;
      top: 100%;
      left: 0;
      padding: 30px 0;
      transition: all 0.2s;
      border-radius: 5px;
      background-color: $c-white;
      box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(168, 168, 168, 0.2);
      opacity: 0;
      visibility: hidden;

      .menu-item {
        min-width: 200px;
        padding: 5px 20px;
      }

      .sub-menu {
        top: -30px;
        left: 100%;
      }

      .dropdown-btn {
        float: right;

        &:before {
          content: '\f125';
        }
      }
    }

    @include menu-size-max {
      .sub-menu {
        padding-top: 10px;
      }

      .menu-item {
        padding: 7px 30px;
      }
    }
  }

  // Mega menu
  .mega-menu {

    &__title {
      margin-bottom: 10px;
      font-size: 20px;
    }

    @include menu-size-min {
      display: flex;
      padding: 0;

      &__col {
        padding: 30px 20px;
      }

      .menu-item {
        padding: 5px 0;
      }
    }

    @include menu-size-max {
      padding: 0 30px;

      &__title {
        margin-bottom: 0;
        padding: 10px 0;
        font-size: 24px;
        font-weight: 500;
        color:$c-white;
      }

      .menu-item {
        padding: 5px 0;
      }
    }
  }

  // logo
  .logo {
    &__text {
      color: $c-white;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 2px;
    }
  }

  // Authentication
  .authentication {
    display: flex;

    &__sign-in,
    &__sign-up {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      white-space: nowrap;

      @include menu-size-max {
        padding: 5px 20px;
      }
    }

    &__sign-in {
      background-color: transparent;
      color: $c-white;

      @include menu-size-max {
        margin-right: 15px;
        border: 1px solid $c-white;
        border-radius: 5px;
        &:hover{
          border: 1px solid #ff704f;
        }
      }
    }

    &__sign-up {
      border-radius: 5px;
      background-color: $c-white;
      box-shadow: 1.169px 3.825px 15.66px 2.34px rgba(168, 168, 168, 0.2);
    }

    @include menu-size-max {
      padding-top: 25px;
    }

    @include xs-size-max {
        margin-left: auto;
        margin-right: auto;
    }
  }
}
