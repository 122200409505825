.aheto-heading {

  &__link {
    .aheto-btn {
      & + .aheto-btn {
        margin-left: 35px;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__desc {

    &--drop-caps {
      &::first-letter {
        margin-right: 40px;
        //margin-bottom: -20px;
        float: left;
        font-size: 160px;
        line-height: 147px;
      }
    }
  }

  p {
    margin-top: 15px;
    margin-bottom: 5px;
    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
    }
  }

  h6 + p {
    margin-top: 15px;
  }

  &__subtitle {
    margin-top: 0;
    padding: 0 4px;
    margin-bottom: 10px;

    &--opacity {
      opacity: 0.5;
    }
    &--big {
      font-size: 52px;
    }
    &--medium {
      font-size: 40px;
    }
  }

  &--opacity {
    opacity: 0.5;
  }

  &--divider-t1 {
    position: relative;

    .aheto-heading__title {
      margin-bottom: 50px;
      &::after {
        display: block;
        width: 50px;
        height: 6px;
        margin-top: 35px;
        content: '';
      }
    }

    &.t-center {
      .aheto-heading__title {
        &::after {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    &.t-right {
      .aheto-heading__title {
        &::after {
          margin-left: auto;
        }
      }
    }
  }

  &--divider-t2 {
    position: relative;

    .aheto-heading__title {
      &::after {
        display: block;
        width: 50px;
        height: 3px;
        margin-top: 44px;
        background-color: #eee;
        content: '';
      }
    }
    &.t-center {
      .aheto-heading__title {
        &::after {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    &.t-right {
      .aheto-heading__title {
        &::after {
          margin-left: auto;
        }
      }
    }
  }

  &--divider-t3 {
    .aheto-heading__title {
      position: relative;

      &:after {
        display: block;
        width: 100px;
        height: 5px;
        margin-top: 30px;
        margin-bottom: 30px;
        transform: translate(-50px, 0);
        content: '';

        @include large-size-max {
          transform: none;
        }
      }
    }

    &.t-center {
      .aheto-heading__title {
        &:after {
          margin-right: auto;
          margin-left: auto;
          transform: none;
        }
      }
    }

    &.t-right {
      .aheto-heading__title {
        &:after {
          margin-left: auto;
          transform: none;
        }
      }
    }
  }

  &--simple {
    .aheto-heading__subtitle {
      margin-bottom: 5px;
      font-size: 30px;
      font-weight: 400;
      letter-spacing: 0;
    }

    .aheto-heading__title {
      letter-spacing: 4px;
    }
  }

  &--business {
    .aheto-heading__subtitle {
      font-size: 16px;
    }

    .aheto-heading__title {
      font-weight: bold;
      @include small-size-max {
        font-size: 40px;
      }
    }
  }

  &--classic {
    .aheto-heading__subtitle {
      display: inline-block;
      position: relative;
      margin-bottom: 30px;
      padding: 0;
      font-size: 16px;
      letter-spacing: 3.2px;

      &:after {
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
      }
    }
  }

  &--t-white {
    .aheto-heading__title {
      &:after {
        background-color: $c-white;
      }
    }

    .aheto-heading__title,
    p,
    .aheto-heading__subtitle {
      color: $c-white;
    }
  }

  &--modern {
    .aheto-heading__subtitle {
      margin-bottom: 50px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 4px;
    }
  }

  &--small {
    p {
      margin: 10px 0 0;
    }
  }

  &--events {
    &-title {
      font-size: 100px;
      letter-spacing: 10.3px;
    }
    @media screen and (max-width: 768px) {
      &-title {
        font-size: 28px;
        letter-spacing: 10.3px;
      }
    }
  }
  &--edu {
    .aheto-heading__title {
      &::after {
        margin-top: 40px;
      }
    }
    @media screen and (max-width: 768px){
      .aheto-heading__title {
        &::after {
          margin-top: 35px;
        }
      }
    }

  }
  &--yoga {
    .aheto-heading {

      &__subtitle {
        margin-bottom: 10px;
        font-family: $f-dancing-script;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0;
      }

      &__title {
        line-height: 1.33;
      }

      &__desc {
        &--drop-caps {
          margin-top: 47px;

          @include medium-size-min {
            max-width: 500px;
          }
        }
      }
    }
  }

  &--construction,
  &--construction_about {
    .aheto-heading {
      &__title {
        font-size: 40px;
        @include xs-size-max {
          font-size: 22px;
        }
      }
      &__desc {
        margin-top: 21px;
        margin-bottom: 37px;
        font-size: 16px;
        @include small-size-max{
          margin-top: 15px;
          margin-bottom: 25px;
        }
      }
    }
  }

  &--construction_about {
    .aheto-heading {
      &__desc {
        margin-top: 27px;
        margin-bottom: 70px;
        line-height: 1.5;
        @include small-size-max{
          margin-top: 15px;
          margin-bottom: 25px;
        }
      }
    }
  }



  &--construction-tm {
    .aheto-heading {
      &__title {
        font-size: 70px;
        font-weight: normal;
        @include xs-size-max {
          font-size: 50px;
        }
      }
      &__desc {
        padding-right: 5px;
        color: $c-white;
        font-family: $f-source-sans-pro;
        font-size: 40px;
        font-weight: 600;
        line-height: 1.1;
        @include xs-size-max {
          font-size: 20px;
        }
      }
    }
  }

  &--chr {
    .aheto-heading__title {
      font-size: 42px;
      font-weight: bold;
      height: 180px;
      font-family: 'PlayfairDisplay';
    }

    .aheto-heading__subtitle {
      margin-bottom: 16px;
    }
  }

  &--restaurant {
    .aheto-heading {
      font-family: "Catamaran", sans-serif;
      &__subtitle {
        font-size: 14px;
        letter-spacing: 1.4px;
        position: relative;
        font-weight: 600;
        display: inline-block;
        &:after {
          content: '';
          position: absolute;
          bottom: -7px;
          left: 4px;
          right: 4px;
          border-top: 1px solid #eee;
        }
      }
      &__title {
        margin-top: 25px;
      }
    }
  }

  &--restaurant-awards {
    .aheto-heading {
      &__title {
        -webkit-font-smoothing: antialiased;
      }
      &__desc {
        margin: 0 auto;
        margin-top: 40px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-transform: none;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        padding: 0 10px;
        max-width: 370px;
        letter-spacing: normal;
      }
    }
  }



  &--restaurant-contact {
    .aheto-heading {
      &__title {
        font-family: $f-playfair-display;
      }
      &__desc {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        margin-top: 34px;
        margin-bottom: 40px;
      }
    }
  }

  &--restaurant-signature {
    .aheto-heading {
      &__subtitle:after {
        bottom: -3px;
      }
      &__title {
        margin-top: 10px;
        line-height: 52px;
      }
      &__undertitle {
        margin-top: 50px;
        color: #999;
        font-family: $f-playfair-display;
        font-size: 18px;
        font-style: italic;
        line-height: 24px;
        -webkit-font-smoothing: antialiased;
        @include xs-size-max {
          margin-top: 40px;
        }
      }
      &__desc {
        margin-top: 40px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  &--single-gallery {
    max-width: 340px;
    .aheto-heading {
      &__title {
        font-family: $f-playfair-display;
        font-size: 40px;
        -webkit-font-smoothing: antialiased;
      }
      &__desc {
        margin-top: 40px;
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}


.aht-heading {

  &__subtitle,
  &__title,
  &__desc {
    margin: 0;
  }

  b {
    font-weight: inherit;
  }

  &--travel {
    background-repeat: no-repeat;


    &.t-center {
      background-position: 50% 0;
    }

    &.t-right {
      background-position: 100% 0;
    }

    &.aht-heading--bg {
      padding-top: 40px;

      @include medium-size-max {
        padding-top: 0;
      }
      @include xs-size-max {
        padding-bottom: 0;
      }
    }

    .aht-heading {

      &__subtitle {
        // margin: 0 0 5px;
        font-weight: 400;
        line-height: normal;
      }

      &__title {
        margin: 0;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      &__desc {
        margin-top: 22px;
        @include small-size-max {
          margin-top: 0;
        }
      }
    }
  }

  &--main {
    .aht-heading {
      &__title {
        margin: 0;
        font-weight: 300;
      }

      &__desc {
        margin: 20px 0 0;
        max-width: 250px;
      }
    }
  }

  &--chr {
    .aht-heading {
      &__subtitle {
        display: inline-block;
        position: relative;
        margin-bottom: 35px;
        font-weight: 700;
        letter-spacing: 0.15em;

        &:before {
          display: block;
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: #eee;
          content: '';
        }
      }

      &__title {
        font-weight: 700;
        letter-spacing: -0.025em;
      }

      &__desc {
        margin-top: 35px;
      }
    }

    &.aht-heading--white {
      .aht-heading__subtitle,
      .aht-heading__title,
      .aht-heading__desc {
        color: $c-white;
      }

      .aht-heading__subtitle {
        &:before {
          background-color: $c-white;
        }
      }
    }
  }
}


.event-slider--church-wrap-2 {
  .aht-heading {
    &--chr {
      .aht-heading {
        &__desc {
          margin-top: 35px;
          width: 60%;
        }
      }
    }
  }
}




.about-church-wrap-1,
.about-church-service-wrap-1,
.about-church-team-wrap-1,
.about-church-faq-wrap-1,
.church-news-blog-wrap,
.church-ministries-wrap,
.church-events-wrap,
.church-contact-us-wrap,
.church-sermons-event-wrap {
  .aht-heading {
    &--chr {
      .aht-heading {
        &__subtitle {
          letter-spacing: 0.2em;
        }
      }
    }
  }
}

.about-church-team-wrap-1 {
  .aht-heading {
    &--chr {
      .aht-heading {
        &__subtitle {
          margin-bottom: 28px;
        }

        &__desc {
          margin-top: 40px;
          line-height: 1.63;
        }
      }
    }
  }
}


.about-church-team-wrap-1,
.church-ministries-wrap,
.church-events-wrap {
  .aht-heading {
    &--chr {
      .aht-heading {
        &__desc {
          font-family: $f-roboto;
          width: 80%;
        }
      }
    }
  }
}

.aheto-heading__title--agency-mrg {
  margin-bottom: 30px;
}