.logo {
  
  &__link {
    display: flex;
  }

  &__img-holder {
    display: flex;
    align-items: center;

    & + .logo__text-holder {
      margin-left: 11px;
    }
  }

  &__img {
    max-height: 35px;
  }

  &__text-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text {
  }

  &__sub-text {
  }
}
