.blog--small {
    background: #f6f9ff;

hr {
    border-top: 1px solid #eee;
    margin: 40px 0;
    display: table;
    width: 100%;
}
.post {
  background-color: $c-white;
  box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0);
  position: relative;
  transition: 0.2s all;
  .swiper {
    height: 100%;
    .swiper-container{
      height: 100%;
      object-fit: cover;
    }
  }
  &:hover {
    box-shadow: 0px 15px 30px 0px rgba(42, 116, 237, 0.1);
    transition: 0.2s all;
  }

  .content-wrapper {
    padding: 30px 30px 40px;
    .post-title {
      a {
        font-size: 24px;
        color: #222;
        line-height: 1.25;
        display: inline-block;
        &:hover {
          color:#2a74ed;
        }
      }
    }

    .post-date {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 30px;
    }

    .post-cats {
      a {
        background: #2a74ed;
        color:#fff;
        font-size: 13px;
        line-height: 26px;
        display: inline-block;
        margin-right: 1px;
        border-radius: 3px;
        padding: 0 10px;
        &:hover {
          background: #1954b4;
          color:#fff;
        }
      }
    }
  }

  .content-top-wrapper {
    height: 100%;
    .image-wrapper {
      height: 100%;
      background-size: cover;
      img {

        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

  }
}

.format-quote {

    text-align: center;
    background: #222222;
    position: relative;;
    box-shadow: none;
    padding: 80px 50px 40px 50px;

  &:before {
    content: '”';
    position: absolute;
    font-family: 'Times New Roman';
    font-size: 180px;
    font-weight: bold;
    top: 24px;
    right: 46px;
    color: #3f3e3e;
    line-height: 120px;
  }

  p {
      font-size: 30px;
      font-family: Playfair Display, serif;
      color: #fff;
      font-style: italic;
      line-height: 1.333;
  }

  cite {
    color: #2a74ed;
    font-size: 14px;
    line-height: 26px;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-top: 40px;
    display: block;
  }
}

.format-standard {
  .post-cats {
    padding: 30px 30px 0;
  }
}

.format-slider {
  .swiper {
    &-button-prev:before,
    &-button-next:before {
    display: none;
    }

    &-button-prev,
    &-button-prev:hover {
      color: #fff;
      left: 35px;
      font-size: 26px;
      margin-top: 10px;
    }

    &-button-next ,
    &-button-next:hover {
      color: #fff;
      right: 35px;
      font-size: 26px;
      margin-top: 10px;
    }
  }
}

.format-video {
  .video-wrapper a {
    position: relative;
    display: table;
    width: 100%;
    height: 100%;
    background-size: cover;
    &:before {
        content:"\f04b";
        font-family: Fontawesome;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 20px 30px 0px rgba(42, 116, 237, 0.1);
        position: absolute;
        width: 60px;
        height: 60px;
        z-index: 44;
        text-align: center;
        line-height: 60px;
        font-size: 13px;
        top: 50%;
        left: 0;
        right: 0;
        margin: -30px auto 0;
    }
  }
}

.format-audio {
  .audio-wrapper {
  	padding: 30px 30px 0;

  	.mejs__container {
  		background: #222;
  		min-height: 60px;
      width: 100% !important;
  		max-width: 100%;
      margin: 20% 0;

      @include large-size-max {
        margin:0;
      }
  	}

  	.mejs__horizontal-volume-slider {
  		display:none !important;
  	}

  	.mejs__controls {
  		height: 60px;
  		padding:0;
  	}

    .mejs__button.mejs__volume-button {
        height: 60px;
        margin: 0;
        width: 60px;
        border-left: 1px solid #3f3e3e;
        text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
    }

  	.mejs__button.mejs__playpause-button {
  	    height: 60px;
  		  margin: 0;
  		  width: 60px;
  		  border-right: 1px solid #3f3e3e;
  		  text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
  	}

    .mejs__time {
        color: #fff;
        font-size: 11px;
        height: 60px;
        padding: 26px 11px 0;
        font-family: Roboto;
        font-weight: 400;

        &-total {
            background: #ffffff;
            margin: 19px 0 0;
            width: 100%;
            border-radius: 0;
            height: 2px;
        }

        &-handle-content {
            border: none;
            border-radius: 0;
            height: 2px;
            width: 10px;
        }

        &-hovered {
            border-radius: 0;
            height: 2px;
        }

        &-buffering {
          border-radius: 0;
          height: 2px;
          margin-top:1px;
        }

        &-current {
            border-radius: 0;
            height: 2px;
            background: #999 !important;
            margin-top:0px;
        }

        &-loaded {
            display: none;
        }

    }
  }
}

.blog-pagination-wrapper .pagination {
  .wrap .page-numbers, .prev span {
    color: #222222;
    transition: 0.2s all;
  }
  .next {
    span {
      color: #222222;
      transition: 0.2s all;
    }
    &:hover span {
      color: #2a74ed;
    }
  }
  .prev:hover span {
    color: #2a74ed;
  }
  .wrap .page-numbers {
    &:hover, &.current {
      background: #2a74ed;
      color: #fff;
    }
  }
}

@include large-size-all {

  .content-top-wrapper .image-wrapper img {
      min-height: 330px;
  }

  .audio-wrapper .mejs__container {
      margin: 25% 0 !important;
  }
}

@include medium-size-max {

article {
  margin-bottom: 30px;
  min-height: 600px;

  .post-cats {
    margin-bottom: 10px;
  }

  &.format-quote {
    padding: 150px 50px 40px 50px;
  }
}

}

@include small-size-max {
article {
  min-height: inherit;
}
}

@include xs-size-max {
article {
  min-height: inherit;
}
}

}
