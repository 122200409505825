.aht-blog-rest {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 30px;

  @include small-size-max {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  @include xs-size-max {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  &__item {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.3s ease-out;
    background-color: $c-white;
    //box-shadow: 0px 4px 59px 0px rgba($c-black, 0.05);
    border: 1px solid #f5f5f5;

    &:hover {
      box-shadow: 0px 39px 54px 0px rgba($c-black, 0.14);
    }
  }

  &__imgs {
    width: 100%;
  }

  &__img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    
    @include small-size-max {
      height: 350px;
    }

    @include xs-size-max {
      height: 250px;
    }
  }

  &__cats {
    display: flex;
    position: absolute;
    top: 30px;
    left: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 80%;

    &--static {
      position: static;
      margin-bottom: 30px;
    }
  }

  &__cat {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 400;
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 30px 35px;
  }

  &__date {
    margin: 0;
    font-size: 13px;
  }

  &__title {
    margin: 15px 0 0;
    font-weight: 600;
    line-height: 30px;
    font-size: 24px;
    text-transform: uppercase;
  }

  &__desc {
    margin: 13px 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  &__link {
    display: inline-block;
    margin-top: auto;
    padding-top: 25px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid;
    padding-bottom: 2px;
  }

  // Video
  &__video {
    position: relative;
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    
    @include small-size-max {
      height: 350px;
    }

    @include xs-size-max {
      height: 250px;
    }
  }

  &__video-link {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    outline: none;
    background-color: $c-white;
    line-height: 60px;
    text-align: center;
  }

  &__video-play {
    color: #2a74ed;
    position: relative;
    top: 2px;
    left: 1px;
  }


  // Audio
  &__audio {
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
    .mejs__container {
      background: #222;
      min-height: 60px;
      width: 100% !important;
      max-width: 100%;
    }

    .mejs__horizontal-volume-slider {
      display:none !important;
    }

    .mejs__controls {
      height: 60px;
      padding:0;
    }

    .mejs__button.mejs__volume-button {
        height: 60px;
        margin: 0;
        width: 60px;
        border-left: 1px solid #3f3e3e;
        text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
    }

    .mejs__button.mejs__playpause-button {
        height: 60px;
        margin: 0;
        width: 60px;
        border-right: 1px solid #3f3e3e;
        text-align: center;

        button {
          margin:20px;
          transform: scale(0.8);
        }
    }

    .mejs__time {
        color: $c-white;
        font-size: 11px;
        height: 60px;
        padding: 26px 11px 0;
        font-family: $f-roboto;
        font-weight: 400;

        &-total {
            background: $c-white;
            margin: 19px 0 0;
            width: 100%;
            border-radius: 0;
            height: 2px;
        }

        &-handle-content {
            border: none;
            border-radius: 0;
            height: 2px;
            width: 10px;
        }

        &-hovered {
            border-radius: 0;
            height: 2px;
        }

        &-buffering {
          border-radius: 0;
          height: 2px;
          margin-top:1px;
        }

        &-current {
            border-radius: 0;
            height: 2px;
            background: #999 !important;
            margin-top:0px;
        }

        &-loaded {
            display: none;
        }

    }
  }

  // Quote
  &__bq-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 52px 35px;
  }

  &__bq-holder {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 40px 0 30px;
  }

  &__bq {
    color: $c-white;
    font-family: $f-playfair-display;
    font-size: 30px;
    line-height: 40px;
  }

  &__author {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.4px;
    line-height: 26px;
    text-transform: uppercase;
  }

  &--recent {
    display: flex;
    height: 100%;
    min-height: 570px;
    .aht-blog-rest__item {
      box-shadow: 0px 10px 15px 0px rgba($c-black, 0.07);

      &:hover {
        box-shadow: 0px 10px 15px 0px rgba($c-black, 0.14);
      }
    }
  }
  @include small-size-max {
    &--recent {
      min-height: 620px;
    }
  }
}
