.aht-post-trvl {

  &__tb {
    display: flex;
    align-items: center;
    height: 500px;
    text-align: center;

    &-content {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 155px 15px 15px;
    }

    &-author {
      margin-top: 45px;

      &-img {
        display: inline-block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
      }

      &-name {
        margin: 14px 0 0;
        color: $c-white;
      }
    }
  }

  &__cats {
    margin-bottom: 22px;
  }

  &__cat {
    display: inline-block;
    margin-bottom: 3px;
    padding: 4px 12px;
    border-radius: 3px;
    color: $c-white;
    font-size: 13px;
    line-height: 1;
  }

  &__title {
    margin: 0;
    color: $c-white;
  }

  &__details {
    margin-top: 17px;
  }

  &__detail {
    padding: 0 15px;
    color: $c-white;
    font-size: 15px;
  }

  &__page {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 100px 15px 120px;
  }

  &__content {

    blockquote {
      background-color: transparent;

      &:before {
        font-size: 140px;
      }

      p {
        margin: 0;
        font-size: 30px;
        line-height: 40px;
      }
    }

    p {
      margin: 23px 0;
    }

    img {
      max-width: 100%;
    }
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    @include small-size-max {
      justify-content: center;
    }
  }

  &__tags {
    margin-right: 30px;

    &-name {
      margin-right: 15px;
      font-size: 18px;
      font-weight: 700;
    }

    &-link {
      font-weight: 400;
    }

    @include small-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__likes {
    padding: 15px 21px;
    border: 1px solid #eee;
    border-radius: 5px;
    font-weight: 400;

    &-icon {
      margin-right: 7px;
      color: #EE2865;
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    padding: 50px 50px 60px 70px;
    border: 1px solid #eee;

    @include small-size-max {
      padding: 50px 30px;
    }
  }

  &__author-img-holder {
    margin-right: 40px;

    @include small-size-max {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__author-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;

    @include small-size-max {
      margin: 0 auto;
    }
  }

  &__author-about {
    flex: 1;
  }

  &__author-title {
    margin: -3px 0 0;
    font-size: 18px;
    font-weight: bold;
  }

  &__author-desc {
    margin-top: 30px;
  }

  &__author-more {
    margin: 45px 0 0;
  }

  &__author-link {
    font-weight: 400;
    text-decoration: underline;
  }

  &__socials {
    margin-top: 50px;
    text-align: center;
  }

  &__comments {
    padding-top: 93px;
    padding-bottom: 130px;
  }
}
