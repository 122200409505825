.aht-sermon {
  display: flex;
  position: relative;
  align-items: flex-end;
  min-height: 500px;
  padding: 40px 30px;
  background-position: center;
  background-size: cover;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 20%, $c-black);
    content: '';
    opacity: 0.8;
  }

  &:hover {
    .aht-sermon__icon-links {
      height: 28px;
      margin-top: 24px;
    }

    .aht-sermon__link-wrap {
      height: 18px;
      margin-top: 29px;
    }
  }

  &__content {
    position: relative;
  }

  &__cats {
    margin-bottom: 7px;
  }

  &__cat {
    color: $c-white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__title {
    margin: 0;
    color: $c-white;
    line-height: 30px;
  }

  &__icon-links {
    height: 0;
    margin-top: 0;
    transition: all 0.5s;
    overflow: hidden;
  }

  &__icon-link {
    margin-right: 15px;
    color: $c-white;
    font-size: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__icon {
    color: inherit;
  }

  &__link-wrap {
    height: 0;
    margin-top: 0;
    transition: all 0.5s;
    overflow: hidden;
  }

  .aht-sermon__link {
    color: $c-white;
    font-size: 12px;
  }
}

.sermons--church-wrap-3 {
  .aht-heading {
    &--chr {
      margin-bottom: 55px;

      .heading__subtitle {
        margin-bottom: 40px;
      }
    }
  }
}

.sermons--church-wrap {
  .aht-sermon {
    padding-bottom: 0;
    padding-top: 0;

    &__title {
      margin-bottom: 30px;
      letter-spacing: -0.5px;
      font-weight: bold;
    }

    &:hover {
      .aht-sermon__link-wrap {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .aht-sermon__link {
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
}

.sermons--church-wrap-4 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .sermons--church-item {
    margin: 0 25px 50px;
  }

  .aht-sermon {
    width: 320px;

    &__cats {
      font-weight: bold;
    }

    &:hover {
      .aht-sermon__link-wrap {
        margin-bottom: 42px;
      }
    }

    .aht-sermon__link {
      font-size: 12px;
      letter-spacing: 1.2px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .padding-sermons-chr {
    padding-top: 125px;
  }
  .margin-sermons-chr {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 768px) {
  .sermons--church-wrap-4 {
    .sermons--church-item {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 425px) {
  .sermons--church-wrap-3 {
    .aht-heading {
      &--chr {
        margin-bottom: 25px;
      }
    }
  }
}