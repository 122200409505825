.aheto-header-4 {

  &__inner {
    max-width: 1840px;
    margin: 0 auto;
  }

  &__line {
    display: flex;
    position: relative;

    @include menu-size-min {
      justify-content: space-between;
    }

    @include menu-size-max {
      flex-wrap: wrap;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    @include menu-size-min {
      margin: 0 15px;
    }

    @media screen and (min-width: 1500px) {
      margin: 0 50px;
    }

    @include menu-size-max {
      flex-basis: 100%;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    padding: 5px 15px;

    @include menu-size-min {
      flex: 1 1 0;
    }
  }

  &__hamburger {
    display: flex;
    align-items: center;

    @include menu-size-min {
      display: none;
    }
  }

  &__menu {
    -webkit-overflow-scrolling: touch;

    @include menu-size-max {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - 124px) ;
      padding: 0 15px 30px;
      background-color: $c-white;
      overflow-y: scroll;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    padding: 5px 15px;

    @include menu-size-min {
      flex: 1 1 0;
      justify-content: flex-end;
    }

    @include menu-size-max {
      margin-left: auto;
      padding: 5px 15px;
    }
  }

  &__button {
    margin-right: 15px;

    @media screen and (min-width: 1500px) {
      margin-right: 24px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__button-icon {
    font-size: 22px;
  }

  // Main menu
  .main-menu {

    @include menu-size-min {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      height: 100%;

      .menu-item {
        position: relative;
        &:hover {
          > .sub-menu {
            opacity: 1;
            visibility: visible;
          }
        }

        a {
          font-size: 15px;
          font-weight: 400;
        }
      }

      > .menu-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 27px 10px;

        > a {
          font-weight: 500;
        }
      }
    }

    @media screen and (min-width: 1500px) {
      > .menu-item {
        min-width: 110px;
        padding: 27px 5px;
      }
    }

    @include menu-size-max {

      .mega-menu__title {
          color: #999999;
          text-transform: uppercase;
          font-size: 15px;
      }

      .menu-item {
        a {
          font-size: 15px;
          font-weight: 400;
        }
      }

      > .menu-item {
        padding-top: 20px;

        > a {
          font-size: 20px;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      position: absolute;
      top: 103%;
      left: 0;
      transition: all 0.2s;
      background-color: $c-white;
      opacity: 0;
      visibility: hidden;

      .sub-menu {
        top: 0;
        left: 100%;
      }

      .menu-item {
        min-width: 230px;
        padding: 12px 30px;
        border-bottom: 1px solid rgba($c-black, 0.03);

        &:first-child {
          padding-top: 30px;
        }

        &:last-child {
          padding-bottom: 30px;
          border-bottom: 0;
        }
      }

      .dropdown-btn {
        float: right;

        &:before {
          content: '\f10a';
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        padding: 15px 20px 0;
      }
    }
  }

  // Menu item
  .mega-menu {
    @include menu-size-min {
      display: flex;

      &__col {
        padding: 30px;
        border-right: 1px solid rgba($c-black, 0.03);
        background-color: $c-white;

        &:last-child {
          border-right: 0;
        }
      }

      &__title {
        text-transform: uppercase;
        font-size: 15px;
      }

      .menu-item {
        padding: 12px 0;
      }
    }

    @include menu-size-max {
      &__title {
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.15);
        font-size: 20px;
        font-weight: 700;
      }

      &__col {
        padding: 20px 20px 0;
      }

      .menu-item {
        padding: 15px 0 0;
      }
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    margin-left: 8px;
    font-size: 8px;

    @include menu-size-max {
      display: none;
    }
  }

  // Logo
  .logo {

    @include large-size-max {
      margin: 0 auto;
    }

    &__img {
      max-height: 50px;
    }

    &__text {
      font-family: 'Caveat';
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 2px;
    }

    &__sub-text {
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 4.5px;
      text-transform: uppercase;
    }

    &__link {
      padding: 5px 38px 15px;

      @include menu-size-max {
        padding: 5px 15px;
      }
    }
  }

  // Socials
  .aht-socials {
    display: flex;
    flex-wrap: wrap;

    &__link {
      display: inline-block;
      margin-right: 15px;

      @media screen and (min-width: 1500px) {
        margin-right: 30px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__icon {
      font-size: 22px;

      // @include menu-size-max {
      //   color: $c-active;
      // }
    }
  }
}
